import { useLazyQuery } from '@apollo/client';
import { PROPOSAL_AGGREGATES } from 'graphql/proposals/aggregates';
import { proposalAggregates } from 'graphql/proposals/types/proposalAggregates';
import { useCallback, useEffect, useRef } from 'react';

export interface Total {
  year: number;
}

export const useAggregatesTotal = ({ year }: Total) => {
  const [Total, { called, data, loading, error, refetch }] = useLazyQuery<proposalAggregates>(
    PROPOSAL_AGGREGATES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadTotal = useCallback(
    (year: number) => {
      const variables = {
        filter: {
          createdAtFrom: new Date(year, 0, 1),
          createdAtTo: new Date(year, 11, 31, 23, 59, 59, 999),
          entityType: 'PROPOSAL',
        },
      };

      if (called) {
        refetch!(variables);
      } else {
        Total({ variables });
      }
    },
    [Total, called, refetch]
  );

  const loadTotalRef = useRef(loadTotal);

  useEffect(() => {
    loadTotalRef.current && loadTotalRef.current(year);
  }, [year, loadTotalRef]);

  return {
    loadTotal,
    data,
    loading,
    error,
  };
};

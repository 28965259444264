import { changeProposalSuccessChanceName } from 'utils/formats';
import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';
import s from './style.module.scss';
import { Box, Grid } from '@mui/material';
import { currencyFormatUS } from 'utils/currencyFormat';

export const AmountBySuccessChance = () => {
  const { aggregatesBySuccessChance, aggregatesTotal } = useProposalAggregatesContext();

  return (
    <Grid container spacing={0}>
      <Grid item className={s.head} xs={6}>
        Chance of Success
      </Grid>
      <Grid item className={s.resultHead} xs={6}>
        Proposal Amount
      </Grid>
      {aggregatesBySuccessChance?.proposals_monetaryBreakdownAggregates
        .filter((x) => x.valueSum)
        .map((row) => {
          return (
            <>
              <Grid item className={s.keyColumn} xs={6}>
                {changeProposalSuccessChanceName(row.successChance || '--')}
              </Grid>
              <Grid item className={s.resultColumn} xs={6}>
                $ {currencyFormatUS(row.valueSum || 0)}
              </Grid>
            </>
          );
        })}
      <Grid item xs={12}>
        <Box mt={2} />
      </Grid>
      <Grid item xs={4} className={s.finalKey}>
        Total
      </Grid>
      <Grid item xs={8} className={s.finalResult}>
        ${' '}
        {currencyFormatUS(aggregatesTotal?.proposals_monetaryBreakdownAggregates[0].valueSum || 0)}
      </Grid>
    </Grid>
  );
};

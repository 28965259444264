import { IconButton, TextField, Tooltip, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { makeStyles } from '@mui/styles';
import React, { FC, useMemo } from 'react';
import { Delete } from '@mui/icons-material';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

import { CurrencyInputField } from '../../CurrencyInputField/CurrencyInputField';

export interface IMonetaryTransaction {
  startTimestamp: any;
  monetaryTransactionValue: string;
}

export enum MonetaryTransactionType {
  COST = 'COST',
  REVENUE = 'REVENUE',
}

export interface IMonetaryTransactionsProps {
  monetaryTransactionBreakdowns: IMonetaryTransaction[];
  onRemoveMonetaryTransaction: (index: number) => void;
  onChangeMonetaryTransaction: (index: number, transaction: IMonetaryTransaction) => void;
  onAddMonetaryTransaction: () => void;
  monetaryTransactionType?: MonetaryTransactionType;
  showValidator: boolean;
  fromStage?: string;
  toStage?: string;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

const finalStage = ['CANCELED', 'WON', 'LOST'];

export const MonetaryTransactions: FC<IMonetaryTransactionsProps> = ({
  monetaryTransactionBreakdowns,
  onRemoveMonetaryTransaction,
  onChangeMonetaryTransaction,
  onAddMonetaryTransaction,
  monetaryTransactionType,
  showValidator,
  fromStage,
  toStage,
}) => {
  const classes = useStyles();
  let index = -1;

  const updateValue =
    (index: number, startTimestamp: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMonetaryTransaction!(index, {
        monetaryTransactionValue: e.target.value,
        startTimestamp,
      });
    };

  const updateTimestamp =
    (index: number, monetaryTransactionValue: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (/^(\d){0,5}$/.test(e.target.value)) {
        onChangeMonetaryTransaction!(index, {
          monetaryTransactionValue,
          startTimestamp: e.target.value,
        });
      }
    };

  const yearFieldValidation = useMemo(() => {
    return monetaryTransactionBreakdowns?.map((monetaryTransaction) => {
      const year = monetaryTransaction.startTimestamp;
      return (
        year > 3000 ||
        (year < new Date().getFullYear() && (!toStage || !finalStage.includes(toStage)))
      );
    });
  }, [monetaryTransactionBreakdowns, toStage]);

  const valueFieldValidation = useMemo(() => {
    return monetaryTransactionBreakdowns?.map((monetaryTransaction) => {
      const value = monetaryTransaction.monetaryTransactionValue;
      return !value || parseFloat(value) <= 0;
    });
  }, [monetaryTransactionBreakdowns]);

  const remove = (index: number) => () => {
    onRemoveMonetaryTransaction!(index);
  };

  return (
    <Grid container padding={0} spacing={2} style={{ width: '100%' }}>
      {monetaryTransactionBreakdowns?.map((monetaryTransaction) => {
        const { monetaryTransactionValue, startTimestamp } = monetaryTransaction;
        index++;
        return (
          <Grid xs={12} key={index}>
            <Grid container padding={0} spacing={2}>
              <Grid xs={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <div>
                  <Typography variant="body2" className="label-title-nocase">
                    Year:
                  </Typography>
                </div>
                <div style={{ width: '90px', paddingLeft: '10px', paddingRight: '10px' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={startTimestamp}
                    onChange={updateTimestamp(index, monetaryTransactionValue)}
                    error={showValidator && yearFieldValidation[index]}
                  />
                </div>
              </Grid>

              <Grid xs={6}>
                <CurrencyInputField
                  size="small"
                  value={parseFloat(monetaryTransactionValue)}
                  onChange={updateValue(index, startTimestamp)}
                  fullWidth
                  error={showValidator && valueFieldValidation[index]}
                />
              </Grid>
              <Grid xs={1}>
                <Tooltip title="Delete Year">
                  <IconButton
                    size="small"
                    onClick={debounce(remove(index), DEBOUNCE_TIMEOUT)}
                    className={classes.hoverWarning}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid xs={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <div style={{ paddingRight: '10px' }}>
          <Typography variant="body2" className="label-title">
            <Button
              variant="contained"
              color="primary"
              onClick={onAddMonetaryTransaction}
              disabled={false}
            >
              Add year
            </Button>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

import { gql } from '@apollo/client';

// MIC - market identifier code
// acronym
export const IStockExchange = gql`
  fragment IStockExchange on StockExchange {
    id
    name
    mic
    acronym
  }
`;

export const GET_STOCK_EXCHANGES = gql`
  query stockExchanges($filter: StockExchangeFilter, $take: Int, $skip: Int = 0) {
    stockExchanges(filter: $filter, take: $take, skip: $skip) {
      ...IStockExchange
    }
  }
  ${IStockExchange}
`;

import { useQuery } from '@apollo/client';
import { GET_WORK_TYPES } from 'graphql/proposals/options';
import { useCallback, useMemo } from 'react';
import { workTypes } from 'graphql/proposals/types/workTypes';

export const useWorkTypes = () => {
  const { data } = useQuery<workTypes>(GET_WORK_TYPES);

  const getPairs = useCallback(() => {
    return (
      data?.workTypes
        .map(({ id, name }) => {
          return {
            id,
            name,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    );
  }, [data]);

  const pairs = useMemo(() => {
    return getPairs();
  }, [getPairs]);

  return { pairs };
};

import { FC, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DataSource } from 'template/Charting/context/DataSourceConfig';
import {
  aggregateFunctionHash,
  conversionFunctionHash,
  sortFunctionHash,
} from '../SelectDataSource/constants';

export interface IChartingTableProps {
  headings: any;
  csvData: any[];
  columns: DataSource[];
}

const getColumnDesc = (column: any) => {
  const { sortFunctionId, conversionFunctionId, aggregateFunctionId } = column;
  const codes = [
    conversionFunctionId ? conversionFunctionHash[conversionFunctionId].short : undefined,
    aggregateFunctionId ? aggregateFunctionHash[aggregateFunctionId].short : undefined,
    sortFunctionId ? sortFunctionHash[sortFunctionId].short : undefined,
  ].filter((code) => code);
  return codes.filter((code) => !!code).join(', ');
};

export const ChartingTable: FC<IChartingTableProps> = ({ headings, csvData, columns }) => {
  const remappedColumns = useMemo(() => {
    return columns.map((column, index) => ({ ...column, fieldKey: `${index}_${column.fieldKey}` }));
  }, [columns]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {remappedColumns.map((column) => {
              const desc = getColumnDesc(column);
              return (
                <TableCell>
                  {column.fieldName} {desc ? `(${desc})` : ''}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {csvData.map((row, rowIndex) => (
            <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {remappedColumns.map((column) => (
                <TableCell align="right">{row[column.fieldKey]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

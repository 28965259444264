export const isNumeric = (val: any) => {
  if (typeof val === 'number') {
    return true;
  }
  if (typeof val === 'string' && !!val && !isNaN(+val)) {
    return true;
  }
};

export const isDate = (date: any) => {
  return new Date(date).toString() !== 'Invalid Date' && !isNumeric(date);
};

export const countDecimals = function (n: number) {
  if (Math.floor(n.valueOf()) === n.valueOf()) return 0;

  var str = n.toString();
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1].length || 0;
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return str.split('-')[1].length || 0;
};

import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import React, { FC } from 'react';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import shadowIcon from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

export interface ICoords {
  latitude: number;
  longitude: number;
}

export interface IGeoLocationProps {
  markers?: { coords: ICoords; displayName: any; className: string }[];
  coords?: ICoords;
}

export const GeoLocation: FC<IGeoLocationProps> = ({ coords, markers }) => {
  const customIcon = (className?: string) =>
    new L.Icon({
      //creating a custom icon to use in Marker
      iconUrl: icon,
      iconSize: [25, 35],
      iconAnchor: [5, 30],
      shadowUrl: shadowIcon,
      className,
    });

  function MapView() {
    let map = useMap();
    if (coords) {
      const { latitude, longitude } = coords;
      //Sets geographical center and zoom for the view of the map
      map.setView([latitude, longitude], map.getZoom());
    }
    return null;
  }

  return (
    <div style={{ height: '80vh' }}>
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={[0, 0]}
        zoom={2}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers?.map((marker) => {
          const {
            displayName,
            coords: { latitude, longitude },
          } = marker;
          return (
            <Marker icon={customIcon(marker.className)} position={[latitude, longitude]}>
              <Popup>
                <div style={{ minWidth: '200px' }}>{displayName}</div>
              </Popup>
            </Marker>
          );
        })}
        <MapView />
      </MapContainer>
    </div>
  );
};

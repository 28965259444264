import React, { createContext, FC, useContext, useMemo } from 'react';

import { useAggregatesTotal } from './hooks/aggregatesTotalHook';
import { useAggregatesBySuccessChance } from './hooks/aggregatesBySuccessChanceHook';
import { monetaryBreakdownAggregates } from 'graphql/proposals/types/monetaryBreakdownAggregates';
import { useAggregatesByDivisionAndSuccessChance } from './hooks/aggregatesByDivisionAndSuccessChanceHook';
import { useAggregatesByDivision } from './hooks/aggregatesByDivision';

export interface IProposalAggregatesContextState {
  aggregatesByDivisionAndSuccessChance?: monetaryBreakdownAggregates;
  aggregatesBySuccessChance?: monetaryBreakdownAggregates;
  aggregatesByDivision?: monetaryBreakdownAggregates;
  aggregatesTotal?: monetaryBreakdownAggregates;
  year?: number;
}

export interface IProposalAggregatesContextActions {}

const initialState: IProposalAggregatesContextState = {};

const ProposalAggregatesContext = createContext<
  IProposalAggregatesContextState & Partial<IProposalAggregatesContextActions>
>(initialState);

interface IProposalAggregatesProviderProps {
  children: any;
  year: number;
}

export const ProposalAggregatesProvider: FC<IProposalAggregatesProviderProps> = ({
  children,
  year,
}) => {
  const { data: aggregatesByDivisionAndSuccessChance } = useAggregatesByDivisionAndSuccessChance({
    year,
  });
  const { data: aggregatesBySuccessChance } = useAggregatesBySuccessChance({ year });
  const { data: aggregatesByDivision } = useAggregatesByDivision({ year });
  const { data: aggregatesTotal } = useAggregatesTotal({ year });

  const values = useMemo(
    () => ({
      aggregatesByDivisionAndSuccessChance,
      aggregatesBySuccessChance,
      aggregatesByDivision,
      aggregatesTotal,
      year,
    }),
    [
      aggregatesByDivisionAndSuccessChance,
      aggregatesBySuccessChance,
      aggregatesByDivision,
      aggregatesTotal,
      year,
    ]
  );
  return (
    <ProposalAggregatesContext.Provider value={values}>
      {children}
    </ProposalAggregatesContext.Provider>
  );
};

export const useProposalAggregatesContext = () => useContext(ProposalAggregatesContext);

import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { ProposalHeader } from '../ProposalHeader/ProposalHeader';
import { ProposalMainTabs } from '../ProposalMainTabs/ProposalMainTabs';
import { useComponentContext } from 'template/Opportunity/ProposalContext';

export interface IProposalFrameProps {
  children: any;
}

export const ProposalFrame: FC<IProposalFrameProps> = ({ children }) => {
  const { activeTab, changeActiveTab } = useComponentContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <Box m={4} />
      <div>
        <ProposalHeader></ProposalHeader>
      </div>
      <Box m={3} />
      <div style={{ width: 'fit-content' }}>
        {activeTab ? (
          <ProposalMainTabs
            changeView={(newTab) => {
              changeActiveTab(newTab);
            }}
            currentView={activeTab}
          ></ProposalMainTabs>
        ) : undefined}
      </div>
      <Paper elevation={3} style={{ padding: '2rem', borderTopLeftRadius: '0px' }} id="main-paper">
        {children}
      </Paper>
    </Container>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { FC, useState, useEffect, useCallback } from 'react';

import { useMutation, useApolloClient } from '@apollo/client';
import { Typography, Button, Box, Divider, LinearProgress } from '@mui/material';

import LoadingOverlay from 'react-loading-overlay-ts';

import { DocumentApprovalSectionProps } from './interfaces';
import './index.scss';

import { AlertDialog } from 'components';

import { id } from 'utils/id';
import { errorMessage } from 'utils/errors';

import { useComponentContext } from 'template/Proposal/ProposalContext';
import {
  PROPOSAL_REQUEST_REVIEW,
  PROPOSAL_REVIEW_APPROVE,
  PROPOSAL_REVIEW_DISAPPROVE,
} from 'graphql/proposals/proposals';
import DocumentApprovalUploadSection from '../DocumentApprovalUploadSection';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const DocumentApprovalSection: FC<DocumentApprovalSectionProps> = ({
  proposal,
  account,
  refetchProposal,
  clientProposalDocuments,
}: DocumentApprovalSectionProps) => {
  const [comment, setComment] = useState('');
  const [nextReviewers, setNextReviewers] = useState<any>([]);

  const [documentApprovalMessage, setDocumentApprovalMessage] = useState('');
  const [approved, setApproved] = useState(false);
  const [notApproved, setNotApproved] = useState(false);
  const [postponed, setPostponed] = useState(false);
  const [approvedBtn, setApprovedBtn] = useState(false);
  const [notApprovedBtn, setNotApprovedBtn] = useState(false);
  const [postponedBtn, setPostponedBtn] = useState(false);

  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const { newDocumentStatus, setNewDocumentStatus } = useComponentContext();
  const [proposalDocumentIds, setSelectedProposalDocumentIds] = useState<string[] | undefined>();

  const [
    proposalReviewApprove,
    { error: documentReviewApproveError, loading: loadingDocumentReviewApprove },
  ] = useMutation(PROPOSAL_REVIEW_APPROVE);
  const [
    proposalReviewDisapprove,
    { error: documentReviewDisapproveError, loading: loadingDocumentReviewDisapprove },
  ] = useMutation(PROPOSAL_REVIEW_DISAPPROVE);

  const apolloClient = useApolloClient();

  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const [assignNextReviewerAlertOpen, setAssignNextReviewerAlertOpen] = useState(false);

  const disableButtons = (val: boolean): void => {
    setApprovedBtn(val);
    setNotApprovedBtn(val);
    setPostponedBtn(val);
  };

  const handleFiles = (data: any) => {
    setFiles(data);
  };

  const documentStatusHandler = useCallback((): void => {
    if (proposal?.canReview) {
      disableButtons(false);
    } else {
      disableButtons(true);
    }
  }, [proposal?.canReview]);

  const userIncludedInDocument = useCallback((): void => {
    const user = proposal?.reviewers.find(
      (documentUser) =>
        documentUser?.employeeEmail === account?.idToken?.email ||
        documentUser?.employeeEmail === account?.idToken?.preferred_username
    );

    if (!user) {
      disableButtons(true);
    }
  }, [account?.idToken?.email, account?.idToken?.preferred_username, proposal?.reviewers]);

  useEffect(() => {
    documentStatusHandler();
    userIncludedInDocument();
  }, [account, documentStatusHandler, userIncludedInDocument]);

  const handleApprove = useCallback(() => {
    setApproved(true);
    setNotApproved(false);
    setNotApprovedBtn(true);
    setPostponedBtn(true);
    setAssignNextReviewerAlertOpen(true);
    setNextReviewers([]);

    newDocumentStatus.approveDeclineClicked = true;
    setNewDocumentStatus(newDocumentStatus);
  }, [newDocumentStatus, setNewDocumentStatus]);

  const handleNotApproved = useCallback(() => {
    setNotApproved(true);
    setApproved(false);
    setApprovedBtn(true);
    setPostponedBtn(true);
    setAssignNextReviewerAlertOpen(true);
    setNextReviewers([]);

    newDocumentStatus.approveDeclineClicked = true;
    setNewDocumentStatus(newDocumentStatus);
  }, [newDocumentStatus, setNewDocumentStatus]);

  const [selectNextReviewer, { loading: loadingSelectedReviewer, error: errorSelectedReviewer }] =
    useMutation(PROPOSAL_REQUEST_REVIEW);

  const handleDocumentApproval = async (confirm: boolean): Promise<any> => {
    newDocumentStatus.approveDeclineClicked = false;
    setNewDocumentStatus(newDocumentStatus);

    setAssignNextReviewerAlertOpen(false);

    if (confirm) {
      let documentMutation;
      disableButtons(true);

      const variables = {
        variables: { comment, proposalId: proposal?.id, proposalDocumentIds },
      };

      if (approved) documentMutation = proposalReviewApprove(variables);
      if (notApproved) documentMutation = proposalReviewDisapprove(variables);

      if (documentMutation) {
        documentMutation
          .then((): void => {
            // Set next Reviewers (if any)

            if (nextReviewers && nextReviewers.length > 0) {
              for (const selectedReviewer of nextReviewers) {
                selectNextReviewer({
                  variables: {
                    userId: selectedReviewer?.employeeId,
                    proposalId: proposal.id,
                  },
                })
                  .then(() => {
                    refetchProposal({ variables: { id: id() } });
                  })
                  .catch((e) => {
                    setAlertOpen(true);
                    setAlertMessage(e?.message);
                  });
              }
            }

            refetchProposal({ variables: { id: id() } });
          })
          .catch((e) => {
            setAlertOpen(true);
            setAlertMessage(e?.message);
          });
      }
    } else {
      disableButtons(false);
    }

    setApproved(false);
    setNotApproved(false);
    setPostponed(false);
  };

  const handleDocumentDecline = (): void => {
    setFiles([]);

    disableButtons(false);
    setApproved(false);
    setNotApproved(false);
    setPostponed(false);

    newDocumentStatus.approveDeclineClicked = false;
    setNewDocumentStatus(newDocumentStatus);
  };

  return (
    <div className="document-section">
      <AlertDialog
        title="Error"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <LoadingOverlay active={uploadingLoading} spinner text="Please wait, upload in progress...">
        {proposal && proposal.canReview && (
          <>
            <Typography variant="body2" className="label-title">
              Approval
            </Typography>
            <p>{documentApprovalMessage}</p>
            <br></br>
            <div>
              <Button
                className="document-approval-section-btn btn-responsive"
                variant="contained"
                color="primary"
                onClick={debounce(handleApprove, DEBOUNCE_TIMEOUT)}
                disabled={approved || notApproved}
              >
                Approve
              </Button>
              <Button
                className="document-approval-section-btn btn-responsive"
                variant="contained"
                color="secondary"
                onClick={debounce(handleNotApproved, DEBOUNCE_TIMEOUT)}
                disabled={approved || notApproved}
              >
                Revision Needed
              </Button>
            </div>

            <Box marginTop={5}>
              {' '}
              {errorMessage(documentReviewApproveError)}
              {errorMessage(documentReviewDisapproveError)}
            </Box>

            <Box marginTop={5}>
              {loadingDocumentReviewApprove && <LinearProgress />}
              {loadingDocumentReviewDisapprove && <LinearProgress />}
              <Divider />
            </Box>

            <DocumentApprovalUploadSection
              open={assignNextReviewerAlertOpen}
              forApproval={approved}
              title="Document Approved"
              isDocumentDecline={false}
              handleDocumentApproval={handleDocumentApproval}
              handleFiles={handleFiles}
              files={files}
              comment={comment}
              handleDocumentDecline={handleDocumentDecline}
              onChangeComment={(event: any): void => {
                setComment(event?.target?.value);
              }}
              onChangeReviewers={(reviewers: any): void => {
                setNextReviewers(reviewers);
              }}
              account={account}
              proposal={proposal}
              refetchProposal={refetchProposal}
              disableTitlebar={false}
              clientProposalDocuments={clientProposalDocuments}
              updateSelectedProposalDocumentIds={setSelectedProposalDocumentIds}
            />
          </>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default DocumentApprovalSection;

import { useQuery } from '@apollo/client';
import { GET_PARTIES_DIVISIONS } from 'graphql/proposals/divisions';
import { partiesDivisionsList } from 'graphql/proposals/types/partiesDivisionsList';
import { useCallback, useMemo } from 'react';

export const usePartyDivisions = () => {
  const { data, loading } = useQuery<partiesDivisionsList>(GET_PARTIES_DIVISIONS);

  const getDivisionsPairs = useCallback(() => {
    return (
      data?.divisions.map(({ id, name }) => {
        return {
          id,
          name,
        };
      }) || []
    );
  }, [data]);

  const divisionPairs = useMemo(() => {
    return getDivisionsPairs();
  }, [getDivisionsPairs]);

  return { data, divisionPairs, loading };
};

import { useMemo } from 'react';
import { IProposalData } from 'template/Proposal/ProposalContext';
import { IFieldsDescription } from '../interface';
import { FieldType } from '../components/ColumnsTemplate/TwoColumnTemplate';

export const useBasicDetailsFields = (
  proposalData?: IProposalData
): { fields: Array<Array<IFieldsDescription>> } => {
  const otherFields = useMemo(() => {
    const { selectedClient, autoClientDivisionOwnership, autoClientFocalPoint } =
      proposalData || {};

    return [
      [
        {
          id: 'id',
          title: 'Business Proposal ID',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
      ],
      [
        {
          id: 'selectedType',
          title: 'Type',
          showColapsed: true,
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select a proposal type' },
          },
          group: 2,
        },
      ],
      [
        {
          id: 'name',
          title: 'Proposal Name',
          showColapsed: true,
          field: {
            type: 'textareaAutosize' as FieldType,
            props: {
              minRows: 1,
              maxLength: 100,
              placeholder: 'Input proposal name...',
              overrideStyle: {
                resize: 'vertical',
                minWidth: 'unset',
              },
            },
          },
          group: 1,
        },
        {
          ...{
            id: 'selectedClient',
            title: 'Client Name',
            showColapsed: true,
            field: {
              type: 'easyAutoComplete' as FieldType,
              props: { placeholder: 'Select a Client' },
            },
            group: 1,
          },
        },
      ],
      [
        {
          id: 'scopeOfWorkDescription',
          title: 'Scope of Work (description)',
          showColapsed: true,
          field: {
            type: 'textareaAutosize' as FieldType,
            props: { placeholder: 'Input the scope of work...' },
          },
          group: 2,
        },
      ],

      [
        {
          id: 'selectedClientContact',
          title: 'Contact on Client side',
          field: {
            type: (!!selectedClient ? 'easyAutoComplete' : 'label') as FieldType,
            props: {
              multiple: true,
              note: !!selectedClient ? undefined : 'select a Client Name first',
              placeholder: 'Select client contacts',
            },
          },
        },
      ],
      [
        {
          id: 'businessDecisions',
          title: 'Business Decisions to Consider',
          field: {
            type: 'textareaAutosize' as FieldType,
            props: { placeholder: 'Input business decisions...' },
          },
        },
      ],

      [
        {
          id: 'autoClientDivisionOwnership',
          title: 'D&M Client Division Ownership',
          showColapsed: true,
          field: {
            type: 'label' as FieldType,
            props: {
              note:
                !!selectedClient && !autoClientDivisionOwnership
                  ? 'go to Client link to populate the field'
                  : undefined,
            },
          },
          parentField: 'selectedClient',
        },
      ],
      [
        {
          id: 'selectedSuccessChance',
          title: 'Chances of Success',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select the chance of success' },
          },
          group: 1,
        },
      ],

      [
        {
          id: 'autoClientFocalPoint',
          title: 'D&M Client Focal Point',
          showColapsed: true,
          field: {
            type: 'label' as FieldType,
            props: {
              note:
                !!selectedClient && !autoClientFocalPoint
                  ? 'go to Client link to populate the field'
                  : undefined,
            },
          },
          parentField: 'selectedClient',
        },
      ],
      [
        {
          id: 'receiveDate',
          title: 'Proposal Received Date',
          field: { type: 'date' as FieldType, props: { placeholder: 'Select a date' } },
        },
      ],

      [
        {
          id: 'selectedDivisionOpportunityOwner',
          title: 'Assigned Division',
          showColapsed: true,
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select D&M division' },
          },
          group: 1,
        },
      ],
      [
        {
          id: 'dueDate',
          title: 'Proposal Due Date',
          field: { type: 'date' as FieldType, props: { placeholder: 'Select a date' } },
        },
      ],

      [
        {
          id: 'selectedDivisionsParticipating',
          title: 'D&M Divisions Participating',
          showColapsed: true,
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: {
              multiple: true,
              placeholder: 'Select D&M divisions',
            },
          },
        },
      ],
      [
        {
          id: 'selectedSubmissionMethods',
          title: 'Submission Method',
          showColapsed: true,
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: {
              multiple: true,
              placeholder: 'Select a submission method',
            },
          },
          group: 2,
        },
      ],

      [
        {
          id: 'selectedLead',
          title: 'D&M Proposal Lead',
          showColapsed: true,
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select D&M user' },
          },
          group: 2,
        },
      ],
      [
        {
          id: 'expectedAwardDate',
          title: 'Expected Award Date',
          field: { type: 'date' as FieldType, props: { placeholder: 'Select a date' } },
          group: 1,
        },
      ],

      [
        {
          id: 'selectedIndividualsInvolved',
          title: 'D&M Individuals Involved in Proposal',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: {
              multiple: true,
              placeholder: 'Select D&M Individuals Involved in Proposal',
            },
          },
        },
      ],
      [
        {
          id: 'expectedProjectStartDate',
          title: 'Expected Project Start Date',
          field: { type: 'date' as FieldType, props: { placeholder: 'Select a date' } },
        },
      ],

      [{ id: '', title: '' }],
      [
        {
          id: 'expectedProjectEndDate',
          title: 'Expected Project End Date',
          field: { type: 'date' as FieldType, props: { placeholder: 'Select a date' } },
        },
      ],
    ];
  }, [proposalData]);

  return {
    fields: otherFields,
  };
};

import { ProposalReviewStatus } from 'graphql/proposals/types/graphql-types';
import { IGeneralListItem } from 'template/Proposal/ProposalContext';

export const proposalReviewStatusOptions: IGeneralListItem<ProposalReviewStatus>[] = [
  { id: ProposalReviewStatus.IDLE, name: 'Idle' },
  { id: ProposalReviewStatus.PENDING, name: 'Pending' },
  { id: ProposalReviewStatus.APPROVED, name: 'Approved' },
  { id: ProposalReviewStatus.DISAPPROVED, name: 'Disapproved' },
];

export const changeProposalReviewStatusName = (name: string): string => {
  return (
    proposalReviewStatusOptions.find((option) => option.id.toLowerCase() === name.toLowerCase())
      ?.name || name
  );
};

import { FC, useEffect, useState } from 'react';

import { PartyForm } from 'template/Party/components/PartyForm/PartyForm';
import { Box, Button, Container, Grid } from '@mui/material';

import { useComponentContext } from 'template/Party/PartyContext';
import { AlertDialog, ConfirmationDialog } from 'components';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IPartyContent {
  onClose: () => void;
}

export const PartyContent: FC<IPartyContent> = ({ onClose }) => {
  const { party, onSubmitPopupProcess, onSubmitValidate, validationSummary } =
    useComponentContext();
  const { showValidator } = party;

  const [partyDialogOpen, setPartyDialogOpen] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<any>();

  useEffect(() => {
    if (submited && showValidator && !!validationSummary?.length) {
      setAlertMessage(
        <div>
          Please make sure following fields are filled in correctly:
          <ul>
            {validationSummary.map((err) => (
              <li>{Array.isArray(err) ? err.join('. ') : err}</li>
            ))}
          </ul>
        </div>
      );
      setAlertOpen(() => true);
    }
  }, [showValidator, validationSummary, submited]);

  return (
    <div style={{ overflowY: 'scroll', height: 'calc(100vh - 200px)' }}>
      <Container maxWidth="md">
        <AlertDialog
          title="Form validation failed"
          message={alertMessage}
          open={alertOpen}
          onClose={() => {
            setSubmited(false);
            setAlertOpen(() => false);
          }}
        />
        <ConfirmationDialog
          title="Client update"
          message={'Please confirm Client update'}
          open={partyDialogOpen}
          onClose={async (confirm: boolean) => {
            if (confirm) {
              if (await onSubmitPopupProcess!()) {
                onClose();
              }
            }
            setPartyDialogOpen(() => false);
          }}
        />
        <PartyForm></PartyForm>
        <Box mt={5} />
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={debounce(onClose, DEBOUNCE_TIMEOUT)}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={debounce(() => {
                if (onSubmitValidate!()) {
                  setPartyDialogOpen(true);
                } else {
                  setSubmited(true);
                }
              }, DEBOUNCE_TIMEOUT)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

import { EasyAutoComplete } from 'components';
import { GET_CURRENCIES } from 'graphql/proposals/proposals';
import { useAutoCompleteSimpleDataSource } from 'hooks/autoCompleteSimpleDataSource';
import { FC, useMemo } from 'react';
import { currencies, currencies_currencies } from 'graphql/proposals/types/currencies';
import { useCurrencies } from 'hooks/currencyHook';

export interface ISelectCurrencyProps {
  currency: any | null;
  onSelectChange: any;
  error?: boolean;
  disabled?: boolean;
}

export const SelectCurrency: FC<ISelectCurrencyProps> = ({
  currency,
  onSelectChange,
  error,
  disabled,
}) => {
  const { currencyPairs } = useCurrencies();

  const selectedCurrency = useMemo(() => {
    const found = currencyPairs.find((pair) => pair.id === currency);
    if (found) {
      return found;
    }
  }, [currencyPairs, currency]);

  const currenciesQuery = useMemo(() => {
    return {
      GET_QUERY: GET_CURRENCIES,
      getItemId: (item: currencies_currencies) => item.abbreviation,
      getItems: (data: currencies) =>
        [...data.currencies].map((item) => ({
          id: item.abbreviation,
          name: item.description,
          ddLabel: `${item.description}${item.abbreviation ? ' (' + item.abbreviation + ')' : ''}`,
        })),
    };
  }, []);

  const currencysProps = useAutoCompleteSimpleDataSource<any, any, any>(currenciesQuery);

  return (
    <EasyAutoComplete
      selected={selectedCurrency ?? null}
      key={`currencies`}
      label=""
      optionsLabel="ddLabel"
      textFieldStyle="outlined"
      {...currencysProps}
      selectedChanged={(e: any) => {
        onSelectChange(e.id);
      }}
      getSelectedLabel={(option: any) => {
        return option.ddLabel;
      }}
      error={error}
      disabled={disabled}
    />
  );
};

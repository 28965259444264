import { useLazyQuery } from '@apollo/client';
import { PROPOSAL_MONETARY_BREAKDOWN_AGGREGATES } from 'graphql/proposals/aggregates';
import { monetaryBreakdownAggregates } from 'graphql/proposals/types/monetaryBreakdownAggregates';
import { useCallback, useEffect, useRef } from 'react';

export interface AggregatesByDivision {
  year: number;
}

export const useAggregatesByDivision = ({ year }: AggregatesByDivision) => {
  const [AggregatesByDivision, { called, data, loading, error, refetch }] =
    useLazyQuery<monetaryBreakdownAggregates>(PROPOSAL_MONETARY_BREAKDOWN_AGGREGATES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadAggregatesByDivision = useCallback(
    (year: number) => {
      const variables = {
        filter: {
          yearFrom: year,
          yearTo: year,
          // entityType: 'PROPOSAL',
          stages: ['DRAFT', 'PENDING'],
        },
        group: {
          owningDivision: true,
        },
        sort: [
          {
            column: 'OWNING_DIVISION_NAME',
            order: 'ASC',
          },
        ],
      };

      if (called) {
        refetch!(variables);
      } else {
        AggregatesByDivision({ variables });
      }
    },
    [AggregatesByDivision, called, refetch]
  );

  const loadAggregatesByDivisionRef = useRef(loadAggregatesByDivision);

  useEffect(() => {
    loadAggregatesByDivisionRef.current && loadAggregatesByDivisionRef.current(year);
  }, [year, loadAggregatesByDivisionRef]);

  return {
    loadAggregatesByDivision,
    data,
    loading,
    error,
  };
};

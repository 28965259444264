/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getMsal1Account } from 'authorization/auth-utils-msal2';
import { getTokenFromLocalStorage } from '../utils/user';

export const resolvers = {
  Query: {
    account: () => {
      return getMsal1Account();
    },
    token: () => {
      return getTokenFromLocalStorage();
    },
  },
};

import { NAME_LENGTH_LIMIT } from './config';

export const validators = {
  simpleText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1,
    },
  },
  required: {
    presence: true,
    // length: {
    //   minimum: 1,
    //   message: "can't be blank",
    // },
  },
  requiredAndNotEmpty: {
    presence: true,
    length: {
      minimum: 1,
      message: "can't be blank",
    },
  },

  dateRequired: {
    presence: true,
  },

  positiveCurrency: {
    // presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      noStrings: true,
      greaterThan: 0,
      notGreaterThan: 'must be greater than $0',
    },
    presence: { allowEmpty: false, message: 'is required' },
  },
  nameText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1,
      maximum: NAME_LENGTH_LIMIT,
    },
  },
};

import { AZURE_AUTHORITY, AZURE_CLIENT_ID, POST_LOGOUT_REDIRECT_URI } from 'constants/config';
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';

export const isIE = (): boolean => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  return msie || msie11;
};

export const msalScopes: string[] = [
  'api://' + AZURE_CLIENT_ID + '/access_as_user',
  'User.Read',
  'User.ReadBasic.All',
  'People.Read',
  'Presence.Read',
  'Presence.Read.All',
  'profile',
];

const msalConfig: Configuration = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: AZURE_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE(),
  },
};

export async function getAccessToken(): Promise<string | undefined> {
  const accounts: AccountInfo[] = msalInstance.getAllAccounts();
  if (accounts.length) {
    const result: AuthenticationResult = await msalInstance.acquireTokenSilent({
      account: accounts[0],
      scopes: msalScopes,
    });
    return result.accessToken;
  }
}

export function getMsal1Account() {
  const accounts: AccountInfo[] = msalInstance.getAllAccounts();
  if (accounts.length) {
    const account = accounts[0];
    return {
      accountIdentifier: account.localAccountId,
      homeAccountIdentifier: undefined,
      userName: account.username,
      name: account.name,
      idToken: { ...account.idTokenClaims },
      idTokenClaims: { ...account.idTokenClaims },
      sid: undefined,
      environment: undefined,
    };
  }
}

export function msalLogout() {
  const accounts: AccountInfo[] = msalInstance.getAllAccounts();
  if (accounts.length) {
    const account = accounts[0];
    const logoutRequest = {
      account,
    };
    msalInstance.logoutRedirect(logoutRequest);
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

import { ProposalSuccessChance } from 'graphql/proposals/types/graphql-types';
import { useMemo } from 'react';
import { capitalizeFirstLetter, enumToArray } from 'utils/formats';

export interface IUseChancesOfSuccessProps {
  selectedItem?: { id: string; name: string };
}

function rotateLeft(arr: any[]) {
  let first = arr.shift();
  arr.push(first);
  return arr;
}
export const useChancesOfSuccess = ({ selectedItem }: IUseChancesOfSuccessProps) => {
  const predefinedList = useMemo(() => {
    const arr = enumToArray(ProposalSuccessChance);
    return rotateLeft(
      arr
        .map((item) => ({
          id: item,
          name: capitalizeFirstLetter(item.toLowerCase()),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, []);

  const items = useMemo(() => {
    if (
      !selectedItem ||
      ProposalSuccessChance[selectedItem.id as keyof typeof ProposalSuccessChance]
    ) {
      return predefinedList;
    }
    return [selectedItem, ...predefinedList];
  }, [predefinedList, selectedItem]);

  return {
    items,
  };
};

export const amountRange = [
  // { id: '0-1000', name: 'Min - $1,000' },
  // { id: '1000-10000', name: '$1,000 - $10K' },

  // { id: '10000-49999.99', name: '$10,000 up to $50,000' },
  // { id: '50000-99999.99', name: '$50,000 up to $100,000' },
  // { id: '100000-499999.99', name: '$100,000 up to $500,000' },
  // { id: '500000-999999.99', name: '$500,000 up to $1,000,000' },
  // { id: '1000000-0', name: '$1,000,000 - Max' },

  { id: '10000-99999.99', name: '$10,000 up to $100,000' },
  { id: '100000-999999.99', name: '$100,000 up to $1,000,000' },
  { id: '1000000-9999999.99', name: '$1,000,000 up to $10,000,000' },
];

import React, { FC, useEffect, useCallback } from 'react';

import { FiltersList } from './components/FiltersList/FiltersList';
import { SortOrder } from '../Table/components/HeaderCell/HeaderCell';
import { IHeadCell } from '../Table/components/TableHead/TableHead';
export interface IFilterFormProps {
  headCells: IHeadCell[];
  loadPage?: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => boolean;
  initRowsPerPage?: number;
  initOrder?: SortOrder;
  initOrderBy?: string;
  initPage?: number;
}

export const FilterForm: FC<IFilterFormProps> = ({
  loadPage,
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  initRowsPerPage,
  initOrder,
  initOrderBy,
  initPage,
}) => {
  const [order] = React.useState<SortOrder>(initOrder || SortOrder.ASC);
  const [orderBy] = React.useState<string | undefined>(initOrderBy);
  const [page, setPage] = React.useState(initPage || 0);
  const [rowsPerPage] = React.useState(initRowsPerPage || 10);

  const handleFilterChange = useCallback(
    (filterValues: any) => {
      if (onFilterChange!(filterValues)) {
        setPage(0);
      }
    },
    [onFilterChange]
  );

  const refresh = useCallback(() => {
    if (loadPage) {
      loadPage(order, orderBy, page, rowsPerPage);
    }
  }, [order, orderBy, page, rowsPerPage, loadPage]);

  useEffect(() => {
    refresh();
  }, [order, orderBy, page, rowsPerPage, refresh]);

  return (
    <div>
      <FiltersList
        headCells={headCells}
        filterOptions={filterOptions}
        filterValues={filterValues}
        onFilterChange={handleFilterChange}
      ></FiltersList>
    </div>
  );
};

import { FC, useRef, useCallback, useEffect, useMemo } from 'react';

import { Chart as ChartJS } from 'chart.js';

import {
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import s from './style.module.scss';
import cn from 'classnames';

import { Chart, ChartType } from 'components/Chart/Chart';
import { chartTypeOptions, getChartOptions } from './constants';

import { useChartConfigContext } from 'template/Charting/context/ChartConfig';
import { SelectSeries } from '../SelectSeries/SelectSeries';
import { SelectSeriesArr } from '../SelectSeriesArr/SelectSeriesArr';
import { ChartingPrint } from '../ChartingPrint/ChartingPrint';
import { generateColors } from 'template/Charting/utils/colors';
import { useDataSourceConfigContext } from 'template/Charting/context/DataSourceConfig';
import { conversionFunction } from '../SelectDataSource/constants';

const colors = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  // '#FFFF00', // yellow is to light
  '#FF00FF',
  // '#00FFFF', // light blue

  '#990000',
  '#009900',
  '#000099',
  '#999900',
  '#990099',
  '#009999',
  // '#CC0000',
  // '#00CC00',
  // '#0000CC',
  // '#CCCC00',
  // '#CC00CC',
  // '#00CCCC',
  // '#330000',
  // '#003300',
  // '#000033',
  // '#333300',
  // '#330033',
  // '#003333',
  '#660000',
  '#006600',
  '#000066',
  '#666600',
  '#660066',
  '#006666',
];

const initData = {
  datasets: [],
};

export interface IChartingData {
  sheetName: string;
  csvData: any[]; // columnKey/fieldValue
  headings: any; // columnKey/columnName
}

const getLabel = (x: string | number | undefined) => {
  if (!x) {
    return 'N/A';
  }
  const s = typeof x === 'string' ? x : x.toString();
  if (s.length > 20) {
    return s.substring(0, 17).trim() + '...';
  }
  return s;
};

export interface IChartingPageProps {
  pageDivRef?: any;
}

export const ChartingPage: FC<IChartingPageProps> = ({ pageDivRef }) => {
  const { config, setConfig, headings, chartData, orderedAxes } = useChartConfigContext();
  const { config: dataSourceConfig } = useDataSourceConfigContext();

  const data = useMemo(() => {
    if (chartData) {
      return [chartData];
    }
    return [];
  }, [chartData]);

  const chartRef = useRef<ChartJS>();

  const syncStateToChart = useCallback(() => {
    setTimeout(() => {
      if (!chartRef.current || !config?.chartConfig) {
        return;
      }

      const { chartConfig: state } = config;

      // Title
      if (chartRef.current.options.plugins) {
        if (state.title) {
          chartRef.current.options.plugins.title = {
            color: 'gray',
            display: true,
            text: state.title,
            font: { weight: 'bold', size: 16 },
          };
        } else {
          // @ts-ignore
          chartRef.current.options.plugins.title = false;
        }

        // Subtitle
        chartRef.current.options.plugins.subtitle = state.subtitle
          ? {
              display: true,
              text: state.subtitle,
            }
          : undefined;

        // @ts-ignore
        chartRef.current.options.plugins.legend = state.showSerieLabels;

        // Set Axis title
        // for(const axisKey of Object.keys(chartRef.current.scales)){

        // }
      }

      // @ts-ignore
      chartRef.current.config.type = state.type;
      chartRef.current.update();
    }, 10);
  }, [config]);

  useEffect(syncStateToChart, [syncStateToChart]);

  const syncConfigToChart = useCallback(() => {
    setTimeout(() => {
      if (!config?.chartConfig || !chartRef.current) {
        return;
      }

      const { chartConfig: state } = config;

      const roundChart = (['pie', 'doughnut', 'radar', 'polarArea'] as ChartType[]).includes(
        state.type
      );

      const overlappingChart = (['radar', 'polarArea'] as ChartType[]).includes(state.type);

      const { yAxis: yAxisArr, xAxis, seriesAssignment } = config;

      // Series assignment
      let assignmentLabels: string[] = [''];
      let assignedRows: { [id: string]: any[] } = { '': data[0].csvData };

      if (seriesAssignment?.selectedKey && orderedAxes[seriesAssignment?.selectedKey]) {
        const { selectedKey: field } = seriesAssignment;

        // labels ordered
        assignmentLabels = orderedAxes[seriesAssignment?.selectedKey].map((val) =>
          val !== undefined ? val.toString() : ''
        );

        // rows assignment
        assignedRows = data[0].csvData.reduce((acc, cur) => {
          const key = cur[field] ?? '';

          if (acc[key]) {
            acc[key].push(cur);
          } else {
            acc[key] = [cur];
          }

          return acc;
        }, {} as any);
      }

      // Distinct values on X axis (if X is not type of number)
      let categoryLabels: (string | number)[] = [];
      let excludeEmptyXValue = false;
      if (xAxis?.selectedKey && orderedAxes[xAxis?.selectedKey]) {
        const { selectedKey: xField } = xAxis;

        // has empty value
        excludeEmptyXValue = !Object.keys(assignedRows).some((key) =>
          assignedRows[key].some((row) =>
            yAxisArr?.selectedKeys.some(
              (yField) =>
                (row[xField] === undefined || row[xField] === '') &&
                !(row[yField] === undefined || row[yField] === '' || row[yField] === 0)
            )
          )
        );

        categoryLabels = excludeEmptyXValue
          ? orderedAxes[xField].filter((x) => x !== undefined && x !== '')
          : orderedAxes[xField];

        if (roundChart) {
          chartRef.current.data.labels = categoryLabels.map((x) => getLabel(x));

          // @ts-ignore
          chartRef.current.options.scales = false;
        } else {
          const columnindex = parseInt(xAxis.selectedKey.split('_')[0]);
          const { columns } = dataSourceConfig;
          const column = columns[columnindex];
          const cf = conversionFunction.find((f) => f.id === column.conversionFunctionId);
          const dataType = cf?.resultType ?? column.fieldType;

          if (dataType === 'month') {
            chartRef.current.options.scales = {
              x: {
                type: 'time',
                time: {
                  parser: 'M',
                  // parser: 'MM/DD/YYYY HH:mm',
                  // tooltipFormat: 'll HH:mm',
                  unit: 'month',
                  displayFormats: {
                    month: 'MMMM',
                  },
                },
                bounds: 'ticks',
                title: state.showSerieLabels
                  ? {
                      text: column.fieldName,
                      color: 'gray',
                      display: true,
                      font: {
                        // style: 'italic',
                        weight: 'bold',
                        size: 12,
                      },
                    }
                  : undefined,
              },
            };
          } else if (dataType === 'date') {
            chartRef.current.options.scales = {
              x: {
                type: 'time',
                time: {
                  parser: 'M/d/yyyy',
                  // parser: 'MM/DD/YYYY HH:mm',
                  // tooltipFormat: 'll HH:mm',
                  unit: 'month',
                  displayFormats: {
                    day: 'MMMM y',
                  },
                },
                bounds: 'ticks',
                title: state.showSerieLabels
                  ? {
                      text: column.fieldName,
                      color: 'gray',
                      display: true,
                      font: {
                        // style: 'italic',
                        weight: 'bold',
                        size: 12,
                      },
                    }
                  : undefined,
              },
            };
          } else {
            chartRef.current.options.scales = {
              x: {
                type: 'category',
                labels: categoryLabels.map((x) => getLabel(x)),
                ticks: {
                  autoSkip: false,
                  // maxRotation: 90,
                  // minRotation: 0,
                },
                title: state.showSerieLabels
                  ? {
                      text: column.fieldName,
                      color: 'gray',
                      display: true,
                      font: {
                        // style: 'italic',
                        weight: 'bold',
                        size: 12,
                      },
                    }
                  : undefined,
              },
            };
          }

          // @ts-ignore
          chartRef.current.data.labels = false;
        }
      }

      // Y series
      const genColors = Array.from(
        { length: Math.min(categoryLabels.length, colors.length) },
        (_, i) => colors[i % colors.length]
      );

      if (categoryLabels.length > colors.length) {
        genColors.push(
          ...generateColors(categoryLabels.length)
            .filter((color) => colors!.includes(color))
            .slice(0, categoryLabels.length - colors.length)
        );
      }

      let datasets: any[] = [];

      if (roundChart) {
        if (yAxisArr?.selectedKeys?.length && xAxis) {
          const { selectedKey: xField } = xAxis;

          for (let j = 0; j < assignmentLabels.length; j++) {
            const assignmentLabel = assignmentLabels[j];
            const rows = assignedRows[assignmentLabel];

            for (let i = 0; i < yAxisArr.selectedKeys.length; i++) {
              const yField = yAxisArr.selectedKeys[i];

              const values = rows
                .filter((row) => row[yField] !== undefined)
                .filter((row) => !excludeEmptyXValue || row[xField] !== '')
                .reduce((acc, row) => {
                  acc[row[xField]] = row[yField];
                  return acc;
                }, {});

              datasets.push({
                label:
                  assignmentLabels.length > 1 ||
                  (assignmentLabels.length === 1 && !!assignmentLabel)
                    ? getLabel(assignmentLabel)
                    : data[0].headings[yField],
                data: categoryLabels.map((category) =>
                  values[category] ? (values[category] < 0 ? 0 : values[category]) : 0
                ),
                backgroundColor: genColors.map(
                  (c) => c + (overlappingChart && assignmentLabels.length > 1 ? '90' : '')
                ),
              });
            }
          }

          datasets = datasets.filter((dataset) => dataset.data.some((item: any) => item !== 0));
        }
      } else {
        if (yAxisArr?.selectedKeys?.length && xAxis) {
          for (let j = 0; j < assignmentLabels.length; j++) {
            const assignmentLabel = assignmentLabels[j];
            const rows = assignedRows[assignmentLabel];
            const { selectedKey: xField } = xAxis;
            for (let i = 0; i < yAxisArr.selectedKeys.length; i++) {
              const yField = yAxisArr.selectedKeys[i];
              const values = rows
                ? rows
                    .filter((row) => row[yField] !== undefined)
                    .filter((row) => !excludeEmptyXValue || row[xField] !== '')
                    .map((row) => ({
                      y: row[yField],
                      x: row[xField],
                    }))
                    .sort((a, b) =>
                      categoryLabels.indexOf(a.x ?? '') < categoryLabels.indexOf(b.x ?? '')
                        ? 1
                        : categoryLabels.indexOf(a.x ?? '') > categoryLabels.indexOf(b.x ?? '')
                        ? -1
                        : 0
                    )
                    .map(({ x, y }) => ({
                      y,
                      x: getLabel(x),
                    }))
                : undefined;
              datasets.push({
                label:
                  assignmentLabels.length > 1 ||
                  (assignmentLabels.length === 1 && !!assignmentLabel)
                    ? yAxisArr.selectedKeys.length > 1
                      ? `${data[0].headings[yField]}, ${getLabel(assignmentLabel)}`
                      : getLabel(assignmentLabel)
                    : data[0].headings[yField],
                // label:
                //   assignmentLabels.length > 1 ||
                //   (assignmentLabels.length === 1 && !!assignmentLabel)
                //     ? getLabel(assignmentLabel)
                //     : data[0].headings[yField],
                data: values,
                backgroundColor: colors[(i * assignmentLabels.length + j) % colors.length],
                borderColor: colors[(i * assignmentLabels.length + j) % colors.length],
              });
            }
          }
          datasets = datasets.filter((dataset) => dataset.data?.some((item: any) => item !== 0));
        }
      }

      chartRef.current.data.datasets = datasets;

      chartRef.current.update();
    }, 10);
  }, [config, data, chartRef, orderedAxes, dataSourceConfig]);

  useEffect(syncConfigToChart, [syncConfigToChart]);

  const chartTypeMenuItems = useMemo(() => {
    return chartTypeOptions
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((item) => <MenuItem value={item.type}>{item.title}</MenuItem>);
  }, []);

  const chartDivRef = useRef<any>();

  return (
    <div className={s.charting}>
      <div className={s.config}>
        <div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Chart Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={config?.chartConfig.type}
              label="Chart Type"
              onChange={(ev) => {
                setConfig &&
                  setConfig((config: any) => ({
                    ...config,
                    chartConfig: { ...config.chartConfig, type: ev.target.value as ChartType },
                  }));
                // setState((state) => ({ ...state, type: ev.target.value as ChartType }));
              }}
            >
              {chartTypeMenuItems}
            </Select>
          </FormControl>
        </div>
        <div className={cn(s.field, s.margin)}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={config?.chartConfig.title}
            onChange={(ev: any) => {
              setConfig &&
                setConfig((config: any) => ({
                  ...config,
                  chartConfig: { ...config.chartConfig, title: ev.target.value },
                }));
              // setState((state) => ({ ...state, title: ev.target.value }));
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={cn(s.field, s.margin)}>
          <TextField
            fullWidth
            label="Subtitle"
            variant="outlined"
            value={config?.chartConfig.subtitle}
            onChange={(ev: any) => {
              setConfig &&
                setConfig((config: any) => ({
                  ...config,
                  chartConfig: { ...config.chartConfig, subtitle: ev.target.value },
                }));
              // setState((state) => ({ ...state, subtitle: ev.target.value }));
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={s.field}>
          <FormControlLabel
            label={<Typography sx={{ fontSize: '0.875rem' }}>Show Series Labels</Typography>}
            control={
              <Checkbox
                size="small"
                checked={config?.chartConfig.showSerieLabels}
                onChange={(e) => {
                  setConfig &&
                    setConfig((config: any) => ({
                      ...config,
                      chartConfig: {
                        ...config.chartConfig,
                        showSerieLabels: !config.chartConfig.showSerieLabels,
                      },
                    }));
                  // setState((state) => ({ ...state, showSerieLabels: !state.showSerieLabels }));
                }}
              />
            }
          />
        </div>
        <div>
          <SelectSeries
            options={headings[0].headings}
            dictionary={{ notSelectedName: 'Select Data Series', title: 'Series Assignment' }}
            state={config?.seriesAssignment}
            setState={(cb) => {
              setConfig &&
                setConfig((config: any) => ({
                  ...config,
                  seriesAssignment: cb(config?.seriesAssignment),
                }));
            }}
          />
        </div>
        <div>
          <SelectSeries
            options={headings[0].headings}
            dictionary={{ notSelectedName: 'Not Selected (optional)', title: 'X Axis' }}
            state={config?.xAxis}
            setState={(cb) => {
              setConfig && setConfig((config: any) => ({ ...config, xAxis: cb(config?.xAxis) }));
            }}
          />
        </div>
        <div>
          <SelectSeriesArr
            options={headings[0].headings}
            dictionary={{ notSelectedName: 'Please select ...', title: 'Y Axis' }}
            state={config?.yAxis}
            setState={(cb) => {
              setConfig && setConfig((config: any) => ({ ...config, yAxis: cb(config?.yAxis) }));
            }}
          />
        </div>
        {/* <div>
          <SelectSeries
            options={headings[0].headings}
            dictionary={{ notSelectedName: 'Not Selected (optional)', title: 'Auxiliary Value' }}
            state={config?.auxValue}
            setState={(cb) => {
              setConfig &&
                setConfig((config: any) => ({ ...config, auxValue: cb(config?.auxValue) }));
            }}
          />
        </div> */}

        {chartDivRef.current && (
          <div className={cn(s.field, s.margin)}>
            <ChartingPrint
              componentRef={chartDivRef}
              title={'Print Chart'}
              color="secondary"
            ></ChartingPrint>
          </div>
        )}
        {pageDivRef?.current && (
          <div className={cn(s.field, s.margin)}>
            <ChartingPrint
              componentRef={pageDivRef}
              title={'Print Page'}
              color="secondary"
            ></ChartingPrint>
          </div>
        )}
      </div>
      <div className={s.chart} ref={chartDivRef}>
        <Chart
          type={config?.chartConfig.type || 'bar'}
          options={getChartOptions({ stacked: (config?.chartConfig.type || 'bar') === 'bar' })}
          data={initData}
          chartRef={chartRef}
        ></Chart>
      </div>
    </div>
  );
};

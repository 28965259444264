import { useCallback, useState } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';

import { proposals } from 'graphql/proposals/types/proposals';
import { proposalCount } from 'graphql/proposals/types/proposalCount';
import { GET_PROPOSALS_EXPORT, GET_PROPOSAL_COUNT } from 'graphql/proposals/proposals';

export interface IProcess {
  status: 'stop' | 'run' | 'fin';
  stages: Array<{ key: string; name: string }>;
  progress: { [key: string]: { steps: number; current: number } };
}

const getInitialLoadProposalsStatus: () => IProcess = () => ({
  status: 'stop',
  stages: [
    { key: 'calculate', name: 'Collecting Info' },
    { key: 'getProposals', name: 'Loading Pages' },
  ],
  progress: { calculate: { steps: 1, current: 0 }, getProposals: { steps: 1, current: 0 } },
});

export const useLoadProposals = () => {
  const client = useApolloClient();
  const [loadProposalsStatus, setLoadProposalsStatus] = useState<IProcess>(
    getInitialLoadProposalsStatus()
  );

  const setLoadProposalStepCount = useCallback((step: string, count: number) => {
    setLoadProposalsStatus((old) => ({
      ...old,
      progress: { ...old.progress, [step]: { steps: count, current: 0 } },
    }));
  }, []);

  const incLoadProposalStepProgress = useCallback((step: string) => {
    setLoadProposalsStatus((old) => ({
      ...old,
      progress: {
        ...old.progress,
        [step]: { ...old.progress[step], current: old.progress[step].current + 1 },
      },
    }));
  }, []);

  const loadProposals = useCallback(
    async ({
      allPages,
      rowsPerPage,
      variables,
    }: {
      variables: any;
      allPages: boolean;
      rowsPerPage: number;
    }) => {
      setLoadProposalsStatus(() => getInitialLoadProposalsStatus());
      setLoadProposalsStatus((old) => ({ ...old, status: 'run' }));
      let result: { data: any; error: ApolloError | undefined };
      if (!allPages) {
        incLoadProposalStepProgress('calculate');

        const { data, error } = await client.query<proposals>({
          query: GET_PROPOSALS_EXPORT,
          variables: { ...variables },
          fetchPolicy: 'network-only',
        });
        incLoadProposalStepProgress('getProposals');

        if (data.proposal_proposals) {
          result = { data: data?.proposal_proposals, error };
        } else {
          result = { data: [], error };
        }
      } else {
        const { data: proposalCount, error: proposalCountError } =
          await client.query<proposalCount>({
            query: GET_PROPOSAL_COUNT,
            variables: { ...variables },
          });
        incLoadProposalStepProgress('calculate');

        if (proposalCount && !proposalCountError) {
          setLoadProposalStepCount(
            'getProposals',
            Math.ceil(proposalCount.proposal_proposalCount / rowsPerPage)
          );
        }

        result = { data: [], error: undefined };
        let lastPage = false;

        for (var pageNo = 0; !lastPage; pageNo++) {
          const { data, error } = await client.query<proposals>({
            query: GET_PROPOSALS_EXPORT,
            variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
          });

          if (error) {
            result.data.error = error;
            break;
          }

          lastPage = !data?.proposal_proposals?.length;
          if (!lastPage) {
            incLoadProposalStepProgress('getProposals');
            result.data.push(...data.proposal_proposals);
          }
        }
      }

      setLoadProposalsStatus((old) => ({ ...old, status: 'fin' }));
      return result;
    },
    [client, incLoadProposalStepProgress, setLoadProposalStepCount]
  );

  return {
    loadProposals,
    loadProposalsStatus,
  };
};

import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { ProjectSortableColumn } from 'graphql/proposals/types/graphql-types';

export const demacPartyProjectsTableHead: IHeadCell[] = [
  {
    id: 'key',
    numeric: true,
    disablePadding: false,
    label: 'Project ID',
    sortable: true,
    // filter: 'text',
    sortBy: ProjectSortableColumn.ID,
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Project Name',
    sortable: true,
    sortBy: ProjectSortableColumn.NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
  },
  {
    id: 'asOfDate',
    numeric: false,
    disablePadding: false,
    label: 'AOD',
    sortable: true,
    sortBy: ProjectSortableColumn.AS_OF_DATE,
    // filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
];

import { useEffect, useMemo, useRef } from 'react';
import { Chart } from 'components/Chart/Chart';
import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';
import s from './style.module.scss';
import { Chart as ChartJS } from 'chart.js';
import { changeProposalSuccessChanceName } from 'utils/formats';

const options = {
  plugins: {
    datalabels: {
      color: '#333',
      display: true,
      align: 'bottom',
      backgroundColor: '#EEE',
      borderRadius: 3,
      font: {
        size: 14,
      },
      formatter: (value: number, ctx: any) => {
        if (value < 5) {
          return `${value}%`;
        }

        const label = ctx.chart.data.labels[ctx.dataIndex];
        return `${label}: ${value}%`;
      },
    },
  },
};

const successChanceColors = {
  MEDIUM: 'rgb(86, 86, 255)',
  HIGH: 'rgb(255, 99, 132)',
  LOW: 'rgb(255, 205, 86)',
};

export const AmountBySuccessChanceChart = () => {
  const { aggregatesBySuccessChance, aggregatesTotal } = useProposalAggregatesContext();

  const chartDivRef = useRef<any>();
  const chartRef = useRef<ChartJS>();

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.canvas.style.backgroundColor = 'lightgray';
    }
  }, [chartRef]);

  const total = useMemo(() => {
    if (aggregatesTotal) {
      return aggregatesTotal.proposals_monetaryBreakdownAggregates[0].valueSum;
    }
  }, [aggregatesTotal]);

  const keyValue = useMemo(() => {
    if (!aggregatesBySuccessChance) {
      return {};
    }
    const reduced = aggregatesBySuccessChance.proposals_monetaryBreakdownAggregates.reduce(
      (acc, { successChance, valueSum }) => {
        acc[successChance || ''] = Math.round(((valueSum || 0) / (total || 1)) * 100);
        return acc;
      },
      {} as any
    );

    return reduced;
  }, [aggregatesBySuccessChance, total]);

  const labels = useMemo(() => {
    return Object.keys(keyValue).sort((a, b) => keyValue[a] - keyValue[b]);
  }, [keyValue]);

  const displayLabels = useMemo(() => {
    return labels.map((label) =>
      label ? changeProposalSuccessChanceName(label) : 'Chance of Success not defined'
    );
  }, [labels]);

  const dataSet = useMemo(() => {
    return labels.map((label) => keyValue[label]);
  }, [keyValue, labels]);

  const data = useMemo(
    () => ({
      labels: displayLabels,
      datasets: [
        {
          label: 'Sum of Proposal Amount by SuccessChance in %',
          data: dataSet,
          backgroundColor: labels.map(
            (label) => successChanceColors[label as keyof typeof successChanceColors]
          ),
          hoverOffset: 4,
        },
      ],
    }),
    [dataSet, displayLabels, labels]
  );

  return (
    <div className={s.charting}>
      <div className={s.chart} ref={chartDivRef}>
        <Chart type={'doughnut'} options={options} data={data} chartRef={chartRef}></Chart>
      </div>
    </div>
  );
};

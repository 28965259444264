import { useCallback } from 'react';
import { useAutoCompleteDataSource } from './autoCompleteDataSource';

import { proposals, proposals_proposal_proposals } from 'graphql/proposals/types/proposals';
import { proposalCount } from 'graphql/proposals/types/proposalCount';
import { ProposalEntityType, ProposalFilter } from 'graphql/proposals/types/graphql-types';

import { GET_PROPOSAL_COUNT, GET_PROPOSALS } from 'graphql/proposals/proposals';

const getCount = (count: proposalCount) => count.proposal_proposalCount;
const getItems = (data: proposals) => data.proposal_proposals;
const getItemId = (item: any) => item.id;

export interface IProposalsNamesAutocompleteDataHook {
  search: string;
  entityType?: ProposalEntityType;
}

export const useProposalsNamesAutocompleteDataHook = ({
  search,
  entityType,
}: IProposalsNamesAutocompleteDataHook) => {
  const filterToAPI = useCallback(
    (localSearch: string) => ({ nameContains: localSearch, entityType }),
    [entityType]
  );

  const resp = useAutoCompleteDataSource<
    proposals,
    proposalCount,
    proposals_proposal_proposals,
    ProposalFilter
  >({
    GET_QUERY: GET_PROPOSALS,
    GET_COUNT_QUERY: GET_PROPOSAL_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};

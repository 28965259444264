import React, { FC, useEffect } from 'react';

import ReactDOM from 'react-dom/client';

import * as serviceWorker from './serviceWorker';

import './index.scss';

import { MsalProvider } from '@azure/msal-react';
import { msalInstance, msalScopes } from 'authorization/auth-utils-msal2';

import { AppMsal } from './appMsal';

import { Msal2Provider as MGT_Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { Providers as MGT_Providers } from '@microsoft/mgt-element';
import { AZURE_AUTHORITY } from 'constants/config';

MGT_Providers.globalProvider = new MGT_Msal2Provider({
  publicClientApplication: msalInstance,
  authority: AZURE_AUTHORITY,
  scopes: msalScopes,
});

const App: FC<any> = () => {
  useEffect(() => {
    const process: any = {
      ...window.process,
    };
    window.process = process;
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <AppMsal />
    </MsalProvider>
  );
};

// // Test Microsoft Graph
// import { MicrosoftGraph } from 'components/MicrosoftGraph/MicrosoftGraph';
// const App: FC<any> = () => <MicrosoftGraph />;

const root = document.getElementById('root');
if (root) {
  const anchor = ReactDOM.createRoot(root);
  if (anchor) {
    anchor.render(<App />);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { useLazyQuery } from '@apollo/client';
import { GET_COUNTRIES } from 'graphql/proposals/countries';
import { countries } from 'graphql/proposals/types/countries';
import { useCallback, useEffect, useMemo } from 'react';

export interface IGeneralDataItemsState {
  items: { id: string; name: string; code: string }[];
  loading: boolean;
}

interface IUseGeneralDataItemsProps {
  iDs?: string[];
}

export const useSelectedCountrieDataItems = ({
  iDs,
}: IUseGeneralDataItemsProps): IGeneralDataItemsState => {
  const [loadCountries, { data, loading, refetch, called }] = useLazyQuery<countries>(
    GET_COUNTRIES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (ids: string[]) => {
      const variables = {
        filter: { ids },
      };

      if (called) {
        refetch!(variables);
      } else {
        loadCountries({ variables });
      }
    },
    [loadCountries, refetch, called]
  );

  useEffect(() => {
    if (iDs?.length) {
      loadPage(iDs);
    }
  }, [iDs, loadPage]);

  const getItems = useCallback(() => {
    return (data && !loading && data.countries) || [];
  }, [data, loading]);

  const items = useMemo(() => {
    return getItems();
  }, [getItems]);

  return { items, loading };
};

import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { TextareaAutosize, Button } from '@mui/material';

import { EventCommentSubmitProps } from './interfaces';

import { UserInitials, AlertDialog } from 'components';

import { id } from 'utils/id';

import './index.scss';
import { PARTY_COMMENT_CREATE } from 'graphql/proposals/parties';

import { getMsal1Account } from 'authorization/auth-utils-msal2';
import { SCOPE } from 'constants/config';

export const EventCommentSubmit: FC<EventCommentSubmitProps> = ({
  partyId,
  refetchParty,
}: EventCommentSubmitProps) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const [commentError, setCommentError] = useState('');
  const [commentErrorOpen, setAlertOpen] = useState(false);

  const [setPartyComment] = useMutation(PARTY_COMMENT_CREATE);

  const handleSubmit = (event: any): void => {
    event.preventDefault();

    setPartyComment({
      variables: { comment: value, partyId, application: SCOPE },
    })
      .then(() => {
        refetchParty({ variables: { id: id() } });
      })
      .catch((e) => {
        setCommentError(e?.message);
        setAlertOpen(true);
      });

    setValue('');
    setIsFocused(false);
  };

  const handleCommentSubmit = (event: any): void => {
    setValue(event.target.value);
  };

  const getUserInfoButton = (): JSX.Element => <UserInitials {...getMsal1Account()} />;

  return (
    <>
      <AlertDialog
        title="Error"
        message={commentError}
        open={commentErrorOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <div style={{ display: 'flex', gap: '2em', width: '90%' }}>
        <div>{getUserInfoButton()}</div>
        <div style={{ flexGrow: 2 }}>
          <form onSubmit={handleSubmit}>
            <TextareaAutosize
              // className="event-logs-comment-input"
              className="MuiInputBase-input"
              color="grey"
              style={{
                width: '95%',
                maxWidth: '95%',
                minWidth: '95%',
                minHeight: '1rem',
                padding: 10,
                borderRadius: 4,
                border: '1px #ccc solid',
                overflow: 'auto',
                marginBottom: '1rem',
              }}
              aria-label="minimum height"
              minRows={1}
              placeholder="Write a requirement/comment..."
              onChange={handleCommentSubmit}
              onFocus={(): void => setIsFocused(true)}
              onBlur={(): void => {
                if (!value) {
                  setIsFocused(false);
                }
              }}
              value={value}
            />
            {isFocused && (
              <div>
                <Button type="submit" variant="contained" color="primary" disabled={!value}>
                  Submit
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

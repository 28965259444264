import { gql } from '@apollo/client';
import { IPartyContact } from './partyContactFragments';

export const GET_PARTY_CONTACTS = gql`
  query partyContacts($filter: PartyContactFilterInput, $take: Int = 5, $skip: Int = 0) {
    partyContacts(filter: $filter, take: $take, skip: $skip) {
      ...IPartyContact
    }
  }
  ${IPartyContact}
`;

export const PARTY_CONTACT_CREATE_MUTATION = gql`
  mutation partyContactCreate(
    $partyId: ID!
    $firstName: String
    $lastName: String
    $contactType: PartyContactType!
    $jobTitle: String
    $email: String
    $phone: String
    $linkedInUrl: String
    $notes: String
    $isActive: Boolean = true
  ) {
    partyContactCreate(
      partyId: $partyId
      firstName: $firstName
      lastName: $lastName
      contactType: $contactType
      jobTitle: $jobTitle
      email: $email
      phone: $phone
      linkedInUrl: $linkedInUrl
      notes: $notes
      isActive: $isActive
    ) {
      id
    }
  }
`;

export const PARTY_CONTACT_UPDATE_MUTATION = gql`
  mutation partyContactUpdate(
    $id: ID!
    $partyId: ID!
    $firstName: String
    $lastName: String
    $contactType: PartyContactType!
    $jobTitle: String
    $email: String
    $phone: String
    $linkedInUrl: String
    $notes: String
    $isActive: Boolean = true
  ) {
    partyContactUpdate(
      id: $id
      partyId: $partyId
      firstName: $firstName
      lastName: $lastName
      contactType: $contactType
      jobTitle: $jobTitle
      email: $email
      phone: $phone
      linkedInUrl: $linkedInUrl
      notes: $notes
      isActive: $isActive
    ) {
      id
    }
  }
`;

export const PARTY_CONTACT_SOFT_DELETE_MUTATION = gql`
  mutation partyContactSoftDelete($id: ID!, $partyId: ID!) {
    partyContactSoftDelete(id: $id, partyId: $partyId)
  }
`;

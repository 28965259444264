import { gql } from '@apollo/client';

export const IUserData = gql`
  fragment IUserData on User {
    id
    oid
    email
    name
    isActive
  }
`;

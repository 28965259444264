import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { capitalizeFirstLetter } from 'utils/formats';
import { ProposalSortableColumn } from 'graphql/proposals/types/graphql-types';
import {
  TransformCurrencyUS,
  TransformCurrentlyReviewing,
  transformCurrentlyReviewingText,
  TransformProgress,
  TransformProposalLead,
  transformProposalLeadText,
  TransformProposalStage,
  transformProposalStageText,
  // TransformStaleStatus,
  TransformStringList,
} from 'utils/TableTransformFunctions';
import { proposalTypeOptions } from 'template/Proposal/components/ProposalForm/constants/proposalTypeOptions';

export const proposalsTableHead: IHeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Id',
  //   sortable: true,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Name',
    sortable: true,
    sortBy: ProposalSortableColumn.NAME,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
  },
  // {
  //   id: 'referencePartyDivisionOwnership',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Division Ownership',
  //   sortable: true,
  //   sortBy: ProposalSortableColumn.OWNING_DIVISION_NAME,
  //   filter: 'dropdown',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  //   transformFunction: (data) => data?.division?.name || '--',
  // },
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Division',
    sortable: true,
    sortBy: ProposalSortableColumn.OWNING_DIVISION_NAME,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data) => data?.division?.name || '--',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    sortable: true,
    sortBy: ProposalSortableColumn.PARTY_NAME,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data) =>
      data
        ? (data.projectSetupClientCode ? data.projectSetupClientCode + ' - ' : '') + data.name
        : '--',
  },
  {
    id: 'proposalType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: true,
    sortBy: ProposalSortableColumn.PROPOSAL_TYPE,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },

    transformFunction: (proposalType) => {
      const found = proposalTypeOptions.find((item) => item.id === proposalType);
      return proposalType ? (found ? found.name : proposalType) : '--';
    },
  },
  {
    id: 'leadUser',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Lead',
    sortable: true,
    sortBy: ProposalSortableColumn.PROPOSAL_USER_LEAD_NAME,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: transformProposalLeadText,
    transformDataCell: TransformProposalLead,
  },
  {
    id: 'successChance',
    numeric: false,
    disablePadding: false,
    label: 'Chance of Success',
    sortable: true,
    sortBy: ProposalSortableColumn.SUCCESS_CHANCE,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : '--'),
  },
  {
    id: 'bidProposedValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Amount',
    sortable: true,
    sortBy: ProposalSortableColumn.BID_PROPOSED_VALUE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: any) => (data ? data : '-- '),
    transformDataCell: TransformCurrencyUS,
    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    sortable: true,
    sortBy: ProposalSortableColumn.CREATED_AT,
    filter: 'mobile-date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Changed',
    sortable: true,
    sortBy: ProposalSortableColumn.UPDATED_AT,
    filter: 'mobile-date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'pendingReviewers',
    numeric: false,
    disablePadding: false,
    label: 'Reviewing',
    sortable: true,
    sortBy: ProposalSortableColumn.PENDING_REVIEWER_NAMES,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: transformCurrentlyReviewingText,
    transformDataCell: TransformCurrentlyReviewing,
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'reviewStatus',
    numeric: false,
    disablePadding: false,
    label: 'Review State',
    sortable: true,
    sortBy: ProposalSortableColumn.REVIEW_STATUS,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : '--'),
  },
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Stage',
    sortable: true,
    sortBy: ProposalSortableColumn.STAGE,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: transformProposalStageText,
    transformDataCell: TransformProposalStage,
  },
  {
    id: 'activity',
    numeric: false,
    disablePadding: false,
    label: 'Activity',
    sortable: true,
    sortBy: ProposalSortableColumn.ACTIVITY,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : '--'),
  },
  // {
  //   id: 'receiveDate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Proposal Received Date',
  //   sortable: false,
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  //   transformFunction: (date) =>
  //     date
  //       ? new Date(date).toLocaleDateString('en-EN', {
  //           year: 'numeric',
  //           month: 'numeric',
  //           day: 'numeric',
  //         })
  //       : 'N/A',
  // },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Due Date',
    sortable: true,
    sortBy: ProposalSortableColumn.DUE_DATE,
    filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
  {
    id: 'workTypes',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',
    sortable: true,
    sortBy: ProposalSortableColumn.WORK_TYPE_NAME,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    // transformFunction: (workTypes) =>
    //   workTypes ? workTypes.map((item: any) => item.name).join(', ') : '--',
    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames.join(String.fromCharCode(10));
    },
    transformDataCell: ({ ...props }) => {
      const { data } = props;
      const dataNames = data ? data.map((item: any) => item.name) : undefined;
      return TransformStringList({ ...props, data: dataNames });
    },
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
  {
    id: 'progress',
    numeric: false,
    disablePadding: false,
    label: 'Progress',
    sortable: false,
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: TransformProgress,
  },
];

import { Box, Typography } from '@mui/material';

import s from './style.module.scss';
import paths from 'constants/paths';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useComponentContext } from '../../ProposalContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const ProposalHeader = () => {
  const history = useHistory();
  const { confirmRequest } = useFormChangedDialogContext();
  const { proposal, referenceOpportunityId } = useComponentContext();
  const { id, name, stageAsString } = proposal;

  const goToProposals = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && history.push(paths.client.PROPOSALS);
    });
  }, [history, confirmRequest]);

  return (
    <>
      <div>
        <div className={s.linkStyle} onClick={debounce(goToProposals, DEBOUNCE_TIMEOUT)}>
          Go back to proposal list
        </div>
        <Box m={3} />
        <div>
          <div className={s.statusLine}>
            <div>
              <Typography
                variant="body2"
                className="label-title-nocase"
                style={{ fontSize: '16px' }}
              >
                Proposal #ID{id} - "{name}"
              </Typography>
            </div>
            <div style={{ margin: '0 auto', paddingLeft: '10px', paddingRight: '10px' }}>
              {referenceOpportunityId && (
                <div>
                  <Typography variant="body2" style={{ fontSize: '16px' }}>
                    This Proposal is Promoted from Opportunity
                  </Typography>
                </div>
              )}
            </div>
            <div>
              <Typography
                variant="body2"
                className="label-title-nocase"
                style={{ fontSize: '16px' }}
              >
                STAGE: {stageAsString}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { useMemo } from 'react';
import { FieldType } from 'template/Proposal/components/ProposalForm/components/ColumnsTemplate/TwoColumnTemplate';
import { IFieldsDescription } from 'template/Proposal/components/ProposalForm/interface';
import { capitalizeAllWordsFirstLetterUS, splitByCapitalLetters } from 'utils/formats';

export const useBondFields = (): { fields: Array<Array<IFieldsDescription>> } => {
  const otherFields = useMemo(() => {
    return [
      [
        {
          id: 'amount',
          title: 'Amount',
          showColapsed: true,
          field: { type: 'currency' as FieldType },
        },
        {
          id: 'stageMapped',
          title: 'Stage',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        {
          id: 'partyMapped',
          title: 'Party',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        {
          id: 'proposalMapped',
          title: 'Proposal',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        {
          id: 'validityThroughDate',
          title: 'Validity Through Date',
          showColapsed: true,
          field: { type: 'date' as FieldType },
        },
        {
          id: 'recipientReceiveDeadline',
          title: 'Recipient Receive Deadline',
          showColapsed: true,
          field: { type: 'date' as FieldType },
        },
        {
          id: 'originalFilename',
          title: 'File Name',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        {
          id: 'bondFileTypeMapped',
          title: 'File Type',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        {
          id: 'bondTypeMapped',
          title: 'Bond Type',
          showColapsed: true,
          field: { type: 'label' as FieldType },
        },
        ...[
          'beneficiaryEmail',
          'beneficiaryFaxNumber',
          'beneficiaryPhoneNumber',
          'contractName',
          'contractNumber',
          'currency',
          // 'deliveryInstructions',
          // 'description',
          // 'issuingBankRequirements',
          'tenderName',
          'tenderNumber',
          // 'wordingOrFormatRequirements',
          // 'bondFileType',
          // 'originalFilename',
          // 'blobContainer',
          // 'blobName',
          // 'mimeType',
        ].map((id) => ({
          id,
          title: capitalizeAllWordsFirstLetterUS(splitByCapitalLetters(id).join(' ')),
          showColapsed: true,
          field: {
            type: 'label' as FieldType,
          },
        })),
        ...[
          'description',
          'issuingBankRequirements',
          'wordingOrFormatRequirements',
          'beneficiaryNameAndAddress',
          'deliveryInstructions',
        ].map((id) => ({
          id,
          title: capitalizeAllWordsFirstLetterUS(splitByCapitalLetters(id).join(' ')),
          showColapsed: true,
          field: {
            type: 'textareaAutosize' as FieldType,
          },
        })),
      ],
    ];
  }, []);

  return {
    fields: otherFields,
  };
};

import React, { FC, useState, useCallback } from 'react';

import { buildInitials } from './user';
import { Badge, Container, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { changeProposalStageName, statusVariant } from './formats';
import { currencyFormatUS } from './currencyFormat';
import { compare } from './functions';
import {
  Battery0Bar,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery90,
  BatteryFull,
  FileCopy as MultipleFilesIcon,
  InsertDriveFile as SingleFileIcon,
} from '@mui/icons-material';

import { IconButton } from '@mui/material';

import { useHistory } from 'react-router-dom';
import { paths } from 'constants/index';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { getProposalStaleLevel } from './proposal';
import { DialogOppener } from 'components/DialogOppener/DialogOppener';
import { ProposalGanttLog } from 'template/Proposal/components/EventsLog/components/ProposalGanttLog/ProposalGanttLog';

interface INameInitials {
  name: string;
  initials: string;
}

export const transformUserText = (data: any) => {
  if (!data) {
    return '--';
  }

  return data.name;
};

export const TransformUser: FC<{ data: any; row: any; order?: SortOrder; sorted?: boolean }> = ({
  data,
}) => {
  if (!data) {
    return (
      <div className="focal_point_user_column">
        <div>-</div>
      </div>
    );
  }

  const item: INameInitials = {
    name: data.name,
    initials: buildInitials(data.name),
  };

  const userText = (
    <div key={item.name}>
      <Tooltip title={item.name}>
        <span>{item.initials}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className="focal_point_user_column">
      <div>{userText}</div>
    </div>
  );
};

export const transformProposalLeadText = (data: any) => {
  if (!data?.user) {
    return '--';
  }

  const item: INameInitials = {
    name: data.user.name,
    initials: buildInitials(data.user.name),
  };

  const userText = item.name;
  return userText;
};

export const TransformProposalLead: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data }) => {
  if (!data?.user) {
    return (
      <div className="focal_point_user_column">
        <div>--</div>
      </div>
    );
  }

  const item: INameInitials = {
    name: data.user.name,
    initials: buildInitials(data.user.name),
  };

  const userText = (
    <div key={item.name}>
      <Tooltip title={item.name}>
        <span>{item.initials}</span>
      </Tooltip>
    </div>
  );

  return (
    <div className="focal_point_user_column">
      <div>{userText}</div>
    </div>
  );
};

export const TransformProposalStage: FC<{ data: any; row: any }> = ({ data, row }) => {
  const statusName = changeProposalStageName(data);
  return <div style={{ color: statusVariant(data) }}>{statusName}</div>;
};

export const transformProposalStageText = (data: any) => {
  const statusName = changeProposalStageName(data);
  return statusName;
};

export const TransformStringList: FC<{
  data: string[];
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  let ordered = [...data].sort();
  if (!!sorted) {
    if (order === SortOrder.DESC) {
      ordered = ordered.reverse();
    }
  }
  let count = 0;
  const text = data.length
    ? ordered.map((item) => {
        count++;
        return (
          <div key={count}>
            <span>{item}</span>
          </div>
        );
      })
    : '-';
  return <div>{text}</div>;
};

export const TransformCurrencyUS: FC<{
  data: string;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data }) => {
  const text = data ? (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ marginLeft: 'auto' }}>$ {currencyFormatUS(parseFloat(data))}</div>
    </div>
  ) : (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ marginLeft: 'auto' }}>--</div>
    </div>
  );
  return <>{text}</>;
};

export const TransformCurrentlyReviewing: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ data, row, order, sorted }) => {
  const reviewers = data.map((pendingReviewer: any) => pendingReviewer.user.name);

  let orderedReviewers: INameInitials[] = reviewers.map((name: string) => ({
    name,
    initials: buildInitials(name),
  }));

  if (!!sorted) {
    orderedReviewers = orderedReviewers.sort((a: INameInitials, b: INameInitials) =>
      compare(a.initials, b.initials)
    );
    if (order === SortOrder.DESC) {
      orderedReviewers = orderedReviewers.reverse();
    }
  }

  const reviewersText = reviewers.length
    ? orderedReviewers.map((item: INameInitials) => (
        <div key={item.name}>
          <Tooltip title={item.name}>
            <span>{item.initials}</span>
          </Tooltip>
        </div>
      ))
    : '--';
  return (
    <div className="currently_reviewing_column">
      <div>{reviewersText}</div>
    </div>
  );
};

export const transformCurrentlyReviewingText = (data: any) => {
  if (!data) {
    return 'N/A';
  }

  const reviewers = data
    .map((pendingReviewer: any) => pendingReviewer?.user?.name)
    .filter((name: any) => !!name);

  if (!reviewers.length) {
    return 'N/A';
  }

  return reviewers.join(String.fromCharCode(10));
};

export const PromotedToProposal: FC<{ row: any }> = ({ row }) => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const useStyles = makeStyles((theme) => ({
    font1: {
      // fontSize: '0.65rem',
      // width: '1rem',
      // height: '1rem',
      // borderRadius: '25%',
      // backgroundColor: 'rgb(0, 0, 100, 0.5)',
      // color: 'black',
    },
  }));
  const classes = useStyles();

  const handleOnClick = useCallback(
    (e: any) => {
      if (row.promotedProposals.length === 1) {
        history.push(paths.client.PROPOSAL_DETAILS.replace(':id', row.promotedProposals[0].id));
      } else {
        setOpenDialog(() => true);
      }
    },
    [history, row.promotedProposals]
  );

  if (row.promotedProposals?.length) {
    return (
      <div
        onClick={(e: any) => {
          e.preventDefault();
          e.cancelBubble = true;
          e.stopPropagation();
        }}
      >
        <DialogForm
          dialogTitle={'Promoted Proposals'}
          onClose={() => {
            setOpenDialog(false);
          }}
          open={openDialog}
          maxWidth={'sm'}
        >
          <div style={{ overflowY: 'scroll' }}>
            <Container maxWidth="sm">
              <div>This Opportunity is used to create multiple Proposals:</div>
              <ul>
                {row.promotedProposals.map((proposal: { id: string; name: string }) => (
                  <li style={{ paddingBottom: '0.5em' }}>
                    Proposal{' '}
                    <a
                      onClick={() => {
                        history.push(paths.client.PROPOSAL_DETAILS.replace(':id', proposal.id));
                      }}
                      href={paths.client.PROPOSAL_DETAILS.replace(':id', proposal.id)}
                    >
                      #ID{proposal.id}
                    </a>{' '}
                    - {proposal.name}
                  </li>
                ))}
              </ul>
            </Container>
          </div>
        </DialogForm>
        <div className="promoted_to_proposal_column">
          <Tooltip
            title={
              <div>
                <div>
                  <div>
                    {row.promotedProposals.length > 1
                      ? 'This Opportunity is used to create Proposals'
                      : 'This Opportunity is used to create a Proposal'}
                    :
                  </div>
                </div>
                {row.promotedProposals.map((proposal: { id: string; name: string }) => (
                  <div>
                    #{proposal.id} {proposal.name}
                  </div>
                ))}
              </div>
            }
          >
            <IconButton size="small" onClick={handleOnClick}>
              {row.promotedProposals.length > 1 ? (
                row.promotedProposals.length > 2 ? (
                  <Badge
                    badgeContent={row.promotedProposals.length}
                    color="primary"
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    classes={{
                      badge: classes.font1,
                    }}
                  >
                    <MultipleFilesIcon fontSize="small" />
                  </Badge>
                ) : (
                  <MultipleFilesIcon fontSize="small" />
                )
              ) : (
                <SingleFileIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }
  return <div className="promoted_to_proposal_column">--</div>;
};

const getBatteryLevel = (value: number) => {
  if (value > 95) {
    return <BatteryFull fontSize="small" style={{ color: 'green' }}></BatteryFull>;
  }
  if (value >= 90) {
    return <Battery90 fontSize="small" style={{ color: 'green' }} />;
  }

  if (value >= 60) {
    return <Battery60 fontSize="small" style={{ color: 'green' }} />;
  }
  if (value >= 50) {
    return <Battery50 fontSize="small" style={{ color: 'green' }} />;
  }

  if (value >= 30) {
    return <Battery30 fontSize="small" style={{ color: 'green' }} />;
  }
  if (value >= 20) {
    return <Battery20 fontSize="small" style={{ color: 'green' }} />;
  }
  if (value > 0) {
    return <Battery0Bar fontSize="small" style={{ color: 'green' }} />;
  }

  return (
    <Tooltip title={<>STALE</>}>
      <Battery0Bar fontSize="small" style={{ color: 'red' }} />
    </Tooltip>
  );

  // return (
  //   <div
  //     style={{
  //       color: 'red',
  //       // fontWeight: 'bold',
  //       display: 'inline',
  //     }}
  //   >
  //     STALE
  //   </div>
  // );
};

export const TransformStaleStatus: FC<{
  data: any;
  row: any;
  order?: SortOrder;
  sorted?: boolean;
}> = ({ row }) => {
  const { stage, updatedAt } = row;
  const statusName = changeProposalStageName(stage);
  if (!['DRAFT', 'PENDING'].includes(stage)) {
    return <>{statusName}</>;
  }
  const { value, limit } = getProposalStaleLevel(updatedAt);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>{statusName}</div>
      <div style={{ paddingRight: '10px' }}>{getBatteryLevel((value * 100) / limit)}</div>
    </div>
  );
};

export const TransformProgress = (_: any, rowData: any): JSX.Element => {
  return (
    <DialogOppener
      getDialog={(open, onClose) => (
        <DialogForm
          dialogTitle={`Proposal #ID${rowData?.id} - ${rowData?.name} - Document Progress Chart`}
          onClose={onClose}
          open={open}
          maxWidth="xl"
        >
          <div className="dialogGanttChart" style={{ padding: '0 10px 0 10px' }}>
            <ProposalGanttLog proposalId={rowData.id}></ProposalGanttLog>
          </div>
        </DialogForm>
      )}
    >
      <span>
        {rowData?.approvalCount} /{rowData?.reviewerCount}
      </span>
    </DialogOppener>
  );
};

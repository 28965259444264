import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';
import s from './style.module.scss';
import { Box, Grid } from '@mui/material';
import { currencyFormatUS } from 'utils/currencyFormat';
import { changeProposalStageName } from 'utils/formats';

export const AmountByStage = () => {
  const { aggregatesByStage, aggregatesTotal } = useProposalAggregatesContext();

  return (
    <Grid container spacing={0}>
      <Grid item className={s.head} xs={4}>
        Proposal Stage
      </Grid>
      <Grid item className={s.resultHead} xs={8}>
        Proposal Amount
      </Grid>
      {aggregatesByStage?.proposal_proposalAggregates
        .filter((x) => x.bidProposedValueSum)
        .map((row) => {
          return (
            <>
              <Grid item className={s.keyColumn} xs={4}>
                {changeProposalStageName(row.stage || '--')}
              </Grid>
              <Grid item className={s.resultColumn} xs={8}>
                $ {currencyFormatUS(row.bidProposedValueSum || 0)}
              </Grid>
            </>
          );
        })}
      <Grid item xs={12}>
        <Box mt={2} />
      </Grid>
      <Grid item xs={4} className={s.finalKey}>
        Total
      </Grid>
      <Grid item xs={8} className={s.finalResult}>
        ${' '}
        {currencyFormatUS(aggregatesTotal?.proposal_proposalAggregates[0].bidProposedValueSum || 0)}
      </Grid>
    </Grid>
  );
};

import { GET_PARTY_DESIGNATIONS } from 'graphql/proposals/parties';
import { GET_STOCK_EXCHANGES } from 'graphql/proposals/stockExchanges';

export const partyQueries = {
  partyDesignations: {
    GET_QUERY: GET_PARTY_DESIGNATIONS,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => data.partyDesignations,
  },
  stockExchanges: {
    GET_QUERY: GET_STOCK_EXCHANGES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => data.stockExchanges,
  },
};

import { Grid, Typography } from '@mui/material';
import { IProposalEvent } from 'graphql/proposals/types/IProposalEvent';
import { FC } from 'react';
import { EventCommentLog } from './components/EventCommentLogs';
import { EventCommentSubmit } from './components/EventCommentSubmit/EventCommentSubmit';
import { useComponentContext } from 'template/Opportunity/ProposalContext';
export interface IEventsLog {
  id: string;
  refetch: any;
  events: IProposalEvent[];
}

export const EventsLog: FC<IEventsLog> = ({ id, refetch, events }) => {
  const { promotedProposal } = useComponentContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" className="label-title-nocase">
          Events/comments log
        </Typography>
      </Grid>
      {!promotedProposal ? (
        <Grid item xs={12}>
          <EventCommentSubmit proposalId={id} refetchDocument={refetch}></EventCommentSubmit>
        </Grid>
      ) : undefined}
      <Grid item xs={12}>
        <EventCommentLog events={events} documentArchived={false}></EventCommentLog>
      </Grid>
    </Grid>
  );
};

import { keyBy, mapValues, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDivisions } from 'hooks/divisionsHook';
import { useUsersList } from '../usersListHook';

import { customFields } from './customFields';

import { IEvent } from 'template/Proposal/ProposalContext';
import { PartyEventType } from 'constants/enums';

import { formatProposalDate } from 'utils/time';
import { currencyFormatUS } from 'utils/currencyFormat';
import { useSelectedPartyDataItems } from 'hooks/selectedPartiesDataItemsHook';
import { useSelectedCountrieDataItems } from 'hooks/selectedCountriesDataItemsHook';
import { useGeneralDataPairs } from 'hooks/generalDataPairsHook';
import { partyQueries } from './partyQueries';
import { useGeneralDataObjects } from 'hooks/generalDataObjectsHook';

export interface ICompetitor {
  bidValue?: number;
  competitorName?: string;
  hasWonBid?: boolean;
}

export interface IUsePayloadConvertProps {
  events: IEvent[];
}

const showCountry = (country: any) => {
  return `${country?.name} (${country?.code.substring(0, 3)})`;
};
export const usePayloadConvert = ({ events }: IUsePayloadConvertProps) => {
  const { divisionPairs, loading: divisionsLoading } = useDivisions();
  const { usersMap, loadUsers, loadingUsers } = useUsersList();

  const { pairs: designationsPairs, loading: designationsPairsLoading } = useGeneralDataPairs(
    partyQueries.partyDesignations
  );

  const { items: stockExchanges, loading: stockExchangesLoading } = useGeneralDataObjects(
    partyQueries.stockExchanges
  );

  const stockExchangesHash = useMemo(() => {
    return mapValues(keyBy(stockExchanges, 'id'), (item) =>
      item.acronym ? item.acronym : item.name
    );
  }, [stockExchanges]);

  const [selectedParties, setSelectedParties] = useState<string[]>();
  const [selectedCountries, setSelectedCountries] = useState<string[]>();

  const { items: partiesItems } = useSelectedPartyDataItems({ IDs: selectedParties });
  const { items: countriesItems } = useSelectedCountrieDataItems({ iDs: selectedCountries });

  useEffect(() => {
    if (events) {
      const partiesToLoad: { [key: string]: boolean } = {};
      const countriesToLoad: { [key: string]: boolean } = {};

      events.forEach((event) => {
        if (
          (event.eventType === PartyEventType.PARTY_UPDATED ||
            event.eventType === PartyEventType.PARTY_CREATED) &&
          event.payload
        ) {
          const payload = JSON.parse(event.payload);

          if (payload.partyRelationshipPartyIdsToAdd) {
            payload.partyRelationshipPartyIdsToAdd.forEach(
              (id: string) => (partiesToLoad[id] = true)
            );
          }
          if (payload.partyRelationshipPartyIdsToremove) {
            payload.partyRelationshipPartyIdsToremove.forEach(
              (id: string) => (partiesToLoad[id] = true)
            );
          }
          if (payload.addressCountryId) {
            countriesToLoad[payload.addressCountryId] = true;
          }
        }
      });

      setSelectedParties(Object.keys(partiesToLoad));
      setSelectedCountries(Object.keys(countriesToLoad));
    }
  }, [events]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const divisionPairs2 = useMemo(() => {
    return [...divisionPairs];
  }, [divisionPairs]);

  const mapSimpleOptions = useMemo(
    () => ({
      designationId: {
        id: 'selectedDesignation',
        options: mapValues(keyBy(designationsPairs, 'id'), 'name'),
      },
      focalPointUserId: {
        id: 'selectedFocalPointUser',
        options: usersMap,
      },
      divisionOwnershipId: {
        id: 'selectedDivisionOwnership',
        options: mapValues(keyBy(divisionPairs2, 'id'), 'name'),
      },
    }),
    [usersMap, designationsPairs, divisionPairs2]
  );

  const proposalArrayToFieldMap = useMemo<{
    [key: string]: { id: string; type: string; options?: any; valueMap?: (obj: any) => string };
  }>(
    () => ({
      partyStockExchangesToAdd: {
        id: 'stockExchanges',
        type: 'Added',

        valueMap: ({ stockExchangeSymbol, stockExchangeId }: any) =>
          `${stockExchangeId ? stockExchangesHash[stockExchangeId] : '--'} / ${
            stockExchangeSymbol ? stockExchangeSymbol : '--'
          } `,
      },
      partyStockExchangesToModify: {
        id: 'stockExchanges',
        type: 'Modified',

        valueMap: ({ partyStockExchangeId, stockExchangeSymbol, stockExchangeId }: any) =>
          `#ID${partyStockExchangeId || '--'}: ${
            stockExchangeId ? stockExchangesHash[stockExchangeId] : '--'
          } / ${stockExchangeSymbol ? stockExchangeSymbol : '--'} `,
      },
      partyStockExchangeIdsToRemove: {
        id: 'stockExchanges',
        type: 'Removed',

        valueMap: (id: string) => `#ID${id}`,
      },
      partyRelationshipPartyIdsToAdd: {
        id: 'selectedRelationshipParty',
        type: 'Added',
        options: mapValues(
          keyBy(partiesItems, 'id'),
          (item) => item.projectSetupClientCode + ' - ' + item.name
        ),
      },
      partyRelationshipPartyIdsToRemove: {
        id: 'selectedRelationshipParty',
        type: 'Removed',
        options: mapValues(
          keyBy(partiesItems, 'id'),
          (item) => item.projectSetupClientCode + ' - ' + item.name
        ),
      },
      addressCountryId: {
        id: 'addressCountryId',
        type: 'Selected',
        options: mapValues(
          keyBy(countriesItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
      },
    }),
    [partiesItems, countriesItems, stockExchangesHash]
  );

  const fieldsInfo = useMemo(() => {
    const collectedInfo: any[] = [];
    [customFields].forEach((section) => {
      section.forEach((fieldGroup) => {
        fieldGroup.forEach((field) => {
          if (!!field.id) {
            collectedInfo.push({ ...pick(field, ['id', 'title', 'field']) });
          }
        });
      });
    });
    return keyBy(collectedInfo, 'id');
  }, []);

  const payloadConvert = useCallback(
    (payload: any) => {
      const collected: any[] = [];
      Object.keys(payload).forEach((key) => {
        if (key === 'id' || key === 'partyId') {
          return;
        }
        if (Array.isArray(payload[key as keyof typeof payload])) {
          if (proposalArrayToFieldMap[key as keyof typeof proposalArrayToFieldMap]) {
            const {
              id: mappedId,
              type,
              options,
              valueMap,
            } = proposalArrayToFieldMap[key as keyof typeof proposalArrayToFieldMap];
            if (payload[key].length) {
              collected.push({
                id: key,
                title: fieldsInfo[mappedId]?.title + ' ' + type,
                value:
                  '"' +
                  (valueMap
                    ? payload[key].map((item: any) => valueMap(item)).join('", "')
                    : options
                    ? payload[key]
                        .map((id: string) => {
                          return options[id]?.name || options[id];
                        })
                        .join('", "')
                    : payload[key]) +
                  '"',
              });
            } else {
              // empty list is not important
            }
          } else {
            collected.push({
              id: key,
              title: key,
              value: payload[key].join(', ') || '--',
            });
          }
          return;
        }

        if (fieldsInfo[key]) {
          let displayValue;
          if (payload.hasOwnProperty(key)) {
            switch (fieldsInfo[key].field?.type) {
              case '$-breakdown': {
                displayValue = {
                  displayValue: '$ ' + currencyFormatUS(parseFloat(payload[key]), 0),
                };
                break;
              }
              case '$': {
                displayValue = '$ ' + currencyFormatUS(parseFloat(payload[key]), 0);
                break;
              }
              case 'date': {
                displayValue = formatProposalDate(payload[key]);
                break;
              }
              case 'boolean': {
                displayValue = payload[key] ? 'true' : 'false';
                break;
              }
              case 'countryId': {
                displayValue = showCountry(countriesItems.find((item) => item.id === payload[key]));
                break;
              }
              default:
                displayValue = payload[key];
            }
          } else {
            displayValue = '--';
          }

          collected.push({
            id: key,
            title: fieldsInfo[key].title,
            value: displayValue,
          });
          return;
        }

        if (mapSimpleOptions[key as keyof typeof mapSimpleOptions]) {
          const { id: mappedId, options } = mapSimpleOptions[key as keyof typeof mapSimpleOptions];

          collected.push({
            id: mappedId,
            title: fieldsInfo[mappedId].title,
            value:
              (!!options && (options[payload[key]]?.name || options[payload[key]])) ||
              payload[key] ||
              '--',
          });

          return;
        }

        collected.push({
          id: key,
          title: key,
          value: JSON.stringify(payload[key]),
        });

        console.log('Key not mapped', key);
        console.log('Value not mapped', JSON.stringify(payload[key]));
      });

      return collected;
    },
    [fieldsInfo, mapSimpleOptions, proposalArrayToFieldMap, countriesItems]
  );

  return {
    payloadConvert,
    loading: loadingUsers || designationsPairsLoading || stockExchangesLoading || divisionsLoading,
  };
};

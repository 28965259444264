import { Box, Container, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { ProposalDocumentsList } from '../ProposalDocumentsList/ProposalDocumentsList';
import { NewDocumentVersion } from '../NewDocumentVersion';
import { DocumentApprovalReviewer } from './components/DocumentApprovalReviewer';
import { isCurrentUser, useComponentContext } from 'template/Proposal/ProposalContext';
import { useMemo, useState, useRef, useEffect } from 'react';

import DocumentApprovalSection from './components/DocumentApprovalSection';
import { useProposalDocuments } from 'hooks/proposalDocumentsHook';
import { useMsalAccount } from 'hooks/msalAccount';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DEBOUNCE_TIMEOUT } from 'constants/config';

export const ProposalFileManagement = () => {
  const { proposal, refetchProposal } = useComponentContext();

  const { msalAccount: currentAccount } = useMsalAccount();

  const isReviewer = useMemo(() => {
    return !!proposal?.reviewers.find((user) => isCurrentUser(currentAccount, user));
  }, [proposal?.reviewers, currentAccount]);

  const { id, updatedAt } = proposal;
  const { data, loading } = useProposalDocuments({ id, updatedAt });

  const [showLoading, setShowLoading] = useState(false);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (loading) {
      setShowLoading(true);
      timer.current && clearInterval(timer.current);
    } else {
      timer.current = setTimeout(() => {
        setShowLoading(false);
      }, DEBOUNCE_TIMEOUT);
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [loading, setShowLoading]);

  const clientProposalDocuments = useMemo(
    () =>
      data?.proposal_proposal.documents?.filter((x) => x?.versions?.length && x?.versions[0].file),
    [data]
  );

  const lastApprovedTs: number | undefined = useMemo(() => {
    const { events, canReview } = proposal;
    if (!!isReviewer && canReview) {
      const lastReviewEvent = events
        ?.slice()
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
        .find((event) => {
          return (
            ['PROPOSAL_REVIEW_APPROVED', 'PROPOSAL_REVIEW_DISAPPROVED'].includes(event.eventType) &&
            event?.author?.user?.email === currentAccount?.idToken?.email
          );
        });
      if (lastReviewEvent?.eventType === 'PROPOSAL_REVIEW_APPROVED') {
        return lastReviewEvent.createdAt;
      }
    }
  }, [isReviewer, proposal, currentAccount?.idToken?.email]);

  return (
    <Container maxWidth="lg">
      <Grid container padding={0} spacing={2}>
        <Grid xs={12}>
          <Box m={5} />
          <Grid container padding={0} spacing={4}>
            <Grid xs={12}>
              <Grid container padding={0} spacing={4}>
                <Grid xs={12}>
                  <Grid container padding={0} spacing={3}>
                    <Grid xs={12}>
                      <NewDocumentVersion clientProposalDocuments={clientProposalDocuments || []} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid container padding={0} spacing={3}>
                    {!!isReviewer && proposal.canReview ? (
                      <Grid xs={12}>
                        <DocumentApprovalSection
                          account={currentAccount}
                          proposal={proposal}
                          refetchProposal={refetchProposal!}
                          clientProposalDocuments={clientProposalDocuments || []}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Typography variant="body2" className="label-title">
                    SELECT NEXT REVIEWER FOR DOCUMENT APPROVAL
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <DocumentApprovalReviewer
                    proposal={proposal}
                    refetchProposal={refetchProposal}
                    enableSubmit
                    clientProposalDocuments={clientProposalDocuments || []}
                  ></DocumentApprovalReviewer>
                </Grid>

                <Grid xs={12}>
                  <Grid container padding={0} spacing={2} xs={12}>
                    <Grid xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <LoadingOverlay spinner active={showLoading} text="Loading your content...">
                    <div style={showLoading ? { display: 'none' } : {}}>
                      <ProposalDocumentsList
                        approvedDateTs={lastApprovedTs}
                        clientProposalDocuments={clientProposalDocuments || []}
                      ></ProposalDocumentsList>
                    </div>
                  </LoadingOverlay>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box m={5} />
    </Container>
  );
};

import { useLazyQuery } from '@apollo/client';
import { GET_PROPOSALS } from 'graphql/proposals/proposals';
import { proposals, proposals_proposal_proposals } from 'graphql/proposals/types/proposals';
import { useCallback, useEffect, useMemo } from 'react';

export interface IUseSelectedProposalDataState {
  items: proposals_proposal_proposals[];
}

interface IUseSelectedProposalDataProps {
  IDs?: string[];
}

export const useSelectedProposalDataItems = ({
  IDs,
}: IUseSelectedProposalDataProps): IUseSelectedProposalDataState => {
  const [loadProposals, { data, loading, refetch, called }] = useLazyQuery<proposals>(
    GET_PROPOSALS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (ids: string[]) => {
      const variables = {
        filter: { ids },
      };

      if (called) {
        refetch!(variables);
      } else {
        loadProposals({ variables });
      }
    },
    [loadProposals, refetch, called]
  );

  useEffect(() => {
    if (IDs?.length) {
      loadPage(IDs);
    }
  }, [IDs, loadPage]);

  const getItems = useCallback(() => {
    return (data && !loading && data.proposal_proposals) || [];
  }, [data, loading]);

  const items = useMemo(() => {
    return getItems();
  }, [getItems]);

  return { items };
};

import paths from './paths';

import { Login } from '../pages';
import { PagesRoute as PagesRoutePageComponent } from 'pages/PagesRoute';
import { ProposalsList as ProposalsListComponent } from 'pages/Proposals/components/ProposalsList/ProposalsList';

import { NewProposalPage as NewProposalComponent } from 'pages/ProposalNew/NewProposal';

import { ProposalPage as ProposalPageComponent } from 'pages/Proposal/Proposal';
import { ProposalDetails as ProposalDetailsComponent } from 'pages/Proposal/components/ProposalDetails/ProposalDetails';
import { ProposalFileManagement as ProposalFileManagementComponent } from 'pages/Proposal/components/ProposalFileManagement/ProposalFileManagement';
import { ProposalEvents as ProposalEventsComponent } from 'pages/Proposal/components/ProposalEvents/ProposalEvents';
import { ProposalBidPerformanceBondsList as ProposalBidPerformanceBondsListComponent } from 'pages/Proposal/components/ProposalBidPerformanceBondsList/ProposalBidPerformanceBondsList';
import { ProposalBidPerformanceBondsView as ProposalBidPerformanceBondsComponent } from 'pages/Proposal/components/ProposalBidPerformanceBondsView';
import { ProposalBidPerformanceBondsRoute as ProposalBidPerformanceBondsRouteComponent } from 'pages/Proposal/components/ProposalBidPerformanceBondsRoute/ProposalBidPerformanceBondsRoute';

import { ProposalsList as OpportunitiesListComponent } from 'pages/Opportunities/components/ProposalsList/ProposalsList';

import { NewProposalPage as NewOpportunityComponent } from 'pages/OpportunityNew/NewProposal';

import { ProposalPage as OpportunityPageComponent } from 'pages/Opportunity/Proposal';
import { ProposalDetails as OpportunityDetailsComponent } from 'pages/Opportunity/components/ProposalDetails/ProposalDetails';
import { ProposalFileManagement as OpportunityFileManagementComponent } from 'pages/Opportunity/components/ProposalFileManagement/ProposalFileManagement';
import { ProposalEvents as OpportunityEventsComponent } from 'pages/Opportunity/components/ProposalEvents/ProposalEvents';
import { NewProposalPage as OpportunityNewProposalComponent } from 'pages/Opportunity/components/NewProposal/NewProposal';
import { ConvertToProposalPage as OpportunityConvertToProposalComponent } from 'pages/Opportunity/components/ConvertToProposal/ConvertToProposal';

import { NewPartie as NewPartyComponent } from 'pages/NewPartie/NewPartie';
import { Parties as PartiesComponent } from 'pages/Parties/Parties';
// import { Party as PartyComponent } from 'pages/Party/Party';

import { ClientPage as ClientPageComponent } from 'pages/Client/Client';
import { ClientDetails as ClientDetailsComponent } from 'pages/Client/components/ClientDetails/ClientDetails';
import { ClientEvents as ClientEventsComponent } from 'pages/Client/components/ClientEvents/ClientEvents';

import { ReportsPage } from 'pages/Reports/Reports';
import { YearSummaryPage } from 'pages/Reports/YearSummaryPage/YearSummaryPage';
import { YearSummaryBySuccessChancePage } from 'pages/Reports/YearSummaryBySuccessChancePage/YearSummaryBySuccessChancePage';

import { BondsPage as BondsListComponent } from 'pages/Bonds/Bonds';
import { NewBond } from 'pages/BondNew/NewBond';
import { BondDetails } from 'pages/Bond/components/BondDetails/BondDetails';
import { BondRoutePage } from 'pages/Bond/BondRoute';
import { BondEvents } from 'pages/Bond/components/BondEvents/BondEvents';

const routes = [
  {
    path: paths.client.LOGIN,
    exact: true,
    component: Login,
    guest: true,
  },
  {
    path: paths.client.BASE,
    exact: false,
    component: PagesRoutePageComponent,
    private: true,
    guest: false,
    routes: [
      {
        path: paths.client.BASE,
        exact: true,
        component: ProposalsListComponent,
        private: true,
        guest: false,
      },

      {
        path: paths.client.CLIENT_MANAGEMENT,
        exact: true,
        component: PartiesComponent,
        private: true,
      },
      {
        path: paths.client.NEW_CLIENT,
        exact: true,
        component: NewPartyComponent,
        private: true,
      },
      {
        path: paths.client.CLIENT_PAGES,
        exact: false,
        component: ClientPageComponent,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.CLIENT_DETAILS,
            exact: true,
            component: ClientDetailsComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.CLIENT_EVENTS,
            exact: true,
            component: ClientEventsComponent,
            private: true,
            guest: false,
          },
        ],
      },

      {
        path: paths.client.NEW_PROPOSAL,
        exact: true,
        component: NewProposalComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.PROPOSAL_PAGES,
        exact: false,
        component: ProposalPageComponent,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.PROPOSAL_DETAILS,
            exact: true,
            component: ProposalDetailsComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.PROPOSAL_FM,
            exact: true,
            component: ProposalFileManagementComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.PROPOSAL_EVENTS,
            exact: true,
            component: ProposalEventsComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.PROPOSAL_BID_PERFORMANCE_BONDS,
            exact: false,
            component: ProposalBidPerformanceBondsRouteComponent,
            private: true,
            guest: false,
            routes: [
              {
                path: paths.client.PROPOSAL_BID_PERFORMANCE_BOND_NEW,
                exact: true,
                component: ProposalBidPerformanceBondsComponent,
                private: true,
                guest: false,
              },
              {
                path: paths.client.PROPOSAL_BID_PERFORMANCE_BOND_VIEW,
                exact: true,
                component: ProposalBidPerformanceBondsComponent,
                private: true,
                guest: false,
              },
              {
                path: paths.client.PROPOSAL_BID_PERFORMANCE_BONDS,
                exact: true,
                component: ProposalBidPerformanceBondsListComponent,
                private: true,
                guest: false,
              },
            ],
          },
        ],
      },
      {
        path: paths.client.OPPORTUNITIES,
        exact: true,
        component: OpportunitiesListComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.NEW_OPPORTUNITY,
        exact: true,
        component: NewOpportunityComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.OPPORTUNITY_PAGES,
        exact: false,
        component: OpportunityPageComponent,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.OPPORTUNITY_DETAILS,
            exact: true,
            component: OpportunityDetailsComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.OPPORTUNITY_FM,
            exact: true,
            component: OpportunityFileManagementComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.OPPORTUNITY_EVENTS,
            exact: true,
            component: OpportunityEventsComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.OPPORTUNITY_NEW_PROPOSAL,
            exact: true,
            component: OpportunityNewProposalComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.OPPORTUNITY_CONVERT_TO_PROPOSAL,
            exact: true,
            component: OpportunityConvertToProposalComponent,
            private: true,
            guest: false,
          },
        ],
      },
      {
        path: paths.client.REPORTS,
        exact: false,
        component: ReportsPage,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.REPORT_YEAR_BY_SUCCESS_CHANCE_SUMMARY,
            exact: true,
            component: YearSummaryBySuccessChancePage,
            private: true,
            guest: false,
          },
          {
            path: paths.client.REPORT_YEAR_SUMMARY,
            exact: true,
            component: YearSummaryPage,
            private: true,
            guest: false,
          },
        ],
      },
      {
        path: paths.client.BONDS,
        exact: true,
        component: BondsListComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.NEW_BOND,
        exact: true,
        component: NewBond,
        private: true,
        guest: false,
      },
      {
        path: paths.client.BOND_PAGES,
        exact: false,
        component: BondRoutePage,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.BOND_DETAILS,
            exact: true,
            component: BondDetails,
            private: true,
            guest: false,
          },
          {
            path: paths.client.BOND_EVENTS,
            exact: true,
            component: BondEvents,
            private: true,
            guest: false,
          },
        ],
      },
    ],
  },
];

export default routes;

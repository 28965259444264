import { useLazyQuery } from '@apollo/client';
import { TYPING_TIMEOUT } from 'constants/config';

import { GET_PARTY_COUNT, GET_PARTY_NAMES } from 'graphql/proposals/parties';
import { Order, PartySortableColumn } from 'graphql/proposals/types/graphql-types';
import { partyCount } from 'graphql/proposals/types/partyCount';
import { partyNames } from 'graphql/proposals/types/partyNames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';

const rowsPerPage = 20;
// const orderBy = 'NAME';
// const order = 'ASC';

const defaultSort = [
  { column: PartySortableColumn.CLIENT_CODE, order: Order.ASC },
  { column: PartySortableColumn.NAME, order: Order.ASC },
];
interface IGeneralListItem2 {
  key: string;
  name: string;
}

interface IPartiesListItem {
  key: string;
  name: string;
  clientCode: string | null;
  ddLabel: string;
}

export interface IUseSelectClientProps {
  error?: boolean;
  selectedItem?: IGeneralListItem2;
  order?: any;
}

export const useSelectClient = ({ selectedItem, error, order }: IUseSelectClientProps) => {
  const [search, setSearch] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [parties, setParties] = useState<IPartiesListItem[]>([]);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const changeSearchString = useCallback((searchString: string) => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setTimeout(() => {
      setSearch(searchString);
    }, TYPING_TIMEOUT);
  }, []);

  const [
    loadPartyCount,
    {
      data: dataPartyCount,
      loading: loadingPartyCount,
      refetch: refetchPartyCount,
      called: calledPartyCount,
    },
  ] = useLazyQuery<partyCount>(GET_PARTY_COUNT);

  const [getPartyNames, { data, loading, refetch, called, error: getPartyNamesError }] =
    useLazyQuery<partyNames>(GET_PARTY_NAMES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(
    (page: number, search: string) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: order && order.length ? order : defaultSort,
        filter: { projectSetupClientCodeOrNameContains: search },
      };

      if (called) {
        refetch!(variables);
      } else {
        getPartyNames({ variables });
      }
    },
    [getPartyNames, refetch, called, order]
  );

  const loadPageCount = useCallback(
    (search: string) => {
      const variables = {
        filter: { projectSetupClientCodeOrNameContains: search },
      };

      if (calledPartyCount) {
        refetchPartyCount!(variables);
      } else {
        loadPartyCount({ variables });
      }
    },
    [loadPartyCount, refetchPartyCount, calledPartyCount]
  );

  useEffect(() => {
    if (!loadingPartyCount && dataPartyCount) {
      setTotalItems(dataPartyCount.partyCount);
    }
  }, [loadingPartyCount, dataPartyCount]);

  useEffect(() => {
    if (data && !loading && !getPartyNamesError) {
      const ddList = data.parties.map((party) => {
        return {
          key: party.id,
          name: party.name,
          clientCode: party.projectSetupClientCode,
          ddLabel:
            (party.projectSetupClientCode ||
              (party.partyType?.name === 'CLIENT' ? 'Client' : 'Non-Client')) +
            ' - ' +
            party.name,
        };
      });
      setParties((parties) => {
        return [...parties, ...ddList];
      });
    }
  }, [data, loading, getPartyNamesError]);

  useEffect(() => {
    setParties([]);
    loadPage(0, search);
    loadPageCount(search);
  }, [search, loadPage, loadPageCount]);

  const onOpen = useCallback(() => {
    if (!parties.length) {
      setParties([]);
      loadPage(0, search);
      loadPageCount(search);
    }
  }, [search, setParties, loadPage, loadPageCount, parties.length]);

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  return {
    items: parties,
    error,
    pagination: {
      pages: maxPage,
      loadPage: (page: number) => {
        loadPage(page, search);
      },
    },
    onInputChange: (event: object, value: string, reason: string) => {
      // setSearch(value);
      changeSearchString(value);
    },
    optionsLabel: 'ddLabel',
    onOpen,
    loading: loadingPartyCount || loading,
  };
};

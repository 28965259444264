import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { snackbarSeverityType, useUI } from 'contexts/UiContext';

export const apolloErrorHandler =
  (cb: (msg: { text: string; severity: snackbarSeverityType }) => void) => (e: ApolloError) => {
    if (e.graphQLErrors) {
      e.graphQLErrors.forEach((err) => {
        if (typeof err.message === 'string') {
          cb!({ text: err.message, severity: 'error' });
        }
      });
    }
    if (e.networkError) {
      cb!({ text: 'Network error', severity: 'error' });
    }
  };
export const apolloErrorTexts = (e: ApolloError) => {
  const errors: string[] = [];
  if (e.graphQLErrors) {
    e.graphQLErrors.forEach((err) => {
      if (typeof err.message === 'string') {
        errors.push(err.message);
      }
    });
  }
  if (e.networkError) {
    errors.push('Network error');
  }
  return errors;
};

export const withApolloErrorHandler: any = (
  wrappedHook: ({ ...props }) => { error: ApolloError | undefined }
) => {
  return ({ ...props }) => {
    const { error, ...values } = wrappedHook(props);
    const { addSnackbar } = useUI();
    useEffect(() => {
      if (error) {
        console.log('ERROR', error);
        apolloErrorHandler(addSnackbar!)(error as ApolloError);
      }
    }, [error, addSnackbar]);
    return { error, ...values };
  };
};

import {
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextareaAutosize,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { IContact } from 'hooks/partyContactsHook';
import { FC, useState } from 'react';
import { TextInputField } from 'components/TextInputField/TextInputField';
import s from './style.module.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
export interface IContactProps {
  contact: IContact;
  onRemoveContact: () => void;
  onUpdateContact: (part: any) => void;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export const Contact: FC<IContactProps> = ({ contact, onRemoveContact, onUpdateContact }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(contact.id === '');

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div style={{ display: contact.deleted ? 'none' : undefined }} className={s.contactBox}>
      <Grid container spacing={2}>
        <Grid item xs={9} md={10} lg={11}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextInputField
                placeholder="Input contact first name…"
                value={contact.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onUpdateContact!({ firstName: e.target.value });
                }}
              ></TextInputField>
            </Grid>
            {expanded || matches ? (
              <Grid item xs={12} md={6}>
                <TextInputField
                  placeholder="Input contact last name…"
                  value={contact.lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onUpdateContact!({ lastName: e.target.value });
                  }}
                ></TextInputField>
              </Grid>
            ) : undefined}
            {expanded ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    placeholder="Input contact job title…"
                    value={contact.jobTitle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onUpdateContact!({ jobTitle: e.target.value });
                    }}
                  ></TextInputField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    placeholder="Input contact email…"
                    value={contact.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onUpdateContact!({ email: e.target.value });
                    }}
                  ></TextInputField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    placeholder="Input contact phone number…"
                    value={contact.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onUpdateContact!({ phone: e.target.value });
                    }}
                  ></TextInputField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputField
                    placeholder="Input contact LinkedIn…"
                    value={contact.linkedInUrl}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onUpdateContact!({ linkedInUrl: e.target.value });
                    }}
                  ></TextInputField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      {contact.isActive ? 'Active Contact' : ''}
                    </Grid>
                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={contact.isActive}
                            onChange={() => {
                              onUpdateContact!({ isActive: !contact.isActive });
                            }}
                          ></Switch>
                        }
                        label=""
                      />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                    >
                      {contact.isActive ? '' : 'Inactive Contact'}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    // className="event-logs-comment-input"
                    className="MuiInputBase-input"
                    color="grey"
                    style={{
                      width: '95%',
                      maxWidth: '95%',
                      minWidth: '95%',
                      minHeight: '1rem',
                      padding: 10,
                      borderRadius: 4,
                      border: '1px #ccc solid',
                      overflow: 'auto',
                      marginBottom: '1rem',
                    }}
                    aria-label="minimum height"
                    minRows={3}
                    value={contact.notes || undefined}
                    placeholder="Input notes…"
                    onChange={(event: React.ChangeEvent<{ value: string }>) => {
                      onUpdateContact!({ notes: event.target.value });
                    }}
                  ></TextareaAutosize>
                </Grid>
              </>
            ) : undefined}
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          md={2}
          lg={1}
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}
        >
          <div style={{ paddingRight: '5px' }}>
            <Tooltip title="Delete Contact">
              <IconButton
                size="small"
                onClick={debounce(onRemoveContact, DEBOUNCE_TIMEOUT)}
                className={classes.hoverWarning}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
          <div
            onClick={debounce(() => setExpanded(!expanded), DEBOUNCE_TIMEOUT)}
            style={{ cursor: 'pointer' }}
          >
            {expanded ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

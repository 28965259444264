import React from 'react';
import { Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ProposalFormFrame } from '../ProposalFormFrame/ProposalFormFrame';

export const NewProposal = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <Paper
        elevation={3}
        style={{
          padding: '1rem 28px 1rem 28px',
          marginTop: '35px',
          width: 'fit-content',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
        id="main-paper"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className="paper-title">
                New Opportunity
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper elevation={3} style={{ padding: '2rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <ProposalFormFrame></ProposalFormFrame>
      </Paper>
    </Container>
  );
};

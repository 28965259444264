import React, { FC, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Box, Grid, LinearProgress, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { uniqueId } from 'lodash';

import './index.scss';

import { Close as CloseIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import { FileDotIcon } from 'template/Proposal/components/FilesList.tsx/components/FileDotIcon/FileDotIcon';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const AttachFile: FC<any> = ({
  onDrop,
  removeFile,
  isUploading,
  percentage,
  multiple = false,
  files,
  hint = '',
  currentReportFileVersion = '',
  smallLayout = false,
  formId = 'uploadForm',
  disabled,
  // accept = ['.pdf', '.docx', ''],
  // btnSize = '',
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple,
  });
  const empty = useMemo(
    () => !currentReportFileVersion && files.length === 0,
    [currentReportFileVersion, files]
  );

  const fileItem = (file: any): JSX.Element => (
    <div key={uniqueId()}>
      <Box mt={2}>
        <Grid container alignItems="center" justifyContent="center" direction="row">
          <Grid item xs={3} sm={1}>
            <FileDotIcon fontSize="small" style={{ width: '32px', height: '32px' }} />
          </Grid>
          <Grid item xs={8} sm={10}>
            <div style={{ wordBreak: 'break-all' }}>{file.name}</div>
          </Grid>
          <Grid item xs={1}>
            <div
              onClick={debounce(removeFile(file.name), DEBOUNCE_TIMEOUT)}
              style={{ cursor: 'pointer' }}
            >
              <CloseIcon />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  const selectedFileView = (): JSX.Element => {
    if (empty) return <></>;

    if (files.length === 0) return <>{fileItem(currentReportFileVersion)}</>;
    return (
      <Box marginTop={1} className="upload-file-item">
        {files.map((file: any, index: number) => (
          <>
            {/* {!!index && (
              <div style={{ display: 'flex' }}>
                <div style={{ width: '46px' }}></div>
                <div style={{ width: '100%' }}>
                  <Box m={1} />
                  <Divider />
                </div>
              </div>
            )} */}
            {fileItem(file)}
          </>
        ))}
        <Grid>{isUploading && <LinearProgress variant="determinate" value={percentage} />}</Grid>
      </Box>
    );
  };

  const shouldDisplayFileChooser = (): boolean => {
    if (!smallLayout) return true;

    if (empty) return true;

    return files.length === 0;
  };

  const useStyles = makeStyles((theme) => ({
    hoverWarning: {
      '&:hover': {
        color: 'blue',
      },
    },
  }));
  const classes = useStyles();

  if (disabled) {
    return <></>;
  }

  return (
    <>
      <div style={{ display: files.length ? 'none' : undefined }}>
        <Tooltip title="Attach file">
          <IconButton
            aria-label="close"
            onClick={open}
            style={{ padding: '0px' }}
            className={classes.hoverWarning}
          >
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Grid container alignContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} style={{ display: files.length ? undefined : 'none' }}>
          {selectedFileView()}
        </Grid>

        {shouldDisplayFileChooser() && (
          <Grid item xs={12}>
            <form id={formId} name={formId} {...getRootProps()}>
              <input className="upload-input" {...getInputProps()} />
              <div
                className="upload-container"
                style={{ display: files.length ? undefined : 'none' }}
              >
                <Box>
                  <Button
                    className="btn-responsive"
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      width: isDragActive ? '100%' : '',
                      padding: isDragActive ? '4rem' : '',
                    }}
                  >
                    {isDragActive
                      ? `Drag 'n' drop some files here, or click to select files`
                      : 'Add files'}
                  </Button>
                </Box>{' '}
                {hint && <span className="upload-container-message ">{hint}</span>}
              </div>
            </form>
          </Grid>
        )}
      </Grid>
    </>
  );
};

import React, { FC, useMemo } from 'react';
import { version } from '../../../../../package.json';
import s from './style.module.scss';
import cn from 'classnames';

export const PrintingFooter: FC = () => {
  const timestamp = useMemo(() => {
    return new Date().toLocaleDateString('en-EN', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
      second: '2-digit',
    });
  }, []);

  return (
    <div className={cn(s.footer, s.dataBlock)}>
      <div style={{ width: '150px' }}></div>
      <div>© 2021 DeGolyer and MacNaughton Version {version}.</div>
      <div style={{ width: '150px', textAlign: 'right' }}>{timestamp}</div>
    </div>
  );
};

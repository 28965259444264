import { DialogForm } from 'components/DialogForm/DialogForm';
import { FC } from 'react';
import { Charting, IChartingProps } from 'template/Charting/Charting';

export interface IChartingDialogProps extends IChartingProps {
  open: boolean;
  onClose: () => void;
  [id: string]: any;
}

export const ChartingDialog: FC<IChartingDialogProps> = ({ onClose, open, ...props }) => {
  return (
    <DialogForm dialogTitle={'Charting'} onClose={onClose} open={open} maxWidth="lg">
      <Charting {...props}></Charting>
    </DialogForm>
  );
};

import { FC, useState, useMemo, useEffect } from 'react';
import { IChartingPageProps } from 'template/Charting/Components/ChartingPage/ChartingPage';
import { Config } from '../Charting/context/DataSourceConfig';
import { chartTableFields } from 'constants/chartTableFields';
import { Typography, FormControlLabel, Checkbox, Button, Grid, Box } from '@mui/material';
import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Asterisks } from 'components/Asterisks/Asterisks';

export interface IChartingPreselectionProps extends IChartingPageProps {
  onSubmit: (config: Config) => void;
}

export const ChartingPreselection: FC<IChartingPreselectionProps> = ({ onSubmit }) => {
  const [config, setConfig] = useState<Config>({ columns: [] });

  const allColumns = useMemo(() => {
    const all = [];
    for (let item of chartTableFields.data) {
      all.push({
        id: uuidv4(),
        sheetIndex: 0,
        ...omit(item, 'sheet'),
      });
      if (item.sheet) {
        for (let item2 of item.sheet.data) {
          all.push({
            id: uuidv4(),
            sheetIndex: 1,
            ...omit(item2, 'sheet'),
          });
        }
      }
    }
    return all;
  }, []);

  useEffect(() => {
    console.log('All Columns', allColumns);
  }, [allColumns]);

  return (
    <div style={{ margin: '0 20px 20px 20px' }}>
      <Grid container>
        {allColumns.map((item) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControlLabel
                label={
                  <Typography sx={{ fontSize: '0.875rem' }}>
                    {item.fieldName}
                    <Asterisks
                      count={item.group}
                      tooltipTitle="Document Type is required"
                    ></Asterisks>
                  </Typography>
                }
                control={
                  <Checkbox
                    value={item.id}
                    size="small"
                    checked={config.columns.some((col) => col.id === item.id)}
                    onChange={(e) => {
                      setConfig((config) => {
                        if (!e.currentTarget) {
                          return config;
                        }
                        const columns = [...config.columns];
                        const index = columns.findIndex((col) => col.id === e.currentTarget.value);
                        if (index >= 0) {
                          columns.splice(index, 1);
                        } else {
                          columns.push(allColumns.find((col) => col.id === e.currentTarget.value)!);
                        }
                        return { columns };
                      });
                    }}
                  />
                }
              />
            </Grid>
          );
        })}
      </Grid>
      <Box mt={2} />
      <Grid container>
        <Grid item>
          <Asterisks count={1}></Asterisks> - Required input data
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button color="primary" variant="contained" size="medium" onClick={() => onSubmit(config)}>
          Continue
        </Button>
      </div>
    </div>
  );
};

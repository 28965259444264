import { useQuery } from '@apollo/client';
import { FC } from 'react';

import { useGanttEvents } from '../../hooks/ganttEvents';
import { GanttChart } from 'components/GanttChart/GanttChart';
import { proposal } from 'graphql/proposals/types/proposal';
import { GET_PROPOSAL } from 'graphql/proposals/proposals';

export interface IReportGantLog {
  proposalId: number;
}

export const ProposalGanttLog: FC<IReportGantLog> = ({ proposalId }) => {
  const { data } = useQuery<proposal>(GET_PROPOSAL, {
    variables: {
      id: proposalId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { tasks } = useGanttEvents(data?.proposal_proposal.events || []);

  return <GanttChart tasks={tasks} />;
};

import { EventsLog } from '../EventsLog/EventsLog';
import { useComponentContext } from 'template/Proposal/ProposalContext';

// const simulatedValue = {
//   author: {
//     persona: 'PERSONA',
//     user: {
//       id: '1',
//       name: 'Test User',
//       email: 'test@test.com',
//     },
//   },
//   subject: {
//     user: {
//       name: 'subject user',
//     },
//   },
//   version: {
//     version: '1',
//     file: {
//       originalFilename: 'filename',
//     },
//   },
// };

export const ProposalEvents = () => {
  const { proposal, refetchProposal } = useComponentContext();
  const { events, id, name } = proposal;

  return (
    <EventsLog events={events || []} id={id} refetch={refetchProposal} name={name}></EventsLog>
  );
};

import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BondEventsLog } from './components/BondEventsLog';
import { useBondContext } from 'template/Bond/BondContext';

export const BondEvents = () => {
  const { bond } = useBondContext();

  if (!bond?.id) {
    return <></>;
  }

  return (
    <>
      <Paper elevation={3} style={{ padding: '2rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" className="label-title-nocase">
              Events/comments log
            </Typography>
          </Grid>
        </Grid>
        <Box m={2} />

        <Grid container spacing={0}>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <BondEventsLog events={bond.events ?? []}></BondEventsLog>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

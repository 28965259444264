import React, { FC } from 'react';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { ActiveTab, Provider as ProposalProvider } from 'template/Proposal/ProposalContext';
import { Switch, useParams } from 'react-router-dom';
import { ProposalFrame } from 'template/Proposal/components/ProposalFrame/ProposalFrame';
import { useQuery } from '@apollo/client';
import { proposal } from 'graphql/proposals/types/proposal';
import { GET_PROPOSAL } from 'graphql/proposals/proposals';
import LoadingOverlay from 'react-loading-overlay-ts';

interface IUrlParams {
  id: string;
  tab: string;
}
export interface IProps {
  routes: Array<any>;
}

export const ProposalPage: FC<IProps> = ({ routes }) => {
  const { id, tab } = useParams<IUrlParams>();
  const activeTab: ActiveTab = tab ? (tab as ActiveTab) : 'details';

  const { data, loading, refetch } = useQuery<proposal>(GET_PROPOSAL, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <LoadingOverlay spinner active={loading && !data} text="Loading your content...">
      <ProposalProvider
        key={id}
        proposalId={id}
        activeTab={activeTab}
        loadedProposal={data?.proposal_proposal}
        refetch={refetch}
        loading={loading}
      >
        <ProposalFrame>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </ProposalFrame>
      </ProposalProvider>
    </LoadingOverlay>
  );
};

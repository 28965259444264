function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}

export const isValidYearSearch = (year?: string) => {
  return (
    year && isNumeric(year) && parseInt(year) >= 2000 && parseInt(year) <= new Date().getFullYear()
  );
};

export const isValidChancesYearSearch = (year?: string) => {
  return year && isNumeric(year) && parseInt(year) >= new Date().getFullYear();
};

import React from 'react';
import { NewParty as NewPartyTemplate } from 'template/NewParty/NewParty';
import { Provider as PartyProvider } from 'template/Party/PartyContext';

export const NewPartie = () => {
  return (
    <PartyProvider activeTab="new">
      <NewPartyTemplate></NewPartyTemplate>
    </PartyProvider>
  );
};

import { keyBy } from 'lodash';

export enum ChartingFieldType {
  'numeric' = 'numeric',
  'date' = 'date',
  'month' = 'month',
  'category' = 'category',
  'categoryNumber' = 'categoryNumber',
  'categoryArray' = 'categoryArray',
  'any' = 'any',
}

export type ConversionFunctionId = 'value' | 'year' | 'month' | 'month1st';
export type AggregateFunctionId =
  | 'select'
  | 'group'
  | 'count'
  | 'countNumbers'
  | 'sum'
  | 'max'
  | 'min';
export type SortFunctionId = 'asc' | 'desc';

export interface IConversionFunction {
  id: ConversionFunctionId;
  name: string;
  short?: string;
  resultType?: ChartingFieldType;
}

export const conversionFunction: IConversionFunction[] = [
  {
    id: 'value',
    name: 'Take original value',
  },
  {
    id: 'year',
    name: 'Convert Date to Year (Year)',
    short: 'year',
    resultType: ChartingFieldType.numeric,
  },
  {
    id: 'month',
    name: 'Convert Date to Month (Month)',
    short: 'month',
    resultType: ChartingFieldType.month,
  },
  {
    id: 'month1st',
    name: 'Get first day of month',
    short: 'month 1st',
    resultType: ChartingFieldType.date,
  },
];
export const conversionFunctionHash = keyBy(conversionFunction, 'id');

export interface IAggregateFunction {
  id: AggregateFunctionId;
  name: string;
  short?: string;
}

export const aggregateFunction: IAggregateFunction[] = [
  {
    id: 'select',
    name: 'Select Items',
  },
  {
    id: 'group',
    name: 'Group by Value',
    short: 'group by',
  },
  {
    id: 'count',
    name: 'Count Items',
    short: 'count',
  },
  {
    id: 'countNumbers',
    name: 'Count Numbers',
    short: 'count num.',
  },
  {
    id: 'sum',
    name: 'Get Cumulative Value (Sum)',
    short: 'sum',
  },
  {
    id: 'max',
    name: 'Find Maximum Value (Max)',
    short: 'max',
  },
  {
    id: 'min',
    name: 'Find Minimum Value (Min)',
    short: 'min',
  },
];
export const aggregateFunctionHash = keyBy(aggregateFunction, 'id');

export interface ISortFunction {
  id: SortFunctionId;
  name: string;
  short: string;
}

export const sortFunction: ISortFunction[] = [
  {
    id: 'asc',
    name: 'Ascending',
    short: '',
  },
  {
    id: 'desc',
    name: 'Descending',
    short: 'desc',
  },
];

export const sortFunctionHash = keyBy(sortFunction, 'id');

export type IOptionsByType = {
  [key in ChartingFieldType]: {
    aggregateFunctionIds: AggregateFunctionId[];
    sortFunctionIds: SortFunctionId[];
    conversionFunctionIds: ConversionFunctionId[];
    defaults: {
      aggregateFunctionId: AggregateFunctionId;
      sortFunctionId: SortFunctionId;
      conversionFunctionId: ConversionFunctionId;
    };
  };
};

export const optionsByType: IOptionsByType = {
  numeric: {
    aggregateFunctionIds: ['select', 'count', 'countNumbers', 'group', 'min', 'max', 'sum'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value'],
    defaults: {
      aggregateFunctionId: 'select',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
  date: {
    aggregateFunctionIds: ['select', 'count', 'group', 'min', 'max'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value', 'month', 'year', 'month1st'],
    defaults: {
      aggregateFunctionId: 'select',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
  month: {
    aggregateFunctionIds: ['select', 'count', 'group', 'min', 'max'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value'],
    defaults: {
      aggregateFunctionId: 'group',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
  category: {
    aggregateFunctionIds: ['select', 'count', 'group'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value'],
    defaults: {
      aggregateFunctionId: 'group',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
  categoryArray: {
    aggregateFunctionIds: ['select', 'count', 'group'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value'],
    defaults: {
      aggregateFunctionId: 'group',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
  categoryNumber: {
    aggregateFunctionIds: ['select', 'count', 'group', 'min', 'max'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value'],
    defaults: {
      aggregateFunctionId: 'group',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
  any: {
    aggregateFunctionIds: ['select', 'count', 'countNumbers', 'group', 'min', 'max', 'sum'],
    sortFunctionIds: ['asc', 'desc'],
    conversionFunctionIds: ['value', 'year', 'month'],
    defaults: {
      aggregateFunctionId: 'select',
      conversionFunctionId: 'value',
      sortFunctionId: 'asc',
    },
  },
};

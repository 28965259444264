// #region React Core

import React, { FC, useState, useEffect } from 'react';

// #endregion

// #region Material-UI Components

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
  Container,
  Grid,
  LinearProgress,
  LinearProgressProps,
} from '@mui/material';

// #endregion

// #region Component definition interface

import { ProgressInfoProps } from './interfaces';

// #endregion

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

//
// Displays generic Confirmation Dialog with title and message
// with handler when Ok or Cancel button is clicked
//
export const ProgressInfo: FC<ProgressInfoProps> = ({
  open,
  onClose,
  notes,
  progress,
  ...props
}) => {
  // #region State

  const [dialogOpen, setOpen] = useState(false);

  // #endregion

  // #region Effects

  useEffect((): void => {
    setOpen(open);
  }, [open]);

  // #endregion

  // #region Form Methods

  // #endregion
  return (
    <Dialog maxWidth="md" open={dialogOpen}>
      <Container>
        <DialogTitle>
          <Typography>
            <span style={{ fontWeight: 800, fontSize: '0.9rem' }}>
              <div dangerouslySetInnerHTML={{ __html: props?.title ?? 'Confirmation' }} />
            </span>
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText style={{ color: '#444', fontSize: '0.9rem', ...props?.style }}>
            <div dangerouslySetInnerHTML={{ __html: props?.message ?? '' }} />
            {notes ? notes : undefined}
          </DialogContentText>
          <Grid container style={{ width: '100%' }}>
            {progress.map((p) => (
              <div style={{ display: 'contents' }} key={p.name}>
                <Grid item xs={12}>
                  {p.name}:
                </Grid>
                <Grid item xs={12}>
                  <LinearProgressWithLabel value={p.progress} />
                </Grid>
              </div>
            ))}
          </Grid>
        </DialogContent>

        <Box marginBottom={2} />
      </Container>
    </Dialog>
  );
};

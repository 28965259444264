import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import s from './style.module.scss';
import cn from 'classnames';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

export interface IProposalMainTabsProps {
  currentView: string;
  changeView: (view: string) => void;
}

const visibleTabs = [
  {
    label: 'Opportunity Details',
    value: ['details'],
  },
  {
    label: 'File Management',
    value: ['file-management'],
  },
  {
    label: 'Comments/Event Log',
    value: ['events-log'],
  },
];

export const ProposalMainTabs: FC<IProposalMainTabsProps> = ({ currentView, changeView }) => {
  const { confirmRequest } = useFormChangedDialogContext();
  return (
    <Tabs
      value={currentView}
      onChange={(e, val) => {
        confirmRequest!((confirmed) => {
          confirmed && changeView!(val);
        });
      }}
      variant="fullWidth"
      className={s.folderTypeTabs}
      TabIndicatorProps={{ style: { background: '#006AD4' } }}
    >
      {visibleTabs.map((tab) => {
        return (
          <Tab
            label={tab.label}
            value={tab.value[0]}
            className={cn(s.allTabs, tab.value.includes(currentView) ? s.activeTab : s.inactiveTab)}
            key={tab.value[0]}
          />
        );
      })}
    </Tabs>
  );
};

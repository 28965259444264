import React from 'react';
import { Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import { ApolloError } from '@apollo/client';

export const errorMessage = (err: ApolloError | undefined): JSX.Element => {
  return (
    <>
      {err &&
        err?.graphQLErrors?.map((error: any) => (
          <Typography key={uniqueId()} style={{ color: '#f44336' }}>
            {error?.message}
          </Typography>
        ))}
    </>
  );
};

import { ApolloClient, ApolloLink, InMemoryCache, gql } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

import { useCallback, useMemo } from 'react';
import { resolvers } from 'graphql/resolvers';
import { typeDefs } from 'graphql/types';
import { getTokenFromLocalStorage } from 'utils/user';
import server from '../constants/paths/server';
import { useUI } from 'contexts/UiContext';

export const useApolloClient = () => {
  const { addSnackbar } = useUI();

  const httpLink = useMemo(
    () =>
      createUploadLink({
        uri: server.GRAPHQL_API,
      }),
    []
  );

  const cache = useMemo(() => {
    const c = new InMemoryCache();
    c.writeQuery({
      query: gql`
        query {
          token
          account
        }
      `,
      data: {
        account: null,
        token: getTokenFromLocalStorage(),
      },
    });
    return c;
  }, []);

  const getAuthorizationLink = useCallback(
    (token: string) =>
      new ApolloLink((operation, forward) => {
        // const token = getTokenFromLocalStorage();
        // if (!token) {
        //   console.log("authorizationLink Empty token :-(");
        // } else {
        //   console.log("authorizationLink Set token :-)");
        // }

        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : null,
          },
        });

        return forward(operation);
      }),
    []
  );

  const errorLink = useMemo(
    () =>
      onError(({ networkError, graphQLErrors }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            addSnackbar &&
              addSnackbar({
                severity: 'error',
                text: `[Error] ${message}`,
              });

            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          });
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
    [addSnackbar]
  );

  const getClient = useCallback(
    (token: string) =>
      new ApolloClient({
        cache,
        link: ApolloLink.from([errorLink, getAuthorizationLink(token), httpLink as any]),
        typeDefs,
        resolvers,
      }),
    [errorLink, getAuthorizationLink, httpLink, cache]
  );

  return {
    getClient,
  };
};

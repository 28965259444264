import { useCallback } from 'react';
import { IExportToXLSXProps, exportToXLSX } from 'utils/exportToExcel';
import { saveToFile } from 'utils/saveToFile';

export const useExportToExcel = () => {
  const exportData = useCallback((data: IExportToXLSXProps) => {
    const xlsxData = exportToXLSX(data);
    saveToFile(xlsxData, 'chart', '.xlsx');
  }, []);
  return { exportData };
};

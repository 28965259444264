import { FC, useState, useEffect } from 'react';

import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import { RoutesProps } from './interfaces';

import { routes, paths } from '../../constants';
import { useIsAuthenticated } from '@azure/msal-react';
import { useAfterLogin } from 'hooks/afterLoginHook';

const Routes: FC<RoutesProps> = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const location = useLocation();

  const [authenticated, setAuthenticated] = useState<Boolean | undefined>();
  const { getAfterLogin, setAfterLogin, clearAfterLogin } = useAfterLogin();

  useEffect(() => {
    setAuthenticated(isAuthenticated);
  }, [isAuthenticated]);

  // ---------------------

  useEffect(() => {
    const afterLogin = getAfterLogin();
    if (!isAuthenticated) {
      if (!location.pathname?.includes('/login')) {
        const requestedPage =
          history.location.pathname !== '/' ? history.location.pathname : paths.client.BASE;

        if (!afterLogin || (afterLogin === paths.client.BASE && requestedPage !== afterLogin)) {
          setAfterLogin(requestedPage);
        }
      }

      if (!location.pathname?.includes('/login')) {
        history.replace(paths.client.LOGIN);
      }
    } else {
      if (afterLogin) {
        history.replace(afterLogin);
      } else {
        if (location.pathname?.includes('/login')) {
          history.replace(paths.client.BASE);
        }
      }
      clearAfterLogin();
    }
  }, [location.pathname, history, isAuthenticated, setAfterLogin, clearAfterLogin, getAfterLogin]);

  return (
    <div>
      {authenticated !== undefined ? (
        <Switch>
          {routes.map((route: any) => (
            <Route
              exact={route.exact}
              key={route.path}
              path={route.path}
              render={(props) => <route.component {...props} routes={route.routes} />}
            />
          ))}
          <Redirect from={paths.client.BASE} to={paths.client.BASE} />
        </Switch>
      ) : undefined}
    </div>
  );
};

export default Routes;

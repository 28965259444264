import { ProposalEventType } from 'constants/enums';

export const documentStatusColor = (val: Record<string, any>): string => {
  switch (val?.eventType) {
    case ProposalEventType.PROPOSAL_UPDATED:
    case ProposalEventType.PROPOSAL_CREATED:
      return 'black';
    case ProposalEventType.PROPOSAL_FILE_CREATE:
      return 'blue';
    case ProposalEventType.PROPOSAL_COMMENT:
      return 'black';
    case ProposalEventType.PROPOSAL_REVIEW_REQUESTED:
      return 'orange';
    case ProposalEventType.PROPOSAL_REVIEW_APPROVED:
      return 'green';
    // case ProposalEventType.PROPOSAL_ALL_REVIEWS_COMPLETED:
    //   return 'green';
    // case ProposalEventType.PROPOSAL_VERSION_DELETED:
    case ProposalEventType.PROPOSAL_REVIEW_DISAPPROVED:
      return 'red';
    case ProposalEventType.PROPOSAL_REVIEW_POSTPONED:
      return 'grey';
    // case ProposalEventType.PROPOSAL_ARCHIVED:
    // case ProposalEventType.PROPOSAL_ARCHIVE_INITIATED:
    // case ProposalEventType.PROPOSAL_ARCHIVE_PROPOSAL_STORED:
    //   return 'green';
    // case ProposalEventType.PROPOSAL_CLIENT_APPROVAL_STATUS_CHANGED:
    //   return 'black';

    default:
      return 'black';
  }
};

import { useLazyQuery } from '@apollo/client';
import { PROPOSAL_AGGREGATES } from 'graphql/proposals/aggregates';
import { proposalAggregates } from 'graphql/proposals/types/proposalAggregates';
import { useCallback, useEffect, useRef } from 'react';

export interface AggregatesByStage {
  year: number;
}

export const useAggregatesByStage = ({ year }: AggregatesByStage) => {
  const [AggregatesByStage, { called, data, loading, error, refetch }] =
    useLazyQuery<proposalAggregates>(PROPOSAL_AGGREGATES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadAggregatesByStage = useCallback(
    (year: number) => {
      const variables = {
        filter: {
          createdAtFrom: new Date(year, 0, 1),
          createdAtTo: new Date(year, 11, 31, 23, 59, 59, 999),
          entityType: 'PROPOSAL',
        },
        group: {
          stage: true,
        },
        sort: {
          column: 'BID_PROPOSED_VALUE_SUM',
          order: 'ASC',
        },
      };

      if (called) {
        refetch!(variables);
      } else {
        AggregatesByStage({ variables });
      }
    },
    [AggregatesByStage, called, refetch]
  );

  const loadAggregatesByStageRef = useRef(loadAggregatesByStage);

  useEffect(() => {
    loadAggregatesByStageRef.current && loadAggregatesByStageRef.current(year);
  }, [year, loadAggregatesByStageRef]);

  return {
    loadAggregatesByStage,
    data,
    loading,
    error,
  };
};

import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { ProposalSortableColumn } from 'graphql/proposals/types/graphql-types';
import { proposalTypeOptions } from 'template/Opportunity/components/ProposalForm/constants/proposalTypeOptions';
import { capitalizeFirstLetter } from 'utils/formats';
import {
  PromotedToProposal,
  TransformCurrencyUS,
  TransformProposalLead,
  transformProposalLeadText,
  TransformProposalStage,
  transformProposalStageText,
  TransformStringList,
} from 'utils/TableTransformFunctions';

export const opportunitiesTableHead: IHeadCell[] = [
  {
    id: 'proposal',
    numeric: false,
    disablePadding: false,
    label: 'Proposal',
    sortable: true,
    sortBy: ProposalSortableColumn.REFERENCE_PROPOSALS_COUNT,
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: ({ ...props }): any => {
      return PromotedToProposal({ ...props });
    },
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Opportunity Name',
    sortable: true,
    sortBy: ProposalSortableColumn.NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
  },
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Division',
    sortable: true,
    sortBy: ProposalSortableColumn.OWNING_DIVISION_NAME,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data) => data?.division?.name || '--',
  },
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    sortable: true,
    sortBy: ProposalSortableColumn.PARTY_NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data) =>
      data
        ? (data.projectSetupClientCode ? data.projectSetupClientCode + ' - ' : '') + data.name
        : '--',
  },
  {
    id: 'proposalType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: true,
    sortBy: ProposalSortableColumn.PROPOSAL_TYPE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },

    transformFunction: (proposalType) => {
      const found = proposalTypeOptions.find((item) => item.id === proposalType);
      return proposalType ? (found ? found.name : proposalType) : '--';
    },
  },
  {
    id: 'leadUser',
    numeric: false,
    disablePadding: false,
    label: 'Opportunity Lead',
    sortable: true,
    sortBy: ProposalSortableColumn.PROPOSAL_USER_LEAD_NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: transformProposalLeadText,
    transformDataCell: TransformProposalLead,
  },
  {
    id: 'successChance',
    numeric: false,
    disablePadding: false,
    label: 'Chance of Success',
    sortable: true,
    sortBy: ProposalSortableColumn.SUCCESS_CHANCE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : '--'),
  },
  {
    id: 'bidProposedValue',
    numeric: true,
    disablePadding: false,
    label: 'Opportunity Amount',
    sortable: true,
    sortBy: ProposalSortableColumn.BID_PROPOSED_VALUE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: any) => (data ? data : '-- '),
    transformDataCell: TransformCurrencyUS,
    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    sortable: true,
    sortBy: ProposalSortableColumn.CREATED_AT,
    filter: 'mobile-date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Changed',
    sortable: true,
    sortBy: ProposalSortableColumn.UPDATED_AT,
    filter: 'mobile-date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Opportunity Stage',
    sortable: true,
    sortBy: ProposalSortableColumn.STAGE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: transformProposalStageText,
    transformDataCell: TransformProposalStage,
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Opportunity Due Date',
    sortable: true,
    sortBy: ProposalSortableColumn.DUE_DATE,
    filter: 'date',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
  {
    id: 'workTypes',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',
    sortable: true,
    sortBy: ProposalSortableColumn.WORK_TYPE_NAME,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames.join(String.fromCharCode(10));
    },
    transformDataCell: ({ ...props }) => {
      const { data } = props;
      const dataNames = data ? data.map((item: any) => item.name) : undefined;
      return TransformStringList({ ...props, data: dataNames });
    },
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },
];

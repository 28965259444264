import { ProposalStage } from 'graphql/proposals/types/graphql-types';

export const getWorkflowGeneralItem = (itemId: ProposalStage) => {
  const found = AllWorkflowStates.find((item) => item.id === itemId);
  if (!found) return undefined;
  return { id: itemId, name: found.name };
};

type CustomProposalStages = 'PromoteToProposal' | 'PromotedToProposal';
type ExtendedProposalStages = ProposalStage | CustomProposalStages;

export const AllWorkflowStates: {
  id?: ExtendedProposalStages;
  name: string;
  group: number;
}[] = [
  { id: ProposalStage.DRAFT, name: 'Draft', group: 1 },
  { id: ProposalStage.PENDING, name: 'Pending Decision', group: 2 },
  { id: ProposalStage.CANCELED, name: 'Canceled', group: 2 },
  { id: ProposalStage.PROMOTED, name: 'Promoted to Proposal', group: 2 },
];

export const WorkflowStates: {
  id?: ExtendedProposalStages;
  name: string;
  group: number;
}[] = [
  { id: ProposalStage.DRAFT, name: 'Draft', group: 1 },
  { id: ProposalStage.PENDING, name: 'Pending Decision', group: 2 },
  { id: ProposalStage.CANCELED, name: 'Canceled', group: 2 },
  { id: 'PromoteToProposal', name: 'Promote to Proposal', group: 2 },
];

export const NewProposalWorkflowStates: {
  id: ExtendedProposalStages;
  name: string;
  group: number;
}[] = [
  { id: ProposalStage.DRAFT, name: 'Draft', group: 1 },
  { id: ProposalStage.PENDING, name: 'Pending Decision', group: 2 },
  { id: ProposalStage.CANCELED, name: 'Canceled', group: 2 },
];

export const PromotedProposalWorkflowStates: {
  id: ExtendedProposalStages;
  name: string;
  group: number;
}[] = [{ id: 'PromotedToProposal', name: 'Promoted to Proposal', group: 2 }];

export interface WorkflowTransition {
  toState: ProposalStage;
  askForReason?: boolean;
  askForLoss?: boolean;
}

export const proposalWorkflow: Array<{ state: ProposalStage; to: WorkflowTransition[] }> = [
  {
    state: ProposalStage.DRAFT,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.PENDING,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.WON,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.LOST,
    to: [
      { toState: ProposalStage.PENDING, askForLoss: true },
      { toState: ProposalStage.WON, askForLoss: true },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.CANCELED,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.POSTPONED,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
];

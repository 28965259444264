import { useState, useEffect, FC } from 'react';
import { Tabs, Tab } from '@mui/material';

export type ChartingTabsCode = 'ds' | 'chart';

const tabs = [
  {
    label: 'Data Source',
    value: 'ds',
  },
  {
    label: 'Chart',
    value: 'chart',
  },
];

export interface IChartingTabs {
  onChange: (view: ChartingTabsCode) => void;
}
export const ChartingTabs: FC<IChartingTabs> = ({ onChange }) => {
  const [view, setView] = useState<ChartingTabsCode>('ds');
  useEffect(() => {
    onChange(view);
  }, [view, onChange]);

  return (
    <div>
      <Tabs
        value={view}
        onChange={(e, val) => setView(val)}
        //   variant="fullWidth"
        //   className={s.folderTypeTabs}
        TabIndicatorProps={{ style: { background: '#006AD4' } }}
      >
        {tabs.map((tab) => {
          return (
            <Tab
              label={tab.label}
              value={tab.value}
              // className={cn(s.allTabs, tab.value.includes(view) ? s.activeTab : s.inactiveTab)}
              key={tab.value}
            />
          );
        })}
      </Tabs>
    </div>
  );
};

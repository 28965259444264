import { Popover, PopoverProps } from '@mui/material';
import { FC } from 'react';

export interface IPopoverFormProps extends PopoverProps {
  handleClose: () => void;
}

export const PopoverForm: FC<IPopoverFormProps> = ({
  id,
  open,
  anchorEl,
  handleClose,
  children,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
};

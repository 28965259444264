import { gql } from '@apollo/client';
import { ICountry } from './countriesFragments';
import { IStockExchange } from './stockExchanges';

export const IUserData = gql`
  fragment IUserData on User {
    id
    oid
    email
    name
    isActive
  }
`;

export const IPartyType = gql`
  fragment IPartyType on PartyType {
    id
    name
  }
`;

export const IPartyDesignation = gql`
  fragment IPartyDesignation on PartyDesignation {
    id
    name
  }
`;

export const IPartyEvent = gql`
  fragment IPartyEvent on PartyEvent {
    id
    eventType
    payload
    createdAt
    author {
      id
      oid
      email
      name
    }
  }
`;

export const IDivisionName = gql`
  fragment IDivisionName on Division {
    id
    name
  }
`;

export const IPartyGeo = gql`
  fragment IPartyGeo on PartyGeo {
    geoX
    geoY
    updatedAt
  }
`;

export const IPartyRef = gql`
  fragment IPartyRef on Party {
    id
    name
    projectSetupClientCode
  }
`;

export const IAssignedPartyRelationship = gql`
  fragment IAssignedPartyRelationship on AssignedPartyRelationship {
    id
    otherParty {
      ...IPartyRef
    }
  }
  ${IPartyRef}
`;

export const IPartyStockExchange = gql`
  fragment IPartyStockExchange on PartyStockExchange {
    id
    stockExchangeValue
    stockExchangeSymbol
    stockMarket {
      ...IStockExchange
    }
  }
  ${IStockExchange}
`;

export const IPartyGeoInfo = gql`
  fragment IPartyGeoInfo on Party {
    id
    name
    isActive
    partyType {
      ...IPartyType
    }
    isApplicationControlled
    geos {
      ...IPartyGeo
    }
  }
  ${IPartyType}
  ${IPartyGeo}
`;

export const IParty = gql`
  fragment IParty on Party {
    id
    name
    alias
    streetAddress
    streetAddress2
    addressCity
    addressState
    addressZip
    isActive
    partyType {
      ...IPartyType
    }
    partyDesignation {
      ...IPartyDesignation
    }
    country {
      ...ICountry
    }
    isApplicationControlled
    projectSetupClientCode
    events {
      ...IPartyEvent
    }
    geos {
      ...IPartyGeo
    }
    divisionOwnership {
      ...IDivisionName
    }
    focalPointUser {
      ...IUserData
    }
    stockExchanges {
      ...IPartyStockExchange
    }

    notes
    assignedPartyRelationships {
      ...IAssignedPartyRelationship
    }

    twitterUsername
    linkedInVanityName
    websiteUrl
    parentCompanyName
    createdAt
    updatedAt
  }
  ${IPartyType}
  ${IPartyDesignation}
  ${ICountry}
  ${IPartyEvent}
  ${IDivisionName}
  ${IUserData}
  ${IPartyStockExchange}
  ${IAssignedPartyRelationship}
  ${IPartyGeo}
`;

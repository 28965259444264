const PREVENT_WHITE_AND_BLACK = true;

export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const generateColors = (total: number) => {
  const area = 0xffffff;
  const stepSize = Math.floor(area / (total + (PREVENT_WHITE_AND_BLACK ? 2 : 0)));
  const offset = PREVENT_WHITE_AND_BLACK ? stepSize : 0;
  const colors: string[] = [];
  for (let i = 0; i < total; i++) {
    const h = (offset + i * stepSize).toString(16).toUpperCase();

    const color = '#000000'.slice(0, 7 - h.length) + h;
    colors.push(color);
  }
  return colors;
};

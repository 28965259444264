// 2020-06-18T11:55:32.308Z
// https://moment.github.io/luxon/docs/manual/formatting

import { DateTime } from 'luxon';

// const FORMAT = 'MM.DD.YYYY - hh:mm A';
const FORMAT_DATE = 'MM.DD.YYYY';

const formatProposalDate = (date: string | Date): string => {
  if (!date) {
    return '-';
  }
  let stillUtc = DateTime.fromISO(date);
  return stillUtc.toLocaleString(DateTime.DATE_SHORT).replaceAll(' ', '');
};

const formatProposalFileManagementTime = (date: string | Date): string => {
  if (!date) {
    return '-';
  }
  let stillUtc = DateTime.fromISO(date);
  return (
    stillUtc.toLocaleString(DateTime.DATE_SHORT).replaceAll(' ', '') +
    ' - ' +
    stillUtc.toLocaleString(DateTime.TIME_24_SIMPLE)
  );
};

const formatTime = (date: string | Date): string => {
  if (!date) {
    return '-';
  }
  let stillUtc = DateTime.fromISO(date);
  return stillUtc.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
};

const formatDate = (date: string | Date): string => {
  let stillUtc;

  return date
    ? ((stillUtc = DateTime.utc(date)), DateTime(stillUtc).local().format(FORMAT_DATE))
    : '-';
};

export { formatTime, formatDate, formatProposalFileManagementTime, formatProposalDate };

import React, { FC, ReactElement } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { HeaderCell, SortOrder } from '../HeaderCell/HeaderCell';
import s from './style.module.scss';
import cn from 'classnames';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { ChartingFieldType } from 'template/Charting/Components/SelectDataSource/constants';

interface IItem {
  id: string;
  name: string;
}

export interface IChartHeadCell extends IHeadCell {
  fieldType?: ChartingFieldType;
  group?: number;
}
export interface IHeadCell {
  cellType?: 'input' | 'divider';
  disablePadding: boolean;
  id: string;
  label: string;
  inputLabel?: string;
  numeric: boolean;
  sortable?: boolean;
  sortBy?: string;
  align?: 'left' | 'center' | 'right';
  padding?: 'half';
  dataCell?: {
    align?: 'left' | 'center' | 'right';
    wrap?: 'nowrap' | 'box';
    connected?: 'left' | 'middle' | 'right';
    shrink?: 'content';
    padding?: 'half';
  };
  transformDataCell?: (
    data: any,
    row?: any,
    order?: SortOrder,
    sorted?: boolean
  ) => ReactElement<any, any> | null;
  transformFunction?: (
    data: any | undefined,
    row?: any,
    order?: SortOrder,
    sorted?: boolean
  ) => any;
  filter?:
    | 'text'
    | 'dropdown'
    | 'date'
    | 'mobile-date'
    | 'component'
    | 'hierarchy-multiple'
    | 'text-dropdown'
    | 'date-range';
  filterProps?: any;
  filterComponent?: ({
    onSelectChange,
    selectedItem,
  }: {
    onSelectChange?: (selectedItem: IItem) => void;
    selectedItem?: IItem | null;
    [key: string]: any;
  }) => ReactElement<any, any> | null;
  colSpan?: number;
  excelHeadStyle?: any;
  excelRowStyle?: any;
}

export interface TableHeadProps {
  headCells: IHeadCell[];
  orderBy?: string;
  order?: SortOrder;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  printView?: boolean;
  stickyHeader?: boolean;
}

export const TableHead: FC<TableHeadProps> = ({
  order,
  orderBy,
  headCells,
  onRequestSort,
  printView,
  stickyHeader,
}) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  let count = -1;
  return (
    <TableRow>
      {headCells.map((headCell) => {
        count++;
        return (
          <TableCell
            key={count.toString() + '_' + headCell.id}
            className={cn({ [s.paddingHalf]: headCell.padding === 'half' })}
            style={stickyHeader ? { backgroundColor: 'white' } : undefined}
          >
            <div
              onClick={
                headCell.sortable
                  ? debounce(createSortHandler(headCell.sortBy || headCell.id), DEBOUNCE_TIMEOUT)
                  : undefined
              }
            >
              <HeaderCell
                key={headCell.id}
                label={headCell.label}
                sortable={
                  headCell.sortable &&
                  (!printView || orderBy === headCell.sortBy || orderBy === headCell.id)
                }
                sort={orderBy === headCell.sortBy || orderBy === headCell.id ? order : undefined}
              ></HeaderCell>
            </div>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

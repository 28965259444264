import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FC } from 'react';
import { ColumnTemplateItem } from './ColumnTemplateItem';

const splitInto2Tuple = (arr: Array<any>) => {
  const result = arr.reduce(function (r, a, i) {
    if (i % 2) {
      r[r.length - 1].second = a;
    } else {
      r.push({ first: a });
    }
    return r;
  }, []);
  return result;
};

interface ITuple {
  first: Array<ITwoColumnItem>;
  second?: Array<ITwoColumnItem>;
}

export type FieldType =
  | 'label'
  | 'text'
  | '$'
  | 'easyAutoComplete'
  | 'textareaAutosize'
  | 'date'
  | 'msal';
export interface ITwoColumnItem {
  title: string;
  id: string;
  field?:
    | {
        type: FieldType;
        props?: any;
      }
    | undefined;
  parse?: (value: any) => any;
  parentField?: string;
  hidden?: boolean;
}

export interface ITwoColumnTemplateProps {
  data: Array<Array<ITwoColumnItem>>;
  meta: {
    [key: string]: {
      props: any;
    };
  };
  values: any;
  onFieldChange: any;
  onFieldUndo: any;
  hasFieldChanged: any;
  printView?: boolean;
  disabled?: boolean;
}

export const TwoColumnTemplate: FC<ITwoColumnTemplateProps> = ({
  data,
  meta,
  values,
  onFieldChange,
  onFieldUndo,
  hasFieldChanged,
  printView,
  disabled,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const tuples = splitInto2Tuple(data);
  let index = -1;
  return tuples.map((tuple: ITuple) => {
    index++;
    return (
      <Grid container padding={0} spacing={4} key={index}>
        <Grid xs={12}>
          <Grid container padding={0} spacing={matches ? 6 : 2}>
            <Grid xs={12} md={6}>
              {tuple.first.map((item: ITwoColumnItem) => {
                index++;
                return (
                  <Grid container padding={0} spacing={4} key={index}>
                    <ColumnTemplateItem
                      disabled={disabled}
                      item={item}
                      meta={meta}
                      values={values}
                      onFieldChange={onFieldChange}
                      onFieldUndo={onFieldUndo}
                      hasFieldChanged={hasFieldChanged}
                      printView={printView}
                    ></ColumnTemplateItem>
                  </Grid>
                );
              })}
            </Grid>
            <Grid xs={12} md={6}>
              {tuple.second?.map((item: ITwoColumnItem) => {
                index++;
                return (
                  <Grid container padding={0} spacing={4} key={index}>
                    <ColumnTemplateItem
                      disabled={disabled}
                      item={item}
                      meta={meta}
                      values={values}
                      onFieldChange={onFieldChange}
                      onFieldUndo={onFieldUndo}
                      hasFieldChanged={hasFieldChanged}
                      printView={printView}
                    ></ColumnTemplateItem>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });
};

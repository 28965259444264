import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ITableProps, Table } from './Table';

export interface IStickyHeaderTableProps extends ITableProps {}
export const StickyHeaderTable: FC<IStickyHeaderTableProps> = ({ ...props }) => {
  const paginationRef = useRef<HTMLDivElement>(null);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [bottomHeight, setBottomHeight] = useState<number | undefined>();

  const updateWindowDimensions = useCallback((): void => {
    setScreenHeight(() => window.innerHeight);
    setBottomHeight(() => paginationRef.current?.getBoundingClientRect()?.height);
  }, []);

  const getHeight = useMemo(
    () => (bottomHeight ? `${screenHeight - bottomHeight - 220}px` : undefined),
    [screenHeight, bottomHeight]
  );

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return (): void => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  return (
    <Table
      stickyHeader
      maxHeight={getHeight}
      paginationProps={{ ref: paginationRef }}
      {...props}
    ></Table>
  );
};

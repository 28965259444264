/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';

import { id } from 'utils/id';

import { azureStorageUpload } from 'utils/azureStorageUpload';
import { PROPOSAL_FILE_CREATE } from 'graphql/proposals/fileManager';
import { ProposalFileType } from 'graphql/proposals/types/graphql-types';

export const useNewDocumentVersion = ({
  document,
  proposalFileType,
  proposalFileGroup,
  refetchDocument,
  onUploadStart,
  onUploadEnd,
  multiple,
}: any): any => {
  const [newDocument, setNewDocument] = useState(false);
  const [comment, setComment] = useState('');
  const [requireReapprovalFromAllStakeHolders, setRequireReapprovalFromAllStakeHolders] =
    useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const [percentage, setPercentage] = useState(0);
  const [documentVersionCreate, { loading: isUploading }] = useMutation(PROPOSAL_FILE_CREATE);

  const apolloClient = useApolloClient();

  const handleNewDocument = useCallback(
    (value: any): void => {
      setNewDocument(value);
    },
    [setNewDocument]
  );

  const handleComment = (event: any): void => {
    setComment(event.target.value);
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!multiple) {
        setFiles(acceptedFiles);
      } else {
        setFiles((oldFiles) => {
          const files = [
            ...oldFiles,
            ...acceptedFiles.filter(
              (file: { path: string }) => !oldFiles.find((oldFile) => oldFile.path === file.path)
            ),
          ];
          return files;
        });
      }
    },
    [multiple]
  );

  const removeFile = useCallback(
    (filename: string) => () => {
      setFiles((files) => {
        return files.filter((file) => file.path !== filename);
      });
    },
    []
  );

  const removeFiles = useCallback(() => {
    setFiles([]);
  }, []);

  const handleOpenConfirmDialog = (): void => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = async (result: any): Promise<void> => {
    setOpenConfirmDialog(false);

    if (result === false) return;

    if (files) {
      onUploadStart();

      let hasErrors = false;

      try {
        for (const file of files) {
          const uploadResult = await azureStorageUpload(
            apolloClient,
            proposalFileType,
            proposalFileGroup,
            document?.id,
            file.name,
            file as any
          );

          if (uploadResult.hasErrors) {
            onUploadEnd({ hasErrors: true, errorMessage: uploadResult.errorMessage });
            hasErrors = true;
            // displayError(uploadResult.errorMessage);
            break;
          }

          const uploadedFile = {
            blobContainerName: uploadResult.containerName,
            blobName: uploadResult.blobName,
            fileFilename: uploadResult.fileFilename,
            fileMimeType: uploadResult.fileMimeType,
          };

          await documentVersionCreate({
            variables: {
              comment,
              proposalId: document?.id,
              uploadedFile,
              proposalFileType,
              proposalFileGroup,
            },
          });
        }
      } catch (e: any) {
        onUploadEnd({ hasErrors: true, errorMessage: e?.message });
        hasErrors = true;
      }

      if (!hasErrors) {
        onUploadEnd({ hasErrors: false, errorMessage: '' });

        removeFiles();
        setComment('');
        setRequireReapprovalFromAllStakeHolders(false);
        refetchDocument && refetchDocument({ variables: { id: id() } });
      }
    }
  };

  const handleRequireReapprovalFromAllStakeHolders = (): void => {
    setRequireReapprovalFromAllStakeHolders((prev) => !prev);
  };

  return {
    isUploading,
    percentage,
    files,
    onDrop,
    removeFile,
    removeFiles,
    openConfirmDialog,
    setOpenConfirmDialog,
    requireReapprovalFromAllStakeHolders,
    setRequireReapprovalFromAllStakeHolders,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleRequireReapprovalFromAllStakeHolders,
    comment,
    handleComment,
    newDocument,
    handleNewDocument,
  };
};

import React, { createContext, FC, useContext, useState } from 'react';

export interface IConvertToProposalContextState {
  convertToProposal: IConvertToProposalState;
}

export interface IConvertToProposalContextActions {
  setConvertToProposal: any;
}

export interface IConvertToProposalState {
  convert: boolean;
}

const initialState: IConvertToProposalContextState = {
  convertToProposal: { convert: false },
};

const ConvertToProposalContext = createContext<
  IConvertToProposalContextState & Partial<IConvertToProposalContextActions>
>(initialState);

interface IConvertToProposalProviderProps {
  children: any;
}

export const ConvertToProposalProvider: FC<IConvertToProposalProviderProps> = ({ children }) => {
  const [convertToProposal, setConvertToProposal] = useState<IConvertToProposalState>({
    convert: false,
  });

  return (
    <ConvertToProposalContext.Provider value={{ convertToProposal, setConvertToProposal }}>
      {children}
    </ConvertToProposalContext.Provider>
  );
};

export const useConvertToProposalContext = () => useContext(ConvertToProposalContext);

import { IFieldsDescription } from '../interface';

export const customFields: Array<Array<IFieldsDescription>> = [
  [
    {
      id: 'selectedStage',
      title: 'Stage',
    },
    {
      id: 'selectedCompetitors',
      title: 'Competitors',
    },
    {
      id: 'reviewStatus',
      title: 'Review Status',
    },
    {
      id: 'entityType',
      title: 'Entity Type',
    },
  ],
];

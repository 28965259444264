import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

export interface IGeneralDataObjectsState {
  items: any[];
  loading: boolean;
}

interface IUseGeneralDataObjectsProps {
  GET_QUERY: any;
  getItems: (data: any) => any[];
}

export const useGeneralDataObjects = <itemsList extends unknown>({
  GET_QUERY,
  getItems,
}: IUseGeneralDataObjectsProps): IGeneralDataObjectsState => {
  const { data, loading } = useQuery<itemsList>(GET_QUERY);

  const getObjects = useCallback(() => {
    return (data && !loading && getItems(data)) || [];
  }, [data, getItems, loading]);

  const items = useMemo(() => {
    return getObjects();
  }, [getObjects]);

  return { items, loading };
};

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  SubTitle,
  LogarithmicScale,
  ScatterController,
  RadialLinearScale,
  CategoryScale,
  BarController,
  BarElement,
  BubbleController,
  DoughnutController,
  ArcElement,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  Decimation,
  Filler,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ScatterController,

  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  SubTitle,

  // Axis Scale
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  CategoryScale,

  // Bar chart
  BarController,
  BarElement,
  // Default scales: CategoryScale (x), LinearScale (y)

  // Bubble chart
  BubbleController,
  PointElement,
  // Default scales: LinearScale (x/y)

  // Doughnut chart
  DoughnutController,
  ArcElement,
  // Not using scales

  // Line chart
  LineController,
  LineElement,
  PointElement,
  // Default scales: CategoryScale (x), LinearScale (y)

  // Pie chart
  PieController,
  ArcElement,
  // Not using scales

  //PolarArea chart
  PolarAreaController,
  ArcElement,
  // Default scale: RadialLinearScale (r)

  // Radar chart
  RadarController,
  LineElement,
  // PointElement
  // Default scale: RadialLinearScale (r)

  Decimation,
  Filler,
  TimeScale,
  TimeSeriesScale,
  ChartDataLabels
);

export const useChartJS = () => {
  return {};
};

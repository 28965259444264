import { ConfirmationDialog } from 'components';
import { useCallback, useMemo } from 'react';
import { useComponentContext as useFormChangedDialogContext } from './FormChangedDialogContext';

export const FormChangedDialog = () => {
  const { confirmCallback, leaveAllow, leaveDecline } = useFormChangedDialogContext();

  const openConfirmDialog = useMemo(() => {
    return !!confirmCallback;
  }, [confirmCallback]);

  const handleCloseConfirmDialog = useCallback(
    (confirm: boolean) => {
      if (!confirm) {
        leaveDecline && leaveDecline();
      } else {
        leaveAllow && leaveAllow();
      }
    },
    [leaveDecline, leaveAllow]
  );

  return (
    <ConfirmationDialog
      open={openConfirmDialog}
      title={'Changes will be lost'}
      message={'Please confirm that you would like to leave a page'}
      onClose={handleCloseConfirmDialog}
      reverseColors
    />
  );
};

import { gql } from '@apollo/client';

export const IPartyContact = gql`
  fragment IPartyContact on PartyContact {
    id
    partyId
    contactType
    firstName
    lastName
    jobTitle
    email
    phone
    linkedInUrl
    notes
  }
`;

import { Box, Grid, Link, Typography } from '@mui/material';
import { WaterfallChart as WaterfallChartIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { IProposalEvent } from 'graphql/proposals/types/IProposalEvent';
import { FC, useState } from 'react';
import { EventCommentLog } from './components/EventCommentLogs';
import { EventCommentSubmit } from './components/EventCommentSubmit/EventCommentSubmit';
import { DialogForm } from 'components/DialogForm/DialogForm';
import { GanttChart } from 'components/GanttChart/GanttChart';
import { useGanttEvents } from './hooks/ganttEvents';

const useStyles = makeStyles({
  iconRotate: {
    transform: 'rotate(-90deg)',
  },
});

export interface IEventsLog {
  id: string;
  refetch: any;
  events: IProposalEvent[];
  name?: string | null;
}

export const EventsLog: FC<IEventsLog> = ({ id, refetch, events, name }) => {
  const classes = useStyles();
  const [openGantt, setOpenGantt] = useState(false);
  const { tasks } = useGanttEvents(events);

  return (
    <>
      <DialogForm
        dialogTitle={`Proposal #ID${id} - ${name} - Progress Chart`}
        onClose={() => setOpenGantt(false)}
        open={openGantt}
        maxWidth="xl"
      >
        <div className="dialogGanttChart" style={{ padding: '0 10px 0 10px' }}>
          <GanttChart tasks={tasks} />
        </div>
      </DialogForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              component="button"
              onClick={() => {
                setOpenGantt(true);
              }}
            >
              <div style={{ border: '1px solid lightblue', marginRight: '9px' }}>
                <WaterfallChartIcon className={classes.iconRotate} />
              </div>
            </Link>
            Document Progress Chart
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} />
          <Typography variant="body2" className="label-title-nocase">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>Events/comments log</div>
            </div>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <EventCommentSubmit proposalId={id} refetchDocument={refetch}></EventCommentSubmit>
        </Grid>
        <Grid item xs={12}>
          <EventCommentLog events={events} documentArchived={false}></EventCommentLog>
        </Grid>
      </Grid>
    </>
  );
};

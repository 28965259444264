import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { pick, isArray, forOwn } from 'lodash';
import {
  AggregateFunctionId,
  ChartingFieldType,
  ConversionFunctionId,
  SortFunctionId,
  aggregateFunctionHash,
  conversionFunctionHash,
  sortFunctionHash,
} from '../Components/SelectDataSource/constants';
import { joinCsvTable } from 'utils/csvTable';
import { isDate, isNumeric } from 'utils/math';

export interface DataSource {
  id: string;
  sheetIndex: number;
  fieldKey: string;
  fieldName: string;
  fieldType: ChartingFieldType;
  conversionFunctionId: ConversionFunctionId;
  aggregateFunctionId: AggregateFunctionId;
  sortFunctionId: SortFunctionId;
}

export interface Config {
  columns: DataSource[];
}

export interface IDataSourceConfigContextState {
  chartData?: IChartingData;
  config: Config;
  orderedAxes: { [columnKey: string]: (string | number)[] };
}

export interface IDataSourceConfigContextActions {
  setConfig?: React.Dispatch<React.SetStateAction<Config>>;
  addConfigColumns: (columns: DataSource[]) => void;
}

const initialState: IDataSourceConfigContextState = {
  config: { columns: [] },
  orderedAxes: {},
};

const DataSourceConfigContext = createContext<
  IDataSourceConfigContextState & Partial<IDataSourceConfigContextActions>
>(initialState);

export interface IChartingData {
  sheetName: string;
  csvData: any[]; // columnKey/fieldValue
  headings: any; // columnKey/columnName
}

interface IDataSourceConfigProviderProps {
  children: any;
  data: IChartingData[];
  config: Config;
}

export const compareColumnValues = (
  x: string | number | undefined,
  y: string | number | undefined
) => {
  const a = typeof x !== 'string' ? x : isDate(x) ? new Date(x) : isNumeric(x) ? parseFloat(x) : x;
  const b = typeof y !== 'string' ? y : isDate(y) ? new Date(y) : isNumeric(y) ? parseFloat(y) : y;

  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  if (typeof a === 'string' && typeof b !== 'string') {
    return -1;
  }
  if (typeof a !== 'string' && typeof b === 'string') {
    return 1;
  }
  if (a === '') {
    return 1;
  }
  if (b === '') {
    return -1;
  }

  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }

  return 0;
};

export const getSortFn = (columns: DataSource[]) => {
  return (a: any, b: any) => {
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const compareResult = compareColumnValues(a[column.fieldKey], b[column.fieldKey]);
      if (compareResult === 0) {
        continue;
      }
      return column.sortFunctionId === 'asc' ? compareResult : -compareResult;
    }
    return 0;
  };
};

export const convertFn = (columns: DataSource[], row: any) => {
  return columns.reduce((acc, column) => {
    const { conversionFunctionId, fieldKey } = column;
    switch (conversionFunctionId) {
      case 'value':
        acc[fieldKey] = row[fieldKey];
        break;
      case 'month':
        const dtM = new Date(row[fieldKey]);
        acc[fieldKey] = dtM.getMonth() + 1;
        break;
      case 'year':
        const dtY = new Date(row[fieldKey]);
        acc[fieldKey] = dtY.getFullYear();
        break;
      case 'month1st':
        const d1 = new Date(row[fieldKey]);
        acc[fieldKey] = new Date(d1.getFullYear(), d1.getMonth(), 1).toLocaleDateString('en-EN', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        break;
      default:
        break;
    }
    return acc;
  }, {} as any);
};

export const DataSourceConfigProvider: FC<IDataSourceConfigProviderProps> = ({
  data,
  config: preConfig,
  children,
}) => {
  const [config, setConfig] = useState<Config>(preConfig);
  const [chartData, setChartData] = useState<IChartingData | undefined>();

  const remapedColumns = useMemo(() => {
    const joinData = joinCsvTable(data, config.columns);

    const { sheetName } = joinData[0];

    const csvData = joinData[0].csvData.map((row) => {
      const remappedRow: any = {};
      config.columns.forEach((column, index) => {
        remappedRow[`${index}_${column.fieldKey}`] = row[column.fieldKey];
      });
      return remappedRow;
    });

    const headingsRemapped: any = {};
    config.columns.forEach((column, index) => {
      headingsRemapped[`${index}_${column.fieldKey}`] = column.fieldName;
    });

    const columns = config.columns.map((column, index) => ({
      ...column,
      fieldKey: `${index}_${column.fieldKey}`,
    }));

    return { csvData, headings: headingsRemapped, columns, sheetName };
  }, [config.columns, data]);

  useEffect(() => {
    const { csvData: csvDataS, headings, columns, sheetName } = remapedColumns;

    const csvData = csvDataS.map((row: any) => convertFn(columns, row));

    const columnDescHash = columns.reduce((acc, column) => {
      const { fieldKey, sortFunctionId, conversionFunctionId, aggregateFunctionId } = column;
      const codes = [
        conversionFunctionId ? conversionFunctionHash[conversionFunctionId].short : undefined,
        aggregateFunctionId ? aggregateFunctionHash[aggregateFunctionId].short : undefined,
        sortFunctionId ? sortFunctionHash[sortFunctionId].short : undefined,
      ].filter((code) => code);
      const desc = codes.filter((code) => !!code).join(', ');
      acc[fieldKey] = desc;
      return acc;
    }, {} as any);

    // Select mode active only if only select function is used
    const selectMode = !columns.some(
      (column) =>
        column.sheetIndex >= 0 && column.fieldKey && column.aggregateFunctionId !== 'select'
    );

    if (selectMode) {
      const selectColumns = columns.filter(
        (column) =>
          column.sheetIndex >= 0 && column.fieldKey && column.aggregateFunctionId === 'select'
      );

      const finalTable = {
        sheetName,
        csvData: csvData
          .map((row) =>
            pick(
              row,
              selectColumns.map((column) => column.fieldKey)
            )
          )
          .sort(getSortFn(selectColumns)),
        headings: pick(
          headings,
          selectColumns.map((column) => column.fieldKey)
        ),
      };

      setChartData(finalTable);
    } else {
      const groupColumns = columns.filter(
        (column) =>
          column.sheetIndex >= 0 && column.fieldKey && column.aggregateFunctionId === 'group'
      );

      const groups: { [grupKey: string]: { rowIndex: number[]; group: any } } = {};

      if (groupColumns.length > 0) {
        // group values
        const values = csvData.map((row, rowIndex) => ({
          rowIndex,
          group: pick(
            row,
            groupColumns.map((column) => column.fieldKey)
          ),
        }));

        const combination = Array.from({ length: groupColumns.length }, () => 0);
        const expandedValues: { rowIndex: number; group: any }[] = [];

        values.forEach((row, rowIndex) => {
          let incremented: boolean;
          do {
            incremented = false;
            const rowValue: DataSource[] = [];
            for (let columnIndex = 0; columnIndex < groupColumns.length; columnIndex++) {
              const column = groupColumns[columnIndex];
              const item = row.group[column.fieldKey];
              if (isArray(item)) {
                const arrayItem = item[combination[columnIndex]];
                if (item.length > 1 && !incremented) {
                  combination[columnIndex]++;
                  if (combination[columnIndex] < item.length) {
                    incremented = true;
                  } else {
                    combination[columnIndex] = 0;
                  }
                }
                rowValue.push(arrayItem);
              } else {
                rowValue.push(item);
              }
            }

            expandedValues.push({
              rowIndex: row.rowIndex,
              group: groupColumns.reduce((acc, column, index) => {
                acc[column.fieldKey] = rowValue[index];
                return acc;
              }, {} as any),
            });
          } while (incremented);
        });

        // Make groups
        for (const value of expandedValues) {
          const key = groupColumns.map((column) => value.group[column.fieldKey] || '').join('//');
          if (groups[key]) {
            if (!groups[key].rowIndex.includes(value.rowIndex)) {
              groups[key].rowIndex.push(value.rowIndex);
            }
          } else {
            groups[key] = { rowIndex: [value.rowIndex], group: { ...value.group } };
          }
        }
      } else {
        groups['All'] = {
          rowIndex: Array.from({ length: csvData.length }, (_, i) => i),
          group: {},
        };
      }

      // Add aggregate
      const aggregateColumns = columns.filter(
        (column) =>
          column.sheetIndex >= 0 &&
          column.fieldKey &&
          !['select', 'group'].includes(column.aggregateFunctionId)
      );

      for (const groupKey of Object.keys(groups)) {
        const group = groups[groupKey];
        for (const column of aggregateColumns) {
          let value;

          const numberValues = group.rowIndex
            .map((index) => csvData[index][column.fieldKey])
            .filter((value) => typeof value === 'number');

          switch (column.aggregateFunctionId) {
            case 'count':
              value = group.rowIndex.length;
              break;

            case 'countNumbers':
              value = numberValues.length;
              break;

            case 'max':
              value = numberValues.length
                ? parseFloat(Math.min(...numberValues).toFixed(6))
                : undefined;
              break;

            case 'min':
              value = numberValues.length
                ? parseFloat(Math.max(...numberValues).toFixed(6))
                : undefined;
              break;

            case 'sum':
              value = numberValues.reduce((acc, curr) => {
                const b = parseFloat(curr);
                return parseFloat((acc + b).toFixed(6));
              }, 0);
              break;

            default:
              break;
          }
          group.group[column.fieldKey] = value;
        }
      }

      // Add select
      const selectColumns = columns.filter(
        (column) =>
          column.sheetIndex >= 0 && column.fieldKey && column.aggregateFunctionId === 'select'
      );
      for (const groupKey of Object.keys(groups)) {
        const group = groups[groupKey];
        const rows = group.rowIndex.map((index) => csvData[index]);
        const sorted = rows.sort((a, b) => {
          for (const column of selectColumns) {
            if (column.sortFunctionId === 'asc') {
              if (a[column.fieldKey] > b[column.fieldKey]) {
                return 1;
              }
              if (a[column.fieldKey] < b[column.fieldKey]) {
                return -1;
              }
            } else {
              if (a[column.fieldKey] < b[column.fieldKey]) {
                return 1;
              }
              if (a[column.fieldKey] > b[column.fieldKey]) {
                return -1;
              }
            }
          }
          return 0;
        });

        const selectFields = pick(
          sorted[0],
          selectColumns.map((col) => col.fieldKey)
        );
        Object.assign(group.group, selectFields as any);
      }

      // to keep ordering
      const usedColumnFields = [...groupColumns, ...aggregateColumns, ...selectColumns].map(
        (col) => col.fieldKey
      );
      const showColumns = columns.filter((col) => usedColumnFields.includes(col.fieldKey));

      const newHeadings = pick(
        headings,
        showColumns.map((column) => column.fieldKey)
      );

      for (const headingKey of Object.keys(newHeadings)) {
        newHeadings[headingKey] += columnDescHash[headingKey]
          ? ` (${columnDescHash[headingKey]})`
          : '';
      }

      const finalTable = {
        sheetName,
        csvData: Object.keys(groups)
          .map((key) =>
            pick(
              groups[key].group,
              showColumns.map((column) => column.fieldKey)
            )
          )
          .sort(getSortFn(showColumns)),
        headings: newHeadings,
      };

      setChartData(finalTable);
    }
  }, [data, config, remapedColumns]);

  const orderedAxes = useMemo(() => {
    if (remapedColumns && chartData) {
      const { csvData } = chartData;
      const { columns } = remapedColumns;

      const columnKeys = columns.map((column) => column.fieldKey);
      const uniqueValues: { [columnKey: string]: { [columnValue: string | number]: boolean } } =
        columnKeys.reduce((acc, key) => {
          acc[key] = {};
          return acc;
        }, {} as any);

      for (const row of csvData) {
        for (const column of columns) {
          uniqueValues[column.fieldKey][
            row[column.fieldKey] === undefined ? '' : row[column.fieldKey]
          ] = true;
        }
      }

      const orderedUniqueValues = columns.reduce((acc, column) => {
        const arr: (number | string)[] = [];
        forOwn<{ [columnValue: string | number]: boolean }>(
          uniqueValues[column.fieldKey],
          (b, keyAsColValue) => arr.push(keyAsColValue)
        );

        const ordered = arr.sort(compareColumnValues);
        if (column.sortFunctionId === 'desc') {
          ordered.reverse();
        }

        acc[column.fieldKey] = ordered;
        return acc;
      }, {} as any);

      return orderedUniqueValues;
    }
  }, [remapedColumns, chartData]);

  const addConfigColumns = useCallback((columns: DataSource[]) => {
    setConfig((old) => ({
      ...old,
      columns: [...old.columns, ...columns],
    }));
  }, []);

  const values = useMemo(
    () => ({ chartData, config, setConfig, addConfigColumns, orderedAxes }),
    [chartData, config, orderedAxes, addConfigColumns]
  );

  return (
    <DataSourceConfigContext.Provider value={values}>{children}</DataSourceConfigContext.Provider>
  );
};

export const useDataSourceConfigContext = () => useContext(DataSourceConfigContext);

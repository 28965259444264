import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { allBondsTableHead } from 'constants/allBondsTable';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { useBondsTableContext } from './BondsContext';
import { FC, useEffect, useMemo, useCallback } from 'react';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { useUI } from 'contexts/UiContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useProposalsNamesAutocompleteDataHook } from 'hooks/proposalNamesAutocompleteDataHook';
import { ProposalEntityType } from 'graphql/proposals/types/graphql-types';
import { usePartiesNamesAutocompleteDataHook } from 'hooks/partiesNamesAutocompleteDataHook';
import { ExportToExcel } from './ExportToExcel/ExportToExcel';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useHistory } from 'react-router-dom';

import paths from 'constants/paths';

export interface IBondsViewProps {
  title?: string;
  onReady?: () => void;
  printView?: boolean;
}

export const BondsTableView: FC<IBondsViewProps> = ({ onReady, printView, title }) => {
  const history = useHistory();
  const { addSnackbar } = useUI();
  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    onBondSelect,
    filterOptions,
    filterValues,
    error,
  } = useBondsTableContext();

  const gotoNewBond = useCallback(() => {
    history.push(paths.client.NEW_BOND);
  }, [history]);

  useEffect(() => {
    if (!!onReady && !loading && documents) {
      onReady();
    }
  }, [loading, documents, onReady]);

  useEffect(() => {
    if (!loading && !!error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [loading, error, addSnackbar]);

  const proposalsNamesProps = useProposalsNamesAutocompleteDataHook({
    search: filterValues['name'],
    entityType: ProposalEntityType.PROPOSAL,
  });

  const partiesNamesProps = usePartiesNamesAutocompleteDataHook({
    search: filterValues['party'],
  });

  const extendedBondsHead = useMemo(() => {
    allBondsTableHead.find((item) => item.id === 'proposalName')!.filterProps = proposalsNamesProps;
    allBondsTableHead.find((item) => item.id === 'clientName')!.filterProps = partiesNamesProps;

    return allBondsTableHead;
  }, [proposalsNamesProps, partiesNamesProps]);

  const onSelect = useCallback(
    (id: string) => {
      onBondSelect && onBondSelect(id);
      return true;
    },
    [onBondSelect]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box mt={2} />
          <Grid container spacing={2} padding={'0px'}>
            <Grid xs={12} md={4}>
              <Box
                display="flex"
                justifyContent="left"
                height="100%"
                alignItems="center"
                paddingLeft="10px"
              >
                <ConfirmationButton
                  action={gotoNewBond}
                  text="Create New Bond"
                ></ConfirmationButton>
              </Box>
            </Grid>
            <Grid xs={12} md={4}>
              {filterApplied ? (
                <Box
                  display="flex"
                  justifyContent={matches ? 'center' : 'left'}
                  paddingLeft={matches ? undefined : '10px'}
                >
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={documents}
              headCells={extendedBondsHead}
              loadPage={loadPage}
              handleSelect={onSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrder={pageLoadParams.order || SortOrder.ASC}
              initOrderBy={pageLoadParams.orderBy}
              initPage={pageLoadParams.page}
              paginationSideComponent={
                <ExportToExcel
                  pageLoadParams={pageLoadParams}
                  totalItems={totalItems}
                ></ExportToExcel>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

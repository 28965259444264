import { gql } from '@apollo/client';

export const GET_SAS_URL = gql`
  query generateUploadBlobSASUrl(
    $proposalId: ID!
    $filename: String!
    $proposalFileType: ProposalFileType!
    $proposalFileGroup: ProposalFileGroup!
  ) {
    proposal_generateUploadBlobSASUrl(
      proposalId: $proposalId
      filename: $filename
      proposalFileType: $proposalFileType
      proposalFileGroup: $proposalFileGroup
    ) {
      url
    }
  }
`;

export const GET_BOND_SAS_URL = gql`
  query generateBondUploadBlobSASUrl($bondId: ID!, $filename: String!) {
    bond_generateUploadBlobSASUrl(bondId: $bondId, filename: $filename) {
      url
    }
  }
`;

import React, { FC } from 'react';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { ActiveTab, Provider as PartyProvider } from 'template/Party/PartyContext';
import { Switch, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { party } from 'graphql/proposals/types/party';

import LoadingOverlay from 'react-loading-overlay-ts';
import { GET_PARTY } from 'graphql/proposals/parties';
import { PartyFrame } from 'template/Party/components/PartyFrame/PartyFrame';

interface IUrlParams {
  id: string;
  tab: string;
}
export interface IProps {
  routes: Array<any>;
}

export const ClientPage: FC<IProps> = ({ routes }) => {
  const { id, tab } = useParams<IUrlParams>();
  const activeTab: ActiveTab = tab ? (tab as ActiveTab) : 'details';

  const { data, loading, refetch } = useQuery<party>(GET_PARTY, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <PartyProvider
        partyId={id}
        loadedParty={data?.party ? { ...data?.party } : undefined}
        activeTab={activeTab}
        refetch={refetch}
        loading={loading}
      >
        <PartyFrame id={id}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </PartyFrame>
      </PartyProvider>
    </LoadingOverlay>
  );
};

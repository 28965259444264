import { Grid, useMediaQuery, useTheme } from '@mui/material';
import s from './style.module.scss';
import { useComponentContext } from 'template/Opportunity/ProposalContext';
import { ProposalFileGroup } from 'graphql/proposals/types/graphql-types';
import { FC } from 'react';
import { ProposalDocumentInfo } from './components/ProposalDocumentInfo/ProposalDocumentInfo';
import { proposalDocuments_proposal_proposal_documents } from 'graphql/proposals/types/proposalDocuments';

export interface IProposalDocumentsList {
  approvedDateTs: number | undefined;
  clientProposalDocuments: proposalDocuments_proposal_proposal_documents[];
  disabled?: boolean;
}

export const ProposalDocumentsList: FC<IProposalDocumentsList> = ({
  approvedDateTs,
  clientProposalDocuments,
  disabled,
}) => {
  const {
    proposal: { id },
    refetchProposal,
    reviewersDocumentUsersByDocumentId,
  } = useComponentContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} className={s.categoryTitle} order={{ xs: 1, md: 1 }}>
        <div className={s.categoryUnderline}>CLIENT DOCUMENTS</div>
      </Grid>

      <Grid item xs={12} md={4} className={s.categoryTitle} order={{ xs: 3, md: 2 }}>
        <div className={s.categoryUnderline}>D&M SUBMITTED DOCUMENTS</div>
      </Grid>

      <Grid item xs={12} md={4} className={s.categoryTitle} order={{ xs: 5, md: 3 }}>
        <div className={s.categoryUnderline}>D&M SUPPORTING DOCUMENTS</div>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        className={matches ? s.categoryFilesColumn : undefined}
        order={{ xs: 2, md: 4 }}
        style={{ position: 'relative' }}
      >
        <Grid container spacing={2}>
          {clientProposalDocuments
            ?.filter(
              (proposalDocument) =>
                proposalDocument.proposalFileGroup === ProposalFileGroup.CLIENT_DOCUMENT
            )
            .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
            .map((proposalDocument) => (
              <Grid item xs={12} key={proposalDocument.id}>
                <ProposalDocumentInfo
                  disabled={disabled}
                  reviewers={reviewersDocumentUsersByDocumentId[proposalDocument.id]}
                  proposalDocument={proposalDocument}
                  approvedDateTs={approvedDateTs}
                  proposalId={id}
                  refetchProposal={refetchProposal!}
                ></ProposalDocumentInfo>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        className={matches ? s.categoryFilesColumn : undefined}
        order={{ xs: 4, md: 5 }}
        style={{ position: 'relative' }}
      >
        <Grid container spacing={2}>
          {clientProposalDocuments
            ?.filter(
              (proposalDocument) =>
                proposalDocument.proposalFileGroup === ProposalFileGroup.DEMAC_SUBMITTED_DOCUMENT
            )
            .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
            .map((proposalDocument) => (
              <Grid item xs={12} key={proposalDocument.id}>
                <ProposalDocumentInfo
                  disabled={disabled}
                  reviewers={reviewersDocumentUsersByDocumentId[proposalDocument.id]}
                  proposalDocument={proposalDocument}
                  approvedDateTs={approvedDateTs}
                  proposalId={id}
                  refetchProposal={refetchProposal!}
                ></ProposalDocumentInfo>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        className={matches ? s.categoryFilesColumn : undefined}
        order={{ xs: 6, md: 6 }}
        style={{ position: 'relative' }}
      >
        <Grid container spacing={2}>
          {clientProposalDocuments
            ?.filter(
              (proposalDocument) =>
                proposalDocument.proposalFileGroup === ProposalFileGroup.DEMAC_SUPPORTING_DOCUMENT
            )
            .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
            .map((proposalDocument) => (
              <Grid item xs={12} key={proposalDocument.id}>
                <ProposalDocumentInfo
                  disabled={disabled}
                  reviewers={reviewersDocumentUsersByDocumentId[proposalDocument.id]}
                  proposalDocument={proposalDocument}
                  approvedDateTs={approvedDateTs}
                  proposalId={id}
                  refetchProposal={refetchProposal!}
                ></ProposalDocumentInfo>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

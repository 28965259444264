import { ProposalStage } from 'graphql/proposals/types/graphql-types';
import { useMemo } from 'react';
import { IProposalData } from 'template/Opportunity/ProposalContext';
import { IFieldsDescription } from '../interface';

export const useOtherFields = (
  proposalData: IProposalData,
  proposalStatus: ProposalStage
): { fields: Array<Array<IFieldsDescription>> } => {
  const otherFields = useMemo(() => {
    return [[]];
  }, []);

  return {
    fields: otherFields,
  };
};

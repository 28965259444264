import React, { FC, useState, useEffect } from 'react';
import { Grid, Button, CircularProgress, useTheme, useMediaQuery } from '@mui/material';

import { DocumentApprovalReviewerProps } from './interfaces';

import { EasyAutoComplete, ConfirmationDialog, AlertDialog } from 'components';
import { Dialog } from 'interfaces';
import { DocumentUserStatus } from 'constants/enums';
import { PROPOSAL_REQUEST_REVIEW } from 'graphql/proposals/proposals';
import { useMutation } from '@apollo/client';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

// import { IReviewer } from 'template/LegalFolderDocument/components/Reviewers/interfaces';
// import { DOCUMENT_REQUEST_REVIEW } from 'graphql/legalFolders/documents';

export interface IReviewer {
  id: number;
  type: string;
  status: DocumentUserStatus | string;
  employeeName?: string;
  employeeId?: string;
  employeeEmail?: string;
  disabled: boolean;
  userFromDatabase: boolean;
  changed: boolean;
  deleted: boolean;
  dbOrder?: number | null;
  originIsEnabled?: boolean;
}

interface IReviewerDocumentUser extends IReviewer {
  reviewer: string;
}

export const DocumentApprovalReviewer: FC<DocumentApprovalReviewerProps> = ({
  proposal,
  refetchProposal,
  enableSubmit,
  reviewersChanged,
}: DocumentApprovalReviewerProps) => {
  const [selectedReviewers, setSelectedReviewers] = useState<IReviewerDocumentUser[]>([]);
  const [reviewers, setReviewers] = useState<IReviewerDocumentUser[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);

  const [selectNextReviewer, { loading: loadingSelectedReviewer }] =
    useMutation(PROPOSAL_REQUEST_REVIEW);

  useEffect(() => {
    if (proposal?.reviewers.length > 0) {
      const onlyActive = proposal?.reviewers.filter((e) => e.disabled === false);

      setReviewers(
        onlyActive.map((item) => ({
          ...item,
          reviewer: `${item?.type?.replace(/_/g, ' ') || ''} - ${item?.employeeName}`,
        }))
      );
    }
  }, [proposal.reviewers]);

  const handleCloseConfirmDialog = (confirmation = false): void => {
    if (confirmation) {
      for (const selectedReviewer of selectedReviewers) {
        selectNextReviewer({
          variables: { userId: selectedReviewer?.employeeId, proposalId: proposal.id },
        })
          .then(() => {
            setSelectedReviewers([]);
            refetchProposal();
          })
          .catch((e) => {
            setMutationErrorMessage(e?.message);
            setMutationErrorOpen(true);
          });
      }
    } else {
      setSelectedReviewers([]);
    }
    setOpenConfirmDialog(false);
    if (!confirmation) setSelectedReviewers([]);
  };

  const handleReviewerSelection = (value: any): void => {
    setSelectedReviewers(value);

    if (reviewersChanged) reviewersChanged(value);
  };

  const submitReviewerSelection = (): void => {
    setOpenConfirmDialog(true);
  };

  const dialogMessages = (): Dialog[] => {
    let dialog = { title: '', message: '', style: { width: 380 } };

    return selectedReviewers.map((selectedReviewer) => {
      const reviewer = `${selectedReviewer?.type} - ${selectedReviewer?.employeeName}`;
      const condition = selectedReviewer?.status === 'REVIEW_APPROVED';
      const style = 'color: #555;';

      if (condition) {
        dialog = {
          title: `CONFIRMING RE-APPROVAL OF REVIEWER, <u style="${style}">${reviewer}</u>`,
          message: `Please confirm you want the ${reviewer} to re-approve the current version.`,
          style: { width: 380 },
        };
      } else {
        dialog = {
          title: `CONFIRMING REVIEWER, <u style="${style}">${reviewer}</u>`,
          message: `Please confirm you are sending the document to the reviewer. ${reviewer}.`,
          style: { width: 320 },
        };
      }

      return dialog;
    });
  };

  const theme = useTheme();
  const isNotMd = useMediaQuery(theme.breakpoints.up('md'));

  const dialogVariant = (): JSX.Element => {
    return (
      <ConfirmationDialog
        open={openConfirmDialog}
        dialogs={dialogMessages()}
        onClose={handleCloseConfirmDialog}
      />
    );
  };

  return (
    <div className="document-section">
      {dialogVariant()}

      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />

      <Grid container spacing={isNotMd ? 0 : 2} alignItems="center" direction="row" item md={12}>
        <Grid item xs={12} sm={8} md={6} lg={8} xl={6}>
          <EasyAutoComplete
            style={{ paddingRight: 20 }}
            items={reviewers}
            selected={selectedReviewers}
            multiple
            textFieldStyle="outlined"
            optionsLabel="reviewer"
            icon={(option: IReviewerDocumentUser): JSX.Element => {
              return option.status === DocumentUserStatus.REVIEW_APPROVED ? (
                <>
                  &nbsp;
                  <i style={{ color: '#00BB13' }} className="material-icons dp48">
                    check
                  </i>
                  &nbsp;
                </>
              ) : (
                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
              );
            }}
            selectedChanged={handleReviewerSelection}
            getOptionSelected={(option: any, value: any) => {
              return (
                (!!option.id && option.id === value.id) ||
                (!!option.key && option.key === value.key) ||
                (!!option.name && option.name === value.name)
              );
            }}
          />
        </Grid>
        {enableSubmit && (
          <Grid item xs={12} sm={4} md={3}>
            <Button
              className="btn-responsive"
              size="small"
              variant="contained"
              color="primary"
              disabled={loadingSelectedReviewer || selectedReviewers?.length === 0}
              onClick={debounce(submitReviewerSelection, DEBOUNCE_TIMEOUT)}
            >
              {loadingSelectedReviewer && <CircularProgress size={22} />}
              {!loadingSelectedReviewer && 'Send'}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

import { useCallback, useEffect, useState, Children, FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { TextInputField } from 'components/TextInputField/TextInputField';

import paths from 'constants/paths';
import { useHistory } from 'react-router-dom';
import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';

import { isValidChancesYearSearch } from 'utils/year';

export interface IYearSummaryGridProps {
  children: any;
}

export const YearSummaryGrid: FC<IYearSummaryGridProps> = ({ children }) => {
  const history = useHistory();
  const childrenArr = Children.toArray(children);

  const { year } = useProposalAggregatesContext();
  const [searchYear, setSearchYear] = useState(year?.toString());

  const gotoYear = useCallback(
    (year: string) => {
      history.push(paths.client.REPORT_YEAR_BY_SUCCESS_CHANCE_SUMMARY.replace(':year', year));
    },
    [history]
  );

  useEffect(() => {
    if (searchYear && isValidChancesYearSearch(searchYear)) {
      gotoYear(searchYear);
    } else {
      console.log('invalid year');
    }
  }, [gotoYear, searchYear]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '30px' }}
        >
          <Typography variant="body2" className="label-title-nocase">
            {year} Proposals Forecast (Draft and Pending)
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ paddingRight: '5px' }}>Year:</div>
          <div style={{ width: '100px' }}>
            <TextInputField
              fullWidth={false}
              value={searchYear}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchYear(e.target.value);
              }}
              onClear={() => setSearchYear('')}
              //   onUndo={() => {
              //     setSearchYear(year ? year.toString() : new Date().getFullYear().toString());
              //   }}
              error={!isValidChancesYearSearch(searchYear)}
            ></TextInputField>
          </div>
        </Grid>
      </Grid>
      <Box mt={1} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          {childrenArr[0]}
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          {childrenArr[1]}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          {childrenArr[2]}
        </Grid>
      </Grid>
    </>
  );
};

import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import {
  proposal_proposal_proposal,
  proposal_proposal_proposal_reviewers,
} from 'graphql/proposals/types/proposal';
import { proposalUpdateVariables } from 'graphql/proposals/types/proposalUpdate';
import {
  ProposalEntityType,
  ProposalStage,
  ProposalSuccessChance,
  ProposalType,
  ProposalPriceType,
  ProposalActivity,
} from 'graphql/proposals/types/graphql-types';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import validate from 'validate.js';
import { validators } from 'constants/validators';
import { capitalizeAllWordsFirstLetterUS, capitalizeFirstLetter } from 'utils/formats';
import { omit, pick } from 'lodash';
import { tryUpdateProcedure } from 'utils/apollo';
import { ApolloError, useApolloClient, useMutation } from '@apollo/client';
import {
  PROPOSAL_CREATE_MUTATION,
  PROPOSAL_UPDATE_MUTATION,
  PROPOSAL_COMPETITORS_CHANGE,
  PROPOSAL_DELETE_MUTATION,
  PROPOSAL_CANCEL_MUTATION,
  PROPOSAL_OWNERS_CHANGE,
  PROPOSAL_REVIEWERS_CHANGE_MUTATION,
  PROPOSAL_MONETARY_BREAKDOWN_CHANGE,
} from 'graphql/proposals/proposals';
import { useUI } from 'contexts/UiContext';
import { proposalTypeOptions } from './components/ProposalForm/constants/proposalTypeOptions';
import { yesNoOptions } from './components/ProposalForm/constants/chancesOfSuccessOptions';
import {
  getWorkflowGeneralItem,
  WorkflowStates,
} from './components/ProposalForm/constants/workflow';

import { useOtherFields } from './components/ProposalForm/hooks/otherFields';
import { useBasicDetailsFields } from './components/ProposalForm/hooks/basicDetailsFields';
import { useAdditionalDetailsFields } from './components/ProposalForm/hooks/additionalDetailsFields';

import { useParty } from 'hooks/partyHook';
import { IProposalEvent } from 'graphql/proposals/types/IProposalEvent';
import { IFieldsDescription } from './components/ProposalForm/interface';
import { IReviewer } from './components/ProposalFileManagement/components/Reviewers/interfaces';
import { IPartyContact } from 'graphql/proposals/types/IPartyContact';
import { proposalCreateVariables } from 'graphql/proposals/types/proposalCreate';
import { ICompetitor } from './interfaces';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useReviewers } from './components/ProposalFileManagement/components/hooks/reviewersHook';
import { IUsersArrayItem } from './components/ProposalFileManagement/components/UserBasedStakeHolder/interfaces';

import { useProposalTimestampHook } from 'hooks/proposalTimestampHook';
import { ProposalEventType } from 'constants/enums';
import { useMsalAccount } from 'hooks/msalAccount';
import { MAX_OWNERS } from 'constants/config';
import { prorateMonetaryBreakdown } from 'utils/monetaryBrakdown';

export interface IContextState {
  activeTab?: ActiveTab;
  proposal: IProposalData;
  originalProposal: IProposalData;
  loading?: boolean;
  proposalId?: string;
  proposalStage: ProposalStage;
  validationSummary: Array<string>;
  basicDetailsFields?: Array<Array<IFieldsDescription>>;
  usersListAsReviewers: IUsersArrayItem[];
  newDocumentStatus: any;
  ts: number;
  reviewersDocumentUsersByDocumentId?: any;
  referenceOpportunityId?: string;
}

export interface IContextActions {
  changeActiveTab: any;
  onChangeState: (cb: (old: IProposalData) => IProposalData) => void;

  onSubmit: () => void;
  onCopy: () => void;

  onSubmitValidateTest: (isNewForm: boolean) => boolean;
  onSubmitDraftValidateTest: (isNewForm: boolean) => boolean;

  onCancelProcess: () => Promise<boolean>;
  onDeleteProcess: () => Promise<boolean>;

  refetchProposal: () => Promise<any>;
  proposalUpdated: () => void;

  setNewDocumentStatus: any;
}

const newProposal: IProposalData = {
  isValid: true,
  showValidator: false,
  stageAsString: '',
  events: [],

  id: '',
  name: '',

  selectedType: undefined,
  selectedClient: undefined,
  prevSelectedClient: undefined, // same value as selectedClient,
  selectedClientContact: [],
  selectedLead: undefined,
  selectedSubmissionMethods: [],
  selectedDivisionsParticipating: [],
  selectedIndividualsInvolved: [],
  selectedProjectCountries: [],
  selectedDivisionOpportunityOwner: undefined,
  selectedBillingOrigin: [],
  selectedProjectFields: [],
  selectedBillingBasis: [],
  selectedWorkType: [],
  selectedEvaluationType: undefined,
  selectedBudgetBasis: undefined,
  selectedGuidelineCode: undefined,
  selectedDeliverables: [],
  selectedPotentialRevenueByArea: [],
  selectedReportType: [],
  selectedFiguresRequired: undefined,
  selectedTravelRequired: undefined,
  selectedPotentialTeamMembers: [],
  selectedStage: getWorkflowGeneralItem(ProposalStage.DRAFT),
  selectedOwners: [],

  reviewers: [],
  canReview: false,

  selectedCompetitors: [],
  bidPriceLowered: false,

  activity: ProposalActivity.ACTIVE,

  bidProposedValueBreakdown: [
    {
      monetaryTransactionValue: '',
      changed: true,
      startTimestamp: new Date().getFullYear().toString(),
      id: '',
      prorated: false,
    },
  ],
};

const initialState: IContextState = {
  proposal: { ...newProposal },
  originalProposal: { ...newProposal },
  proposalStage: ProposalStage.DRAFT,
  validationSummary: [],
  usersListAsReviewers: [],
  newDocumentStatus: {},
  ts: Date.now(),
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

interface IProviderProps {
  proposalId?: string;
  activeTab: ActiveTab;
  loadedProposal?: proposal_proposal_proposal | null;
  preloadProposal?: Partial<IProposalData>;
  refetch?: any;
  loading?: boolean;
  children: any;
}

export type ActiveTab =
  | 'details'
  | 'file-management'
  | 'events-log'
  | 'bid-performance-bonds'
  | 'new';
export interface IEvent {
  [id: string]: any;
}

export const TabToPageMap = {
  details: paths.client.PROPOSAL_DETAILS,
  'file-management': paths.client.PROPOSAL_FM,
  'events-log': paths.client.PROPOSAL_EVENTS,
  'bid-performance-bonds': paths.client.PROPOSAL_BID_PERFORMANCE_BONDS,
  new: paths.client.NEW_PROPOSAL,
};

export interface IGeneralListItem<T> {
  id: T;
  name: string;
}

export interface IGeneralListItemWithCode<T> {
  id: T;
  name: string;
  code: string;
}

interface IGeneralListItem2 {
  key: string;
  name: string;
}

export const pickGeneralListItem = <T extends {}>(arr: IGeneralListItem<T>[] | null) =>
  arr
    ? arr.map((item) => pick(item, ['id', 'name'])).sort((a, b) => a.name.localeCompare(b.name))
    : [];

const getChanges = (
  toSave: { id: string }[] | null | undefined,
  oldItems: { id: string }[] | null | undefined
) => {
  const idsToSave = toSave?.map((item) => item.id) || [];
  const idsOld = oldItems?.map((item) => item.id) || [];
  const remove = idsOld.filter((id) => !idsToSave?.includes(id));
  const add = idsToSave.filter((id) => !idsOld?.includes(id));
  return { remove, add };
};

export interface IProposalPartyContact extends Omit<IPartyContact, '__typename'> {
  name: string;
}

export interface IBidProposedValueBreakdown {
  id: string;
  startTimestamp: string;
  monetaryTransactionValue: string;
  changed: boolean;
  prorated: boolean;
}

export interface IProposalData extends Omit<proposalUpdateVariables, 'stage'> {
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  stageAsString: string;
  events: IProposalEvent[] | null;

  savedClientExists?: boolean;
  selectedSuccessChance?: IGeneralListItem<ProposalSuccessChance>;
  selectedPriceType?: IGeneralListItem<ProposalPriceType>;
  selectedType?: IGeneralListItem<ProposalType>;
  selectedClient?: IGeneralListItem2;
  prevSelectedClient?: IGeneralListItem2;
  selectedClientContact?: IProposalPartyContact[];
  selectedDivisionOwnership?: IGeneralListItem<string>;
  selectedClientFocalPoint?: IGeneralListItem<string>;
  selectedLead?: IGeneralListItem<string>;
  selectedSubmissionMethods?: IGeneralListItem<string>[];
  selectedDivisionsParticipating?: IGeneralListItem<string>[];
  selectedIndividualsInvolved?: IGeneralListItem<string>[];
  selectedProjectCountries?: IGeneralListItemWithCode<string>[];
  selectedDivisionOpportunityOwner?: IGeneralListItem<string>;
  selectedBillingOrigin?: IGeneralListItem<string>[];
  selectedProjectFields?: IGeneralListItem<string>[];
  selectedBillingBasis?: IGeneralListItem<string>[];
  selectedWorkType?: IGeneralListItem<string>[];
  selectedEvaluationType?: IGeneralListItem<string>;
  selectedBudgetBasis?: IGeneralListItem<string>;
  selectedGuidelineCode?: IGeneralListItem<string>;
  selectedDeliverables?: IGeneralListItem<string>[];
  selectedPotentialRevenueByArea?: IGeneralListItem<string>[];
  selectedReportType?: IGeneralListItem<string>[];
  selectedFiguresRequired?: IGeneralListItem<string>;
  selectedTravelRequired?: IGeneralListItem<string>;
  selectedPotentialTeamMembers?: IGeneralListItem<string>[];
  selectedStage?: IGeneralListItem<ProposalStage>;
  selectedOwners?: IGeneralListItem<string>[];

  reviewers: IReviewer[];
  canReview: boolean;
  currentReviewer?: proposal_proposal_proposal_reviewers;

  selectedCompetitors: ICompetitor[];

  autoClientFocalPoint?: string;
  autoClientDivisionOwnership?: string;

  updatedAt?: any;
  createdAt?: any;

  bidPriceLowered: boolean;
  bidProposedValueBreakdown: IBidProposedValueBreakdown[];

  activity: ProposalActivity;
}

export const isCurrentUser = (account: Record<string, any>, reportUser: IReviewer): boolean => {
  return (
    account?.idToken?.email === reportUser.employeeEmail ||
    account?.idToken?.preferred_username === reportUser.employeeEmail
  );
};

export const isUserCurrentUser = (
  account: Record<string, any>,
  reviewer: proposal_proposal_proposal_reviewers
): boolean => {
  return (
    account?.idToken?.email === reviewer.user?.email ||
    account?.idToken?.preferred_username === reviewer.user?.email
  );
};

export const getProposalHash = (proposal: IProposalData) => {
  return JSON.stringify({
    ...omit(proposal, [
      'isValid',
      'showValidator',
      'errors',
      'stageAsString',
      'events',
      'createdAt',
      'updatedAt',
      'reviewers',
    ]),
    reviewers: proposal.reviewers.map((reviewer) =>
      pick(reviewer, ['id', 'empoyeeId', 'disabled', 'changed', 'reviewStatus', 'deleted'])
    ),
  });
};

const reasignOrder = (items: { id: number; changed: boolean }[]) => {
  const reordered = [];
  for (let i = 0; i < items.length; i++) {
    reordered.push({ ...items[i] });
  }
  let current = 1;
  for (let i = 0; i < reordered.length; i++) {
    if (reordered[i].changed) {
      reordered[i].id = current;
      current++;
    } else {
      current = reordered[i].id + 1;
    }
  }
  return reordered;
};

export const Provider: FC<IProviderProps> = ({
  children,
  activeTab,
  proposalId,
  loadedProposal,
  preloadProposal,
  refetch,
  loading,
}) => {
  const history = useHistory();
  const { addSnackbar } = useUI();

  const [newDocumentStatus, setNewDocumentStatus] = useState<any>({});
  const [ts, setTs] = useState<number>(Date.now());

  const { msalAccount: currentAccount } = useMsalAccount();
  const client = useApolloClient();

  const { formChanged, resetChanged } = useFormChangedDialogContext();
  const { usersArray: usersListAsReviewers, loading: usersListAsReviewersLoading } = useReviewers();

  const [proposal, setProposal] = useState<IProposalData>(newProposal);
  const [proposalHash, setProposalHash] = useState<string | undefined>();

  const originalProposalRef = useRef<IProposalData | null>(null);
  const [originalProposal, setOriginalProposal] = useState<IProposalData>(newProposal);
  useEffect(() => {
    originalProposalRef.current = originalProposal;
  }, [originalProposal]);

  const {
    party: clientData,
    loading: clientDataLoading,
    reload: clientDataReload,
  } = useParty({
    id: proposal.selectedClient?.key,
  });

  const { timestamp: proposalTimestamp } = useProposalTimestampHook({ proposalId: proposal.id });

  const proposalRef = useRef<IProposalData | undefined>(undefined);
  useEffect(() => {
    proposalRef.current = proposal;
  }, [proposal]);

  useEffect(() => {
    if (preloadProposal && !loadedProposal) {
      setProposal((old) => ({ ...old, ...preloadProposal } as IProposalData));
    }
  }, [preloadProposal, loadedProposal]);

  useEffect(() => {
    if (
      !!proposalTimestamp &&
      originalProposalRef.current?.updatedAt &&
      proposalTimestamp > new Date(originalProposalRef.current.updatedAt).getTime()
    ) {
      refetch();
    }
  }, [addSnackbar, proposalTimestamp, refetch]);

  const [createMutation] = useMutation(PROPOSAL_CREATE_MUTATION);
  const [updateMutation] = useMutation(PROPOSAL_UPDATE_MUTATION);
  const [competitorsChangeMutation] = useMutation(PROPOSAL_COMPETITORS_CHANGE);
  const [ownersChangeMutation] = useMutation(PROPOSAL_OWNERS_CHANGE);
  const [deleteProposalMutation] = useMutation(PROPOSAL_DELETE_MUTATION);
  const [cancelProposalMutation] = useMutation(PROPOSAL_CANCEL_MUTATION);
  const [proposalReviewersChangeMutation] = useMutation(PROPOSAL_REVIEWERS_CHANGE_MUTATION);
  const [bidProposadValueBreakdownChangeMutation] = useMutation(PROPOSAL_MONETARY_BREAKDOWN_CHANGE);

  const fromStatus = useMemo(() => {
    return (
      (!!loadedProposal
        ? getWorkflowGeneralItem(loadedProposal.stage as ProposalStage)
        : undefined) || getWorkflowGeneralItem(ProposalStage.DRAFT)
    );
  }, [loadedProposal]);

  const { fields: otherAndRelatedFields } = useOtherFields(
    proposal,
    (fromStatus?.id || ProposalStage.DRAFT) as ProposalStage
  );

  const { fields: basicDetailsFields } = useBasicDetailsFields(proposal);
  const { fields: additionalDetailsFields } = useAdditionalDetailsFields(proposal);

  const compareFunctions = useMemo(
    () => ({
      guidelineCodeId: (id: string) => loadedProposal?.guidelineCode?.id === id,
      evaluationTypeId: (id: string) => loadedProposal?.evaluationType?.id === id,
      budgetBasisId: (id: string) => loadedProposal?.budgetBasis?.id === id,
      proposalLeadUserId: (id: string) => loadedProposal?.leadUser?.user?.id === id,
      owningDivisionId: (id: string) => loadedProposal?.owningDivision?.division?.id === id,
    }),
    [loadedProposal]
  );

  const calculateValidatorOptions = useCallback(
    (requiredGroup: number) => {
      const aliases: any = {};
      [otherAndRelatedFields, basicDetailsFields, additionalDetailsFields].forEach((section) => {
        section.forEach((fieldGroup) => {
          fieldGroup.forEach((field) => {
            if (field.group && field.group <= requiredGroup && field.isDisabled !== true) {
              if (
                ['textareaAutosize', 'text', 'easyAutoComplete', '$'].includes(
                  field.field?.type || ''
                )
              ) {
                aliases[field.id] = field.title;
              }
            }
          });
        });
      });

      return { aliases };
    },
    [otherAndRelatedFields, basicDetailsFields, additionalDetailsFields]
  );

  const calculateValidators = useCallback(
    (requiredGroup: number) => {
      const fields: any = {};
      [otherAndRelatedFields, basicDetailsFields, additionalDetailsFields].forEach((section) => {
        section.forEach((fieldGroup) => {
          fieldGroup.forEach((field) => {
            if (field.group && field.group <= requiredGroup && field.isDisabled !== true) {
              if (['textareaAutosize', 'text'].includes(field.field?.type || '')) {
                fields[field.id] = validators.simpleText;
              } else if (['easyAutoComplete'].includes(field.field?.type || '')) {
                fields[field.id] = validators.required;
              } else if (['$'].includes(field.field?.type || '')) {
                fields[field.id] = validators.positiveCurrency;
              }
            }
          });
        });
      });

      return fields;
    },
    [otherAndRelatedFields, basicDetailsFields, additionalDetailsFields]
  );

  const requiredGroup = useMemo(() => {
    const toStatus = WorkflowStates.find((state) => state.id === proposal.selectedStage?.id);
    if (!toStatus) return undefined;
    return toStatus.group;
  }, [proposal.selectedStage?.id]);

  const proposalValidatorOptions = useMemo(() => {
    if (!requiredGroup) return {};
    const options = calculateValidatorOptions(requiredGroup);
    const { aliases } = options;
    return {
      prettify: function prettify(string: string) {
        return aliases[string] || validate.prettify(string);
      },
    };
  }, [calculateValidatorOptions, requiredGroup]);

  const proposalValidators = useMemo(() => {
    if (!requiredGroup) return {};
    return calculateValidators(requiredGroup);
  }, [calculateValidators, requiredGroup]);

  const proposalDraftValidators = useMemo(() => {
    return calculateValidators(1);
  }, [calculateValidators]);

  const commonErrorsCheck = useCallback((newState: any) => {
    const {
      expectedProjectStartDate,
      expectedProjectEndDate,
      receiveDate,
      dueDate,
      expectedAwardDate,
      selectedClient,
      autoClientDivisionOwnership,
      autoClientFocalPoint,
      reviewers,
    } = newState;

    const clientErrors: any = {};

    if (!selectedClient) {
      clientErrors.selectedClient = ['Client Name is mandatory field'];
    } else {
      if (!autoClientDivisionOwnership && !autoClientFocalPoint) {
        clientErrors.autoClientDivisionOwnership = [
          'D&M Client Division Ownership is required',
          'Go to Client link to populate the field',
        ];
        clientErrors.autoClientFocalPoint = [
          'D&M Client Focal Point is required',
          'Go to Client link to populate the field',
        ];
        clientErrors.selectedClient = ['Selected Client is missing required fields'];
      } else {
        if (!autoClientDivisionOwnership) {
          clientErrors.autoClientDivisionOwnership = [
            'D&M Client Division Ownership is required',
            'Go to Client link to populate the field',
          ];
          clientErrors.selectedClient = ['Selected Client is missing required field'];
        }
        if (!autoClientFocalPoint) {
          clientErrors.autoClientFocalPoint = [
            'D&M Client Focal Point is required',
            'Go to Client link to populate the field',
          ];
          clientErrors.selectedClient = ['Selected Client is missing required field'].join('. ');
        }
      }
    }

    let awardToProjectEndDateErrors;
    if (
      !!expectedAwardDate &&
      !!expectedProjectEndDate &&
      expectedAwardDate >= expectedProjectEndDate
    ) {
      awardToProjectEndDateErrors = {
        expectedAwardDate: [
          'Expected Award Date date must be less then Expected Project Start Date',
        ],
        expectedProjectEndDate: ['Project end date must be greather then Expected Award Date'],
      };
    }

    let awardToProjectStartDateErrors;
    if (
      !!expectedAwardDate &&
      !!expectedProjectStartDate &&
      expectedAwardDate >= expectedProjectStartDate
    ) {
      awardToProjectStartDateErrors = {
        expectedAwardDate: [
          'Expected Award Date date must be less then Expected Project Start Date',
        ],
        expectedProjectStartDate: ['Project Start Date must be greather then Expected Award Date'],
      };
    }

    let receivedAndDueDateErrors;
    if (!!receiveDate && !!dueDate && receiveDate >= dueDate) {
      receivedAndDueDateErrors = {
        receiveDate: ['Proposal Received Date must be less then Proposal Due Date'],
        dueDate: ['Proposal Due Date must be greather then Proposal Receive Date'],
      };
    }

    let dateErrors;
    if (
      !!expectedProjectStartDate &&
      !!expectedProjectEndDate &&
      expectedProjectStartDate >= expectedProjectEndDate
    ) {
      dateErrors = {
        expectedProjectStartDate: ['Project Start Date must be less then Project End Date'],
        expectedProjectEndDate: ['Project End Date must be greather then Project Start Date'],
      };
    }

    const reviewersErrors = !!reviewers?.length
      ? {}
      : { reviewers: ['At least one Reviewer is required'] };

    const errors = {
      ...awardToProjectEndDateErrors,
      ...awardToProjectStartDateErrors,
      ...receivedAndDueDateErrors,
      ...clientErrors,
      ...dateErrors,
      ...reviewersErrors,
    };
    // return { ...newState, errors, isValid: errors ? false : true };

    return errors;
  }, []);

  const validateForm = useCallback(
    (newState: any) => {
      const commonErrors = commonErrorsCheck(newState);

      const { selectedSubmissionMethods } = newState;
      const submissionMethodsErrors =
        !requiredGroup || requiredGroup < 2 || selectedSubmissionMethods?.length
          ? {}
          : { selectedSubmissionMethods: ["Submission Method can't be blank"] };

      const { selectedWorkType, selectedStage, lostReason } = newState;

      const workTypeErrors =
        !requiredGroup || requiredGroup < 2 || selectedWorkType?.length
          ? {}
          : { selectedWorkType: ["Work Type can't be blank"] };

      const { selectedPotentialRevenueByArea } = newState;
      const revenueByAreaErrors =
        !requiredGroup || requiredGroup < 2 || selectedPotentialRevenueByArea?.length
          ? {}
          : { selectedPotentialRevenueByArea: ["Potential Revenue by Area can't be blank"] };

      const { bidProposedValue, bidProposedValueBreakdown } = newState;
      const bidProposedValueBreakdownYearsCheck: any = {};

      const bidProposedValueBreakdownErrors: any = {};
      if (!bidProposedValueBreakdown.length) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          'Bid Price Proposed breakdown is missing',
        ];
      } else if (
        bidProposedValueBreakdown.find(
          (bd: any) => !bd.startTimestamp || !bd.monetaryTransactionValue
        )
      ) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          'Bid Price Proposed breakdown field found empty',
        ];
      } else if (
        bidProposedValueBreakdown.find((bd: any) => parseFloat(bd.monetaryTransactionValue) === 0)
      ) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          'Bid Price Proposed breakdown value found empty',
        ];
      } else if (
        parseFloat(bidProposedValue) !==
        bidProposedValueBreakdown.reduce((acc: number, bd: any) => {
          return acc + parseFloat(bd.monetaryTransactionValue);
        }, 0)
      ) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          'Bid Price Proposed value must be equal to sum of breakdowns',
        ];
      } else if (
        ![ProposalStage.LOST, ProposalStage.WON, ProposalStage.CANCELED].includes(
          selectedStage.id
        ) &&
        bidProposedValueBreakdown.find(
          (bd: any) => parseInt(bd.startTimestamp) < new Date().getFullYear()
        )
      ) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          "Passed year can't be used in Bid Price Proposed breakdown",
        ];
      } else if (
        bidProposedValueBreakdown.find((bd: any) => {
          if (bidProposedValueBreakdownYearsCheck[bd.startTimestamp]) {
            return true;
          }
          bidProposedValueBreakdownYearsCheck[bd.startTimestamp] = true;
          return false;
        })
      ) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          'Duplicate year found in Bid Price Proposed breakdown',
        ];
      } else if (
        bidProposedValueBreakdown.find((bd: any) => {
          const year = bd.startTimestamp;
          return year < 2000 || year >= 3000;
        })
      ) {
        bidProposedValueBreakdownErrors.bidProposedValueBreakdown = [
          'Please check Bid Price Breakdown Years',
        ];
      }

      const fieldErrors: any = {};
      if (selectedStage.id === ProposalStage.LOST && !lostReason) {
        fieldErrors.lostReason = ['Lost reason is required'];
      }

      const { expectedAwardDate } = newState;
      if (!expectedAwardDate) {
        fieldErrors.expectedAwardDate = ["Expected Award Date can't be blank"];
      }

      const errors = {
        ...commonErrors,
        ...validate(newState, proposalValidators, proposalValidatorOptions),
        ...submissionMethodsErrors,
        ...bidProposedValueBreakdownErrors,
        ...workTypeErrors,
        ...revenueByAreaErrors,
        ...fieldErrors,
      };

      newState.errors = errors;
      newState.isValid = errors && Object.keys(errors).length ? false : true;
      return newState;
    },
    [proposalValidators, commonErrorsCheck, requiredGroup, proposalValidatorOptions]
  );

  useEffect(() => {
    if (loadedProposal && loadedProposal.bidProposedValue && loadedProposal.bidProposedValue > 0) {
      const loweredOrDeletedPrice =
        !proposal.bidProposedValue || proposal.bidProposedValue < loadedProposal.bidProposedValue;
      if (proposal.bidPriceLowered !== loweredOrDeletedPrice) {
        setProposal((old) => ({ ...old, bidPriceLowered: loweredOrDeletedPrice }));
      }
    }
  }, [loadedProposal, loadedProposal?.bidProposedValue, proposal, proposal.bidProposedValue]);

  const validateFormRef = useRef(validateForm);
  useEffect(() => {
    validateFormRef.current = validateForm;
  }, [validateForm]);

  const validateDraftForm = useCallback(
    (newState: any) => {
      const commonErrors = commonErrorsCheck(newState);

      const errors = {
        ...commonErrors,
        ...validate(newState, proposalDraftValidators, proposalValidatorOptions),
      };

      newState.errors = errors;
      newState.isValid = errors && Object.keys(errors).length ? false : true;
      return newState;
    },
    [proposalDraftValidators, commonErrorsCheck, proposalValidatorOptions]
  );

  useEffect(() => {
    if (!loading && proposalId && !loadedProposal) {
      addSnackbar!({
        text: 'Proposal not exist!',
        severity: 'error',
      });
      addSnackbar!({
        text: 'Redirected to Proposals list ...',
        severity: 'warning',
      });
      history.replace(paths.client.PROPOSALS);
    }
    if (!loading && proposalId && loadedProposal?.entityType === 'OPPORTUNITY') {
      addSnackbar!({ text: 'Opportunity loaded!', severity: 'warning' });
      addSnackbar!({ text: 'Redirected to the Opportunity page ...', severity: 'warning' });
      history.replace(history.location.pathname.replace('proposal', 'opportunity'));
    }
  }, [loading, proposalId, loadedProposal, addSnackbar, history]);

  useEffect(() => {
    if (!usersListAsReviewersLoading && usersListAsReviewers?.length && proposal.reviewers.length) {
      setProposal((proposal) => {
        let changed = false;
        let reviewers = [...proposal.reviewers];
        for (let reviewer of reviewers) {
          if (!reviewer.type) {
            const user = usersListAsReviewers.find((item) => item.id === reviewer.employeeId);
            if (user) {
              reviewer.type = user.discipline || 'UNCONVENTIONAL';
              changed = true;
            }
          }
        }
        if (changed) {
          return { ...proposal, reviewers };
        }
        return proposal;
      });
    }
  }, [usersListAsReviewers, usersListAsReviewersLoading, proposal]);

  useEffect(() => {
    if (proposal.selectedClient) {
      clientDataReload();
    }
  }, [clientDataReload, proposal.selectedClient]);

  useEffect(() => {
    const savedProposal = sessionStorage.getItem('CopyProposal');
    if (!!savedProposal) {
      const overWrite = JSON.parse(savedProposal);
      setProposal((old) => {
        return { ...old, ...overWrite };
      });
      sessionStorage.removeItem('CopyProposal');
    }
  }, []);

  useEffect(() => {
    if (
      loadedProposal &&
      (!originalProposalRef.current?.updatedAt ||
        originalProposalRef.current.updatedAt < loadedProposal.updatedAt)
    ) {
      let readyProposal: any;
      if (preloadProposal) {
        readyProposal = { ...newProposal, ...preloadProposal };
      } else {
        const {
          updatedAt,
          createdAt,
          expectedAwardDate,
          successChance,
          priceType,
          proposalType,
          figuresRequired,
          travelRequired,
          stage,
          billingBases,
          billingOrigins,
          countries,
          deliverables,
          fields,
          partyContacts,
          proposalReportTypes,
          revenueAreas,
          submissionMethods,
          workTypes,
          competitors,
          party,
          guidelineCode,
          evaluationType,
          budgetBasis,
          leadUser,
          individualsInvolved,
          potentialTeamMembers,
          owningDivision,
          participatingDivisions,
          owners,
          reviewers,
          entityType,
          monetaryBreakdowns,
          lastStageChangedEvent,
          bidProposedValue,
        } = loadedProposal;

        const proratedBreakdown = prorateMonetaryBreakdown({
          monetaryBreakdowns,
          stage,
          stageDate: lastStageChangedEvent?.createdAt || updatedAt,
          expectedAwardDate: expectedAwardDate ? new Date(expectedAwardDate) : undefined,
          bidProposedValue,
          proposalCreatedAt: createdAt,
        }).sort((a, b) => a.year - b.year);

        let sortedReviewers = reviewers ? [...reviewers] : [];
        sortedReviewers.sort((a, b) => {
          if (!a.order) return -1;
          if (!b.order) return 1;
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });

        let index = 0;
        const mappedReviewers: IReviewer[] = sortedReviewers.map((reviewer) => {
          index++;
          const { persona, reviewStatus, isEnabled, user, order } = reviewer;

          const inReviewStatus = reviewer.reviewerAssignedDocuments?.find(
            (documentUser) => documentUser.reviewStatus === 'REVIEW_REQUESTED'
          );

          const declinedStatus =
            !inReviewStatus &&
            reviewer.reviewerAssignedDocuments?.find(
              (documentUser) => documentUser.reviewStatus === 'REVIEW_DISAPPROVED'
            );

          const acceptedStatus =
            !inReviewStatus &&
            !declinedStatus &&
            reviewer.reviewerAssignedDocuments?.find(
              (documentUser) => documentUser.reviewStatus === 'REVIEW_APPROVED'
            );

          const calculatedReviewStatus = inReviewStatus
            ? 'review_requested'
            : declinedStatus
            ? 'review_disapproved'
            : acceptedStatus
            ? 'review_approved'
            : reviewStatus
            ? reviewStatus.toLowerCase()
            : '';

          return {
            id: index,
            type: persona || '',
            status: calculatedReviewStatus,
            reviewStatus: reviewStatus,
            employeeName: user?.name || '',
            employeeId: user?.id ? user.id : undefined,
            employeeEmail: user?.email ? user?.email : undefined,
            disabled: !isEnabled,
            userFromDatabase: true,
            deleted: false,
            changed: index !== order,
            dbOrder: order,
            originIsEnabled: isEnabled,
          };
        });

        const selectedClient = party
          ? {
              key: party.id,
              name: party.name,
              clientCode: party.projectSetupClientCode,
              ddLabel:
                (party.projectSetupClientCode ||
                  (party.partyType?.name === 'CLIENT' ? 'Client' : 'Non-Client')) +
                ' - ' +
                party.name,
            }
          : undefined;

        readyProposal = {
          ...newProposal, // temporary, stil missing some fields on API
          ...omit(loadedProposal, ['proposalType']),
          entityType: entityType ? (entityType as ProposalEntityType) : undefined,
          successChance: successChance ? (successChance as ProposalSuccessChance) : undefined,
          selectedSuccessChance: successChance
            ? {
                id: successChance as ProposalSuccessChance,
                name: capitalizeFirstLetter(successChance.toLowerCase()),
              }
            : undefined,

          priceType: priceType ? (priceType as ProposalPriceType) : undefined,
          selectedPriceType: priceType
            ? {
                id: priceType as ProposalPriceType,
                name: capitalizeAllWordsFirstLetterUS(priceType.toLowerCase()),
              }
            : undefined,

          selectedType: proposalType
            ? proposalTypeOptions.find((item) => item.id === proposalType)
            : undefined,
          selectedFiguresRequired:
            figuresRequired === true
              ? yesNoOptions.find((item) => item.id === '1')
              : yesNoOptions.find((item) => item.id === '0'),
          selectedTravelRequired:
            travelRequired === true
              ? yesNoOptions.find((item) => item.id === '1')
              : yesNoOptions.find((item) => item.id === '0'),
          stageAsString: stage,
          selectedStage: fromStatus,
          selectedBillingBasis: pickGeneralListItem(billingBases),
          selectedBillingOrigin: pickGeneralListItem(billingOrigins),
          selectedProjectCountries: countries
            ? countries
                .map((item) => pick(item, ['id', 'name', 'code']))
                .sort((a, b) => a.name.localeCompare(b.name))
            : [],
          selectedDeliverables: pickGeneralListItem(deliverables),
          selectedProjectFields: fields
            ? fields
                .map((field) => ({
                  ...pick(field, ['id', 'code']),
                  name: field.name + ', ' + field.code.substr(0, 3),
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
            : [],
          selectedClientContact: partyContacts
            ? partyContacts.map((contact) => ({
                ...omit(contact, ['__typename']),
                name: contact.firstName + ' ' + contact.lastName,
              }))
            : [],
          selectedReportType: pickGeneralListItem(proposalReportTypes),
          selectedPotentialRevenueByArea: pickGeneralListItem(revenueAreas),
          selectedSubmissionMethods: pickGeneralListItem(submissionMethods),
          selectedWorkType: pickGeneralListItem(workTypes),
          selectedDivisionsParticipating: participatingDivisions
            ? pickGeneralListItem(
                participatingDivisions
                  .filter((item) => !!item.division)
                  .map((item) => item.division!)
              )
            : undefined,

          selectedCompetitors: competitors
            ? competitors?.map((competitor) => ({
                ...pick(competitor, ['hasWonBid', 'bidValue', 'notes']),
                competitorName: competitor.competitor?.name,
                id: competitor.competitor?.id,
                changed: false,
                deleted: false,
              }))
            : [],
          selectedClient,
          prevSelectedClient: selectedClient,
          savedClientExists: !!selectedClient,
          selectedGuidelineCode: guidelineCode ? { ...guidelineCode } : undefined,
          selectedEvaluationType: evaluationType ? { ...evaluationType } : undefined,
          selectedBudgetBasis: budgetBasis ? { ...budgetBasis } : undefined,
          selectedLead: leadUser?.user ? pick(leadUser.user, ['id', 'name', 'email']) : undefined,
          selectedIndividualsInvolved: individualsInvolved
            ? individualsInvolved
                .filter((individual) => !!individual.user)
                .map((individual) => pick(individual.user!, ['id', 'name', 'email']))
                .sort((a, b) => a.name.localeCompare(b.name))
            : undefined,
          selectedPotentialTeamMembers: potentialTeamMembers
            ? potentialTeamMembers
                .filter((teamMember) => !!teamMember.user)
                .map((teamMember) => pick(teamMember.user!, ['id', 'name', 'email']))
                .sort((a, b) => a.name.localeCompare(b.name))
            : undefined,
          selectedDivisionOpportunityOwner: owningDivision?.division
            ? pick(owningDivision?.division, ['id', 'name'])
            : undefined,
          selectedOwners: owners
            ? owners
                .filter((owner) => !!owner.user && owner.isEnabled)
                .map((owner) => pick(owner.user!, ['id', 'name', 'email']))
                .sort((a, b) => a.name.localeCompare(b.name))
            : undefined,
          reviewers: mappedReviewers,
          canReview: !!mappedReviewers.find(
            (reviewer) =>
              reviewer.status === 'review_requested' &&
              !reviewer.disabled &&
              isCurrentUser(currentAccount, reviewer)
          ),
          currentReviewer: reviewers?.find((reviewer) =>
            isUserCurrentUser(currentAccount, reviewer)
          ),
          bidProposedValueBreakdown: (proratedBreakdown?.length
            ? proratedBreakdown
                .sort((a, b) => a.year - b.year)
                .map(({ id, value, year, prorated }) => ({
                  id: id ?? '',
                  startTimestamp: year.toString(),
                  monetaryTransactionValue: value.toString(),
                  changed: false,
                  prorated,
                }))
            : [
                {
                  id: '',
                  startTimestamp: new Date().getFullYear().toString(),
                  monetaryTransactionValue: '',
                  changed: true,
                  prorated: false,
                },
              ]) as IBidProposedValueBreakdown[],
        };
      }

      if (
        !originalProposalRef.current?.updatedAt ||
        (proposalRef.current && getProposalHash(proposalRef.current) !== proposalHash)
      ) {
        setProposal(readyProposal);
        setProposalHash(getProposalHash(readyProposal));
      }

      if (originalProposalRef.current?.updatedAt && loadedProposal?.events) {
        const lastEvent = loadedProposal.events.reduce((last: IEvent | null, current: IEvent) => {
          if (!last || new Date(current.createdAt) > new Date(last.createdAt)) {
            return current;
          }
          return last;
        }, null);
        if (
          !lastEvent?.author?.user.name ||
          !isUserCurrentUser(currentAccount, lastEvent?.author)
        ) {
          addSnackbar &&
            addSnackbar({
              settingsKey: 'ITEM_CHANGED_ON_SERVER',
              severity: 'info',
              text:
                (lastEvent?.author?.user.name || 'An Unidentified User') +
                ' is working on the Proposal and it has been changed on the server. Check Event Log for Details.',
            });
        }
      }

      if (
        originalProposalRef.current?.updatedAt &&
        proposalRef.current &&
        getProposalHash(proposalRef.current) === proposalHash &&
        activeTab === 'details'
      ) {
        addSnackbar &&
          addSnackbar({
            severity: 'success',
            text: 'The form is updated',
          });
      }

      setOriginalProposal(() => ({ ...readyProposal }));
    }
  }, [
    loadedProposal,
    preloadProposal,
    fromStatus,
    currentAccount,
    proposalHash,
    addSnackbar,
    activeTab,
  ]);

  useEffect(() => {
    const { selectedClient, prevSelectedClient } = proposal;
    if (selectedClient?.key !== prevSelectedClient?.key) {
      setProposal((old) => {
        const { selectedClient, prevSelectedClient } = old;
        if (selectedClient?.key !== prevSelectedClient?.key) {
          return { ...old, prevSelectedClient: selectedClient, selectedClientContact: [] };
        }
        return old;
      });
    }
  }, [proposal]);

  useEffect(() => {
    const { selectedProjectFields, selectedProjectCountries } = proposal;
    const countryCodes = selectedProjectCountries?.map((country) => country.code) || [];
    const keepFields =
      selectedProjectFields?.filter((field) =>
        countryCodes?.includes(field.name.substr(field.name.length - 3))
      ) || [];
    if (keepFields?.length !== selectedProjectFields?.length) {
      setProposal((old) => {
        return validateFormRef.current({ ...old, selectedProjectFields: keepFields });
      });
    }
  }, [proposal]);

  useEffect(() => {
    if (clientData && !clientDataLoading) {
      setProposal((old) => {
        const { focalPointUser, divisionOwnership } = clientData;
        return validateFormRef.current({
          ...old,
          autoClientDivisionOwnership: divisionOwnership?.name,
          autoClientFocalPoint: focalPointUser?.name, // `${focalPointUser?.name} (${focalPointUser?.email})`,
          auto: [],
        });
      });
    }
  }, [clientData, clientDataLoading]);

  useEffect(() => {
    if (clientData && !clientDataLoading) {
      const { autoClientDivisionOwnership, autoClientFocalPoint } = proposal;
      const { focalPointUser, divisionOwnership } = clientData;
      if (
        (!autoClientDivisionOwnership && divisionOwnership) ||
        (!autoClientFocalPoint && focalPointUser)
      ) {
        setProposal((old) => {
          const { autoClientDivisionOwnership, autoClientFocalPoint } = old;
          if (!autoClientDivisionOwnership || !autoClientFocalPoint) {
            return validateFormRef.current({
              ...old,
              autoClientDivisionOwnership: autoClientDivisionOwnership ?? divisionOwnership?.name,
              autoClientFocalPoint: autoClientFocalPoint ?? focalPointUser?.name, // `${focalPointUser?.name} (${focalPointUser?.email})`,
              auto: [],
            });
          }
          return old;
        });
      }
    }
  }, [proposal, clientData, clientDataLoading]);

  const changeActiveTab = useCallback(
    (newTab: ActiveTab) => {
      if (proposalId) {
        const newPath = TabToPageMap[newTab].replace(':id', proposalId);
        history.push(newPath);
      } else {
        const newPath = TabToPageMap[newTab];
        history.push(newPath);
      }
    },
    [history, proposalId]
  );

  useEffect(() => {
    setProposal((oldState) => {
      return { ...validateFormRef.current(oldState) };
    });
  }, [proposal.selectedStage, validateFormRef]);

  const onChangeState = useCallback(
    (cb: (oldState: IProposalData) => IProposalData) => {
      formChanged && formChanged();
      setProposal((oldState) => {
        const newState = cb(oldState);
        return validateForm(newState);
      });
    },
    [setProposal, formChanged, validateForm]
  );

  const onDeleteProcess = useCallback(async () => {
    let success = false;
    const variables = { proposalId: proposal.id };
    try {
      const { data } = await deleteProposalMutation({
        variables,
      });
      if (data?.proposal_proposalDelete) {
        addSnackbar!({ text: 'Proposal is deleted', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    return success;
  }, [addSnackbar, deleteProposalMutation, proposal]);

  const onCancelProcess = useCallback(async () => {
    let success = false;
    const variables = { proposalId: proposal.id };
    try {
      const { data } = await cancelProposalMutation({
        variables,
      });
      if (data?.proposal_proposalCancel?.eventType) {
        addSnackbar!({ text: 'Document is canceled', severity: 'success' });
        success = true;
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
    if (success) {
      await client.resetStore();
    }
    return success;
  }, [addSnackbar, cancelProposalMutation, proposal, client]);

  const onSubmitValidate = useCallback(
    (isNewItem: boolean) => {
      const { selectedClient, selectedOwners, reviewers } = proposal;
      const validationResult = validateForm(proposal);
      const { errors } = validationResult;
      if (
        (!!errors && Object.keys(errors).length) ||
        (isNewItem && !selectedClient) ||
        (!isNewItem &&
          (!selectedOwners || selectedOwners.length < 1 || selectedOwners.length > MAX_OWNERS)) ||
        !reviewers?.length
      ) {
        setProposal(validateForm({ ...proposal, showValidator: true }));
        return false;
      }
      return true;
    },
    [proposal, validateForm]
  );

  const onSubmitValidateTest = useCallback(
    (isNewItem: boolean) => {
      const { isValid, selectedClient, selectedOwners } = proposal;
      const validationResult = validateForm(proposal);
      const { errors } = validationResult;
      if (
        (!!errors && Object.keys(errors).length) ||
        !isValid ||
        !selectedClient ||
        (!isNewItem &&
          (!selectedOwners || selectedOwners.length < 1 || selectedOwners.length > MAX_OWNERS))
      ) {
        return false;
      }
      return true;
    },
    [proposal, validateForm]
  );

  const onSubmitDraftValidateTest = useCallback(
    (isNewItem: boolean) => {
      const { selectedClient } = proposal;
      const validationResult = validateDraftForm(proposal);
      const { errors } = validationResult;
      if ((!!errors && Object.keys(errors).length) || (isNewItem && !selectedClient)) {
        return false;
      }
      return true;
    },
    [proposal, validateDraftForm]
  );

  const onSubmitReviewers = async ({ proposalId }: { proposalId: string }) => {
    const { reviewers } = proposal;

    if (reviewers.length && proposalId) {
      const reorderedReviewers = reasignOrder(reviewers) as IReviewer[];
      const reviewersToAdd = reorderedReviewers
        .filter((review) => !review.userFromDatabase && !review.deleted && review.employeeId)
        .map((reviewer) => {
          return {
            userId: reviewer.employeeId,
            order: reviewer.id,
            // persona: reviewer.type,
            isEnabled: !reviewer.disabled,
          };
        });

      const reviewersToModify = reorderedReviewers
        .filter((review) => review.changed && review.userFromDatabase)
        .map((reviewer) => {
          return {
            userId: reviewer.employeeId,
            order: reviewer.id,
            // persona: reviewer.type,
            isEnabled: !reviewer.disabled,
          };
        });

      if (reviewersToAdd.length || reviewersToModify.length) {
        const { result, isError, errors } = await tryUpdateProcedure({
          mutation: () =>
            proposalReviewersChangeMutation({
              variables: {
                proposalId: proposalId,
                reviewersToAdd,
                reviewersToModify,
              },
            }),
          parseResult: (data: any) => data?.proposal_proposalReviewersChange?.eventType,
        });
        return { result, isError, errors };
      }
    }
    return { result: {}, isError: false, errors: undefined };
  };

  const onCopy = () => {
    const storeData = {
      ...pick(proposal, [
        // 'name',
        'referenceOpportunityId',
        'partyId',
        'entityType',
        'proposalType',
        'guidelineCodeId',
        'evaluationTypeId',
        'budgetBasisId',
        'successChance',
        'receiveDate',
        'dueDate',
        'workTypeDetails',
        'scopeOfWorkDescription',
        'businessDecisions',
        'bidProposedValue',
        'bidFinalValue',
        'averageHourlyRate',
        'estimatedTotalHours',
        // 'stageReason',
        // 'lostReason',
        'expectedAwardDate',
        'expectedProjectStartDate',
        'expectedProjectEndDate',
        'taxRate',
        'feesValue',
        'expensesValue',
        'numberOfMatureFields',
        'numberOfGreenFields',
        'figuresRequired',
        'travelRequired',
        'otherTeamMembers',
        'notes',
        'bestTechnicalEstimateValue',
        'selectedClient',
        'selectedType',
        'selectedSuccessChance',
        'selectedPriceType',
        'selectedFiguresRequired',
        'selectedTravelRequired',
        // 'selectedStage',
        'selectedBillingBasis',
        'selectedBillingOrigin',
        'selectedProjectCountries',
        'selectedDeliverables',
        'selectedProjectFields',
        'selectedClientContact',
        'selectedReportType',
        'selectedPotentialRevenueByArea',
        'selectedSubmissionMethods',
        'selectedWorkType',
        'selectedEvaluationType',
        'selectedBudgetBasis',
        'selectedGuidelineCode',
        'selectedCompetitors',
        'selectedLead',
        'selectedIndividualsInvolved',
        'selectedPotentialTeamMembers',
        'selectedDivisionOpportunityOwner',
        'selectedDivisionsParticipating',
        // 'selectedOwners',
      ]),
      // reviewers: proposal?.reviewers?.map((reviewer) => ({
      //   ...reviewer,

      //   disabled: false,
      //   userFromDatabase: false,
      //   deleted: false,
      //   changed: true,
      //   dbOrder: -1,
      // })),
      // selectedStage: getWorkflowGeneralItem(ProposalStage.DRAFT),
      reviewers: [],
    };
    sessionStorage.setItem('CopyProposal', JSON.stringify(storeData));
  };

  const onSubmit = async () => {
    const id = parseInt(proposal.id);
    const isNewItem = !proposal.id || id <= 0;

    if (!onSubmitValidate(isNewItem)) {
      return;
    }

    let errorFound = false;

    const {
      selectedClient,
      selectedType,
      selectedSuccessChance,
      selectedPriceType,
      selectedFiguresRequired,
      selectedTravelRequired,
      selectedStage,
      selectedBillingBasis,
      selectedBillingOrigin,
      selectedProjectCountries,
      selectedDeliverables,
      selectedProjectFields,
      selectedClientContact,
      selectedReportType,
      selectedPotentialRevenueByArea,
      selectedSubmissionMethods,
      selectedWorkType,
      selectedEvaluationType,
      selectedBudgetBasis,
      selectedGuidelineCode,
      selectedCompetitors,
      selectedLead,
      selectedIndividualsInvolved,
      selectedPotentialTeamMembers,
      selectedDivisionOpportunityOwner,
      selectedDivisionsParticipating,
      selectedOwners,
      reviewers,
      bidProposedValueBreakdown,
    } = proposal;

    let newProposalId = -1;

    if (!selectedClient?.key) {
      return;
    }

    if (!isNewItem) {
      // Update Proposal

      const { add: billingOriginIdsToAdd, remove: billingOriginIdsToRemove } = getChanges(
        selectedBillingOrigin,
        loadedProposal?.billingOrigins
      );

      const { add: billingBasisIdsToAdd, remove: billingBasisIdsToRemove } = getChanges(
        selectedBillingBasis,
        loadedProposal?.billingBases
      );

      const { add: countryIdsToAdd, remove: countryIdsToRemove } = getChanges(
        selectedProjectCountries,
        loadedProposal?.countries
      );

      const { add: deliverableIdsToAdd, remove: deliverableIdsToRemove } = getChanges(
        selectedDeliverables,
        loadedProposal?.deliverables
      );

      const { add: fieldIdsToAdd, remove: fieldIdsToRemove } = getChanges(
        selectedProjectFields,
        loadedProposal?.fields
      );

      const { add: partyContactIdsToAdd, remove: partyContactIdsToRemove } = getChanges(
        selectedClientContact,
        loadedProposal?.partyContacts
      );

      const { add: proposalReportTypeIdsToAdd, remove: proposalReportTypeIdsToRemove } = getChanges(
        selectedReportType,
        loadedProposal?.proposalReportTypes
      );

      const { add: revenueAreaIdsToAdd, remove: revenueAreaIdsToRemove } = getChanges(
        selectedPotentialRevenueByArea,
        loadedProposal?.revenueAreas
      );

      const { add: submissionMethodIdsToAdd, remove: submissionMethodIdsToRemove } = getChanges(
        selectedSubmissionMethods,
        loadedProposal?.submissionMethods
      );

      const { add: workTypeIdsToAdd, remove: workTypeIdsToRemove } = getChanges(
        selectedWorkType,
        loadedProposal?.workTypes
      );

      const { add: individualsInvolvedUserIdsToAdd, remove: individualsInvolvedUserIdsToRemove } =
        getChanges(
          selectedIndividualsInvolved,
          loadedProposal?.individualsInvolved
            ? loadedProposal.individualsInvolved
                .filter((individual) => !!individual.user)
                .map((individual) => pick(individual.user!, ['id']))
            : undefined
        );

      const { add: participatingDivisionIdsToAdd, remove: participatingDivisionIdsToRemove } =
        getChanges(
          selectedDivisionsParticipating,
          loadedProposal?.participatingDivisions
            ? loadedProposal.participatingDivisions
                .filter((proposalDivision) => !!proposalDivision.division)
                .map((proposalDivision) => pick(proposalDivision.division!, ['id']))
            : undefined
        );

      const { add: potentialTeamMemberUserIdsToAdd, remove: potentialTeamMemberUserIdsToRemove } =
        getChanges(
          selectedPotentialTeamMembers,
          loadedProposal?.potentialTeamMembers
            ? loadedProposal.potentialTeamMembers
                .filter((teamMember) => !!teamMember.user)
                .map((teamMember) => pick(teamMember.user!, ['id']))
            : undefined
        );

      const { add: ownerIdsToAdd } = getChanges(
        selectedOwners,
        loadedProposal?.owners
          ? loadedProposal.owners
              .filter((owner) => !!owner.user)
              .map((owner) => pick(owner.user!, ['id']))
          : undefined
      );

      const ownerIdsEnabled = selectedOwners?.map((owner) => owner.id) || [];

      const ownerIdsToEnable = loadedProposal?.owners
        ? loadedProposal.owners
            .filter(
              (owner) => !!owner.user && !owner.isEnabled && ownerIdsEnabled.includes(owner.user.id)
            )
            .map((owner) => owner!.user!.id)
        : [];

      const ownerIdsToDisable = loadedProposal?.owners
        ? loadedProposal.owners
            .filter(
              (owner) => !!owner.user && owner.isEnabled && !ownerIdsEnabled.includes(owner.user.id)
            )
            .map((owner) => owner!.user!.id)
        : [];

      const prepareData: proposalUpdateVariables = {
        ...pick(proposal, [
          'id',
          'entityType',
          'referenceOpportunityId',
          'name',
          'proposalType',
          'guidelineCodeId',
          'evaluationTypeId',
          'budgetBasisId',
          'successChance',
          'receiveDate',
          'dueDate',
          'workTypeDetails',
          'scopeOfWorkDescription',
          'businessDecisions',
          'bidProposedValue',
          'bidProposedReason',
          'bidFinalValue',
          'averageHourlyRate',
          'estimatedTotalHours',
          'stageReason',
          'lostReason',
          'expectedAwardDate',
          'expectedProjectStartDate',
          'expectedProjectEndDate',
          'taxRate',
          'feesValue',
          'expensesValue',
          'numberOfMatureFields',
          'numberOfGreenFields',
          'figuresRequired',
          'travelRequired',
          'otherTeamMembers',
          'notes',
          'bestTechnicalEstimateValue',
        ]),
        // available only in create
        // entityType: ProposalEntityType.PROPOSAL,

        partyId: selectedClient?.key,
        proposalType: selectedType?.id,
        successChance: selectedSuccessChance?.id,
        priceType: selectedPriceType?.id,
        figuresRequired: selectedFiguresRequired?.id === '1',
        travelRequired: selectedTravelRequired?.id === '1',
        stage: selectedStage?.id,
        evaluationTypeId: selectedEvaluationType?.id,
        budgetBasisId: selectedBudgetBasis?.id,
        guidelineCodeId: selectedGuidelineCode?.id,
        owningDivisionId: selectedDivisionOpportunityOwner?.id,
        bidProposedReason: proposal.bidPriceLowered ? proposal.bidProposedReason : undefined,

        billingBasisIdsToAdd,
        billingBasisIdsToRemove,
        billingOriginIdsToAdd,
        billingOriginIdsToRemove,
        countryIdsToAdd,
        countryIdsToRemove,
        deliverableIdsToAdd,
        deliverableIdsToRemove,
        fieldIdsToAdd,
        fieldIdsToRemove,
        partyContactIdsToAdd,
        partyContactIdsToRemove,
        proposalReportTypeIdsToAdd,
        proposalReportTypeIdsToRemove,
        revenueAreaIdsToRemove,
        revenueAreaIdsToAdd,
        submissionMethodIdsToAdd,
        submissionMethodIdsToRemove,
        workTypeIdsToAdd,
        workTypeIdsToRemove,
        proposalLeadUserId: selectedLead?.id,
        individualsInvolvedUserIdsToAdd,
        individualsInvolvedUserIdsToRemove,
        potentialTeamMemberUserIdsToAdd,
        potentialTeamMemberUserIdsToRemove,
        participatingDivisionIdsToAdd,
        participatingDivisionIdsToRemove,
      };

      const requiredFields = ['id'];
      const omitKeys: string[] = [];
      const loadedProposalKeys = Object.keys(prepareData);
      const compareFunctionsKeys = Object.keys(compareFunctions);
      if (loadedProposal) {
        Object.keys(prepareData).forEach((key) => {
          if (requiredFields.includes(key)) {
            return;
          }

          if (prepareData[key as keyof typeof prepareData] === undefined) {
            omitKeys.push(key);
          }

          if (
            Array.isArray(prepareData[key as keyof typeof prepareData]) &&
            !prepareData[key as keyof typeof prepareData].length
          ) {
            omitKeys.push(key);
            return;
          }

          if (
            loadedProposalKeys.includes(key) &&
            prepareData[key as keyof typeof prepareData] ===
              loadedProposal[key as keyof typeof loadedProposal]
          ) {
            omitKeys.push(key);
            return;
          }

          if (typeof loadedProposal[key as keyof typeof loadedProposal] == 'boolean') {
            if (
              !loadedProposal[key as keyof typeof loadedProposal] ===
              !prepareData[key as keyof typeof prepareData]
            ) {
              omitKeys.push(key);
              return;
            }
          }

          if (
            compareFunctionsKeys.includes(key) &&
            compareFunctions[key as keyof typeof compareFunctions](
              prepareData[key as keyof typeof prepareData]
            )
          ) {
            omitKeys.push(key);
          }
        });
      }

      const saveData = omitKeys.length ? omit(prepareData, omitKeys) : prepareData;

      if (Object.keys(saveData).length > 1) {
        const { result, isError, errors } = await tryUpdateProcedure({
          mutation: () =>
            updateMutation({
              variables: {
                ...saveData,
              },
            }),
          parseResult: (data: any) => {
            return data;
          },
        });

        if (isError || !result) {
          errorFound = true;
          addSnackbar!({
            text: 'Error...' + errors?.join(' '),
            severity: 'error',
          });
        }
      }

      if (!errorFound) {
        let relatedDone = true;

        const originalCompetitorIds =
          loadedProposal?.competitors?.map((competitor) => competitor.competitor?.id) || [];

        const competitorsToAdd = selectedCompetitors
          .filter((competitor) => !originalCompetitorIds.includes(competitor.id))
          .map((competitor) =>
            pick(competitor, ['competitorName', 'hasWonBid', 'bidValue', 'notes'])
          );

        const keepCompetitorIds = selectedCompetitors
          .filter((competitor) => competitor.id)
          .map((competitor) => competitor.id);
        const competitorIdsToRemove = originalCompetitorIds.filter(
          (id) => !keepCompetitorIds.includes(id)
        );

        const competitorsToModify = selectedCompetitors
          .filter((competitor) => keepCompetitorIds.includes(competitor.id) && competitor.changed)
          .map((competitor) => ({
            ...pick(competitor, ['competitorName', 'hasWonBid', 'bidValue', 'notes']),
            competitorId: competitor.id,
          }));

        if (competitorsToModify.length || competitorIdsToRemove.length || competitorsToAdd.length) {
          const competitorsSaveData = {
            proposalId: proposal.id,
            competitorsToAdd: competitorsToAdd.length ? competitorsToAdd : undefined,
            competitorIdsToRemove: competitorIdsToRemove.length ? competitorIdsToRemove : undefined,
            competitorsToModify: competitorsToModify.length ? competitorsToModify : undefined,
          };

          const { result, isError, errors } = await tryUpdateProcedure({
            mutation: () =>
              competitorsChangeMutation({
                variables: {
                  ...competitorsSaveData,
                },
              }),
            parseResult: (data: any) => {
              return data;
            },
          });

          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        const ownersToAdd = ownerIdsToAdd.map((id: string) => ({ userId: id }));
        const ownersToModify = [
          ...ownerIdsToDisable.map((id: string) => ({
            userId: id,
            isEnabled: false,
          })),
          ...ownerIdsToEnable.map((id: string) => ({
            userId: id,
            isEnabled: true,
          })),
        ];

        if (ownersToAdd.length || ownersToModify.length) {
          const ownersSaveData = {
            proposalId: proposal.id,
            ownersToAdd,
            ownersToModify,
          };
          const { result, isError, errors } = await tryUpdateProcedure({
            mutation: () =>
              ownersChangeMutation({
                variables: {
                  ...ownersSaveData,
                },
              }),
            parseResult: (data: any) => {
              return data;
            },
          });

          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        if (relatedDone && reviewers.length && parseInt(proposal.id) > 0) {
          const { result, isError, errors } = await onSubmitReviewers({ proposalId: proposal.id });
          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        // Find at least one changed but then update prorated too
        if (parseInt(proposal.id) > 0 && !!bidProposedValueBreakdown.find((bd) => bd.changed)) {
          const monetaryBreakdownsToAdd = bidProposedValueBreakdown
            .filter((bd) => !bd.id)
            .map((bd) => ({
              value: parseFloat(bd.monetaryTransactionValue),
              year: parseInt(bd.startTimestamp),
            }));

          const monetaryBreakdownsToModify = bidProposedValueBreakdown
            .filter((bd) => (bd.changed || bd.prorated) && !!bd.id)
            .map((bd) => ({
              monetaryTransactionBreakdownId: bd.id,
              value: parseFloat(bd.monetaryTransactionValue),
              year: parseInt(bd.startTimestamp),
            }));

          const monetaryBreakdownsIds = bidProposedValueBreakdown
            .filter(({ id }) => !!id)
            .map(({ id }) => id);

          const monetaryBreakdownsToRemove = loadedProposal?.monetaryBreakdowns
            .filter(({ id }) => !monetaryBreakdownsIds.includes(id))
            .map(({ id }) => ({ monetaryTransactionBreakdownId: id }));

          const { result, isError, errors } = await tryUpdateProcedure({
            mutation: () =>
              bidProposadValueBreakdownChangeMutation({
                variables: {
                  proposalId: proposal.id,
                  monetaryBreakdownsToAdd: monetaryBreakdownsToAdd.length
                    ? monetaryBreakdownsToAdd
                    : undefined,
                  monetaryBreakdownsToModify: monetaryBreakdownsToModify.length
                    ? monetaryBreakdownsToModify
                    : undefined,
                  monetaryBreakdownsToRemove: monetaryBreakdownsToRemove?.length
                    ? monetaryBreakdownsToRemove
                    : undefined,
                },
              }),
            parseResult: (data: any) => {
              return data;
            },
          });

          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        if (relatedDone) {
          if (
            loadedProposal?.entityType !== ProposalEntityType.OPPORTUNITY ||
            proposal.entityType !== ProposalEntityType.PROPOSAL
          ) {
            addSnackbar!({
              text: 'Success',
              severity: 'success',
            });

            setOriginalProposal((old) => ({ ...old, updatedAt: undefined }));
            resetChanged && resetChanged();
            refetch && refetch();
            setTs(() => Date.now());
          } else {
            addSnackbar!({
              text: 'Conversion to Proposal completed',
              severity: 'success',
            });

            resetChanged && resetChanged();
            history.push(paths.client.PROPOSAL_DETAILS.replace(':id', proposal.id));
          }
        }
      }
    } else {
      // new Proposal
      const saveData: proposalCreateVariables = {
        ...pick(proposal, [
          'referenceOpportunityId',
          'partyId',
          'entityType',
          'proposalType',
          'guidelineCodeId',
          'evaluationTypeId',
          'budgetBasisId',
          'successChance',
          'receiveDate',
          'dueDate',
          'workTypeDetails',
          'scopeOfWorkDescription',
          'businessDecisions',
          'bidProposedValue',
          'bidFinalValue',
          'averageHourlyRate',
          'estimatedTotalHours',
          'stageReason',
          'lostReason',
          'expectedAwardDate',
          'expectedProjectStartDate',
          'expectedProjectEndDate',
          'taxRate',
          'feesValue',
          'expensesValue',
          'numberOfMatureFields',
          'numberOfGreenFields',
          'figuresRequired',
          'travelRequired',
          'otherTeamMembers',
          'notes',
          'bestTechnicalEstimateValue',
        ]),
        name: proposal.name || '',
        partyId: selectedClient?.key,
        entityType: ProposalEntityType.PROPOSAL,

        proposalType: selectedType?.id,
        successChance: selectedSuccessChance?.id,
        priceType: selectedPriceType?.id,
        figuresRequired: selectedFiguresRequired?.id === '1',
        travelRequired: selectedTravelRequired?.id === '1',
        stage: selectedStage?.id,
        evaluationTypeId: selectedEvaluationType?.id,
        budgetBasisId: selectedBudgetBasis?.id,
        guidelineCodeId: selectedGuidelineCode?.id,
        owningDivisionId: selectedDivisionOpportunityOwner?.id,
        proposalLeadUserId: selectedLead?.id,

        billingBasisIdsToAdd: selectedBillingBasis?.map((item) => item.id),
        billingOriginIdsToAdd: selectedBillingOrigin?.map((item) => item.id),
        countryIdsToAdd: selectedProjectCountries?.map((item) => item.id),
        deliverableIdsToAdd: selectedDeliverables?.map((item) => item.id),
        fieldIdsToAdd: selectedProjectFields?.map((item) => item.id),
        partyContactIdsToAdd: selectedClientContact?.map((item) => item.id),
        proposalReportTypeIdsToAdd: selectedReportType?.map((item) => item.id),
        revenueAreaIdsToAdd: selectedPotentialRevenueByArea?.map((item) => item.id),
        submissionMethodIdsToAdd: selectedSubmissionMethods?.map((item) => item.id),
        workTypeIdsToAdd: selectedWorkType?.map((item) => item.id),
        participatingDivisionIdsToAdd: selectedDivisionsParticipating?.map((item) => item.id),

        individualsInvolvedUserIdsToAdd: selectedIndividualsInvolved?.map((item) => item.id),
        potentialTeamMemberUserIdsToAdd: selectedPotentialTeamMembers?.map((item) => item.id),
      };

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createMutation({
            variables: {
              ...saveData,
            },
          }),
        parseResult: (data: any) => {
          return data;
        },
      });

      if (isError || !result) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        let relatedDone = true;

        newProposalId = result.proposal_proposalCreate.id;

        if (selectedCompetitors.length) {
          const competitorsSaveData = {
            proposalId: newProposalId,
            competitorsToAdd: selectedCompetitors.map((competitor) =>
              pick(competitor, ['competitorName', 'hasWonBid', 'bidValue', 'notes'])
            ),
          };

          const { result, isError, errors } = await tryUpdateProcedure({
            mutation: () =>
              competitorsChangeMutation({
                variables: {
                  ...competitorsSaveData,
                },
              }),
            parseResult: (data: any) => {
              return data;
            },
          });

          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        if (relatedDone && reviewers.length && newProposalId) {
          const { result, isError, errors } = await onSubmitReviewers({
            proposalId: newProposalId.toString(),
          });
          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        // Find at least one changed but then update prorated too
        if (newProposalId > 0 && !!bidProposedValueBreakdown.find((bd) => bd.changed)) {
          const monetaryBreakdownsToAdd = bidProposedValueBreakdown
            .filter((bd) => !bd.id)
            .map((bd) => ({
              value: parseFloat(bd.monetaryTransactionValue),
              year: parseInt(bd.startTimestamp),
            }));

          const { result, isError, errors } = await tryUpdateProcedure({
            mutation: () =>
              bidProposadValueBreakdownChangeMutation({
                variables: {
                  proposalId: newProposalId,
                  monetaryBreakdownsToAdd: monetaryBreakdownsToAdd.length
                    ? monetaryBreakdownsToAdd
                    : undefined,
                },
              }),
            parseResult: (data: any) => {
              return data;
            },
          });

          if (isError || !result) {
            relatedDone = false;
            addSnackbar!({
              text: 'Error...' + errors?.join(' '),
              severity: 'error',
            });
          }
        }

        if (relatedDone) {
          addSnackbar!({
            text: 'Success',
            severity: 'success',
          });
        }

        resetChanged && resetChanged();
        history.push(paths.client.PROPOSAL_DETAILS.replace(':id', newProposalId.toString()));
      }
    }
  };

  const validationSummary = useMemo(() => {
    const summary = [];
    const { errors } = proposal;
    let unrecognizedFields = 0;
    let recognizedFields = 0;

    const id = parseInt(proposal.id);
    const isNewItem = !proposal.id || id <= 0;

    const hash: { [key: string]: any } = {};

    const { selectedOwners } = proposal;
    if (!isNewItem) {
      if (!selectedOwners || selectedOwners.length < 1) {
        summary.push('At least one owner is required');
      } else {
        if (selectedOwners.length > MAX_OWNERS) {
          summary.push('Maximum of ' + MAX_OWNERS + ' owners is allowed');
        }
      }
    }

    // const { reviewers } = proposal;
    // if (!reviewers?.length) {
    //   summary.push('At least one Reviewer is required');
    // }

    if (errors && Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((errorKey) => {
        let found = false;
        [otherAndRelatedFields, basicDetailsFields, additionalDetailsFields].every((section) => {
          section.every((fieldGroup) => {
            fieldGroup.every((field) => {
              if (field.id === errorKey) {
                const errorItemSummary = { messages: errors[errorKey], subErrors: [] };
                hash[errorKey] = errorItemSummary;
                found = true;
              }
              return !found;
            });
            return !found;
          });
          return !found;
        });
      });
    }

    if (errors && Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((errorKey) => {
        let found = false;
        [otherAndRelatedFields, basicDetailsFields, additionalDetailsFields].every((section) => {
          section.every((fieldGroup) => {
            fieldGroup.every((field) => {
              if (field.id === errorKey) {
                if (field.parentField) {
                  hash[field.parentField].subErrors.push(hash[errorKey]);
                } else {
                  summary.push(hash[errorKey]);
                }
                found = true;
              }
              return !found;
            });
            return !found;
          });
          return !found;
        });
        if (!found) {
          unrecognizedFields++;
        } else {
          recognizedFields++;
        }
      });
    }

    if (unrecognizedFields === 1) {
      if (recognizedFields === 0) {
        summary.push('Please check an error found in a proposal field');
      } else {
        summary.push('Please also check an error found in an other proposal field');
      }
    }

    if (unrecognizedFields > 1) {
      if (recognizedFields === 0) {
        summary.push('Please check errors found in proposal fields');
      } else {
        summary.push('Please also check errors found in other proposal fields');
      }
    }

    return summary;
  }, [proposal, otherAndRelatedFields, basicDetailsFields, additionalDetailsFields]);

  const reviewersDocumentUsersByDocumentId = useMemo(() => {
    let reduced: any = {};
    loadedProposal?.reviewers?.map(
      ({ reviewerAssignedDocuments }: proposal_proposal_proposal_reviewers) => {
        reduced = reviewerAssignedDocuments?.reduce((r: any, a) => {
          r[a.proposalDocumentId!] = r[a.proposalDocumentId!] || [];
          r[a.proposalDocumentId!].push(a);
          return r;
        }, reduced);
        return undefined;
      }
    );
    return reduced;
  }, [loadedProposal]);

  const referenceOpportunityId = useMemo(() => {
    if (loadedProposal?.events?.length) {
      const createEvent = loadedProposal.events.find(
        (event) => event.eventType === ProposalEventType.PROPOSAL_CREATED
      );
      if (createEvent?.payload) {
        const { referenceOpportunityId } = JSON.parse(createEvent.payload);
        return referenceOpportunityId;
      }
    }
  }, [loadedProposal]);

  const refetchProposal = useCallback(async () => {
    setOriginalProposal((old) => ({ ...old, updatedAt: undefined }));
    resetChanged && resetChanged();
    refetch && (await refetch());
  }, [refetch, resetChanged]);

  const proposalUpdated = useCallback(() => {
    setOriginalProposal((old) => ({ ...old, updatedAt: undefined }));
  }, []);

  return (
    <ComponentContext.Provider
      value={{
        activeTab,
        changeActiveTab,
        proposal,
        originalProposal,
        loading,
        proposalId,
        onChangeState,
        onSubmit,
        onCopy,
        proposalStage: (fromStatus?.id || ProposalStage.DRAFT) as ProposalStage,
        validationSummary,
        basicDetailsFields,
        onSubmitValidateTest,
        onSubmitDraftValidateTest,
        onCancelProcess,
        onDeleteProcess,
        refetchProposal,
        proposalUpdated,
        usersListAsReviewers,
        newDocumentStatus,
        setNewDocumentStatus,
        reviewersDocumentUsersByDocumentId,
        ts,
        referenceOpportunityId,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);

import { BondType } from 'graphql/proposals/types/graphql-types';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { enumToArray } from 'utils/formats';
import { IGeneralListItem } from 'template/Proposal/ProposalContext';

export const bondTypeOptions: IGeneralListItem<BondType>[] = [
  { id: BondType.BID_BOND, name: 'EOI-Market Survey' },
  { id: BondType.PERFORMANCE_BOND, name: 'Proposal Direct Request' },
];

export const changeBondTypeName = (name: string): string => {
  return bondTypeOptions.find((option) => option.id === name)?.name || name;
};

export const useBondType = () => {
  const bondTypePairs = useMemo(() => {
    const arr = enumToArray(BondType);
    const pairs = arr
      .map((item) => ({
        id: item,
        name: changeBondTypeName(startCase(toLower(item))),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return [{ id: 'All', name: 'All' }, ...pairs];
  }, []);

  return { bondTypePairs };
};

// import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { FC } from 'react';
// import s from './style.module.scss';

export interface IPartyPrintingMeta {
  onReady: () => void;
}
export const PartyPrintingMeta: FC<IPartyPrintingMeta> = ({ onReady }) => {
  useEffect(() => {
    if (true) {
      onReady();
    }
  }, [onReady]);

  return (
    <div>
      <div>{window.location.href}</div>
      {/* <div className={s.centerBox}>
        <div className={s.title}>
          <Typography variant="body2" className="label-title-nocase">
            Client Details
          </Typography>
        </div>
      </div> */}
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

export interface IGeneralDataPairsState {
  pairs: { id: string; name: string }[];
  loading: boolean;
}

interface IUseGeneralDataPairsProps {
  GET_QUERY: any;
  getItems: (data: any) => { id: string; name: string }[];
}

export const useGeneralDataPairs = <itemsList extends unknown>({
  GET_QUERY,
  getItems,
}: IUseGeneralDataPairsProps): IGeneralDataPairsState => {
  const { data, loading } = useQuery<itemsList>(GET_QUERY);

  const getPairs = useCallback(() => {
    return (data && !loading && getItems(data)) || [];
  }, [data, getItems, loading]);

  const pairs = useMemo(() => {
    return getPairs();
  }, [getPairs]);

  return { pairs, loading };
};

import { Box, Typography } from '@mui/material';

import s from './style.module.scss';
import paths from 'constants/paths';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useComponentContext } from '../../ProposalContext';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const ProposalHeader = () => {
  const history = useHistory();
  const { confirmRequest } = useFormChangedDialogContext();
  const { proposal } = useComponentContext();
  const { id, name, stageAsString, promotedProposals } = proposal;

  const goToProposals = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && history.push(paths.client.OPPORTUNITIES);
    });
  }, [history, confirmRequest]);

  return (
    <>
      <div className={s.linkStyle} onClick={debounce(goToProposals, DEBOUNCE_TIMEOUT)}>
        Go back to opportunites list
      </div>
      <Box m={3} />
      <div>
        <div className={s.statusLine}>
          <div>
            <Typography variant="body2" className="label-title-nocase" style={{ fontSize: '16px' }}>
              Opportunity #ID{id} - "{name}"
            </Typography>
          </div>
          <div style={{ margin: '0 auto', paddingLeft: '10px', paddingRight: '10px' }}>
            {promotedProposals?.length && (
              <div>
                <Typography variant="body2" style={{ fontSize: '16px' }}>
                  This Opportunity is Promoted to Proposal
                </Typography>
              </div>
            )}
          </div>
          <div>
            <Typography variant="body2" className="label-title-nocase" style={{ fontSize: '16px' }}>
              STAGE: {stageAsString}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

import { Link } from '@mui/material';
import { FC, ReactElement, useCallback, useMemo, useState } from 'react';

export interface IDialogOppenerProps {
  children: any;
  getDialog: (open: boolean, onClose: () => void) => ReactElement;
}
export const DialogOppener: FC<IDialogOppenerProps> = ({ children, getDialog }) => {
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const dialogComponent = useMemo(() => getDialog(open, onClose), [getDialog, open, onClose]);

  return (
    <>
      <Link
        component="button"
        onClick={(e: any) => {
          setOpen(true);

          e.cancelBubble = true;
          e.stopPropagation();
          e.preventDefault();
        }}
        sx={{ textDecoration: 'none' }}
      >
        {dialogComponent}
        {children}
      </Link>
    </>
  );
};

import { useLazyQuery } from '@apollo/client';
import { currentUser, currentUser_currentUser } from 'graphql/proposals/types/currentUser';
import { GET_CURRENT_USER } from 'graphql/proposals/users';
import { useCallback, useEffect, useState } from 'react';

export const useCurrentDbUser = () => {
  const [user, setUser] = useState<currentUser_currentUser>();

  const [getCurrentUser, { data, loading, refetch, called, error }] = useLazyQuery<currentUser>(
    GET_CURRENT_USER,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadCurrentUser = useCallback(() => {
    if (called) {
      refetch!();
    } else {
      getCurrentUser();
    }
  }, [getCurrentUser, refetch, called]);

  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  useEffect(() => {
    if (data?.currentUser && !loading && !error) {
      setUser(data.currentUser);
    }
  }, [data, error, loading]);

  return {
    user,
    error,
    loading,
    refetch,
  };
};

import { useEffect, useMemo, useRef } from 'react';
import { Chart } from 'components/Chart/Chart';
import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';
import s from './style.module.scss';
import { Chart as ChartJS } from 'chart.js';
import { changeProposalStageName } from 'utils/formats';

const options = {
  plugins: {
    datalabels: {
      color: '#333',
      display: true,
      align: 'bottom',
      backgroundColor: '#EEE',
      borderRadius: 3,
      font: {
        size: 14,
      },
      formatter: (value: number, ctx: any) => {
        if (value < 5) {
          return `${value}%`;
        }

        const label = ctx.chart.data.labels[ctx.dataIndex];
        return `${label}: ${value}%`;
      },
    },
  },
};

const stageColors = {
  WON: 'rgb(86, 162, 86)',
  LOST: 'rgb(255, 86, 86)',
  CANCELED: 'rgb(255, 99, 132)',
  POSTPONED: 'rgb(54, 162, 235)',
  DRAFT: 'rgb(255, 205, 86)',
  PENDING: 'rgb(86, 86, 255)',
};

export const AmountByStageChart = () => {
  const { aggregatesByStage, aggregatesTotal } = useProposalAggregatesContext();

  const chartDivRef = useRef<any>();
  const chartRef = useRef<ChartJS>();

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.canvas.style.backgroundColor = 'lightgray';
    }
  }, [chartRef]);

  const total = useMemo(() => {
    if (aggregatesTotal) {
      return aggregatesTotal.proposal_proposalAggregates[0].bidProposedValueSum;
    }
  }, [aggregatesTotal]);

  const keyValue = useMemo(() => {
    if (!aggregatesByStage) {
      return {};
    }
    const reduced = aggregatesByStage.proposal_proposalAggregates.reduce(
      (acc, { stage, bidProposedValueSum }) => {
        acc[stage || ''] = Math.round(((bidProposedValueSum || 0) / (total || 1)) * 100);
        return acc;
      },
      {} as any
    );

    return reduced;
  }, [aggregatesByStage, total]);

  const labels = useMemo(() => {
    return Object.keys(keyValue).sort((a, b) => keyValue[a] - keyValue[b]);
  }, [keyValue]);

  const displayLabels = useMemo(() => {
    return labels.map((label) => (label ? changeProposalStageName(label) : 'Unknown Stage'));
  }, [labels]);

  const dataSet = useMemo(() => {
    return labels.map((label) => keyValue[label]);
  }, [keyValue, labels]);

  const data = useMemo(
    () => ({
      labels: displayLabels,
      datasets: [
        {
          label: 'Sum of Proposal Amount by Stage in %',
          data: dataSet,
          backgroundColor: labels.map((label) => stageColors[label as keyof typeof stageColors]),
          hoverOffset: 4,
        },
      ],
    }),
    [dataSet, displayLabels, labels]
  );

  return (
    <div className={s.charting}>
      <div className={s.chart} ref={chartDivRef}>
        <Chart type={'doughnut'} options={options} data={data} chartRef={chartRef}></Chart>
      </div>
    </div>
  );
};

import { Box, Container, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { NewDocumentVersion } from '../NewDocumentVersion';
import { DocumentApprovalReviewer } from './components/DocumentApprovalReviewer';
import { isCurrentUser, useComponentContext } from 'template/Opportunity/ProposalContext';
import { useMemo } from 'react';

import DocumentApprovalSection from './components/DocumentApprovalSection';
import { useProposalDocuments } from 'hooks/proposalDocumentsHook';
import { ProposalDocumentsList } from '../ProposalDocumentsList/ProposalDocumentsList';
import { useMsalAccount } from 'hooks/msalAccount';

export const ProposalFileManagement = () => {
  const { proposal, refetchProposal, promotedProposal } = useComponentContext();

  const { msalAccount: currentAccount } = useMsalAccount();

  const { id, updatedAt } = proposal;
  const { data } = useProposalDocuments({ id, updatedAt });

  const clientProposalDocuments = data?.proposal_proposal.documents?.filter(
    (x) => x?.versions?.length && x?.versions[0].file
  );

  const isReviewer = useMemo(() => {
    return !!proposal?.reviewers.find((user) => isCurrentUser(currentAccount, user));
  }, [proposal?.reviewers, currentAccount]);

  return (
    <Container maxWidth="lg">
      <Grid container padding={0} spacing={2}>
        <Grid xs={12}>
          <Box m={5} />
          <Grid container padding={0} spacing={4}>
            <Grid xs={12}>
              <Grid container padding={0} spacing={4}>
                {!promotedProposal ? (
                  <>
                    <Grid xs={12}>
                      <Grid container padding={0} spacing={3}>
                        <Grid xs={12}>
                          <NewDocumentVersion
                            clientProposalDocuments={clientProposalDocuments || []}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <div style={{ display: 'none' }}>
                      <Grid xs={12}>
                        <Grid container padding={0} spacing={3}>
                          {!!isReviewer && proposal.canReview ? (
                            <Grid xs={12}>
                              <DocumentApprovalSection
                                account={currentAccount}
                                proposal={proposal}
                                refetchProposal={refetchProposal!}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>

                      <Grid xs={12}>
                        <Typography variant="body2" className="label-title">
                          SELECT NEXT REVIEWER FOR DOCUMENT APPROVAL
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <DocumentApprovalReviewer
                          proposal={proposal}
                          refetchProposal={refetchProposal}
                          enableSubmit
                        ></DocumentApprovalReviewer>
                      </Grid>
                    </div>

                    <Grid xs={12}>
                      <Grid xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </>
                ) : undefined}
                <Grid xs={12}>
                  <ProposalDocumentsList
                    disabled={promotedProposal}
                    approvedDateTs={undefined}
                    clientProposalDocuments={clientProposalDocuments || []}
                  ></ProposalDocumentsList>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box m={5} />
    </Container>
  );
};

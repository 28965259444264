import React, { FC, useMemo, useEffect, useState } from 'react';

import LoadingOverlay from 'react-loading-overlay-ts';

import { Typography, Button, Box, Grid, TextareaAutosize, LinearProgress } from '@mui/material';

import { AlertDialog, ConfirmationDialog, EasyAutoComplete, Upload } from 'components';
import { useNewDocumentVersion } from './hooks';

import { useComponentContext } from 'template/Proposal/ProposalContext';
import { proposalDocuments_proposal_proposal_documents } from 'graphql/proposals/types/proposalDocuments';
import { Asterisks } from '../ProposalForm/components/Asterisks.tsx/Asterisks';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
export interface IKeyNamePair {
  key: string;
  name: string;
}

export interface INewDocumentVersion {
  clientProposalDocuments: proposalDocuments_proposal_proposal_documents[];
}

const getDocumentHashKey = (document: {
  originalFilename?: string | null;
  proposalFileGroup?: string | null;
  proposalFileType?: string | null;
}) => {
  const { originalFilename, proposalFileGroup } = document;
  const key = [originalFilename || '', proposalFileGroup].join(';');
  return key;
};

export const NewDocumentVersion: FC<INewDocumentVersion> = ({ clientProposalDocuments }) => {
  const [proposalFileType, setProposalFileType] = useState<IKeyNamePair | null>(null);
  const [proposalFileGroup, setProposalFileGroup] = useState<IKeyNamePair | null>(null);

  const { proposal, refetchProposal } = useComponentContext();

  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const uploadStart = () => {
    setUploadingLoading(true);
  };

  const uploadEnd = (uploadResult: any) => {
    setUploadingLoading(false);
    setProposalFileType(null);
    setProposalFileGroup(null);

    if (uploadResult.hasErrors === true) {
      setAlertMessage(uploadResult.errorMessage);
      setAlertOpen(true);
    }
  };

  const {
    isUploading,
    percentage,
    onDrop,
    files,
    requireReapprovalFromAllStakeHolders,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    openConfirmDialog,
    removeFile,
    comment,
    handleComment,
    newDocument,
    handleNewDocument,
  } = useNewDocumentVersion({
    document: proposal,
    proposalFileType: proposalFileType?.key,
    proposalFileGroup: proposalFileGroup?.key,
    refetchDocument: refetchProposal,
    onUploadStart: uploadStart,
    onUploadEnd: uploadEnd,
    multiple: true,
  });

  const canSubmit = useMemo(() => {
    return !!proposalFileType && !!proposalFileGroup;
  }, [proposalFileType, proposalFileGroup]);

  useEffect(() => {
    const isNewdocument = (): void => {
      const status = proposal?.stageAsString?.toLowerCase();
      if (status?.includes('review pending approval') || status?.includes('review approved')) {
        handleNewDocument(true);
      } else {
        handleNewDocument(false);
      }
    };

    isNewdocument();
  }, [handleNewDocument, proposal?.stageAsString]);

  const filenamesByCategoryHash = useMemo(() => {
    const filesHash: any = {};
    clientProposalDocuments.forEach((document) => {
      filesHash[getDocumentHashKey(document)] = document;
    });
    return filesHash;
  }, [clientProposalDocuments]);

  const replacementFiles = useMemo(() => {
    const toReplace: any[] = [];
    files.forEach((file: any) => {
      const found =
        filenamesByCategoryHash[
          getDocumentHashKey({
            originalFilename: file.name,
            proposalFileGroup: proposalFileGroup?.key,
            proposalFileType: proposalFileType?.key,
          })
        ];
      if (found) toReplace.push({ name: file.name, document: found });
    });
    return toReplace;
  }, [files, filenamesByCategoryHash, proposalFileGroup?.key, proposalFileType?.key]);

  const dialogVariant = (): JSX.Element => {
    // const hasFile = files.length > 0;
    let dialog = { title: '', message: '', style: { width: 380 } };
    let notes: any;

    if (replacementFiles?.length) {
      notes = (
        <div>
          <Box mt={1}></Box>
          <div>Following list of files will replace previosus versions:</div>
          <ul>
            {replacementFiles.map((replacementDefinition) => {
              return <li>{replacementDefinition.name}</li>;
            })}
          </ul>
        </div>
      );
    }

    if (comment && requireReapprovalFromAllStakeHolders) {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload, new comment and re-approval process.',
        style: { width: 380 },
      };
    } else if (requireReapprovalFromAllStakeHolders) {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload and re-approval process.',
        style: { width: 380 },
      };
    } else if (comment) {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload and new comment.',
        style: { width: 280 },
      };
    } else {
      dialog = {
        title: 'DOCUMENT UPLOAD CONFIRMATION',
        message: 'Please confirm new document version upload.',
        style: { width: 380 },
      };
    }

    return (
      <ConfirmationDialog
        open={openConfirmDialog}
        title={dialog?.title}
        message={dialog?.message}
        style={dialog?.style}
        onClose={handleCloseConfirmDialog}
        notes={notes}
      />
    );
  };

  return (
    <LoadingOverlay active={uploadingLoading} spinner text="Please wait, upload in progress...">
      <AlertDialog
        title="Error"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />

      <div className="document-section">
        {dialogVariant()}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2" className="label-title">
              {newDocument ? 'Upload files' : 'Upload files'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={4}>
              <Upload
                onDrop={onDrop}
                removeFile={removeFile}
                isUploading={isUploading}
                percentage={percentage}
                files={files}
                currentDocumentFileVersion={null}
                multiple
              />
            </Box>
          </Grid>
          {!newDocument && files && files.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" className="label-title">
                        Document Type{' '}
                        <Asterisks count={1} tooltipTitle="Document Type is required"></Asterisks>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div style={{ maxWidth: '250px' }}>
                        <EasyAutoComplete
                          key={`type-proposal-file-type`}
                          items={[
                            { key: 'COMMERCIAL_PROPOSAL', name: 'Commercial Proposal' },
                            { key: 'TECHNICAL_PROPOSAL', name: 'Technical Proposal' },
                            {
                              key: 'JOINT_COMMERCIAL_AND_TECHNICAL_PROPOSAL',
                              name: 'Joint Commercial and Technical',
                            },
                            { key: 'COMMUNICATION', name: 'Communication' },
                            { key: 'SUPPORT_DOCUMENT', name: 'Support Document' },
                          ]}
                          selected={proposalFileType}
                          label=""
                          textFieldStyle="outlined"
                          optionsLabel="name"
                          selectedChanged={(value: any): void => {
                            setProposalFileType!(value);
                          }}
                          error={false}
                          getOptionSelected={(option: any, value: any) => {
                            return option.key === value.key;
                          }}
                          placeholder="Select a document type"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2" className="label-title">
                        Document Group{' '}
                        <Asterisks count={1} tooltipTitle="Document Group is required"></Asterisks>:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div style={{ maxWidth: '250px' }}>
                        <EasyAutoComplete
                          key={`type-proposal-file-group`}
                          items={[
                            { key: 'CLIENT_DOCUMENT', name: 'Client Document' },
                            { key: 'DEMAC_SUBMITTED_DOCUMENT', name: 'D&M Submitted Document' },
                            { key: 'DEMAC_SUPPORTING_DOCUMENT', name: 'D&M Supporting Document' },
                          ]}
                          selected={proposalFileGroup}
                          label=""
                          textFieldStyle="outlined"
                          optionsLabel="name"
                          selectedChanged={(value: any): void => {
                            setProposalFileGroup!(value);
                          }}
                          error={false}
                          getOptionSelected={(option: any, value: any) => {
                            return option.key === value.key;
                          }}
                          placeholder="Select a document group"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box marginBottom={1}>
                    <Box marginBottom={1}>
                      <strong>Comment (optional):</strong>
                    </Box>

                    <TextareaAutosize
                      color="grey"
                      style={{
                        width: '95%',
                        minHeight: '1rem',
                        padding: 10,
                        borderRadius: 4,
                        overflow: 'auto',
                        border: '1px #ccc solid',
                      }}
                      aria-label="minimum height"
                      minRows={4}
                      placeholder="Write comment..."
                      onChange={handleComment}
                      value={comment}
                      className="MuiInputBase-input"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box marginBottom={2} marginTop={1} />

              <Box marginBottom={5} marginTop={1}>
                <Button
                  className="btn-responsive"
                  variant="contained"
                  color="primary"
                  onClick={debounce(handleOpenConfirmDialog, DEBOUNCE_TIMEOUT)}
                  disabled={!canSubmit || isUploading}
                  style={{ width: 135 }}
                >
                  {isUploading && 'Uploading'}
                  {!isUploading && 'Submit'}
                </Button>
              </Box>
            </Grid>
          )}
          {isUploading ? (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          ) : undefined}
        </Grid>
      </div>
    </LoadingOverlay>
  );
};

import { ProposalType } from 'graphql/proposals/types/graphql-types';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { changeProposalTypeName } from 'template/Proposal/components/ProposalForm/constants/proposalTypeOptions';
import { enumToArray } from 'utils/formats';

export const useProposalType = () => {
  const proposalTypePairs = useMemo(() => {
    const arr = enumToArray(ProposalType);
    const pairs = arr
      .map((item) => ({
        id: item,
        name: changeProposalTypeName(startCase(toLower(item))),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return pairs;
  }, []);

  return { proposalTypePairs };
};

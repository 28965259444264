import React, { FC, useMemo } from 'react';
import {
  IProposalData,
  useComponentContext as useOpportunityContext,
} from 'template/Opportunity/ProposalContext';
import { Provider as ProposalProvider } from 'template/Proposal/ProposalContext';

import { NewProposal as NewProposalComponent } from 'pages/ProposalNew/components/NewProposal/NewProposal';
import { ProposalEntityType } from 'graphql/proposals/types/graphql-types';

import { Provider as ChangedDialogContextProvider } from 'template/FormChangedDialog/FormChangedDialogContext';
import { FormChangedDialog } from 'template/FormChangedDialog/FormChangedDialog';
export interface IProps {
  routes: Array<any>;
}

export const ConvertToProposalPage: FC<IProps> = ({ routes }) => {
  const { proposal, loadedProposal } = useOpportunityContext();

  const preloadProposal: Partial<IProposalData> = useMemo(
    () => ({ ...proposal, entityType: ProposalEntityType.PROPOSAL }),
    [proposal]
  );

  return (
    <ChangedDialogContextProvider>
      <FormChangedDialog></FormChangedDialog>
      <ProposalProvider
        preloadProposal={preloadProposal}
        loadedProposal={loadedProposal}
        activeTab="new"
      >
        <NewProposalComponent formTitle="Convert to Proposal"></NewProposalComponent>
      </ProposalProvider>
    </ChangedDialogContextProvider>
  );
};

import { FC } from 'react';
import { Container, Paper, useTheme, useMediaQuery } from '@mui/material';

import './index.scss';

import { IBondsTableHookLoadParams } from 'hooks/bondsHook';
import { BondsTableContextProvider } from './BondsContext';
import { BondsTableView } from './BondsTableView';

export const BondsTemplate: FC<{
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IBondsTableHookLoadParams>;
  tableStorageKey?: string;
  title?: string;
}> = ({ overridePageLoadParams, initPageLoadParams, tableStorageKey, title }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        <BondsTableContextProvider
          overridePageLoadParams={overridePageLoadParams}
          initPageLoadParams={initPageLoadParams}
          tableStorageKey={tableStorageKey}
        >
          <BondsTableView title={title} />

          {/* <Print
            printing={printing}
            setPrinting={setPrinting}
            PrintingMeta={AllBondsTablePrintingMeta}
            PrintingData={AllBondsTableView}
            landscape
            subtitle="Bonds List"
          ></Print> */}
        </BondsTableContextProvider>
      </Paper>
    </Container>
  );
};

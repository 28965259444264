import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Button } from '@mui/material';

import s from './style.module.scss';
import cn from 'classnames';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { DataSource, useDataSourceConfigContext } from 'template/Charting/context/DataSourceConfig';
import { ChartingTable } from '../ChartingTable/ChartingTable';
import { DataSourceItem } from './Components/DataSourceItem/DataSourceItem';
import { Config } from 'template/Charting/context/DataSourceConfig';

import './index.scss';
import { ChartingPreconfigDialog } from 'template/Modals/ChartingPreconfigDialog/ChartingPreconfigDialog';
import { useExportToExcel } from 'template/Charting/hooks/exportToExcel';

export interface IChartingData {
  sheetName: string;
  csvData: any[]; // rows columnKey/fieldValue
  headings: { sheetName: string; headings: { key: string; name: string }[] };
}

export interface IDataSourcePageProps {}

export const DataSourcePage: FC<IDataSourcePageProps> = () => {
  const { config, setConfig, chartData, addConfigColumns } = useDataSourceConfigContext();

  const onChangeColumnConfig = useCallback(
    (index: number, cb: (record: DataSource) => DataSource) => {
      setConfig &&
        setConfig((config) => {
          const columns = [...config.columns];
          columns[index] = cb(columns[index]);
          const newConfig = { ...config, columns };
          return newConfig;
        });
    },
    [setConfig]
  );

  const getOnChangeColumnConfigCallback = useCallback(
    (index: number) => (cb: (record: DataSource) => DataSource) => onChangeColumnConfig(index, cb),
    [onChangeColumnConfig]
  );

  const getOnDelete = useCallback(
    (index: number) => () => {
      setConfig &&
        setConfig((config) => {
          const columns = [...config.columns];
          columns.splice(index, 1);

          return {
            ...config,
            columns,
          };
        });
    },
    [setConfig]
  );

  const onMove = useCallback(
    (fromId: string, toId: string) => {
      setConfig &&
        setConfig((config) => {
          const columns = [...config.columns];
          const indexFrom = columns.findIndex((col) => col.id === fromId);
          const indexTo = columns.findIndex((col) => col.id === toId);
          columns.splice(indexTo, 0, columns.splice(indexFrom, 1)[0]);
          return {
            ...config,
            columns,
          };
        });
    },
    [setConfig]
  );
  // ***************************** Drag & Drop **************************

  const [draggableParentClass, setdraggableParentClass] = useState('');
  const [dragId, setDragId] = useState<string | null>(null);

  let dragTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();
  let dragOffTimeoutHandle = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const holder: any = document.querySelector('#draggable-parent');

    window.onmouseup = (): void => {
      if (dragTimeoutHandle.current) {
        clearTimeout(dragTimeoutHandle.current!);
      }

      setdraggableParentClass('');
    };

    holder.onmousedown = (): void => {
      dragTimeoutHandle.current = setTimeout(() => {
        setdraggableParentClass('dragging');
      }, 250);
    };
  }, []);

  const onReorder = useCallback(
    (event: unknown, fromId: string, toId: string): void => {
      onMove(fromId, toId);
    },
    [onMove]
  );

  const handleDrag = useCallback(
    (ev: any) => {
      setDragId(ev.currentTarget.id);
    },
    [setDragId]
  );

  // *************************** / Drag & Drop **************************
  const [showPreconfig, setShowPreconfig] = useState(false);
  const preconfigPage = useCallback(() => {
    setShowPreconfig(true);
  }, []);

  const setChartConfig = useCallback(
    (config: Config) => {
      addConfigColumns && addConfigColumns(config.columns);
    },
    [addConfigColumns]
  );

  const { exportData } = useExportToExcel();
  const exportToExcel = useCallback(() => {
    if (chartData) {
      const { csvData, headings } = chartData;
      const len = Object.keys(headings).length;
      const nulls = Array.from({ length: len }, () => null);
      const data = {
        sheets: [
          {
            sheetName: 'Proposals Chart Data',
            csvData,
            headings,
            headerStyles: nulls,
            rowStyles: nulls,
          },
        ],
      };
      exportData(data);
    }
  }, [chartData, exportData]);

  return (
    <div className={s.charting}>
      <ChartingPreconfigDialog
        onClose={(config: any) => {
          setShowPreconfig(false);
          if (config) {
            setChartConfig(config);
          }
        }}
        open={showPreconfig}
        data={chartData}
        onSubmit={(config) => {
          setShowPreconfig(false);
          if (config) {
            setChartConfig(config);
          }
        }}
      />
      <div className={s.config}>
        <div id="draggable-parent" className={draggableParentClass}>
          {config.columns?.map((column, index) => {
            return (
              <div
                key={index}
                draggable={true}
                onDragStart={handleDrag}
                onDrop={(ev) => {
                  const targetId = ev.currentTarget.id;
                  if (!!dragId && dragId !== targetId) {
                    onReorder(null, dragId, targetId);
                  }
                  setDragId(null);
                  setdraggableParentClass('');
                  if (dragTimeoutHandle.current) {
                    clearTimeout(dragTimeoutHandle.current!);
                  }
                  if (dragOffTimeoutHandle.current) {
                    clearTimeout(dragOffTimeoutHandle.current!);
                  }
                }}
                onDragOver={(ev) => {
                  ev.preventDefault();
                  const targetId = ev.currentTarget.id;
                  if (!!dragId && dragId !== targetId) {
                    onReorder(null, dragId, targetId);
                  }
                  if (dragOffTimeoutHandle.current) {
                    clearTimeout(dragOffTimeoutHandle.current);
                  }
                  setdraggableParentClass('dragging');
                }}
                onDragLeave={(ev) => {
                  dragOffTimeoutHandle.current = setTimeout(() => {
                    setdraggableParentClass('');
                  }, 2000);
                }}
                id={`${column.id}`}
                className={cn(column.id === dragId ? 'dragged' : undefined, s.dataBlock)}
              >
                <DataSourceItem
                  column={column}
                  setColumn={getOnChangeColumnConfigCallback(index)}
                  onDelete={debounce(getOnDelete(index), DEBOUNCE_TIMEOUT)}
                />
              </div>
            );
          })}
        </div>
        <div className={cn(s.field, s.margin)}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="medium"
            onClick={debounce(preconfigPage, DEBOUNCE_TIMEOUT)}
          >
            + Add
          </Button>
        </div>
        <div className={cn(s.field, s.margin)}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            size="medium"
            onClick={debounce(exportToExcel, DEBOUNCE_TIMEOUT)}
          >
            Export to Excel
          </Button>
        </div>
      </div>
      <div className={s.chart}>
        {chartData ? (
          <ChartingTable {...chartData} columns={config.columns}></ChartingTable>
        ) : undefined}
      </div>
    </div>
  );
};

import { FC, useMemo, useCallback } from 'react';
import { DialogForm } from 'components/DialogForm/DialogForm';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useAllDataLoader } from 'hooks/allDataLoaderHook';
import { PartyFilterInput } from 'graphql/proposals/types/graphql-types';
import { GET_PARTIES_GEO_INFO } from 'graphql/proposals/parties';
import { GeoLocation } from 'components/GeoLocation/GeoLocation';
import { IPartyGeoInfo } from 'graphql/proposals/types/IPartyGeoInfo';
import { PopupPartyName } from './PopupPartyName';
import s from './style.module.scss';

export interface IPartiesGeoMap {
  onClose: () => void;
}

const getCount = (data: any) => data.parties;

export const PartiesGeoMap: FC<IPartiesGeoMap> = ({ onClose }) => {
  // const history=useHistory()
  const partiesFilter = useMemo(() => {
    return {};
  }, []);

  const filterToAPI = useCallback((filterValues: any): PartyFilterInput => ({}), []);

  const { items, loading, isAllLoaded } = useAllDataLoader<any, IPartyGeoInfo, PartyFilterInput>({
    GET_QUERY: GET_PARTIES_GEO_INFO,
    overridePageLoadParams: partiesFilter,
    getItems: getCount,
    filterToAPI,
    initRowsPerPage: 99,
  });

  // const gotoParty = useCallback((id: string)=>{
  //   history.push(paths.client.PAR.replace(':id', id));
  // },[])

  return (
    <DialogForm dialogTitle={'Clients Map'} onClose={onClose} open={true} maxWidth="lg">
      <LoadingOverlay spinner active={loading} text="Loading your content...">
        {isAllLoaded ? (
          <GeoLocation
            coords={{ latitude: 0, longitude: 0 }}
            markers={items
              ?.filter(
                (party: IPartyGeoInfo) =>
                  party?.geos?.length &&
                  party.geos[0].geoX !== null &&
                  party.geos[0].geoX !== 0 &&
                  party.geos[0].geoY !== 0
              )
              .map((party: IPartyGeoInfo) => {
                const { geoX, geoY } = party.geos![0];
                const { id } = party;
                return {
                  coords: { latitude: geoY, longitude: geoX },
                  displayName: (
                    <div>
                      <PopupPartyName id={id} onClose={onClose}></PopupPartyName>
                    </div>
                  ),
                  className: party.isApplicationControlled
                    ? party.partyType?.name === 'CLIENT'
                      ? s.clientMarkerRed
                      : s.clientMarkerGreen
                    : party.partyType?.name === 'CLIENT'
                    ? undefined //s.clientMarkerColor
                    : undefined,
                };
              })}
          ></GeoLocation>
        ) : undefined}
      </LoadingOverlay>
    </DialogForm>
  );
};

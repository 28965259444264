import { useCallback, useState } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';
import { GET_BONDS_EXPORT, GET_BONDS_COUNT } from 'graphql/proposals/bonds';
import { bondsList } from 'graphql/proposals/types/bondsList';
import { bondsListCount } from 'graphql/proposals/types/bondsListCount';

export interface IProcess {
  status: 'stop' | 'run' | 'fin';
  stages: Array<{ key: string; name: string }>;
  progress: { [key: string]: { steps: number; current: number } };
}

const getInitialLoadBondsStatus: () => IProcess = () => ({
  status: 'stop',
  stages: [
    { key: 'calculate', name: 'Collecting Info' },
    { key: 'getBonds', name: 'Loading Pages' },
  ],
  progress: { calculate: { steps: 1, current: 0 }, getBonds: { steps: 1, current: 0 } },
});

export const useLoadBonds = () => {
  const client = useApolloClient();
  const [loadBondsStatus, setLoadBondsStatus] = useState<IProcess>(getInitialLoadBondsStatus());

  const setLoadProposalStepCount = useCallback((step: string, count: number) => {
    setLoadBondsStatus((old) => ({
      ...old,
      progress: { ...old.progress, [step]: { steps: count, current: 0 } },
    }));
  }, []);

  const incLoadProposalStepProgress = useCallback((step: string) => {
    setLoadBondsStatus((old) => ({
      ...old,
      progress: {
        ...old.progress,
        [step]: { ...old.progress[step], current: old.progress[step].current + 1 },
      },
    }));
  }, []);

  const loadBonds = useCallback(
    async ({
      allPages,
      rowsPerPage,
      variables,
    }: {
      variables: any;
      allPages: boolean;
      rowsPerPage: number;
    }) => {
      setLoadBondsStatus(() => getInitialLoadBondsStatus());
      setLoadBondsStatus((old) => ({ ...old, status: 'run' }));
      let result: { data: any; error: ApolloError | undefined };
      if (!allPages) {
        incLoadProposalStepProgress('calculate');

        const { data, error } = await client.query<bondsList>({
          query: GET_BONDS_EXPORT,
          variables: { ...variables },
          fetchPolicy: 'network-only',
        });
        incLoadProposalStepProgress('getBonds');

        if (data.bonds) {
          result = { data: data?.bonds, error };
        } else {
          result = { data: [], error };
        }
      } else {
        const { data: proposalCount, error: proposalCountError } =
          await client.query<bondsListCount>({
            query: GET_BONDS_COUNT,
            variables: { ...variables },
          });
        incLoadProposalStepProgress('calculate');

        if (proposalCount && !proposalCountError) {
          setLoadProposalStepCount('getBonds', Math.ceil(proposalCount.bondCount / rowsPerPage));
        }

        result = { data: [], error: undefined };
        let lastPage = false;

        for (var pageNo = 0; !lastPage; pageNo++) {
          const { data, error } = await client.query<bondsList>({
            query: GET_BONDS_EXPORT,
            variables: { ...variables, take: rowsPerPage, skip: rowsPerPage * pageNo },
          });

          if (error) {
            result.data.error = error;
            break;
          }

          lastPage = !data?.bonds?.length;
          if (!lastPage) {
            incLoadProposalStepProgress('getBonds');
            result.data.push(...data.bonds);
          }
        }
      }

      setLoadBondsStatus((old) => ({ ...old, status: 'fin' }));
      return result;
    },
    [client, incLoadProposalStepProgress, setLoadProposalStepCount]
  );

  return {
    loadBonds,
    loadBondsStatus,
  };
};

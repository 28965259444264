import { FC } from 'react';

import { Container, useTheme, useMediaQuery, Paper, Box } from '@mui/material';
import { ProposalAggregatesProvider } from './Context/ProposalAggregatesContext';
import { YearSummaryGrid } from './Components/YearSummaryGrid/YearSummaryGrid';
import { AmountByDivisionAndStage } from './Components/AmountByDivisionAndStage/AmountByDivisionAndStage';
import { AmountByStage } from './Components/AmountByStage/AmountByStage';
import { AmountByStageChart } from './Components/AmountByStageChart/AmountByStageChart';
import { AmountByDivision } from './Components/AmountByDivision/AmountByDivision';

export interface IYearSummaryProps {
  year: number;
}

export const YearSummary: FC<IYearSummaryProps> = ({ year }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '40px', paddingRight: '40px', paddingTop: '24px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={{
          ...(matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }),
          minHeight: 'calc(100vh - 128px)',
        }}
        id="main-paper"
      >
        <ProposalAggregatesProvider year={year}>
          <YearSummaryGrid>
            <div>
              <AmountByDivisionAndStage />
              <Box mt={3} />
              <AmountByDivision />
              <Box mt={1} />
            </div>
            <AmountByStage />
            <AmountByStageChart />
          </YearSummaryGrid>
        </ProposalAggregatesProvider>
      </Paper>
    </Container>
  );
};

import { gql } from '@apollo/client';
import { ICountry } from './countriesFragments';
import { IUserData } from './fragments';
import { IDivisionName } from './partiesFragments';

export const IField = gql`
  fragment IField on Field {
    id
    code
    name
    createdAt
    updatedAt
    # petrophysicsSubmissions
  }
`;

export const IPetrophysicsSubmissionDetail = gql`
  fragment IPetrophysicsSubmissionDetail on PetrophysicsSubmissionDetail {
    id
    severity
    description
  }
`;

export const IPetrophysicsSubmission = gql`
  fragment IPetrophysicsSubmission on PetrophysicsSubmission {
    id
    originalFileName
    blobContainer
    blobName
    blobSize
    mimeType
    validationStatus
    processingStatus
    startedAt
    endedAt
    createdAt
    updatedAt
    user {
      ...IUserData
    }
    # project: Project
    field {
      ...IField
    }
    details {
      ...IPetrophysicsSubmissionDetail
    }
  }
  ${IField}
  ${IPetrophysicsSubmissionDetail}
  ${IUserData}
`;

export const IProjectListItem = gql`
  fragment IProjectListItem on Project {
    id
    key
    name
    asOfDate
  }
`;

export const IProject = gql`
  fragment IProject on Project {
    id
    key
    name
    asOfDate
    isActive
    predecessor
    createdAt
    updatedAt
    petrophysicsSubmissions {
      ...IPetrophysicsSubmission
    }
    countries {
      ...ICountry
    }
    divisions {
      ...IDivisionName
    }
    owner {
      ...IDivisionName
    }
    leader {
      ...IUserData
    }
    coordinator {
      ...IUserData
    }
    secondaryCoordinator {
      ...IUserData
    }
    technicianCoordinator {
      ...IUserData
    }
    secondaryTechnicianCoordinator {
      ...IUserData
    }
  }
  ${IPetrophysicsSubmission}
  ${ICountry}
  ${IDivisionName}
  ${IUserData}
`;

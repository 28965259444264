import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IPartyEvent } from 'graphql/proposals/types/IPartyEvent';
import { FC } from 'react';
import { EventCommentSubmit } from '../EventCommentSubmit/EventCommentSubmit';
import { EventCommentLog } from './components/EventCommentLogs';

export interface IEventsLog {
  id: string;
  refetch: any;
  events: IPartyEvent[];
}

export const EventsLog: FC<IEventsLog> = ({ id, refetch, events }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" className="label-title-nocase">
            Events/comments log
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', gap: '2em', width: '85%' }}>
            <EventCommentSubmit partyId={id} refetchParty={refetch}></EventCommentSubmit>
          </div>
        </Grid>
      </Grid>
      <Box m={2} />

      <Grid container spacing={0}>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <EventCommentLog
            partyId={id}
            refetchParty={refetch}
            events={events}
            documentArchived={false}
          ></EventCommentLog>
        </Grid>
      </Grid>
    </>
  );
};

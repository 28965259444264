import { DataSource } from 'template/Charting/context/DataSourceConfig';
import { proposalsExportToChartHead } from './proposalsExportToChart';
import {
  ChartingFieldType,
  optionsByType,
} from 'template/Charting/Components/SelectDataSource/constants';

// export const getDefaultsForGroup = () => ({
//   conversionFunctionId: 'value' as ConversionFunctionId,
//   aggregateFunctionId: 'group' as AggregateFunctionId,
//   sortFunctionId: 'asc' as SortFunctionId,
// });

// export const getDefaultsForSelect: () => Omit<
//   DataSource,
//   'id' | 'sheetIndex' | 'fieldKey'
// > = () => ({
//   conversionFunctionId: 'value' as ConversionFunctionId,
//   aggregateFunctionId: 'select' as AggregateFunctionId,
//   sortFunctionId: 'asc' as SortFunctionId,
// });

export const getDefaults = (ft: ChartingFieldType) => ({
  fieldType: ft,
  ...optionsByType[ft].defaults,
});

export const getChartField = (item: any): IData => {
  const { id, label, numeric, fieldType, group } = item;

  const ft = fieldType || (numeric ? ChartingFieldType.numeric : ChartingFieldType.category);

  const retVal = {
    fieldKey: id,
    fieldName: label,
    numeric,
    group,
    ...getDefaults(ft),
  };

  return retVal;
};

export interface ISheet {
  sheetName: string;
  data: IData[];
  sheet?: ISheet;
}
export interface IData extends Omit<DataSource, 'id' | 'sheetIndex'> {
  fieldName: string;
  numeric: boolean;
  sheet?: ISheet;
  group: number;
}

export const chartTableFields: ISheet = {
  sheetName: 'Proposals',
  data: [
    ...proposalsExportToChartHead
      .filter(
        (item) =>
          ![
            'workTypeDetails',
            'id',
            'updatedAt',
            'figuresRequired',
            'reviewStatus',
            'activity',
            'fields',
            'potentialTeamMembers',
            'documents.clientDocumentsCount',
            'numberOfGreenFields',
            'travelRequired',
            'documents.D&MSubmittedDocuments',
            'documents.D&MSupportingDocuments',
          ].includes(item.id)
      )
      .map((item) => getChartField(item)),
    {
      ...getChartField({
        id: 'bidProposedValue',
        fieldType: ChartingFieldType.numeric,
        label: 'Proposal Amount',
        group: 1,
      }),

      sheet: {
        sheetName: 'Monetary Breakdowns',
        data: [
          {
            id: 'mbYear',
            label: 'Bid Price Year',
            fieldType: ChartingFieldType.categoryNumber,
            group: 1,
          },
          {
            id: 'monetaryBreakdowns',
            numeric: true,
            label: 'Bid Price Value',
            fieldType: ChartingFieldType.numeric,
            group: 1,
          },
        ]
          .filter((item) => ['mbYear', 'monetaryBreakdowns'].includes(item.id))
          .map((item) => ({
            ...getChartField(item),
            sheetName: 'Monetary Breakdown',
          })),
      },
    },
  ],
};

import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { GET_BONDS, GET_BONDS_COUNT } from 'graphql/proposals/bonds';
import { bondsList } from 'graphql/proposals/types/bondsList';
import { bondsListCount } from 'graphql/proposals/types/bondsListCount';
import { BondFilter } from 'graphql/proposals/types/graphql-types';

import { useCallback, useEffect, useMemo, useState } from 'react';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IBondsTableHookLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
}

export interface IBondsTableHookProps {
  initFilter?: any;
  overridePageLoadParams?: any;
  initPageLoadParams?: Partial<IBondsTableHookLoadParams>;
  tableStorageKey?: string;
}

export interface IBondsTableHookValue {
  totalItems: number;
  documents: any;
  loadPage: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  filterApplied: any;
  pageLoadParams: any;
}

const defaultFilterValues = {
  type: { id: 'All', name: 'All' },
  amount: { id: 'All', name: 'All' },
};

export const convertSelectedToIds = (item: { id: string } | undefined) => {
  return item && item.id !== 'All' ? [item.id] : undefined;
};

export const useBondsTableHook = ({
  initFilter,
  overridePageLoadParams,
  initPageLoadParams,
  tableStorageKey,
}: IBondsTableHookProps): IBondsTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey || 'AllBondsTable',
  });

  const [totalItems, setTotalItems] = useState(0);
  const [documents, setDocuments] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IBondsTableHookLoadParams>({
    page: 0,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    filter: initFilter,
    ...initPageLoadParams,
    ...getItem(),
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      const { filter } = overridePageLoadParams;
      setPageLoadParams((current) => ({
        ...current,
        ...overridePageLoadParams,
        filter: { ...current.filter, ...filter },
        filterValues: {
          ...defaultFilterValues,
          ...current.filterValues,
          ...overridePageLoadParams.filterValues,
        },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const [loadDocuments, { called, data, loading, error, refetch }] = useLazyQuery<bondsList>(
    GET_BONDS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    loadDocumentsCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCounter,
      refetch: refetchCount,
    },
  ] = useLazyQuery<bondsListCount>(GET_BONDS_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter: { ...filter },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadDocuments({ variables });
    }
  }, [loadDocuments, refetch, called, pageLoadParams]);

  useEffect(() => {
    if (!loading && data) {
      setDocuments(
        data.bonds?.map((bond) => {
          const {
            id,
            type,
            tenderNumber,
            tenderName,
            contractNumber,
            contractName,
            recipientReceiveDeadline,
            validityThroughDate,
            amount,
            currency,
            stage,
            isLocked,
            proposal,
            party,
            owningDivision,
          } = bond;
          return {
            id,
            type,
            tenderNumber,
            tenderName,
            contractNumber,
            contractName,
            recipientReceiveDeadline,
            validityThroughDate,
            amount,
            currency,
            stage,
            isLocked,
            proposal,
            party,
            owningDivision,
          };
        })
      );
    }
  }, [data, loading]);

  useEffect(() => {
    if (!data && !loading && error) {
      setDocuments([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const { filter } = pageLoadParams;

    const variables = {
      filter: { ...filter },
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadDocumentsCount({ variables });
    }
  }, [loadDocumentsCount, refetchCount, calledCount, pageLoadParams]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(dataCount.bondCount);
    }
  }, [dataCount, loadingCount]);

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const amountSelected = convertSelectedToIds(filterValues.amount);
        const amountPair = amountSelected ? amountSelected[0].split('-') : [null, null];

        const newFilter: BondFilter = {
          proposalIds: filterValues.proposalIds || undefined,
          partyIds: filterValues.partyIds || undefined,
          proposalNameContains: filterValues.proposalName || undefined,
          partyNameContains: filterValues.clientName || undefined,
          owningDivisionIds: filterValues.owningDivision || undefined,
          tenderNumberContains: filterValues.tenderNumber || undefined,
          tenderNameContains: filterValues.tenderName || undefined,
          contractNumberContains: filterValues.contractNumber || undefined,
          contractNameContains: filterValues.contractName || undefined,
          type: filterValues.type.id !== 'All' ? filterValues.type.id : undefined,
          // type: filterValues.type || undefined,
          currencies: filterValues.currency || undefined,

          amountFrom: amountPair[0] ? parseFloat(amountPair[0]) : undefined,
          amountTo: amountPair[1] && amountPair[1] !== '0' ? parseFloat(amountPair[1]) : undefined,
        };

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams]
  );

  const filterApplied = useMemo(() => {
    const { filter } = pageLoadParams;
    return (
      JSON.stringify(filter) !== '{}' &&
      (!overridePageLoadParams?.filter ||
        JSON.stringify(filter) !== JSON.stringify(overridePageLoadParams?.filter))
    );
  }, [pageLoadParams, overridePageLoadParams?.filter]);

  return {
    totalItems,
    documents,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: error || errorCounter,
  };
};

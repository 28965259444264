import { useLazyQuery } from '@apollo/client';
import { EasyAutoComplete } from 'components';
import { GET_PROPOSAL_COUNT, GET_PROPOSALS } from 'graphql/proposals/proposals';
import { proposalCount } from 'graphql/proposals/types/proposalCount';
import { proposals } from 'graphql/proposals/types/proposals';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';

const rowsPerPage = 20;
const orderBy = 'NAME';
const order = 'ASC';

export interface IProposalsListItem {
  key: string;
  name: string;
  ddLabel: string;
}

export interface ISelectProposalProps {
  onSelectChange: (selected?: IProposalsListItem) => void;
  error?: boolean;
  usedProposalIds: string[];
  partyIds?: string[];
  selected?: IProposalsListItem;
  placeholder?: string;
  disabled?: boolean;
}

export const SelectProposal: FC<ISelectProposalProps> = ({
  onSelectChange,
  error,
  usedProposalIds,
  partyIds,
  selected,
  placeholder,
  disabled,
}) => {
  const [search, setSearch] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [proposals, setProposals] = useState<IProposalsListItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<IProposalsListItem>();

  useEffect(() => {
    setSelectedItem(selected);
  }, [selected]);

  const [
    loadProposalCount,
    {
      data: dataProposalCount,
      loading: loadingProposalCount,
      refetch: refetchProposalCount,
      called: calledProposalCount,
    },
  ] = useLazyQuery<proposalCount>(GET_PROPOSAL_COUNT);

  const [getProposalNames, { data, loading, refetch, called, error: getProposalNamesError }] =
    useLazyQuery<proposals>(GET_PROPOSALS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(
    (page: number, search: string) => {
      const variables = {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
        filter: { nameContains: search, partyIds: partyIds?.length ? partyIds : undefined },
      };

      if (called) {
        refetch!(variables);
      } else {
        getProposalNames({ variables });
      }
    },
    [getProposalNames, refetch, called, partyIds]
  );

  const loadPageCount = useCallback(
    (search: string) => {
      const variables = {
        filter: { nameContains: search, partyIds: partyIds?.length ? partyIds : undefined },
      };

      if (calledProposalCount) {
        refetchProposalCount!(variables);
      } else {
        loadProposalCount({ variables });
      }
    },
    [loadProposalCount, refetchProposalCount, calledProposalCount, partyIds]
  );

  useEffect(() => {
    if (!loadingProposalCount && dataProposalCount) {
      setTotalItems(dataProposalCount.proposal_proposalCount);
    }
  }, [loadingProposalCount, dataProposalCount]);

  useEffect(() => {
    if (data && !loading && !getProposalNamesError) {
      const ddList = data.proposal_proposals
        .filter((proposal) => !usedProposalIds.includes(proposal.id))
        .map((proposal) => {
          return {
            key: proposal.id,
            name: proposal.name,
            ddLabel: proposal.name,
          };
        });
      setProposals((proposals) => {
        return [...proposals, ...ddList];
      });
    }
  }, [data, loading, getProposalNamesError, usedProposalIds]);

  useEffect(() => {
    setProposals([]);
    loadPage(0, search);
    loadPageCount(search);
  }, [search, loadPage, loadPageCount]);

  const maxPage = useMemo(() => Math.ceil(Number(totalItems) / Number(rowsPerPage)), [totalItems]);

  return (
    <EasyAutoComplete
      items={proposals}
      selected={selectedItem}
      label=""
      textFieldStyle="outlined"
      optionsLabel="ddLabel"
      placeholder={placeholder ?? 'Select Proposal'}
      selectedChanged={(value: any): void => {
        onSelectChange(value);
        setSelectedItem(value);
      }}
      error={error}
      pagination={{
        pages: maxPage,
        loadPage: (page: number) => {
          loadPage(page, search);
        },
      }}
      onInputChange={(event: object, value: string, reason: string) => {
        setSearch(value);
      }}
      disableClearable={false}
      disabled={disabled}
    />
  );
};

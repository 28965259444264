import { useEffect } from 'react';
import { FC } from 'react';

export interface IProposalsTablePrintingMetaProps {
  onReady: () => void;
}
export const ProposalsTablePrintingMeta: FC<IProposalsTablePrintingMetaProps> = ({ onReady }) => {
  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <div>
      <div>{window.location.href}</div>
    </div>
  );
};

import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { transformProposalStageText } from 'utils/TableTransformFunctions';
import { capitalizeFirstLetter } from 'utils/formats';

export const monetaryBreakdownExportToExcelHead: IHeadCell[] = [
  // Business Proposal ID
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Business Proposal ID',
  },

  // Year
  {
    id: 'mbYear',
    numeric: true,
    disablePadding: false,
    label: 'Year',

    transformFunction: (data: any) => (data ? data : 'N/A'),
  },

  // Value
  {
    id: 'monetaryBreakdowns',
    numeric: true,
    disablePadding: false,
    label: 'Value',

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Bid Price Proposed
  {
    id: 'bidProposedValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Amount',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Proposal Name
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Name',
  },

  // Assigned Division
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Division',
    transformFunction: (data) => data?.division?.name || 'N/A',
  },

  // Client Name
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',

    transformFunction: (data) =>
      data
        ? (data.projectSetupClientCode ? data.projectSetupClientCode + ' - ' : '') + data.name
        : 'N/A',
  },

  // Stage
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Stage',

    transformFunction: transformProposalStageText,
  },

  // Last date Stage Changed
  {
    id: 'lastStageChangedEvent',
    numeric: false,
    disablePadding: false,
    label: 'Stage Date',

    transformFunction: (data: any) =>
      data?.createdAt
        ? new Date(data?.createdAt).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // Activity
  {
    id: 'activity', // 'isStale',
    numeric: false,
    disablePadding: false,
    label: 'Activity',
  },

  // Expected Award Date
  {
    id: 'expectedAwardDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Award Date',

    transformFunction: (data: any) =>
      data
        ? new Date(data).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // Bid Price Final
  {
    id: 'bidFinalValue',
    numeric: true,
    disablePadding: false,
    label: 'Bid Price Final',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Changed',

    transformFunction: (data: any) =>
      data
        ? new Date(data).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Created',

    transformFunction: (data: any) =>
      data
        ? new Date(data).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
  {
    id: 'successChance',
    numeric: false,
    disablePadding: false,
    label: 'Chance of Success',

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : 'N/A'),
  },
];

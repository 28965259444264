import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { bondCreateVariables } from 'graphql/proposals/types/bondCreate';
import { BondType } from 'graphql/proposals/types/graphql-types';
import { validateBond } from './components/ProposalBond/validator';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';
import { useMutation } from '@apollo/client';
import {
  BOND_DELETE_MUTATION,
  BOND_LOCK_MUTATION,
  BOND_UNLOCK_MUTATION,
  BOND_UPDATE_MUTATION,
} from 'graphql/proposals/bonds';
import { tryUpdateProcedure } from 'utils/apollo';
import { useUI } from 'contexts/UiContext';
import { omit } from 'lodash';
import { bond_bond } from 'graphql/proposals/types/bond';
import { IBondData } from './components/ProposalBond/interface';

export interface IBondContextState {
  changed: boolean;
  confirmCallback?: (confirmed: boolean) => void;
  bond?: IBondData;
  showValidator: boolean;
}

export interface IBondContextActions {
  formChanged: () => void;
  resetChanged: () => void;
  confirmRequest: (cb: (confirmed: boolean) => void) => void;
  leaveAllow: () => void;
  leaveDecline: () => void;
  checkAndProceed: (cb: any) => () => void;

  setBond: any;

  validateAndSubmit: any;
  lock: () => Promise<void>;
  unLock: () => Promise<void>;
  deleteProcess: any;
  refetchFilesList: any;
}

const initialState: IBondContextState = {
  changed: false,
  bond: undefined,
  showValidator: false,
};

const BondContext = createContext<IBondContextState & Partial<IBondContextActions>>(initialState);

interface IBondProviderProps {
  children: any;

  loadedBond?: bond_bond;
  bond?: IBondData;
  setBond: any;
  loadBond: () => void;
  refetchFilesList: any;
  // setBond: ((fn: ((bond?: IBondData) => IBondData)) => void) | ((bond: IBondData) => void)
}

const getBond: () => bondCreateVariables = () => ({
  proposalId: '',
  partyId: '',
  type: BondType.BID_BOND,
  tenderNumber: '',
  tenderName: '',
  contractNumber: '',
  contractName: '',
  description: '',
  beneficiaryNameAndAddress: '',
  beneficiaryPhoneNumber: '',
  beneficiaryFaxNumber: '',
  beneficiaryEmail: '',
  amount: 0,
  currency: '',
  issuingBankRequirements: '',
  wordingOrFormatRequirements: '',
  deliveryInstructions: '',
  isLocked: false,
});

export const BondProvider: FC<IBondProviderProps> = ({
  children,
  loadedBond,
  bond,
  setBond,
  loadBond,
  refetchFilesList,
}) => {
  const [changed, setChanged] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState<(confirmed: boolean) => void>();
  const { openValidationResult } = useFormValidationReportContext();
  const [showValidator, setShowValidator] = useState(false);

  const { addSnackbar } = useUI();

  const [updateMutation] = useMutation(BOND_UPDATE_MUTATION);
  const [deleteMutation] = useMutation(BOND_DELETE_MUTATION);
  const [bondLockMutation] = useMutation(BOND_LOCK_MUTATION);
  const [bondUnLockMutation] = useMutation(BOND_UNLOCK_MUTATION);

  const formChanged = useCallback(() => {
    setChanged(true);
  }, []);

  const resetChanged = useCallback(() => {
    setChanged(false);
  }, []);

  const confirmRequest = useCallback(
    (cb: (confirmed: boolean) => void) => {
      if (changed) {
        setConfirmCallback(() => cb);
      } else {
        cb(true);
      }
    },
    [changed]
  );

  useEffect(() => {
    setBond(getBond());
  }, [setBond]);

  const leaveAllow = () => {
    setChanged(false);
    setConfirmCallback(() => {
      return undefined;
    });
    confirmCallback && confirmCallback(true);
  };

  const leaveDecline = () => {
    setConfirmCallback(() => {
      return undefined;
    });
    confirmCallback && confirmCallback(false);
  };

  const checkAndProceed = (callback: () => void) => () => {
    confirmRequest!((confirmed) => {
      confirmed && callback();
    });
  };

  const setBondProxy = useCallback(
    (cbOrData: ((bond?: IBondData) => IBondData) | IBondData) => {
      if (cbOrData instanceof Function) {
        setBond((bond: any) => {
          const newData = cbOrData(bond);
          return { ...newData, ...validateBond(newData) };
        });
      } else {
        setBond({ ...cbOrData, ...validateBond(cbOrData) });
      }
    },
    [setBond]
  );

  const compareFunctions = useMemo(
    () => ({
      proposalId: (id: string | undefined) => loadedBond?.proposal?.id === id,
      partyId: (id: string | undefined) =>
        loadedBond?.proposal ? !id : loadedBond?.party?.id === id,
    }),
    [loadedBond]
  );

  const validateAndSubmit = useCallback(async () => {
    if (!bond) {
      return;
    }

    const { isValid } = bond;
    if (!isValid) {
      setShowValidator(true);
      openValidationResult && openValidationResult();
      return;
    }

    const {
      amount,
      beneficiaryEmail,
      beneficiaryFaxNumber,
      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      contractName,
      contractNumber,
      currency,
      deliveryInstructions,
      description,
      issuingBankRequirements,
      recipientReceiveDeadline,
      tenderName,
      tenderNumber,
      type,
      validityThroughDate,
      wordingOrFormatRequirements,
      stage,
    } = bond;

    const prepareData = {
      proposalId: bond.selectedProposal?.key,
      partyId: bond.selectedProposal?.key ? undefined : bond.selectedParty?.key,
      type,
      stage,
      description,

      tenderName,
      tenderNumber,
      contractName: type === BondType.BID_BOND ? '' : contractName,
      contractNumber: type === BondType.BID_BOND ? '' : contractNumber,

      amount,
      currency,

      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      beneficiaryFaxNumber,
      beneficiaryEmail,

      recipientReceiveDeadline,
      validityThroughDate,

      deliveryInstructions,
      issuingBankRequirements,
      wordingOrFormatRequirements,
    };

    const requiredFields: any[] = [];
    const omitKeys: string[] = [];
    const loadedKeys = Object.keys(prepareData);
    const compareFunctionsKeys = Object.keys(compareFunctions);
    if (loadedBond) {
      Object.keys(prepareData).forEach((key) => {
        if (requiredFields.includes(key)) {
          return;
        }

        if (
          Array.isArray(prepareData[key as keyof typeof prepareData]) &&
          !prepareData[key as keyof typeof prepareData].length
        ) {
          omitKeys.push(key);
          return;
        }

        if (
          loadedKeys.includes(key) &&
          prepareData[key as keyof typeof prepareData] ===
            loadedBond[key as keyof typeof loadedBond]
        ) {
          omitKeys.push(key);
          return;
        }

        if (typeof loadedBond[key as keyof typeof loadedBond] == 'boolean') {
          if (
            !loadedBond[key as keyof typeof loadedBond] ===
            !prepareData[key as keyof typeof prepareData]
          ) {
            omitKeys.push(key);
            return;
          }
        }

        if (
          compareFunctionsKeys.includes(key) &&
          compareFunctions[key as keyof typeof compareFunctions](
            prepareData[key as keyof typeof prepareData]
          )
        ) {
          omitKeys.push(key);
        }
      });
    }

    const saveData = omitKeys.length ? omit(prepareData, omitKeys) : prepareData;

    if (!Object.keys(saveData).length) {
      return { isError: false, bondId: bond.id! };
    }

    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        updateMutation({
          variables: {
            bondId: bond.id,
            ...saveData,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });

    return { result, isError, errors, bondId: bond.id };
  }, [bond, openValidationResult, loadedBond, updateMutation, compareFunctions]);

  const lock = useCallback(async () => {
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        bondLockMutation({
          variables: {
            bondId: bond?.id,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (!isError && result?.bondLock?.isLocked) {
      addSnackbar!({
        text: 'Bond is Locked',
        severity: 'success',
      });
      resetChanged && resetChanged();
      loadBond();
    } else {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Error...Unable to lock',
          severity: 'error',
        });
      }
    }
  }, [bond, bondLockMutation, addSnackbar, loadBond, resetChanged]);

  const unLock = useCallback(async () => {
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        bondUnLockMutation({
          variables: {
            bondId: bond?.id,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (!isError && result?.bondUnlock?.isLocked === false) {
      addSnackbar!({
        text: 'Bond is Unlocked',
        severity: 'success',
      });
      resetChanged && resetChanged();
      loadBond();
    } else {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Error...Unable to unlock',
          severity: 'error',
        });
      }
    }
  }, [bond, addSnackbar, loadBond, resetChanged, bondUnLockMutation]);

  const deleteProcess = useCallback(async () => {
    if (!bond) {
      return;
    }
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        deleteMutation({
          variables: {
            bondId: bond.id,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });

    if (isError || !result) {
      addSnackbar!({
        text: 'Error...' + errors?.join(' '),
        severity: 'error',
      });
    } else {
      addSnackbar!({
        text: 'Success',
        severity: 'success',
      });

      resetChanged && resetChanged();
    }
    return !isError;
  }, [bond, deleteMutation, addSnackbar, resetChanged]);

  return (
    <BondContext.Provider
      value={{
        formChanged,
        resetChanged,
        confirmRequest,
        leaveDecline,
        leaveAllow,
        changed,
        confirmCallback,
        checkAndProceed,
        bond,
        setBond: setBondProxy,
        showValidator,
        validateAndSubmit,
        lock,
        unLock,
        deleteProcess,
        refetchFilesList,
      }}
    >
      {children}
    </BondContext.Provider>
  );
};

export const useBondContext = () => useContext(BondContext);

import { gql } from '@apollo/client';

export const IProposalEvent = gql`
  fragment IProposalEvent on ProposalEvent {
    eventType
    payload
    createdAt
    author {
      persona
      user {
        id
        name
        email
      }
    }
    subject {
      user {
        name
      }
    }
    file {
      originalFilename
    }
  }
`;

export const IBondEvent = gql`
  fragment IBondEvent on BondEvent {
    eventType
    createdAt
    author {
      id
      name
      email
    }
  }
`;

export const IProposalFile = gql`
  fragment IProposalFile on ProposalFile {
    id
    proposalFileType
    proposalFileGroup
    originalFilename
    blobContainer
    blobName
    mimeType
    createdAt
    downloadUrl
    event {
      ...IProposalEvent
    }
  }
  ${IProposalEvent}
`;

export const IBondFile = gql`
  fragment IBondFile on BondFile {
    id
    bondFileType
    originalFilename
    blobContainer
    blobName
    mimeType
    createdAt
    downloadUrl
    event {
      ...IBondEvent
    }
  }
  ${IBondEvent}
`;

import React, { FC } from 'react';
import { NewProposal as NewProposalTemplate } from 'template/Proposal/components/NewProposal/NewProposal';

export interface INewProposalProps {
  formTitle?: string;
}

export const NewProposal: FC<INewProposalProps> = ({ formTitle }) => {
  return <NewProposalTemplate formTitle={formTitle}></NewProposalTemplate>;
};

import { Button } from '@mui/material';
import React, { FC } from 'react';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
export interface IConfirmationButton {
  action: any;
  text: string;
}
export const ConfirmationButton: FC<IConfirmationButton> = ({ action, text, ...props }) => {
  const { confirmRequest } = useFormChangedDialogContext();

  const onClick = () => {
    confirmRequest!((confirmed) => {
      confirmed && action && action();
    });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={debounce(onClick, DEBOUNCE_TIMEOUT)}
      {...props}
    >
      {text}
    </Button>
  );
};

import client from './client';
import server from './server';

const build = (path: string, ...params: any[]): string => {
  params.reverse();
  return path.replace(/(:\w+)/g, () => params.pop());
};

const paths = {
  client,
  server,
  build,
};

export default paths;

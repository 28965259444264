import { useLazyQuery } from '@apollo/client';
import { GET_BOND } from 'graphql/proposals/bonds';
import { bond, bond_bond } from 'graphql/proposals/types/bond';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BondProvider } from 'template/Bond/BondContext';
import { IBondData } from 'template/Bond/components/ProposalBond/interface';
import { validateBond } from 'template/Bond/components/ProposalBond/validator';

interface IUrlParams {
  id: string;
}

export interface IBondLoaderProps {
  children: any;
}

export const BondLoader: FC<IBondLoaderProps> = ({ children }) => {
  const { id: bondId } = useParams<IUrlParams>();

  const [loadedBond, setLoadedBond] = useState<bond_bond>();
  const [bond, setBond] = useState<IBondData>();

  const [loadFilesOnly, setLoadFilesOnly] = useState(false);
  const loadFilesOnlyRef = useRef(false);
  useEffect(() => {
    loadFilesOnlyRef.current = loadFilesOnly;
  }, [loadFilesOnly]);

  const [getBond, { called, data, loading, error, refetch }] = useLazyQuery<bond>(GET_BOND, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadBond = useCallback(async () => {
    const variables = { id: bondId };

    if (called) {
      await refetch!(variables);
    } else {
      await getBond({ variables });
    }
  }, [refetch, called, getBond, bondId]);

  useEffect(() => {
    loadBond();
  }, [loadBond]);

  useEffect(() => {
    if (data && !loading && !error) {
      const foundBond = data.bond;

      if (foundBond) {
        if (!loadFilesOnlyRef.current) {
          const bondData = {
            ...foundBond,
            proposalId: foundBond.proposal?.id,
            selectedProposal: foundBond.proposal
              ? {
                  key: foundBond.proposal.id,
                  name: foundBond.proposal.name,
                  ddLabel: foundBond.proposal.name,
                }
              : undefined,
            partyId: foundBond.party?.id,
            selectedParty: foundBond.party
              ? {
                  key: foundBond.party.id,
                  name: foundBond.party.name,
                  ddLabel:
                    (foundBond.party.projectSetupClientCode ||
                      (foundBond.party.partyType?.name === 'CLIENT' ? 'Client' : 'Non-Client')) +
                    ' - ' +
                    foundBond.party.name,
                }
              : undefined,
          };
          setBond({ ...bondData, ...validateBond(bondData) });
          setLoadedBond(foundBond);
        } else {
          setBond((bond) => {
            const b = {
              ...bond,
              files: foundBond?.files,
              events: foundBond?.events,
            };
            return { ...b, ...validateBond(b) };
          });
          setLoadFilesOnly(false);
        }
      }
    }
  }, [data, loading, error, bondId]);

  const refetchFilesList = useCallback(async () => {
    setLoadFilesOnly(true);
    loadBond();
  }, [setLoadFilesOnly, loadBond]);

  return (
    <BondProvider
      loadedBond={loadedBond}
      bond={bond}
      setBond={setBond}
      loadBond={loadBond}
      refetchFilesList={refetchFilesList}
    >
      {children}
    </BondProvider>
  );
};

import { GET_COUNTRIES, GET_COUNTRY_COUNT } from 'graphql/proposals/countries';
import { useCallback } from 'react';
import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { countries } from 'graphql/proposals/types/countries';
import { countryCount } from 'graphql/proposals/types/countryCount';
import { CountryFilter } from 'graphql/proposals/types/graphql-types';

const getCount = (dataCountriesCount: any) => dataCountriesCount.countryCount;

const getItems = (dataCountries: any) => {
  if (!dataCountries.countries) return undefined;
  return dataCountries.countries.map((country: any) => {
    return {
      id: country.id,
      name: `${country.name} (${country.code})`,
      code: country.code,
    };
  });
};

const getItemId = (item: any) => item.id;

export interface ICountriesAutoCompleteDataProps {
  search: string;
}

export interface ICountry {
  id: string;
  name: string;
  code: string;
}

export const useCountriesAutoCompleteDataHook = ({ search }: ICountriesAutoCompleteDataProps) => {
  const filterToAPI = useCallback((search: string) => ({ codeOrNameContains: search }), []);

  const resp = useAutoCompleteDataSource<countries, countryCount, ICountry, CountryFilter>({
    GET_QUERY: GET_COUNTRIES,
    GET_COUNT_QUERY: GET_COUNTRY_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
    filterToAPI,
  });

  return {
    ...resp,
  };
};

import { FC, memo } from 'react';
import { Chart as ChartJS } from 'react-chartjs-2';
import { useChartJS } from './hooks/chartJS';

export type ChartType =
  | 'bar'
  | 'bubble'
  | 'doughnut'
  | 'line'
  | 'pie'
  | 'polarArea'
  | 'radar'
  | 'scatter';
export interface IChartProps {
  data: any;
  chartRef: any;
  options: any;
  type: ChartType;
  [id: string]: any;
}

export const Chart: FC<IChartProps> = memo(({ chartRef, data, options, type, ...props }) => {
  useChartJS();
  return <ChartJS type={type} ref={chartRef} options={options} data={data} {...props} />;
});

import { Application } from 'graphql/proposals/types/graphql-types';

export const TYPING_TIMEOUT =
  process.env.REACT_APP_TYPING_TIMEOUT === undefined
    ? 400
    : parseInt(process.env.REACT_APP_TYPING_TIMEOUT);
export const API_BASE = process.env?.REACT_APP_API_BASE || '';
export const AZURE_CLIENT_ID = process.env?.REACT_APP_AZURE_CLIENT_ID || '';
export const AZURE_AUTHORITY = process.env?.REACT_APP_AZURE_AUTHORITY || '';
export const POST_LOGOUT_REDIRECT_URI = process.env?.REACT_APP_POST_LOGOUT_REDIRECT_URI || '';
export const SNACKBAR_AUTOHIDE = parseInt(process.env.REACT_APP_SNACKBAR_AUTOHIDE || '2') * 1000;
export const SNACKBAR_ERROR_AUTOHIDE =
  parseInt(process.env.REACT_APP_SNACKBAR_ERROR_AUTOHIDE || '5') * 1000;
export const DEFAULT_ROWS_PER_PAGE = parseInt(process.env.REACT_APP_DEFAULT_ROWS_PER_PAGE || '20');
export const APPLICATION = process.env.REACT_APP_APPLICATION || 'REPORTS';
export const SCOPE: Application =
  (process.env.REACT_APP_SCOPE as Application) || Application.PROPOSALS;
export const TABLE_PAGINATION = (process.env.REACT_APP_TABLE_PAGINATION || '20,50,100')
  .split(',')
  .map((x) => parseInt(x));
export const NAME_LENGTH_LIMIT = 40; // set undefined for unlimited length
export const TIMESTAMP_CHECK_PERIOD =
  process.env.REACT_APP_TIMESTAMP_CHECK_PERIOD === undefined
    ? 10000
    : parseInt(process.env.REACT_APP_TIMESTAMP_CHECK_PERIOD) * 1000;
export const DEBOUNCE_TIMEOUT =
  process.env.REACT_APP_DEBOUNCE_TIMEOUT === undefined
    ? 300
    : parseInt(process.env.REACT_APP_DEBOUNCE_TIMEOUT);
export const MAINTENANCE_PERIOD_URL =
  process.env.REACT_APP_MAINTENANCE_PERIOD_URL === ''
    ? undefined
    : process.env.REACT_APP_MAINTENANCE_PERIOD_URL || '/maintenance.json';
export const MAX_OWNERS =
  process.env.REACT_APP_MAX_OWNERS === undefined ? 8 : parseInt(process.env.REACT_APP_MAX_OWNERS);
export const STALE_MONTHS =
  process.env.REACT_APP_STALE_MONTHS === undefined
    ? 6
    : parseInt(process.env.REACT_APP_STALE_MONTHS);

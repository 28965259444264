import { gql } from '@apollo/client';
import { IProject, IProjectListItem } from './projectsFragments';

export const GET_PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...IProject
    }
  }
  ${IProject}
`;

export const GET_PARTY_PROJECTS = gql`
  query projects(
    $sort: [ProjectSort!] = []
    $filter: ProjectFilter
    $take: Int = 100
    $skip: Int = 0
  ) {
    projects(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IProjectListItem
    }
  }
  ${IProjectListItem}
`;

export const GET_PROJECT_COUNT = gql`
  query projectCount($filter: ProjectFilter) {
    projectCount(filter: $filter)
  }
`;

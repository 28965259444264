import { useLazyQuery } from '@apollo/client';
import { GET_PARTY } from 'graphql/proposals/parties';
import { party, party_party } from 'graphql/proposals/types/party';
import { useCallback, useEffect, useState } from 'react';

export const useParty = ({ id }: { id: string | undefined }) => {
  const [party, setParty] = useState<party_party | null>();

  const [getParty, { data, loading, refetch, called }] = useLazyQuery<party>(GET_PARTY, {
    variables: {
      id,
    },
  });

  const load = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        getParty({ variables });
      }
    },
    [getParty, refetch, called]
  );

  const reload = useCallback(() => {
    const variables = {
      id,
    };

    if (called) {
      refetch!(variables);
    } else {
      getParty({ variables });
    }
  }, [getParty, refetch, called, id]);

  useEffect(() => {
    if (!loading && data) {
      setParty(data?.party);
    }
  }, [data, loading]);

  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  return {
    party,
    loading,
    reload,
  };
};

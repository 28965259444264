import XLSX from 'sheetjs-style';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const defaultHeadStyle = {
  font: { sz: '10', bold: true },
};

const defaultRowStyle = {
  font: { sz: '10' },
};

const getSheet = (data: IExportToXLSXCreateSheetProps) => {
  const { csvData, headings, headerStyles, rowStyles } = data;
  const headerKeys = Object.keys(headings);
  const ws = XLSX.utils.json_to_sheet([headings], {
    header: headerKeys,
    skipHeader: true,
  });

  const wscols: Array<any> = headerKeys.map((key) => {
    const maxColumnWidth = Math.max(
      headings[key].length,
      ...csvData.map((cell: any) => {
        if (!cell[key]) {
          return 0;
        }
        if (typeof cell[key] === 'string') {
          return Math.max(
            ...(cell[key] as string).split(String.fromCharCode(10)).map((item) => item?.length)
          );
        }
        return cell[key].length || 0;
      })
    );
    return { wch: maxColumnWidth };
  });

  ws['!cols'] = wscols;

  XLSX.utils.sheet_add_json(ws, csvData, {
    header: headerKeys,
    skipHeader: true,
    origin: 'A2', //ok
    cellStyles: true,
  });

  for (var i = 0; i < headerKeys.length; i++) {
    var cellref: any = XLSX.utils.encode_cell({ c: i, r: 0 });
    if (cellref) {
      ws[cellref].s = defaultHeadStyle;
    }
  }

  for (i = 0; i < headerKeys.length; i++) {
    cellref = XLSX.utils.encode_cell({ c: i, r: 0 });
    if (cellref) {
      if (headerStyles && headerStyles[i]) {
        ws[cellref].s = { ...defaultHeadStyle, ...headerStyles[i] };
      } else {
        ws[cellref].s = { ...defaultHeadStyle };
      }
    }
  }

  for (var j = 0; j < csvData.length; j++) {
    for (i = 0; i < headerKeys.length; i++) {
      cellref = XLSX.utils.encode_cell({ c: i, r: j + 1 });
      if (cellref) {
        try {
          if (rowStyles && rowStyles[i]) {
            ws[cellref].s = { ...defaultRowStyle, ...rowStyles[i] };
          } else {
            ws[cellref].s = { ...defaultRowStyle };
          }
        } catch (error) {
          console.log('Unable to set style on', i, j, error);
        }
      }
    }
  }
  return ws;
};

export interface IExportToXLSXCreateSheetProps {
  csvData: Array<any>;
  headings: any;

  headerStyles?: Array<any>;
  rowStyles?: Array<any>;
}

export interface IExportToXLSXSheetProps extends IExportToXLSXCreateSheetProps {
  sheetName: string;
}
export interface IExportToXLSXProps {
  sheets: IExportToXLSXSheetProps[];
}

export const exportToXLSX = (props: IExportToXLSXProps) => {
  const sheetNames = props.sheets.map((sheetProps) => sheetProps.sheetName);

  const sheets = props.sheets.reduce((acc: any, { sheetName, ...createSheetProps }) => {
    acc[sheetName] = getSheet(createSheetProps);
    return acc;
  }, {});

  const wb = { Sheets: sheets, SheetNames: sheetNames };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const data = new Blob([excelBuffer], { type: fileType });
  return data;
};

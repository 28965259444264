import { Box, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import './index.scss';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { useHistory } from 'react-router-dom';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useNewBondContext } from './NewBondContext';

import { ProposalBond } from 'template/Bond/components/ProposalBond/ProposalBond';
import { useUI } from 'contexts/UiContext';

import paths from 'constants/paths';
import { tryUpdateProcedure } from 'utils/apollo';
import { BondType } from 'graphql/proposals/types/graphql-types';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';
import { BOND_CREATE_MUTATION } from 'graphql/proposals/bonds';
import { useMutation } from '@apollo/client';
import { useCurrencies } from 'hooks/currencyHook';
import { FormValidationReport } from 'components/FormValidationReport/FormValidationReport';

export const NewBondTemplate: FC = () => {
  const { addSnackbar } = useUI();
  const { resetChanged } = useFormChangedDialogContext();

  const [createMutation] = useMutation(BOND_CREATE_MUTATION);

  const { bond, setBond, onSubmitProcess } = useNewBondContext();
  const { openValidationResult } = useFormValidationReportContext();

  const [showValidator, setShowValidator] = useState(false);

  const history = useHistory();

  const [bondDialogOpen, setBondDialogOpen] = useState(false);

  const { currencyPairs } = useCurrencies();

  const defaultCurrency = useMemo(() => {
    let foundUSD = currencyPairs.find((pair) => pair.id === 'USD' || pair.id === '$');
    if (foundUSD) {
      return foundUSD;
    }
  }, [currencyPairs]);

  useEffect(() => {
    if (defaultCurrency) {
      setTimeout(
        () =>
          setBond((bond: any) => {
            if (bond && !bond.currency) {
              return { ...bond, currency: defaultCurrency.id };
            }
            return bond;
          }),
        100
      );
    }
  }, [defaultCurrency, setBond]);

  const showResult = useCallback(
    ({ result, isError, errors }: { result: any; isError: boolean; errors: any }) => {
      const bondAssignment: 'proposal' | 'client' = bond?.proposalId ? 'proposal' : 'client';

      resetChanged && resetChanged();

      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        switch (bondAssignment) {
          case 'proposal':
            addSnackbar!({
              text: 'Proposal Bond is created',
              severity: 'success',
            });
            history.push(paths.client.BOND_DETAILS.replace(':id', result?.bondCreate?.id));
            break;
          case 'client':
            addSnackbar!({
              text: 'Client Bond is created',
              severity: 'success',
            });
            history.push(paths.client.BOND_DETAILS.replace(':id', result?.bondCreate?.id));
            break;
          default:
            addSnackbar!({
              text: 'Success',
              severity: 'success',
            });
            history.push(paths.client.BONDS);
        }
      }
    },
    [addSnackbar, history, bond, resetChanged]
  );

  const onValidateAndSubmit = useCallback(async () => {
    if (!bond) {
      return;
    }

    const { isValid } = bond;
    if (!isValid) {
      setShowValidator(true);
      openValidationResult && openValidationResult();
      return;
    }

    const {
      proposalId,
      partyId,
      amount,
      beneficiaryEmail,
      beneficiaryFaxNumber,
      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      contractName,
      contractNumber,
      currency,
      deliveryInstructions,
      description,
      issuingBankRequirements,
      recipientReceiveDeadline,
      tenderName,
      tenderNumber,
      type,
      validityThroughDate,
      wordingOrFormatRequirements,
      stage,
    } = bond;

    const prepareData = {
      proposalId,
      partyId: proposalId ? undefined : partyId,
      type,
      stage,
      description,

      tenderName,
      tenderNumber,
      contractName: type === BondType.BID_BOND ? '' : contractName,
      contractNumber: type === BondType.BID_BOND ? '' : contractNumber,

      amount,
      currency,

      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      beneficiaryFaxNumber,
      beneficiaryEmail,

      recipientReceiveDeadline,
      validityThroughDate,

      deliveryInstructions,
      issuingBankRequirements,
      wordingOrFormatRequirements,
    };

    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        createMutation({
          variables: {
            ...prepareData,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });

    return { result, isError, errors, bondId: result?.bondCreate?.id ?? '' };
  }, [bond, openValidationResult, createMutation]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <ConfirmationDialog
        title="Add New"
        message={'New Bond will be created'}
        open={bondDialogOpen}
        onClose={(confirm: boolean) => {
          if (confirm) {
            onSubmitProcess!();
          }
          setBondDialogOpen(() => false);
        }}
      />

      <Paper
        elevation={3}
        style={{
          padding: '1.5rem 28px 1.5rem 28px',
          marginTop: '65px',
          width: 'fit-content',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
        id="main-paper"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className="paper-title">
                New Bond
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={5} />
              {bond ? (
                <>
                  <FormValidationReport
                    errors={{
                      ...bond.errors,
                    }}
                  />
                  <ProposalBond
                    bond={bond}
                    setBond={setBond}
                    showValidator={showValidator}
                    onValidateAndSubmit={onValidateAndSubmit}
                    showResult={showResult}
                  ></ProposalBond>
                </>
              ) : undefined}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};

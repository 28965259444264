import { Table } from 'components/ui/Table/Table';
import { FC, useCallback, useEffect, useMemo } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { Box, Grid } from '@mui/material';

import { useProposalsTableHook } from 'template/Opportunities/components/ProposalsListView/proposalsTableHook';
import { relatedProposalsTableHead } from 'constants/relatedProposalsTable';
import { useProposalStage } from 'template/Opportunities/components/ProposalsListView/proposalStageHook';
import { useHistory } from 'react-router';
import paths from 'constants/paths';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useUI } from 'contexts/UiContext';
import { TABLE_PAGINATION } from 'constants/config';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';

export interface IClientProposalsProps {
  clientId?: string;
  excludeProposalId?: string;
  editingProposalId?: string;
  setLoading?: ({ loading, data, error }: { loading: boolean; data: any; error: boolean }) => void;
  printView?: boolean;
}

const defaultFilterValues = {
  status: { id: 'All', name: 'All' },
};

export const ClientProposals: FC<IClientProposalsProps> = ({
  clientId,
  excludeProposalId,
  editingProposalId,
  setLoading,
  printView,
}) => {
  const history = useHistory();
  const { addSnackbar } = useUI();

  const { confirmRequest } = useFormChangedDialogContext();

  const overridePageLoadParams = useMemo(() => {
    return clientId || excludeProposalId
      ? {
          filter: {
            partyIds: clientId ? [clientId] : undefined,
            excludeIds: excludeProposalId ? [excludeProposalId] : undefined,
          },
        }
      : undefined;
  }, [clientId, excludeProposalId]);

  const {
    totalItems,
    proposals,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    // filterApplied,
    error,
  } = useProposalsTableHook({
    tableStorageKey: 'ClientProposalsTable',
    initRowsPerPage: 5,
    overridePageLoadParams,
  });

  const { pairs: proposalStatusPairs } = useProposalStage();

  const filterOptions = useMemo(() => {
    return {
      status: [{ id: 'All', name: 'All' }, ...proposalStatusPairs],
    };
  }, [proposalStatusPairs]);

  const onProposalSelect = useCallback(
    (id: string) => {
      if (id === editingProposalId) {
        addSnackbar!({
          text: 'Proposal is already open',
          severity: 'info',
        });
      } else {
        const proposal = proposals.find((proposal: any) => proposal.id === id);
        const proposalType = proposal?.entityType;
        confirmRequest!((confirmed) => {
          if (confirmed) {
            if (proposalType === 'OPPORTUNITY') {
              // addSnackbar!({
              //   text: 'Loading opportunity...',
              //   severity: 'info',
              // });
              history.push(paths.client.OPPORTUNITY_DETAILS.replace(':id', id));
            } else {
              // addSnackbar!({
              //   text: 'Loading proposal...',
              //   severity: 'info',
              // });
              history.push(paths.client.PROPOSAL_DETAILS.replace(':id', id));
            }
          }
        });
      }
      return true;
    },
    [history, confirmRequest, addSnackbar, editingProposalId, proposals]
  );

  useEffect(() => {
    setLoading && setLoading({ loading, data: proposals, error: !!error });
  }, [loading, proposals, error, setLoading]);

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      {loading || totalItems > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              style={{
                borderRadius: '1px',
                boxShadow: '0px 2px 5px #00000030',
                border: '1px solid #00000030',
              }}
            >
              <Table
                totalItems={totalItems}
                dataCells={proposals}
                headCells={relatedProposalsTableHead}
                loadPage={loadPage}
                handleSelect={onProposalSelect}
                filterOptions={filterOptions}
                filterValues={pageLoadParams.filterValues || defaultFilterValues}
                onFilterChange={onFilterChange}
                initRowsPerPage={pageLoadParams.rowsPerPage}
                hideFilters
                paginationProps={{
                  rowsPerPageOptions: TABLE_PAGINATION.includes(5)
                    ? TABLE_PAGINATION
                    : [5, ...TABLE_PAGINATION],
                }}
                initOrder={pageLoadParams.order || SortOrder.ASC}
                initOrderBy={pageLoadParams.orderBy}
                initPage={pageLoadParams.page}
              ></Table>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div style={{ paddingLeft: '20px' }}>Client projects list is empty</div>
      )}
    </LoadingOverlay>
  );
};

import { FC, useCallback, useEffect, useRef } from 'react';

import { Button } from '@mui/material';
import { IGanttChartProps } from './interface';

import Gantt from 'frappe-gantt';

import './index.scss';

const id = '#GanttChart';

export const GanttChart: FC<IGanttChartProps> = ({ tasks, options }) => {
  const ganttref = useRef<any>(null);
  const gantObj = useRef<any>(null);

  const stopEvent = useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  useEffect(() => {
    if (ganttref.current && tasks?.length) {
      ganttref.current.innerHTML = null;
      //@ts-ignore
      const strGantt = new Gantt(ganttref.current, tasks, {
        view_mode: Gantt.VIEW_MODE.MONTH,
        on_view_change: function () {
          setTimeout(() => {
            // document
            //   .querySelectorAll(id + ' .bar-group')
            //   .forEach((e) => e.addEventListener('mousedown', stopEvent, true));
            document.querySelectorAll(id + ' .handle-group').forEach((e) => e.remove());
          }, 100);
        },
        custom_popup_html: function (task: any) {
          // the task object will contain the updated
          // dates and progress value
          return `<div class="gantt-details-container">${task.ev.htmlContentDivs
            .map((div: HTMLDivElement) => '<div>' + div.innerHTML + '</div>')
            .join('<br /><hr /><br />')}</div>`;
        },
        ...options,
      });

      //   strGantt.render();
      gantObj.current = strGantt;
    }
  }, [ganttref, tasks, options, stopEvent]);

  return (
    <div id="GanttChart" className="ganttChart">
      <div
        ref={ganttref}
        style={{
          //  overflow: 'scroll', height: '400px',
          overflowX: 'hidden',
          height: '100%',
        }}
      ></div>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '10px' }}>
          <Button
            onClick={() => {
              gantObj.current.change_view_mode('Day');
            }}
            color="primary"
            variant="outlined"
            size="small"
          >
            Day
          </Button>
        </div>
        <div style={{ padding: '10px' }}>
          <Button
            onClick={() => {
              gantObj.current.change_view_mode('Week');
            }}
            color="primary"
            variant="outlined"
            size="small"
          >
            Week
          </Button>
        </div>
        <div style={{ padding: '10px' }}>
          <Button
            onClick={() => {
              gantObj.current.change_view_mode('Month');
            }}
            color="primary"
            variant="outlined"
            size="small"
          >
            Month
          </Button>
        </div>
      </div>
    </div>
  );
};

export const sortByName = (a: any, b: any) => {
  const nameA = a.name?.toLowerCase();
  const nameB = b.name?.toLowerCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export function compare(a: any, b: any) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

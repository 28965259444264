import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_PARTY } from 'graphql/proposals/parties';
import { party, party_party } from 'graphql/proposals/types/party';
import { DialogForm } from 'components/DialogForm/DialogForm';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ExampleMap } from './ExampleMap';

export interface IShowParty {
  id?: string | null;
  name?: string | null;
  alias?: string | null;
  streetAddress?: string | null;
  streetAddress2?: string | null;
  addressCity?: string | null;
  addressState?: string | null;
  addressZip?: string | null;
  isActive?: boolean | null;
  country?: { code: string; name: string } | null;
  geos?:
    | {
        geoX: number | null;
        geoY: number | null;
      }[]
    | null;
}
export interface IGeoMapDialog {
  id: string;
  onClose: () => void;
  party?: IShowParty;
}

export const GeoMapDialog: FC<IGeoMapDialog> = ({ id, onClose, party }) => {
  const [loadedParty, setLoadedParty] = useState<party_party | null>();

  const showParty: IShowParty | undefined | null = useMemo(
    () => party || loadedParty,
    [party, loadedParty]
  );

  const [loadParty, { data, loading, called, refetch, error }] = useLazyQuery<party>(GET_PARTY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const load = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        loadParty({ variables });
      }
    },
    [loadParty, refetch, called]
  );

  useEffect(() => {
    if (data && !loading && !error) {
      setLoadedParty(data.party);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (id && !party) {
      load(id);
    }
  }, [id, load, party]);

  return (
    <DialogForm
      dialogTitle={
        'CLIENT GEO MAP (ID:' +
        (loadedParty?.projectSetupClientCode || loadedParty?.id || '...') +
        ')'
      }
      onClose={onClose}
      open={true}
      maxWidth="lg"
    >
      <LoadingOverlay spinner active={loading} text="Loading your content...">
        <ExampleMap
          display_name={
            <>
              <div>
                <strong>{showParty?.name}</strong>
              </div>
              <br />
              <div>{showParty?.streetAddress}</div>
              <div>{showParty?.streetAddress2}</div>
              <div>
                {showParty?.addressZip} {loadedParty?.addressCity}
              </div>
              <div>{showParty?.addressState}</div>
              <div>
                {showParty?.country?.name} ({loadedParty?.country?.code})
              </div>
            </>
          }
          coords={
            showParty?.geos?.length
              ? { latitude: showParty?.geos[0].geoY!, longitude: showParty?.geos[0].geoX! }
              : { latitude: 1, longitude: 1 }
          }
        ></ExampleMap>
      </LoadingOverlay>
    </DialogForm>
  );
};

import React, { FC, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Box, Grid, LinearProgress } from '@mui/material';
import { uniqueId } from 'lodash';

import './index.scss';

import { Close as CloseIcon } from '@mui/icons-material';
import { FileDotIcon } from 'template/Proposal/components/FilesList.tsx/components/FileDotIcon/FileDotIcon';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const DropzoneComponent: FC<any> = ({
  onDrop,
  removeFile,
  isUploading,
  percentage,
  multiple = false,
  files,
  hint = '',
  currentReportFileVersion = '',
  smallLayout = false,
  formId = 'uploadForm',
  // accept = ['.pdf', '.docx', ''],
  // btnSize = '',
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    // #Zoran, removed accept property to accept any file
    // accept,
  });
  const empty = useMemo(
    () => !currentReportFileVersion && files.length === 0,
    [currentReportFileVersion, files]
  );

  const fileItem = (file: any): JSX.Element => (
    <div key={uniqueId()}>
      <Box mt={2}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="row"
          item
          sm={smallLayout ? 12 : 7}
          xs={12}
        >
          <Grid item xs={4}>
            <FileDotIcon></FileDotIcon>
          </Grid>
          <Grid item xs={7}>
            <div style={{ overflow: 'hidden' }}>{file.name}</div>
          </Grid>
          <Grid item xs={1}>
            <div
              onClick={debounce(removeFile(file.name), DEBOUNCE_TIMEOUT)}
              style={{ cursor: 'pointer' }}
            >
              <CloseIcon />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  const selectedFileView = (): JSX.Element => {
    if (empty) return <></>;

    if (files.length === 0) return <>{fileItem(currentReportFileVersion)}</>;
    return (
      <Box marginTop={1} className="upload-file-item">
        {files.map((file: any) => fileItem(file))}
        <Grid>{isUploading && <LinearProgress variant="determinate" value={percentage} />}</Grid>
      </Box>
    );
  };

  const shouldDisplayFileChooser = (): boolean => {
    if (!smallLayout) return true;

    if (empty) return true;

    return files.length === 0;
  };

  return (
    <>
      <Grid container alignContent="center" alignItems="center" spacing={2}>
        {shouldDisplayFileChooser() && (
          <Grid item xs={12}>
            <form id={formId} name={formId} {...getRootProps()}>
              <input className="upload-input" {...getInputProps()} />
              <div className="upload-container">
                <Box>
                  <Button
                    className="btn-responsive"
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{
                      width: isDragActive ? '100%' : '',
                      padding: isDragActive ? '4rem' : '',
                    }}
                  >
                    {isDragActive
                      ? `Drag 'n' drop some files here, or click to select files`
                      : 'Upload files'}
                  </Button>
                </Box>{' '}
                {hint && <span className="upload-container-message ">{hint}</span>}
              </div>
            </form>
          </Grid>
        )}

        <Grid item xs={12}>
          {selectedFileView()}
        </Grid>
      </Grid>
    </>
  );
};

export default DropzoneComponent;

import { Grid } from '@mui/material';

import { FC } from 'react';
// import LoadingOverlay from 'react-loading-overlay-ts';
import s from './style.module.scss';

export interface IClientContactTagTooltipProps {
  contact: any;
  partyId?: string;
}

export const ClientContactTagTooltip: FC<IClientContactTagTooltipProps> = ({
  contact,
  partyId,
}) => {
  const contactData = contact;
  return (
    <Grid container spacing={1} style={{ padding: '0 14px' }}>
      <Grid item xs={6} className={s.title}>
        Title:
      </Grid>
      <Grid item xs={6} className={s.data}>
        {contactData?.name || '--'}
      </Grid>
      <Grid item xs={6} className={s.title}>
        Email:
      </Grid>
      <Grid item xs={6} className={s.data}>
        {contactData?.email || '--'}
      </Grid>
      <Grid item xs={6} className={s.title}>
        Phone number:
      </Grid>
      <Grid item xs={6} className={s.data}>
        {contactData?.phone || '--'}
      </Grid>
      <Grid item xs={6} className={s.title}>
        Linked In:
      </Grid>
      <Grid item xs={6} className={s.data}>
        {contactData?.linkedInUrl || '--'}
      </Grid>
      <Grid item xs={12} className={s.title}>
        Notes:
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        {contactData?.notes || '--'}
      </Grid>
    </Grid>
  );
};

import { useMemo } from 'react';
import { IProposalData } from 'template/Opportunity/ProposalContext';
import { FieldType } from '../components/ColumnsTemplate/TwoColumnTemplate';
import { IFieldsDescription } from '../interface';

const transformFloat = (value: string) => {
  if (['', '0'].includes(value)) return null;
  if (!value) return undefined;
  return parseFloat(value);
};

const transformInt = (value: string) => {
  if (['', '0'].includes(value)) return null;
  if (!value) return undefined;
  return parseInt(value);
};

export const useAdditionalDetailsFields = (
  proposalData: IProposalData
): { fields: Array<Array<IFieldsDescription>> } => {
  const otherFields = useMemo(() => {
    const { selectedProjectCountries, bidPriceLowered, bidProposedReason } = proposalData;

    return [
      [
        {
          id: 'selectedProjectCountries',
          title: 'Project Countries',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: {
              multiple: true,
              placeholder: 'Select project countries',
            },
          },
        },
      ],
      [
        {
          id: 'selectedBillingOrigin',
          title: 'Billing Origin',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select a billing origin', multiple: true },
          },
        },
      ],
      [
        {
          id: 'selectedProjectFields',
          title: 'Project Fields',
          field: {
            type: (selectedProjectCountries?.length ? 'easyAutoComplete' : 'label') as FieldType,
            props: {
              multiple: true,
              placeholder: 'Select project fields',
              note: selectedProjectCountries?.length ? undefined : 'select Project Countries first',
            },
          },
        },
      ],
      [
        {
          id: 'selectedBillingBasis',
          title: 'Billing Basis',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select a billing base', multiple: true },
          },
        },
        {
          id: 'selectedBudgetBasis',
          title: 'Budget Basis',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select a budget basis' },
          },
        },
        {
          id: 'selectedPotentialRevenueByArea',
          title: 'Potential Revenue by Area',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { multiple: true, placeholder: 'Select potential revenue areas' },
          },
        },
      ],
      [
        {
          id: 'numberOfMatureFields',
          title: 'Number of Mature Fields',
          field: {
            type: 'text' as FieldType,
            props: { placeholder: 'Input number of mature fields' },
          },
          parse: transformInt,
        },
      ],
      [
        {
          id: 'feesValue',
          title: 'Opportunity Fees (net to D&M)',
          field: { type: '$' as FieldType, props: { placeholder: 'Input fees...' } },
          parse: transformFloat,
        },
      ],
      [
        {
          id: 'numberOfGreenFields',
          title: 'Number of Green Fields',
          field: {
            type: 'text' as FieldType,
            props: { placeholder: 'Input number of green fields' },
          },
          parse: transformInt,
        },
      ],
      [
        {
          id: 'bestTechnicalEstimateValue',
          title: 'Best Technical Estimate',
          field: {
            type: '$' as FieldType,
            props: { placeholder: 'Input best technical estimate...' },
          },
          parse: transformFloat,
        },
      ],

      [
        {
          id: 'selectedWorkType',
          title: 'Work Type',
          showColapsed: true,
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { multiple: true, placeholder: 'Select work types' },
          },
        },
      ],
      [
        {
          id: 'expensesValue',
          title: 'Opportunity Expenses (net to D&M)',
          field: { type: '$' as FieldType, props: { placeholder: 'Input expenses...' } },
          parse: transformFloat,
        },
      ],
      [
        {
          id: 'workTypeDetails',
          title: 'Work Type Other Detail',
          showColapsed: true,
          field: {
            type: 'textareaAutosize' as FieldType,
            props: { minRows: 1, placeholder: 'Input work type details...' },
          },
        },
      ],
      [
        {
          id: 'bidProposedValue',
          title: 'Bid Price Proposed',
          field: { type: '$' as FieldType, props: { placeholder: 'Input the proposed bid price' } },
          parse: transformFloat,
        },
        {
          id: 'bidProposedReason',
          title: 'Reason for Lowering Bid Price',
          field: {
            type: 'textareaAutosize' as FieldType,
            props: { minRows: 3, placeholder: 'Input the bid price change reason' },
          },
          hidden: !bidPriceLowered && !bidProposedReason,
          isDisabled: !bidPriceLowered && !bidProposedReason,
          group: !!bidPriceLowered || !!bidProposedReason ? 1 : undefined,
        },
      ],
      [
        {
          id: 'selectedEvaluationType',
          title: 'Evaluation Type',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select an evaluation type' },
          },
        },
      ],
      [
        {
          id: 'bidFinalValue',
          title: 'Bid Price Final',
          field: { type: '$' as FieldType, props: { placeholder: 'Input the final bid price' } },
          parse: transformFloat,
        },
      ],
      [
        {
          id: 'selectedGuidelineCode',
          title: 'Guideline Code',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select a guideline code' },
          },
        },
      ],
      [
        {
          id: 'averageHourlyRate',
          title: 'Average Hourly Rate Quoted',
          field: {
            type: '$' as FieldType,
            props: { placeholder: 'Input the quoted average hourly rate' },
          },
          parse: transformFloat,
        },
      ],

      [
        {
          id: 'selectedDeliverables',
          title: 'Deliverables',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { multiple: true, placeholder: 'Select deliverables' },
          },
        },
      ],
      [
        {
          id: 'estimatedTotalHours',
          title: 'Total Hours Estimated',
          field: {
            type: 'text' as FieldType,
            props: { placeholder: 'Input the estimated total hours...' },
          },
          parse: transformFloat,
        },
      ],

      [
        {
          id: 'selectedReportType',
          title: 'Types of Reports',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { multiple: true, placeholder: 'Select report types' },
          },
        },
      ],
      [
        {
          id: 'selectedPotentialTeamMembers',
          title: 'Potential Team Members',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { multiple: true, placeholder: 'Select potential team members' },
          },
        },
      ],
      [
        {
          id: 'selectedFiguresRequired',
          title: 'Figures Required',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select yes or no' },
          },
        },
      ],
      [
        {
          id: 'notes',
          title: 'Notes',
          showColapsed: true,
          field: {
            type: 'textareaAutosize' as FieldType,
            props: { placeholder: 'Input notes...' },
          },
        },
      ],
      [
        {
          id: 'selectedTravelRequired',
          title: 'Travel Required',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select yes or no' },
          },
        },
      ],
      [
        {
          id: 'selectedPriceType',
          title: 'Price Type',
          field: {
            type: 'easyAutoComplete' as FieldType,
            props: { placeholder: 'Select the price type' },
          },
        },
      ],
    ];
  }, [proposalData]);

  return {
    fields: otherFields,
  };
};

import { DialogForm } from 'components/DialogForm/DialogForm';
import { FC } from 'react';
import {
  ChartingPreselection,
  IChartingPreselectionProps,
} from 'template/ChartingPreselection/ChartingPreselection';

export interface IChartingPreconfigDialogProps extends IChartingPreselectionProps {
  open: boolean;
  onClose: (config?: any) => void;
  [id: string]: any;
}

export const ChartingPreconfigDialog: FC<IChartingPreconfigDialogProps> = ({
  onClose,
  open,
  ...props
}) => {
  return (
    <DialogForm
      dialogTitle={'Select Proposals Fields'}
      onClose={() => onClose()}
      open={open}
      maxWidth="lg"
    >
      <ChartingPreselection {...props} />
    </DialogForm>
  );
};

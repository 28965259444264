import { keyBy, mapValues, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAdditionalDetailsFields } from 'template/Proposal/components/ProposalForm/hooks/additionalDetailsFields';
import { useBasicDetailsFields } from 'template/Proposal/components/ProposalForm/hooks/basicDetailsFields';

import { proposalTypeOptions } from 'template/Proposal/components/ProposalForm/constants/proposalTypeOptions';
import {
  chancesOfSuccessOptions,
  priceTypeOptions,
} from 'template/Proposal/components/ProposalForm/constants/chancesOfSuccessOptions';
import { useDivisions } from 'hooks/divisionsHook';
import { useUsersList } from '../usersListHook';
import { useGeneralDataPairs } from 'hooks/generalDataPairsHook';

import { WorkflowStates } from 'template/Proposal/components/ProposalForm/constants/workflow';
import { useOtherFields } from 'template/Proposal/components/ProposalForm/hooks/otherFields';
import { customFields } from 'template/Proposal/components/ProposalForm/constants/customFields';
import { proposalQueries } from 'template/Proposal/components/ProposalForm/ProposalForm';

import { IEvent } from 'template/Proposal/ProposalContext';
import { ProposalEventType } from 'constants/enums';
import { useSelectedCountrieDataItems } from 'hooks/selectedCountriesDataItemsHook';
import { useSelectedProjectFieldsDataItems } from 'hooks/selectedProjectFieldsDataItemsHook';
import { formatProposalDate } from 'utils/time';
import { currencyFormatUS } from 'utils/currencyFormat';
import { trueFalseAsYesNoOptions } from 'template/Opportunity/components/ProposalForm/constants/proposalTypeOptions';

export interface ICompetitor {
  bidValue?: number;
  competitorName?: string;
  hasWonBid?: boolean;
}

export interface IUsePayloadConvertProps {
  events: IEvent[];
}
export const usePayloadConvert = ({ events }: IUsePayloadConvertProps) => {
  const { fields: basicDetailsFields } = useBasicDetailsFields();
  const { fields: additionalDetailsFields } = useAdditionalDetailsFields();
  const { fields: otherAndRelatedFields } = useOtherFields();
  const { divisionPairs, loading: loadingDivisions } = useDivisions();
  const { usersMap, loadUsers, loadingUsers } = useUsersList();

  const [selectedCountries, setSelectedCountries] = useState<string[]>();
  const [selectedFields, setSelectedFields] = useState<string[]>();

  const proposalStatusPairs = useMemo(
    () =>
      WorkflowStates.map((item) => ({
        id: item.id as string,
        name: item.name,
      })),
    []
  );

  const { pairs: evaluationTypePairs, loading: loadingEvaluationTypes } = useGeneralDataPairs(
    proposalQueries.evaluationTypes
  );
  const { pairs: budgetBasisPairs, loading: loadingBudgetBasis } = useGeneralDataPairs(
    proposalQueries.budgetBasis
  );
  const { pairs: guidelineCodePairs, loading: loadingGuidelineCodes } = useGeneralDataPairs(
    proposalQueries.guidelineCodes
  );
  const { pairs: workTypePairs, loading: loadingWorkTypes } = useGeneralDataPairs(
    proposalQueries.workTypes
  );
  const { pairs: submissionMethodPairs, loading: loadingSubmissionMethods } = useGeneralDataPairs(
    proposalQueries.proposalSubmissionMethods
  );
  const { pairs: reportTypePairs, loading: loadingReportTypes } = useGeneralDataPairs(
    proposalQueries.proposalReportTypes
  );
  const { pairs: revenueAreaPairs, loading: loadingRevenueAreas } = useGeneralDataPairs(
    proposalQueries.revenueAreas
  );
  const { pairs: deliverablePairs, loading: loadingDeliverables } = useGeneralDataPairs(
    proposalQueries.deliverables
  );
  const { pairs: billingBasePairs, loading: loadingBillingBases } = useGeneralDataPairs(
    proposalQueries.billingBases
  );
  const { pairs: billingOriginPairs, loading: loadingBillingOrigins } = useGeneralDataPairs(
    proposalQueries.billingOrigins
  );

  const { items: countriesItems, loading: loadingCountries } = useSelectedCountrieDataItems({
    iDs: selectedCountries,
  });
  const { items: fieldsItems, loading: loadingFields } = useSelectedProjectFieldsDataItems({
    iDs: selectedFields,
  });

  useEffect(() => {
    if (events) {
      const countries: { [key: string]: boolean } = {};
      const fields: { [key: string]: boolean } = {};

      events.forEach((event) => {
        if (event.eventType === ProposalEventType.PROPOSAL_UPDATED && event.payload) {
          const payload = JSON.parse(event.payload);

          if (payload.countryIdsToAdd) {
            payload.countryIdsToAdd.forEach((id: string) => (countries[id] = true));
          }
          if (payload.countryIdsToRemove) {
            payload.countryIdsToRemove.forEach((id: string) => (countries[id] = true));
          }

          if (payload.fieldIdsToAdd) {
            payload.fieldIdsToAdd.forEach((id: string) => (fields[id] = true));
          }
          if (payload.fieldIdsToRemove) {
            payload.fieldIdsToRemove.forEach((id: string) => (fields[id] = true));
          }
        }
      });
      setSelectedCountries(Object.keys(countries));
      setSelectedFields(Object.keys(fields));
    }
  }, [events]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const divisionPairs2 = useMemo(() => {
    return [...divisionPairs];
  }, [divisionPairs]);

  const mapSimpleOptions = useMemo(
    () => ({
      proposalType: {
        id: 'selectedType',
        options: mapValues(keyBy(proposalTypeOptions, 'id'), 'name'),
      },
      successChance: {
        id: 'selectedSuccessChance',
        options: mapValues(keyBy(chancesOfSuccessOptions, 'id'), 'name'),
      },
      priceType: {
        id: 'selectedPriceType',
        options: mapValues(keyBy(priceTypeOptions, 'id'), 'name'),
      },
      owningDivisionId: {
        id: 'selectedDivisionOpportunityOwner',
        options: mapValues(keyBy(divisionPairs2, 'id'), 'name'),
      },
      proposalLeadUserId: {
        id: 'selectedLead',
        options: usersMap,
      },
      evaluationTypeId: {
        id: 'selectedEvaluationType',
        options: mapValues(keyBy(evaluationTypePairs, 'id'), 'name'),
      },
      budgetBasisId: {
        id: 'selectedBudgetBasis',
        options: mapValues(keyBy(budgetBasisPairs, 'id'), 'name'),
      },
      guidelineCodeId: {
        id: 'selectedGuidelineCode',
        options: mapValues(keyBy(guidelineCodePairs, 'id'), 'name'),
      },
      status: {
        id: 'selectedStage',
        options: mapValues(keyBy(proposalStatusPairs, 'id'), 'name'),
      },
      stage: {
        id: 'selectedStage',
        options: mapValues(keyBy(proposalStatusPairs, 'id'), 'name'),
      },
      figuresRequired: {
        id: 'selectedFiguresRequired',
        options: mapValues(keyBy(trueFalseAsYesNoOptions, 'id'), 'name'),
      },
      travelRequired: {
        id: 'selectedTravelRequired',
        options: mapValues(keyBy(trueFalseAsYesNoOptions, 'id'), 'name'),
      },
    }),
    [
      divisionPairs2,
      usersMap,
      evaluationTypePairs,
      budgetBasisPairs,
      guidelineCodePairs,
      proposalStatusPairs,
    ]
  );

  const proposalArrayToFieldMap = useMemo<{
    [key: string]: { id: string; type: string; options?: any; valueMap?: (obj: any) => string };
  }>(
    () => ({
      individualsInvolvedUserIdsToAdd: {
        id: 'selectedIndividualsInvolved',
        type: 'Added',
        options: usersMap,
      },
      individualsInvolvedUserIdsToRemove: {
        id: 'selectedIndividualsInvolved',
        type: 'Removed',
        options: usersMap,
      },

      potentialTeamMemberUserIdsToAdd: {
        id: 'selectedPotentialTeamMembers',
        type: 'Added',
        options: usersMap,
      },
      potentialTeamMemberUserIdsToRemove: {
        id: 'selectedPotentialTeamMembers',
        type: 'Removed',
        options: usersMap,
      },

      participatingDivisionIdsToAdd: {
        id: 'selectedDivisionsParticipating',
        type: 'Added',
        options: mapValues(keyBy(divisionPairs2, 'id'), 'name'),
      },

      participatingDivisionIdsToRemove: {
        id: 'selectedDivisionsParticipating',
        type: 'Removed',
        options: mapValues(keyBy(divisionPairs2, 'id'), 'name'),
      },

      workTypeIdsToAdd: {
        id: 'selectedWorkType',
        type: 'Added',
        options: mapValues(keyBy(workTypePairs, 'id'), 'name'),
      },
      workTypeIdsToRemove: {
        id: 'selectedWorkType',
        type: 'Removed',
        options: mapValues(keyBy(workTypePairs, 'id'), 'name'),
      },

      submissionMethodIdsToAdd: {
        id: 'selectedSubmissionMethods',
        type: 'Added',
        options: mapValues(keyBy(submissionMethodPairs, 'id'), 'name'),
      },
      submissionMethodIdsToRemove: {
        id: 'selectedSubmissionMethods',
        type: 'Removed',
        options: mapValues(keyBy(submissionMethodPairs, 'id'), 'name'),
      },

      proposalReportTypeIdsToAdd: {
        id: 'selectedReportType',
        type: 'Added',
        options: mapValues(keyBy(reportTypePairs, 'id'), 'name'),
      },
      proposalReportTypeIdsToRemove: {
        id: 'selectedReportType',
        type: 'Removed',
        options: mapValues(keyBy(reportTypePairs, 'id'), 'name'),
      },

      revenueAreaIdsToAdd: {
        id: 'selectedPotentialRevenueByArea',
        type: 'Added',
        options: mapValues(keyBy(revenueAreaPairs, 'id'), 'name'),
      },
      revenueAreaIdsToRemove: {
        id: 'selectedPotentialRevenueByArea',
        type: 'Removed',
        options: mapValues(keyBy(revenueAreaPairs, 'id'), 'name'),
      },

      deliverableIdsToAdd: {
        id: 'selectedDeliverables',
        type: 'Added',
        options: mapValues(keyBy(deliverablePairs, 'id'), 'name'),
      },
      deliverableIdsToRemove: {
        id: 'selectedDeliverables',
        type: 'Removed',
        options: mapValues(keyBy(deliverablePairs, 'id'), 'name'),
      },

      billingBasisIdsToAdd: {
        id: 'selectedBillingBasis',
        type: 'Added',
        options: mapValues(keyBy(billingBasePairs, 'id'), 'name'),
      },
      billingBasisIdsToRemove: {
        id: 'selectedBillingBasis',
        type: 'Removed',
        options: mapValues(keyBy(billingBasePairs, 'id'), 'name'),
      },

      billingOriginIdsToAdd: {
        id: 'selectedBillingOrigin',
        type: 'Added',
        options: mapValues(keyBy(billingOriginPairs, 'id'), 'name'),
      },
      billingOriginIdsToRemove: {
        id: 'selectedBillingOrigin',
        type: 'Removed',
        options: mapValues(keyBy(billingOriginPairs, 'id'), 'name'),
      },
      countryIdsToAdd: {
        id: 'selectedProjectCountries',
        type: 'Added',
        options: mapValues(
          keyBy(countriesItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
      },
      countryIdsToRemove: {
        id: 'selectedProjectCountries',
        type: 'Removed',
        options: mapValues(
          keyBy(countriesItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
      },
      fieldIdsToAdd: {
        id: 'selectedProjectFields',
        type: 'Added',
        options: mapValues(
          keyBy(fieldsItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
      },
      fieldIdsToRemove: {
        id: 'selectedProjectFields',
        type: 'Removed',
        options: mapValues(
          keyBy(fieldsItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
      },
      competitorsToAdd: {
        id: 'selectedCompetitors',
        type: 'Added',
        options: mapValues(
          keyBy(fieldsItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
        valueMap: ({ bidValue, competitorName, hasWonBid }: ICompetitor) => `${competitorName} (${
          hasWonBid ? 'Won Bid ' : 'Bid '
        } ${bidValue ? '$ ' + currencyFormatUS(bidValue, 0) : 'unknown'} 
        )`,
      },
      competitorIdsToRemove: {
        id: 'selectedCompetitors',
        type: 'Removed',
        options: mapValues(
          keyBy(fieldsItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
        valueMap: (id: string) => `#ID${id}`,
      },
      competitorsToModify: {
        id: 'selectedCompetitors',
        type: 'Changed',
        options: mapValues(
          keyBy(fieldsItems, 'id'),
          (item) => item.name + ' (' + item.code.substring(0, 3) + ')'
        ),
        valueMap: ({ bidValue, competitorName, hasWonBid }: ICompetitor) => `${competitorName} (${
          hasWonBid ? 'Won Bid ' : 'Bid '
        } ${bidValue ? '$ ' + currencyFormatUS(bidValue, 0) : 'unknown'} 
        )`,
      },
    }),
    [
      usersMap,
      divisionPairs2,
      workTypePairs,
      reportTypePairs,
      submissionMethodPairs,
      deliverablePairs,
      revenueAreaPairs,
      billingBasePairs,
      billingOriginPairs,
      countriesItems,
      fieldsItems,
    ]
  );

  const fieldsInfo = useMemo(() => {
    const collectedInfo: any[] = [];
    [customFields, otherAndRelatedFields, basicDetailsFields, additionalDetailsFields].forEach(
      (section) => {
        section.forEach((fieldGroup) => {
          fieldGroup.forEach((field) => {
            if (!!field.id) {
              collectedInfo.push({ ...pick(field, ['id', 'title', 'field']) });
            }
          });
        });
      }
    );
    return keyBy(collectedInfo, 'id');
  }, [additionalDetailsFields, basicDetailsFields, otherAndRelatedFields]);

  const payloadConvert = useCallback(
    (payload: any) => {
      const collected: any[] = [];
      Object.keys(payload).forEach((key) => {
        if (key === 'id') {
          return;
        }

        if (Array.isArray(payload[key as keyof typeof payload])) {
          if (proposalArrayToFieldMap[key as keyof typeof proposalArrayToFieldMap]) {
            const {
              id: mappedId,
              type,
              options,
              valueMap,
            } = proposalArrayToFieldMap[key as keyof typeof proposalArrayToFieldMap];
            if (payload[key].length) {
              collected.push({
                id: key,
                title: fieldsInfo[mappedId].title + ' ' + type,
                value:
                  '"' +
                  (valueMap
                    ? payload[key].map((item: any) => valueMap(item)).join('", "')
                    : options
                    ? payload[key]
                        .map((id: string) => {
                          return options[id]?.name || options[id];
                        })
                        .join('", "')
                    : payload[key]) +
                  '"',
              });
            } else {
              // empty list is not important
            }
          } else {
            collected.push({
              id: key,
              title: key,
              value: payload[key].join(', ') || '--',
            });
          }
          return;
        }

        if (fieldsInfo[key]) {
          let displayValue;
          if (!!payload[key]) {
            switch (fieldsInfo[key].field.type) {
              case '$': {
                displayValue = '$ ' + currencyFormatUS(parseFloat(payload[key]), 0);
                break;
              }
              case 'date': {
                displayValue = formatProposalDate(payload[key]);
                break;
              }
              default:
                displayValue = payload[key];
            }
          } else {
            displayValue = '--';
          }

          collected.push({
            id: key,
            title: fieldsInfo[key].title,
            value: displayValue,
          });
          return;
        }

        if (mapSimpleOptions[key as keyof typeof mapSimpleOptions]) {
          const { id: mappedId, options } = mapSimpleOptions[key as keyof typeof mapSimpleOptions];

          collected.push({
            id: mappedId,
            title: fieldsInfo[mappedId].title,
            value:
              (!!options && (options[payload[key]]?.name || options[payload[key]])) ||
              payload[key] ||
              '--',
          });

          return;
        }
      });
      return collected;
    },
    [fieldsInfo, mapSimpleOptions, proposalArrayToFieldMap]
  );

  return {
    payloadConvert,
    loading:
      loadingBillingOrigins ||
      loadingBillingBases ||
      loadingCountries ||
      loadingDeliverables ||
      loadingDivisions ||
      loadingEvaluationTypes ||
      loadingBudgetBasis ||
      loadingFields ||
      loadingGuidelineCodes ||
      loadingRevenueAreas ||
      loadingReportTypes ||
      loadingSubmissionMethods ||
      loadingUsers ||
      loadingWorkTypes,
  };
};

import { Route } from 'react-router-dom';

export const RouteWithSubRoutes = (route: any, projectFolderId: string | undefined) => {
  return (
    <Route
      exact={route.exact}
      key={route.path}
      path={route.path}
      render={(props) => (
        <route.component {...props} routes={route.routes} projectFolderId={projectFolderId} />
      )}
    />
  );
};

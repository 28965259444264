import { useLazyQuery } from '@apollo/client';
import { GET_PARTY_NAMES } from 'graphql/proposals/parties';
import { partyNames, partyNames_parties } from 'graphql/proposals/types/partyNames';
import { useCallback, useEffect, useMemo } from 'react';

export interface IUseSelectedPartyDataState {
  items: partyNames_parties[];
}

interface IUseSelectedPartyDataProps {
  IDs?: string[];
}

export const useSelectedPartyDataItems = ({
  IDs,
}: IUseSelectedPartyDataProps): IUseSelectedPartyDataState => {
  const [loadParties, { data, loading, refetch, called }] = useLazyQuery<partyNames>(
    GET_PARTY_NAMES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (ids: string[]) => {
      const variables = {
        filter: { ids },
      };

      if (called) {
        refetch!(variables);
      } else {
        loadParties({ variables });
      }
    },
    [loadParties, refetch, called]
  );

  useEffect(() => {
    if (IDs?.length) {
      loadPage(IDs);
    }
  }, [IDs, loadPage]);

  const getItems = useCallback(() => {
    return (data && !loading && data.parties) || [];
  }, [data, loading]);

  const items = useMemo(() => {
    return getItems();
  }, [getItems]);

  return { items };
};

import { IconButton, Tooltip } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { FC } from 'react';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface IEditDocumentProps {
  onClick: any;
  tooltip: any;
}

export const EditDocument: FC<IEditDocumentProps> = ({ onClick, tooltip }) => {
  return (
    <div onClick={debounce(onClick, DEBOUNCE_TIMEOUT)} style={{ cursor: 'pointer' }}>
      <Tooltip title={tooltip || ''} disableInteractive={!tooltip}>
        <IconButton>
          <LinkIcon style={{ color: '#006ad4' }}></LinkIcon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

import { ProposalType } from 'graphql/proposals/types/graphql-types';
import { IGeneralListItem } from 'template/Proposal/ProposalContext';

export const proposalTypeOptions: IGeneralListItem<ProposalType>[] = [
  { id: ProposalType.EOI_MARKET_SURVEY, name: 'EOI-Market Survey' },
  { id: ProposalType.PROPOSAL_DIRECT_REQUEST, name: 'Proposal Direct Request' },
  { id: ProposalType.PROPOSAL_TENDER, name: 'Proposal Tender' },
];

export const changeProposalTypeName = (name: string): string => {
  return proposalTypeOptions.find((option) => option.id === name)?.name || name;
};

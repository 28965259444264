import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import {
  capitalizeAllWordsFirstLetterUS,
  capitalizeFirstLetter,
  formatCurrencyUSD,
} from 'utils/formats';
import {
  transformCurrentlyReviewingText,
  transformProposalLeadText,
  transformProposalStageText,
} from 'utils/TableTransformFunctions';
import { proposalTypeOptions } from 'template/Proposal/components/ProposalForm/constants/proposalTypeOptions';
import { ProposalFileGroup } from 'graphql/proposals/types/graphql-types';

export const proposalsExportToExcelHead: IHeadCell[] = [
  // Stage
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Stage',

    transformFunction: transformProposalStageText,
  },
  // Owners
  {
    id: 'owners',
    numeric: false,
    disablePadding: false,
    label: 'Owners',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      return data?.map((owner: any) => owner.user.name).join(String.fromCharCode(10)) || 'N/A';
    },
  },
  // Created Date
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created',

    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  // Last Modified Date
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Changed',

    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'activity',
    numeric: false,
    disablePadding: false,
    label: 'Activity',

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : 'N/A'),
  },

  // *********************
  // BASIC DETAILS
  // *********************

  // Business Proposal ID
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Business Proposal ID',
  },

  // Type
  {
    id: 'proposalType',
    numeric: false,
    disablePadding: false,
    label: 'Type',

    transformFunction: (proposalType) => {
      const found = proposalTypeOptions.find((item) => item.id === proposalType);
      return proposalType ? (found ? found.name : proposalType) : 'N/A';
    },
  },

  // Proposal Name
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Name',
  },

  // Scope of Work (description)
  {
    id: 'scopeOfWorkDescription',
    numeric: false,
    disablePadding: false,
    label: 'Scope of Work (description)',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Client Name
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',

    transformFunction: (data) =>
      data
        ? (data.projectSetupClientCode ? data.projectSetupClientCode + ' - ' : '') + data.name
        : 'N/A',
  },

  // New Client
  {
    id: 'newParty',
    numeric: false,
    disablePadding: false,
    label: 'New Client',

    transformFunction: (_, row) => {
      if (!row?.party) {
        return 'N/A';
      } else {
        return row?.party?.projectSetupClientCode
          ? 'No'
          : 'Yes - Created on ' +
              new Date(row?.party?.createdAt).toLocaleDateString('en-EN', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              });
      }
    },
  },

  // Contact on Client side
  {
    id: 'partyContacts',
    numeric: false,
    disablePadding: false,
    label: 'Contact on Client side',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) =>
      data?.length
        ? data
            .map((contact: any) => {
              const parts: Array<any> = [];

              if (contact.firstName || contact.lastName) {
                parts.push({
                  name: 'Name',
                  value:
                    (contact.firstName ? contact.firstName + ' ' : '') + (contact.lastName ?? ''),
                });
              }
              if (contact.email) {
                parts.push({ name: 'Email', value: contact.email });
              }
              if (contact.jobTitle) {
                parts.push({ name: 'Job Title', value: contact.jobTitle });
              }
              if (contact.phone) {
                parts.push({ name: 'Phone', value: contact.phone });
              }

              return parts
                .map((part) => part.name + ': ' + part.value)
                .join(String.fromCharCode(10));
            })
            .filter((contact: any) => !!contact)
            .join(String.fromCharCode(10) + String.fromCharCode(10))
        : 'N/A',
  },

  // D&M Client Division Ownership
  {
    id: 'referencePartyDivisionOwnership',
    numeric: false,
    disablePadding: false,
    label: 'D&M Client Division Ownership',

    transformFunction: (_, row) =>
      row?.party?.divisionOwnership?.name ? row.party.divisionOwnership.name : 'N/A',
  },

  // D&M Client Focal Point
  {
    id: 'referencePartyFocalPointUser',
    numeric: false,
    disablePadding: false,
    label: 'D&M Client Focal Point',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (_, row) => {
      if (!row?.party) {
        return 'N/A';
      }
      const data = row.party;
      let content: any[] = [];
      if (data?.focalPointUser?.name) {
        content.push({ name: 'Phone', value: data?.focalPointUser?.name });
      }
      if (data?.focalPointUser?.email) {
        content.push({ name: 'Email', value: data?.focalPointUser?.email });
      }
      return content.length
        ? content.map((part) => part.name + ': ' + part.value).join(String.fromCharCode(10))
        : 'N/A';
    },
  },

  // Business Decisions to Consider
  {
    id: 'businessDecisions',
    numeric: false,
    disablePadding: false,
    label: 'Business Decisions to Consider',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Chance of Success
  {
    id: 'successChance',
    numeric: false,
    disablePadding: false,
    label: 'Chance of Success',

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : 'N/A'),
  },

  // Assigned Division
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Division',
    transformFunction: (data) => data?.division?.name || 'N/A',
  },

  // D&M Divisions Participating
  {
    id: 'participatingDivisions',
    numeric: false,
    disablePadding: false,
    label: 'D&M Divisions Participating',
    transformFunction: (data) =>
      data?.length ? data.map((item: any) => item.division?.name).join(', ') : 'N/A',
  },

  // Proposal Received Date
  {
    id: 'receiveDate',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Received Date',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // Proposal Due Date
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Due Date',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // Submission Method
  {
    id: 'submissionMethods',
    numeric: false,
    disablePadding: false,
    label: 'Submission Method',

    transformFunction: (data) =>
      data?.length ? data.map((item: any) => item.name).join(', ') : 'N/A',
  },

  // D&M Proposal Lead
  {
    id: 'leadUser',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Lead',
    transformFunction: transformProposalLeadText,
  },

  // D&M Individuals Involved in Proposal
  {
    id: 'individualsInvolved',
    numeric: false,
    disablePadding: false,
    label: 'D&M Individuals Involved in Proposal',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) =>
      data?.length
        ? data
            .map((item: any) => {
              const parts: Array<any> = [];

              if (item.user?.name) {
                parts.push({
                  name: 'Name',
                  value: item.user?.name,
                });
              }
              if (item.user?.email) {
                parts.push({
                  name: 'Email',
                  value: item.user?.email,
                });
              }

              return parts
                .map((part) => part.name + ': ' + part.value)
                .join(String.fromCharCode(10));
            })
            .filter((item: any) => !!item)
            .join(String.fromCharCode(10) + String.fromCharCode(10))
        : 'N/A',
  },

  // Expected Award Date
  {
    id: 'expectedAwardDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Award Date',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // Expected Project Start Date
  {
    id: 'expectedProjectStartDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Project Start Date',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // Expected Project End Date
  {
    id: 'expectedProjectEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Project End Date',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  // *********************
  // ADDITIONAL DETAILS
  // *********************

  // Project Countries
  {
    id: 'countries',
    numeric: false,
    disablePadding: false,
    label: 'Project Countries',

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data.map((item: any) => item.name + '(' + item.code + ')')
        : undefined;
      return dataNames.join(String.fromCharCode(10)) || 'N/A';
    },

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Project Fields
  {
    id: 'fields',
    numeric: false,
    disablePadding: false,
    label: 'Project Fields',

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Billing Origin
  {
    id: 'billingOrigins',
    numeric: false,
    disablePadding: false,
    label: 'Billing Origin',

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Billing Basis
  {
    id: 'billingBases',
    numeric: false,
    disablePadding: false,
    label: 'Billing Basis',

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Potential Revenue by Area
  {
    id: 'revenueAreas',
    numeric: false,
    disablePadding: false,
    label: 'Potential Revenue by Area',

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Number of Mature Fields
  {
    id: 'numberOfMatureFields',
    numeric: false,
    disablePadding: false,
    label: 'Number of Mature Fields',

    transformFunction: (data) => (data ? data : 'N/A'),
  },

  // Number of Green Fields
  {
    id: 'numberOfGreenFields',
    numeric: false,
    disablePadding: false,
    label: 'Number of Green Fields',

    transformFunction: (data) => (data ? data : 'N/A'),
  },

  // Work Type
  {
    id: 'workTypes',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },
  },

  // Work Type Other Detail
  {
    id: 'workTypeDetails',
    numeric: false,
    disablePadding: false,
    label: 'Work Type Other Detail',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Evaluation Type
  {
    id: 'evaluationType',
    numeric: false,
    disablePadding: false,
    label: 'Evaluation Type',

    transformFunction: (data) => (data?.name ? data.name : 'N/A'),
  },

  // Budget Basis Type
  {
    id: 'budgetBasis',
    numeric: false,
    disablePadding: false,
    label: 'Budget Basis',

    transformFunction: (data) => (data?.name ? data.name : 'N/A'),
  },

  // Guideline Code
  {
    id: 'guidelineCode',
    numeric: false,
    disablePadding: false,
    label: 'Guideline Code',

    transformFunction: (data) => (data?.name ? data.name : 'N/A'),
  },

  // Proposal Fees (net to D&M)
  {
    id: 'feesValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Fees (net to D&M)',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Best Technical Estimate
  {
    id: 'bestTechnicalEstimateValue',
    numeric: true,
    disablePadding: false,
    label: 'Best Technical Estimate',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Proposal Expenses (net to D&M)
  {
    id: 'expensesValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Expenses (net to D&M)',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Bid Price Proposed
  {
    id: 'bidProposedValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Amount',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Bid Price Final
  {
    id: 'bidFinalValue',
    numeric: true,
    disablePadding: false,
    label: 'Bid Price Final',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Average Hourly Rate Quoted
  {
    id: 'averageHourlyRate',
    numeric: true,
    disablePadding: false,
    label: 'Average Hourly Rate Quoted',

    transformFunction: (data: any) => (data ? data : 'N/A'),

    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },

  // Total Hours Estimated
  {
    id: 'estimatedTotalHours',
    numeric: false,
    disablePadding: false,
    label: 'Total Hours Estimated',
  },

  // Deliverables
  {
    id: 'deliverables',
    numeric: false,
    disablePadding: false,
    label: 'Deliverables',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },
  },

  // Types of Reports
  {
    id: 'proposalReportTypes',
    numeric: false,
    disablePadding: false,
    label: 'Types of Reports',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) => {
      const dataNames: string[] = data ? data.map((item: any) => item.name) : undefined;
      return dataNames?.join(String.fromCharCode(10)) || 'N/A';
    },
  },

  // Figures Required
  {
    id: 'figuresRequired',
    numeric: false,
    disablePadding: false,
    label: 'Figures Required',

    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },

  // Potential Team Members
  {
    id: 'potentialTeamMembers',
    numeric: false,
    disablePadding: false,
    label: 'Potential Team Members',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) =>
      data?.length
        ? data
            .map((teamMember: any) => {
              if (!teamMember.user) {
                return undefined;
              }

              const { user } = teamMember;
              const parts: Array<any> = [];

              if (user.name) {
                parts.push({
                  name: 'Name',
                  value: user.name,
                });
              }
              if (user.email) {
                parts.push({ name: 'Email', value: user.email });
              }

              return parts
                .map((part) => part.name + ': ' + part.value)
                .join(String.fromCharCode(10));
            })
            .filter((contact: any) => !!contact)
            .join(String.fromCharCode(10) + String.fromCharCode(10)) || 'N/A'
        : 'N/A',
  },

  // Notes
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // Travel Required
  {
    id: 'travelRequired',
    numeric: false,
    disablePadding: false,
    label: 'Travel Required',

    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },

  // Price Type
  {
    id: 'priceType',
    numeric: false,
    disablePadding: false,
    label: 'Price Type',

    transformFunction: (data) =>
      data ? capitalizeAllWordsFirstLetterUS(data.toLowerCase()) : 'N/A',
  },

  // Reviewers...
  {
    id: 'pendingReviewers',
    numeric: false,
    disablePadding: false,
    label: 'Reviewing',

    transformFunction: transformCurrentlyReviewingText,

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },
  },

  // ...& Review State
  {
    id: 'reviewStatus',
    numeric: false,
    disablePadding: false,
    label: 'Review State',

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : 'N/A'),
  },

  // Competitor Details
  {
    id: 'competitors',
    numeric: false,
    disablePadding: false,
    label: 'Competitor Details',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (data) =>
      data?.length
        ? data
            .map((competitor: any) => {
              const parts: Array<any> = [];

              if (competitor?.competitor?.name) {
                parts.push({
                  name: 'Name',
                  value: competitor.competitor.name,
                });
              }
              if (competitor.bidValue) {
                parts.push({
                  name: 'Bid Value',
                  value: formatCurrencyUSD(parseFloat(competitor.bidValue)),
                });
              }
              if (!!competitor) {
                parts.push({ name: 'Winning Bid', value: competitor.hasWonBid ? 'Yes' : 'No' });
              }

              return parts
                .map((part) => part.name + ': ' + part.value)
                .join(String.fromCharCode(10));
            })
            .filter((contact: any) => !!contact)
            .join(String.fromCharCode(10) + String.fromCharCode(10))
        : 'N/A',
  },

  // *********************************
  // Proposal Documents & Reviewers
  // *********************************

  {
    id: 'documents.clientDocumentsCount',
    numeric: true,
    disablePadding: false,
    label: 'Number of Client Documents',

    transformFunction: (_, row) => {
      return (
        row?.documents?.filter(
          (document: any) => document.proposalFileGroup === ProposalFileGroup.CLIENT_DOCUMENT
        ).length || 0
      );
    },
  },

  // Number of D&M Submitted Documents
  {
    id: 'documents.D&MSubmittedDocuments',
    numeric: true,
    disablePadding: false,
    label: 'Number of D&M Submitted Documents',

    transformFunction: (_, row) => {
      return (
        row?.documents?.filter(
          (document: any) =>
            document.proposalFileGroup === ProposalFileGroup.DEMAC_SUBMITTED_DOCUMENT
        ).length || 0
      );
    },
  },

  // Number of D&M Supporting Documents
  {
    id: 'documents.D&MSupportingDocuments',
    numeric: true,
    disablePadding: false,
    label: 'Number of D&M Supporting Documents',

    transformFunction: (_, row) => {
      return (
        row?.documents?.filter(
          (document: any) =>
            document.proposalFileGroup === ProposalFileGroup.DEMAC_SUPPORTING_DOCUMENT
        ).length || 0
      );
    },
  },

  // Documents Approved
  {
    id: 'documents.documentsApproved',
    numeric: false,
    disablePadding: false,
    label: 'Documents Approved',

    excelRowStyle: {
      alignment: {
        wrapText: true,
      },
    },

    transformFunction: (_, row) => {
      const approvedOnceOrMore: { [id: string]: boolean } = {};
      const notApprovedOnceOrMore: { [id: string]: boolean } = {};

      row?.reviewers.map((reviewer: any) =>
        reviewer.reviewerAssignedDocuments.map((doc: any) => {
          if (doc.reviewStatus === 'REVIEW_APPROVED') {
            approvedOnceOrMore[doc.proposalDocumentId] = true;
          } else {
            notApprovedOnceOrMore[doc.proposalDocumentId] = true;
          }
          return undefined;
        })
      );

      const approvedDocuments = row?.documents?.filter(
        (document: any) => approvedOnceOrMore[document.id] && !notApprovedOnceOrMore[document.id]
      );

      return (
        approvedDocuments
          ?.map((document: any) => {
            const proposalFileGroup = capitalizeAllWordsFirstLetterUS(
              document.proposalFileGroup.toLowerCase().replaceAll('_', ' ')
            ).replaceAll('Demac', 'D&M');

            const fileName =
              document.originalFilename ??
              document.versions?.slice(-1)[0].file?.originalFilename ??
              '<unknown>';

            // const version = document.versions?.slice(-1)[0].version ?? 0;

            return (
              fileName +
              // + ' V1.' + version
              ' (' +
              proposalFileGroup +
              ')'
            );
          })
          .join(String.fromCharCode(10)) || 'N/A'
      );
    },
  },
];

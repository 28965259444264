import React, { createContext, FC, useContext, useMemo, useState, useEffect } from 'react';
import { IChartingData, useDataSourceConfigContext } from './DataSourceConfig';
import { IChartConfig } from '../Components/ChartingPage/interface';
import { getChartOptions } from '../Components/ChartingPage/constants';

export interface DataSource {
  selectedKey: string;
}

export interface DataSourceArr {
  selectedKeys: string[];
}

export interface Config {
  seriesAssignment?: DataSource;
  xAxis?: DataSource;
  yAxis?: DataSourceArr;
  auxValue?: DataSource;
  chartConfig: IChartConfig;
}

export interface IChartConfigContextState {
  headings: any;
  config: Config;
  setConfig?: React.Dispatch<React.SetStateAction<Config>>;
  chartData?: IChartingData;
  orderedAxes: { [columnKey: string]: (string | number)[] };
}

export interface IChartConfigContextActions {}

const getInitConfig: () => Config = () => ({
  chartConfig: {
    type: 'bar',
    options: getChartOptions({}),
    showSerieLabels: true,
  },
});

const initialState: IChartConfigContextState = {
  headings: [],
  orderedAxes: {},
  config: getInitConfig(),
};

const ChartConfigContext = createContext<
  IChartConfigContextState & Partial<IChartConfigContextActions>
>(initialState);

interface IChartConfigProviderProps {
  children: any;
}

export const ChartConfigProvider: FC<IChartConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<Config>(getInitConfig());
  const { chartData, orderedAxes } = useDataSourceConfigContext();

  useEffect(() => {
    if (orderedAxes) {
      setConfig(getInitConfig());
    }
  }, [orderedAxes]);

  const headings = useMemo(() => {
    const allHeadings = [];
    if (chartData) {
      const { headings, sheetName } = chartData;
      const headingsArr = Object.keys(headings)
        .map((key) => ({ key, name: headings[key] }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      allHeadings.push({ sheetName, headings: headingsArr });
    }
    return allHeadings;
  }, [chartData]);

  const values = useMemo(
    () => ({ headings, chartData, config, setConfig, orderedAxes }),
    [headings, config, chartData, orderedAxes]
  );

  return <ChartConfigContext.Provider value={values}>{children}</ChartConfigContext.Provider>;
};

export const useChartConfigContext = () => useContext(ChartConfigContext);

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { YearSummary } from 'template/Reports/YearSummary/YearSummary';
import { isValidYearSearch } from 'utils/year';

interface IYearSummaryPageParams {
  year: string;
}

export const YearSummaryPage: FC = () => {
  const { year } = useParams<IYearSummaryPageParams>();

  const yearChecked = isValidYearSearch(year) ? parseInt(year) : new Date().getFullYear();

  return <YearSummary year={yearChecked} />;
};

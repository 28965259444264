import {
  GridSize,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker as KeyboardDatePicker } from '@mui/x-date-pickers/DatePicker';
import { EasyAutoComplete } from 'components';
import { FC, useCallback } from 'react';
import { CurrencyInputField } from '../CurrencyInputField/CurrencyInputField';
import { TextInputField } from 'components/TextInputField/TextInputField';
import { Asterisks } from '../Asterisks.tsx/Asterisks';
import { useMemo } from 'react';
import { OpenDocument } from './components/OpenDocument';
import { UserName } from 'components/UserName/UserName';
import { EditDocument } from './components/EditDocument';
import s from './style.module.scss';
import { omit } from 'lodash';

const dateStyle = {
  backgroundColor: '#fff',
  borderRadius: 4,
  border: '1px solid #aaa',
};

export interface IColumnTemplateItem {
  item: any;
  meta: any;
  values: any;
  onFieldChange: any;
  onFieldUndo: any;
  hasFieldChanged: any;
  printView?: boolean;
  disabled?: boolean;
}
export const ColumnTemplateItem: FC<IColumnTemplateItem> = ({
  item,
  meta,
  values,
  onFieldChange,
  onFieldUndo,
  hasFieldChanged,
  printView,
  disabled,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const onTextFieldChange = useCallback(
    (item: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { parse } = item;
      onFieldChange!(item.id)(parse ? parse(e.target.value) : e.target.value);
    },
    [onFieldChange]
  );

  const onTextFieldClear = useCallback(
    (item: any) => () => {
      const { parse } = item;
      onFieldChange!(item.id)(parse ? parse('') : '');
    },
    [onFieldChange]
  );

  const onTextFieldUndo = useCallback(
    (item: any) => () => {
      onFieldUndo!(item.id)();
    },
    [onFieldUndo]
  );

  const onKeyboardDatePickerChange = useCallback(
    (item: any) => (date: Date | null) => {
      const { parse } = item;
      onFieldChange!(item.id)(parse ? parse(date) : date);
    },
    [onFieldChange]
  );

  const onTextareaAutosizeChange = useCallback(
    (item: any) => (e: React.ChangeEvent<{ value: string }>) => {
      const { parse } = item;
      onFieldChange!(item.id)(parse ? parse(e.target.value) : e.target.value);
    },
    [onFieldChange]
  );

  const onCurrencyInputField = useCallback(
    (item: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { parse } = item;
      onFieldChange!(item.id)(parse ? parse(e.target.value) : e.target.value);
    },
    [onFieldChange]
  );

  const onEasyAutoCompleteChange = useCallback(
    (item: any) => (value: any) => {
      const { parse } = item;
      onFieldChange!(item.id)(parse ? parse(value) : value);
    },
    [onFieldChange]
  );

  const isError = useMemo(
    () => !!values && !!values.errors && !!values.errors[item.id] && values.showValidator,
    [item.id, values]
  );

  const actionsLength = (meta[item.id]?.actions && Object.keys(meta[item.id]?.actions).length) || 0;
  const { open, edit } = meta[item.id]?.actions || {};
  const visible = (item.id || item.title) && !item.hidden;

  return (
    <Grid xs={12} className={s.dataBlockContainer}>
      {visible ? (
        <Grid container padding={0} spacing={printView ? 1 : 0} className={s.dataBlock}>
          <Grid
            xs={12}
            sm={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              minHeight: matches ? '44px' : undefined,
            }}
            className={s.dataBlock}
          >
            <Typography variant="body2" className="label-title-nocase">
              {item.title}
              <Asterisks count={item.group}></Asterisks>:
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={(7 - actionsLength) as GridSize}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            className={s.dataBlock}
          >
            {item.field?.type === 'msal' ? (
              <div style={{ paddingLeft: '15px' }}>
                <UserName></UserName>
              </div>
            ) : undefined}
            {item.field?.type === 'textareaAutosize' && printView ? (
              <div
                style={{
                  width: '100%',
                  paddingLeft: '15px',
                  whiteSpace: 'pre-line' /* Preserve line breaks */,
                  borderRadius: 4,
                  ...{ border: isError ? 'solid 1px rgba(255, 0, 0, .3)' : '1px #ccc solid' },
                  ...{ paddingRight: isError ? '15px' : undefined },
                }}
              >
                {item.field?.props?.value ||
                  (values[item.id] &&
                  (typeof values[item.id] === 'string' || values[item.id] instanceof String) &&
                  values[item.id].length !== 0
                    ? values[item.id]
                    : '-- ')}
                <span style={{ opacity: '0.6' }}>{item.field?.props?.note}</span>
              </div>
            ) : undefined}
            {item.field?.type === 'label' ? (
              <div
                style={{
                  paddingLeft: '15px',
                  ...{ border: isError ? 'solid 1px rgba(255, 0, 0, .3)' : undefined },
                  ...{ paddingRight: isError ? '15px' : undefined },
                }}
              >
                {item.field?.props?.value ||
                  (values[item.id] &&
                  (typeof values[item.id] === 'string' || values[item.id] instanceof String) &&
                  values[item.id].length !== 0
                    ? values[item.id]
                    : '-- ')}
                <span style={{ opacity: '0.6' }}>{item.field?.props?.note}</span>
              </div>
            ) : undefined}
            {item.field?.type === 'text' ? (
              <TextInputField
                disabled={disabled}
                {...item.field?.props}
                {...meta[item.id]?.props}
                value={item.field?.props?.value || values[item.id] || ''}
                onChange={onTextFieldChange(item)}
                error={isError}
                onClear={onTextFieldClear(item)}
                onUndo={hasFieldChanged!(item.id) ? onTextFieldUndo(item) : undefined}
              ></TextInputField>
            ) : undefined}
            {item.field?.type === 'date' ? (
              <KeyboardDatePicker
                disabled={disabled}
                format="MM/dd/yyyy"
                style={{ ...dateStyle, ...{ borderColor: isError ? 'red' : undefined } }}
                placeholder=""
                clearable
                InputProps={{
                  disableUnderline: true,
                  style: {
                    paddingLeft: '14px',
                  },
                }}
                {...item.field?.props}
                {...meta[item.id]?.props}
                value={values[item.id] || null}
                onChange={onKeyboardDatePickerChange(item)}
                renderInput={(props) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    autoComplete="off"
                    {...props}
                  />
                )}
              ></KeyboardDatePicker>
            ) : undefined}
            {item.field?.type === 'textareaAutosize' && !printView ? (
              <TextareaAutosize
                disabled={disabled}
                className="MuiInputBase-input"
                color="grey"
                style={{
                  width: '100%',
                  minHeight: '1rem',
                  padding: 10,
                  borderRadius: 4,
                  border: isError ? '1px red solid' : '1px #ccc solid',
                  overflow: 'auto',
                  ...item.field?.props?.overrideStyle,
                }}
                aria-label="minimum height"
                {...omit(item.field?.props, ['overrideStyle'])}
                {...meta[item.id]?.props}
                value={values[item.id] || ''}
                onChange={onTextareaAutosizeChange(item)}
              ></TextareaAutosize>
            ) : undefined}
            {item.field?.type === '$' ? (
              <CurrencyInputField
                disabled={disabled}
                {...item.field?.props}
                {...meta[item.id]?.props}
                value={values[item.id]}
                onChange={onCurrencyInputField(item)}
                error={isError}
              ></CurrencyInputField>
            ) : undefined}
            {item.field?.type === 'easyAutoComplete' ? (
              <EasyAutoComplete
                disabled={disabled}
                key={`eac-${item.id}`}
                label=""
                textFieldStyle="outlined"
                optionsLabel="name"
                selected={values[item.id]}
                selectedChanged={onEasyAutoCompleteChange(item)}
                {...item.field?.props}
                {...meta[item.id]?.props}
                error={isError}
              />
            ) : undefined}
          </Grid>
          {actionsLength > 0 ? (
            <Grid
              xs={12}
              sm={actionsLength as GridSize}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
              }}
            >
              {!!open ? (
                <OpenDocument url={open.url} tooltip={open.tooltip}></OpenDocument>
              ) : undefined}
              {!!edit ? <EditDocument {...edit}></EditDocument> : undefined}
            </Grid>
          ) : undefined}
        </Grid>
      ) : undefined}
    </Grid>
  );
};

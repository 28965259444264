import {
  TableContainer,
  Paper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { FC, useEffect, useCallback } from 'react';

import { IHeadCell, TableHead } from './components/TableHead/TableHead';
import { forEach } from 'lodash';
import { SortOrder } from './components/HeaderCell/HeaderCell';

import { FiltersRow } from './components/FiltersRow/FiltersRow';
import { IPagination, Pagination } from './components/Pagination/Paginations';
import cn from 'classnames';
import s from './style.module.scss';
import { TABLE_PAGINATION } from 'constants/config';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
export interface ITableProps {
  headCells: IHeadCell[];
  dataCells: Array<any>;
  handleSelect?: (id: string) => boolean;
  totalItems: number;
  loadPage?: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => boolean;
  initRowsPerPage?: number;
  hideFilters?: boolean;
  printView?: boolean;
  initOrder?: SortOrder;
  initOrderBy?: string;
  initPage?: number;
  paginationProps?: Partial<IPagination>;
  stickyHeader?: boolean;
  maxHeight?: string;
  paginationSideComponent?: any;
}

export const Table: FC<ITableProps> = ({
  loadPage,
  totalItems,
  handleSelect,
  dataCells,
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  initRowsPerPage,
  hideFilters,
  printView,
  initOrder,
  initOrderBy,
  initPage,
  paginationProps,
  stickyHeader,
  maxHeight,
  paginationSideComponent,
}) => {
  const [order, setOrder] = React.useState<SortOrder>(initOrder || SortOrder.ASC);
  const [orderBy, setOrderBy] = React.useState<string | undefined>(initOrderBy);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(initPage || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initRowsPerPage || 10);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = useCallback(
    (filterValues: any) => {
      if (onFilterChange!(filterValues)) {
        setPage(0);
      }
    },
    [onFilterChange]
  );

  const refresh = useCallback(() => {
    if (loadPage) {
      loadPage(order, orderBy, page, rowsPerPage);
    }
  }, [order, orderBy, page, rowsPerPage, loadPage]);

  useEffect(() => {
    refresh();
  }, [order, orderBy, page, rowsPerPage, refresh]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const isAsc = orderBy === property && order === SortOrder.ASC;
      // toggle sort order
      setOrder(isAsc ? SortOrder.DESC : SortOrder.ASC);

      const newOrderBy = orderBy === property && order === SortOrder.DESC ? undefined : property;
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      if (!handleSelect || !handleSelect(name)) newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight }}>
        <MuiTable stickyHeader={stickyHeader}>
          <MuiTableHead>
            <TableHead
              headCells={headCells}
              onRequestSort={handleRequestSort}
              orderBy={orderBy}
              order={order}
              printView={printView}
              stickyHeader={stickyHeader}
            ></TableHead>
            {!hideFilters ? (
              <FiltersRow
                headCells={headCells}
                filterOptions={filterOptions}
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                stickyHeader={stickyHeader}
              ></FiltersRow>
            ) : undefined}
          </MuiTableHead>
          <TableBody>
            {dataCells?.map((row, index) => {
              const isItemSelected = isSelected(row.id.toString());
              const columns: any[] = [];
              let count = 0;
              let colSpan = 0;
              forEach(headCells, (headCell) => {
                count++;
                if (colSpan > 1) {
                  colSpan--;
                  return undefined;
                }
                if (headCell.colSpan && headCell.colSpan > 1) {
                  colSpan = headCell.colSpan;
                }
                const cellClassName = cn({
                  [s.nowrap]: headCell.dataCell?.wrap === 'nowrap',
                  [s.box]: headCell.dataCell?.wrap === 'box',
                  [s.paddingHalf]: headCell.dataCell?.padding === 'half',
                  [s.noPaddingLeft]:
                    headCell.dataCell?.connected &&
                    ['left', 'middle'].includes(headCell.dataCell.connected),
                  [s.noPaddingRight]:
                    headCell.dataCell?.connected &&
                    ['middle', 'right'].includes(headCell.dataCell.connected),
                  [s.shrinkToContent]: headCell.dataCell?.shrink === 'content',
                });

                if (count === 1)
                  columns.push(
                    <TableCell
                      component="th"
                      id={`tc-${index}`}
                      scope="row"
                      padding="none"
                      key={count.toString() + '_' + headCell.id}
                      align={headCell.dataCell?.align}
                      colSpan={headCell.colSpan}
                      className={cellClassName}
                      title={
                        headCell.dataCell?.wrap === 'box'
                          ? headCell.transformFunction
                            ? headCell.transformFunction(
                                row[headCell.id],
                                row,
                                order,
                                orderBy === headCell.sortBy || orderBy === headCell.id
                              )
                            : row[headCell.id]
                          : undefined
                      }
                    >
                      {headCell.transformDataCell ? (
                        <headCell.transformDataCell
                          data={row[headCell.id]}
                          row={row}
                          order={order}
                          sorted={orderBy === headCell.sortBy || orderBy === headCell.id}
                        ></headCell.transformDataCell>
                      ) : headCell.transformFunction ? (
                        headCell.transformFunction(
                          row[headCell.id],
                          row,
                          order,
                          orderBy === headCell.sortBy || orderBy === headCell.id
                        )
                      ) : (
                        row[headCell.id]
                      )}
                    </TableCell>
                  );
                else {
                  columns.push(
                    <TableCell
                      align={headCell.dataCell?.align || 'left'}
                      key={count.toString() + '_' + headCell.id}
                      colSpan={headCell.colSpan}
                      className={cellClassName}
                      title={
                        headCell.dataCell?.wrap === 'box'
                          ? headCell.transformFunction
                            ? headCell.transformFunction(
                                row[headCell.id],
                                row,
                                order,
                                orderBy === headCell.sortBy || orderBy === headCell.id
                              )
                            : row[headCell.id]
                          : undefined
                      }
                    >
                      {headCell.transformDataCell ? (
                        <headCell.transformDataCell
                          data={row[headCell.id]}
                          row={row}
                          order={order}
                          sorted={orderBy === headCell.sortBy || orderBy === headCell.id}
                        ></headCell.transformDataCell>
                      ) : headCell.transformFunction ? (
                        headCell.transformFunction(
                          row[headCell.id],
                          row,
                          order,
                          orderBy === headCell.sortBy || orderBy === headCell.id
                        )
                      ) : (
                        row[headCell.id]
                      )}
                    </TableCell>
                  );
                }
              });
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onClick={debounce(
                    (event: any) => handleClick(event, row.id.toString()),
                    DEBOUNCE_TIMEOUT
                  )}
                  style={{ cursor: 'pointer' }}
                >
                  {columns}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <Pagination
        rowsPerPageOptions={TABLE_PAGINATION}
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChanged={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        leftSideComponent={paginationSideComponent}
        {...paginationProps}
      ></Pagination>
    </div>
  );
};

import { Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FormValidationReportProvider } from 'components/FormValidationReport/FormValidationReportContext';
import { FC } from 'react';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { RouteWithSubRoutes } from 'utils/RouteWithSubRoutes';
import { BondTabs } from './components/BondTabs/BondTabs';
import paths from 'constants/paths';
import { BondLoader } from './BondLoader';

interface IUrlParams {
  id: string;
  tab: string;
}
export interface ISubRoute {
  routes: Array<any>;
}

export const BondRoutePage: FC<ISubRoute> = ({ routes }) => {
  const { id, tab } = useParams<IUrlParams>();
  const history = useHistory();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <FormValidationReportProvider>
      <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
        <Paper
          elevation={3}
          style={{
            marginTop: '65px',
            width: 'fit-content',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
          id="main-paper"
        >
          <div style={{ width: 'fit-content' }}>
            {tab ? (
              <BondTabs
                changeView={(newTab) => {
                  history.push(paths.client.BOND_PAGES.replace(':id', id).replace(':tab', newTab));
                }}
                currentView={tab}
              ></BondTabs>
            ) : undefined}
          </div>
        </Paper>
        <BondLoader>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </BondLoader>
      </Container>
    </FormValidationReportProvider>
  );
};

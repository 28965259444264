import { gql } from '@apollo/client';

export const GET_BILLING_ORIGINS = gql`
  query billingOrigins {
    billingOrigins {
      id
      name
    }
  }
`;

export const GET_BILLING_BASES = gql`
  query billingBases {
    billingBases {
      id
      name
    }
  }
`;

export const GET_DELIVERABLES = gql`
  query deliverables {
    deliverables {
      id
      name
      order
    }
  }
`;

export const GET_EVALUATION_TYPES = gql`
  query evaluationTypes {
    evaluationTypes {
      id
      name
    }
  }
`;

export const GET_BUDGET_BASIS = gql`
  query budgetBasis {
    budgetBasis {
      id
      name
    }
  }
`;

export const GET_GUIDELINE_CODES = gql`
  query guidelineCodes {
    guidelineCodes {
      id
      name
    }
  }
`;

export const GET_REVENUE_AREAS = gql`
  query revenueAreas {
    revenueAreas {
      id
      name
    }
  }
`;

export const GET_WORK_TYPES = gql`
  query workTypes {
    workTypes {
      id
      name
    }
  }
`;

export const GET_PROPOSAL_SUBMISSION_METHODS = gql`
  query proposalSubmissionMethods {
    proposal_submissionMethods {
      id
      name
    }
  }
`;

export const GET_PROPOSAL_REPORT_TYPES = gql`
  query proposalReportTypes {
    proposal_reportTypes {
      id
      name
    }
  }
`;

export const GET_PROPOSAL_PROJECT_FIELDS = gql`
  query proposalProjectFields(
    $sort: [FieldSort!] = []
    $filter: FieldFilter
    $take: Int
    $skip: Int = 0
  ) {
    fields(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      id
      code
      name
    }
  }
`;

export const GET_PROPOSAL_PROJECT_FIELD_COUNT = gql`
  query proposalProjectFieldCount($filter: FieldFilter) {
    fieldCount(filter: $filter)
  }
`;

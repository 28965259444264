/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import {
  Button,
  Box,
  Divider,
  TextareaAutosize,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useUpload } from './hooks';
import { DocumentApprovalUploadSectionProps } from './interfaces';

import { ConfirmationDialog, EasyAutoComplete } from 'components';

import './index.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

import { useProposalFiles } from 'hooks/proposalFilesHook';
import { ProposalFileGroup } from 'graphql/proposals/types/graphql-types';
import Alert from '@mui/material/Alert';
import { useComponentContext } from 'template/Proposal/ProposalContext';
import { IProposalDocumentUser } from 'graphql/proposals/types/IProposalDocumentUser';

const defaultDialogConfirmation = {
  title: 'DOCUMENT UPLOAD CONFIRMATION',
  message: 'Please confirm new document upload and new comment.',
  style: { width: 380 },
};

const DocumentApprovalUploadSection: FC<DocumentApprovalUploadSectionProps> = ({
  isDocumentDecline = true,
  handleDocumentApproval,
  onChangeComment,
  handleFiles,
  account,
  proposal,
  forApproval,
  open,
  clientProposalDocuments,
  updateSelectedProposalDocumentIds,
}: DocumentApprovalUploadSectionProps) => {
  const { reviewersDocumentUsersByDocumentId } = useComponentContext();

  const [dialogContent, setDialogContent] = useState(defaultDialogConfirmation);
  const { handleCloseConfirmDialog, openConfirmDialog } = useUpload({
    handleDocumentApproval,
    handleFiles,
  });
  const [selectedProposalDocumentIds, setSelectedProposalDocumentIds] = useState<string[]>([]);

  const submitDissabled = useMemo(
    () => selectedProposalDocumentIds.length === 0,
    [selectedProposalDocumentIds]
  );

  useEffect(() => {
    updateSelectedProposalDocumentIds(selectedProposalDocumentIds);
  }, [updateSelectedProposalDocumentIds, selectedProposalDocumentIds]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectNextReviewerVisible, setSelectNextReviewerVisible] = useState(true);

  const proposalDocumentIds = useMemo(() => {
    const { currentReviewer } = proposal;

    const documentIds = Object.keys(reviewersDocumentUsersByDocumentId).filter((documentId) => {
      const reviewers = reviewersDocumentUsersByDocumentId[documentId];
      return !!reviewers.find(
        (reviewer: IProposalDocumentUser) =>
          reviewer.user?.id === currentReviewer?.user?.id &&
          reviewer?.reviewStatus === 'REVIEW_REQUESTED'
      );
    });

    return documentIds;
  }, [proposal, reviewersDocumentUsersByDocumentId]);

  useEffect(() => {
    if (proposalDocumentIds) {
      setSelectedProposalDocumentIds(
        proposalDocumentIds.filter((id) => !!id).map((id) => id!.toString())
      );
    } else {
      setSelectedProposalDocumentIds([]);
    }
  }, [proposalDocumentIds]);

  const getDocumentsList = useCallback(
    (proposalFileGroup: ProposalFileGroup) => {
      const documents = clientProposalDocuments
        ?.filter(
          (proposalDocument) =>
            proposalDocument.proposalFileGroup === proposalFileGroup &&
            proposalDocumentIds?.includes(proposalDocument.id)
        )
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt));

      return documents?.map((proposalDocument) => {
        const lastVersion = proposalDocument.versions
          .slice()
          .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))[0];

        const { originalFilename } = lastVersion.file!;
        return {
          id: proposalDocument.id,
          name: `${originalFilename} (v1.${lastVersion.version})`,
        };
      });
    },
    [clientProposalDocuments, proposalDocumentIds]
  );

  const clientDocuments = useMemo(() => {
    return getDocumentsList(ProposalFileGroup.CLIENT_DOCUMENT);
  }, [getDocumentsList]);

  const selectedClientDocuments = useMemo(() => {
    return clientDocuments.filter((file) => selectedProposalDocumentIds.includes(file.id));
  }, [clientDocuments, selectedProposalDocumentIds]);

  const submittedDocuments = useMemo(() => {
    return getDocumentsList(ProposalFileGroup.DEMAC_SUBMITTED_DOCUMENT);
  }, [getDocumentsList]);

  const selectedSubmittedDocuments = useMemo(() => {
    return submittedDocuments.filter((file) => selectedProposalDocumentIds.includes(file.id));
  }, [submittedDocuments, selectedProposalDocumentIds]);

  const supportingDocuments = useMemo(() => {
    return getDocumentsList(ProposalFileGroup.DEMAC_SUPPORTING_DOCUMENT);
  }, [getDocumentsList]);

  const selectedSupportingDocuments = useMemo(() => {
    return supportingDocuments.filter((file) => selectedProposalDocumentIds.includes(file.id));
  }, [supportingDocuments, selectedProposalDocumentIds]);

  useEffect(() => {
    if (isDocumentDecline) {
      setDialogContent(defaultDialogConfirmation);
    } else {
      setDialogContent({
        title: 'FEEDBACK CONFIRMATION',
        message: 'Please confirm your feedback.',
        style: { width: 380 },
      });
    }
  }, [isDocumentDecline]);

  const checkNextReviewerVisible = useCallback(() => {
    // By default, enable combobox to select next reviewer
    let canSelectNext = true;

    let myEmail = account.userName;
    if (myEmail) {
      myEmail = myEmail.toLocaleLowerCase();
    }

    // Now iterate through list of all reviewers and see if there is at least
    // one reviewer that has been requested to approve/decline
    // if that reviewer is not logged in user, than do not display combobox
    for (const r of proposal?.reviewers) {
      if (
        r.status === 'REVIEW_REQUESTED' &&
        !r.disabled &&
        r.employeeEmail?.toLocaleLowerCase() !== myEmail // r.user.email.toLocaleLowerCase() !== myEmail
      ) {
        canSelectNext = false;
        break;
      }
    }

    setSelectNextReviewerVisible(canSelectNext);
  }, [account.userName, proposal?.reviewers]);

  useEffect(() => {
    setDialogOpen(open);
    if (open === true) {
      checkNextReviewerVisible();
    }
  }, [open, checkNextReviewerVisible]);

  const { id, updatedAt } = proposal;
  const { data } = useProposalFiles({ id, updatedAt });
  const clientFiles = data?.proposal_proposal.files?.filter((x) => !!x);

  const onSelectedClientFilesChange = useCallback(
    (value: any) => {
      const newIds = value.map((item: any) => item.id);
      const excludeIds = clientDocuments
        .filter((doc) => !newIds.includes(doc.id))
        .map((doc) => doc.id);
      const includeIds = newIds.filter((id: string) => !selectedProposalDocumentIds.includes(id));
      setSelectedProposalDocumentIds((old) => [
        ...old.filter((id) => !excludeIds.includes(id)),
        ...includeIds,
      ]);
    },
    [clientDocuments, selectedProposalDocumentIds]
  );

  const onSelectedSubmittedFilesChange = useCallback(
    (value: any) => {
      const newIds = value.map((item: any) => item.id);
      const excludeIds = submittedDocuments
        .filter((doc) => !newIds.includes(doc.id))
        .map((doc) => doc.id);
      const includeIds = newIds.filter((id: string) => !selectedProposalDocumentIds.includes(id));
      setSelectedProposalDocumentIds((old) => [
        ...old.filter((id) => !excludeIds.includes(id)),
        ...includeIds,
      ]);
    },
    [submittedDocuments, selectedProposalDocumentIds]
  );

  const onSelectedSupportingFilesChange = useCallback(
    (value: any) => {
      const newIds = value.map((item: any) => item.id);
      const excludeIds = supportingDocuments
        .filter((doc) => !newIds.includes(doc.id))
        .map((doc) => doc.id);
      const includeIds = newIds.filter((id: string) => !selectedProposalDocumentIds.includes(id));
      setSelectedProposalDocumentIds((old) => [
        ...old.filter((id) => !excludeIds.includes(id)),
        ...includeIds,
      ]);
    },
    [supportingDocuments, selectedProposalDocumentIds]
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={dialogOpen}>
      <ConfirmationDialog
        open={openConfirmDialog}
        title={dialogContent.title}
        message={dialogContent.message}
        style={dialogContent.style}
        onClose={handleCloseConfirmDialog}
      />

      <DialogContent dividers>
        <Box mb={2}>
          <Alert severity={forApproval ? 'success' : 'error'}>
            <strong>You are {forApproval ? 'approving' : 'declining'} documents.</strong>
          </Alert>
        </Box>

        {clientDocuments.length ? (
          <Box mb={2}>
            <strong>Client Document:</strong>{' '}
            <EasyAutoComplete
              key={`eac-client-documents`}
              label=""
              textFieldStyle="outlined"
              optionsLabel="name"
              items={clientDocuments}
              selected={selectedClientDocuments}
              selectedChanged={onSelectedClientFilesChange}
              multiple={true}
              getOptionSelected={(option: any, value: any) => {
                return option.id === value.id;
              }}
              error={false}
            />
          </Box>
        ) : undefined}
        {submittedDocuments.length ? (
          <Box mb={2}>
            <strong>D&M Submitted Document:</strong>{' '}
            <EasyAutoComplete
              key={`eac-submitted-documents`}
              label=""
              textFieldStyle="outlined"
              optionsLabel="name"
              items={submittedDocuments}
              selected={selectedSubmittedDocuments}
              selectedChanged={onSelectedSubmittedFilesChange}
              multiple={true}
              getOptionSelected={(option: any, value: any) => {
                return option.id === value.id;
              }}
              error={false}
            />
          </Box>
        ) : undefined}
        {supportingDocuments.length ? (
          <Box mb={2}>
            <strong>D&M Supporting Document:</strong>{' '}
            <EasyAutoComplete
              key={`eac-supporting-documents`}
              label=""
              textFieldStyle="outlined"
              optionsLabel="name"
              items={supportingDocuments}
              selected={selectedSupportingDocuments}
              selectedChanged={onSelectedSupportingFilesChange}
              multiple={true}
              getOptionSelected={(option: any, value: any) => {
                return option.id === value.id;
              }}
              error={false}
            />
          </Box>
        ) : undefined}

        {/* {selectNextReviewerVisible && (
          <Box my={2}>
            <Alert severity="warning">
              Reminder: Please assign the document to the next reviewer. If unsure leave the option
              blank.
            </Alert>
          </Box>
        )} */}
        {!selectNextReviewerVisible && (
          <Box my={2}>
            <Alert severity="info">There are still pending reviews on this document</Alert>
          </Box>
        )}

        <Divider />
        <Box mt={2} />
        {/* <Box mt={2} marginBottom={4} className="document-approval-upload-section-upload">
          <span className="document-approval-upload-section-upload-component">
            <Upload
              onDrop={onDrop}
              removeFile={removeFile}
              isUploading={isUploading}
              percentage={percentage}
              files={files}
              hint={isDocumentDecline ? 'Upload if the document is marked up.' : ''}
            />
          </span>
        </Box> */}

        {/* {selectNextReviewerVisible && (
          <Box my={1}>
            <DocumentApprovalReviewer
              proposal={proposal}
              refetchProposal={refetchProposal}
              enableSubmit={false}
              reviewersChanged={reviewersChanged}
              clientProposalDocuments={clientProposalDocuments}
            />
          </Box>
        )} */}

        <Box marginBottom={1}>
          <Box marginBottom={1}>
            <strong>Comment (optional):</strong>
          </Box>

          <TextareaAutosize
            color="grey"
            style={{
              width: '95%',
              maxWidth: '95%',
              minWidth: '95%',
              minHeight: '1rem',
              padding: 10,
              borderRadius: 4,
              overflow: 'auto',
              border: '1px #ccc solid',
            }}
            aria-label="minimum height"
            minRows={1}
            placeholder="Write comment..."
            className="MuiInputBase-input"
            onChange={onChangeComment}
          />
        </Box>

        <Box marginTop={1} className="document-approval-upload-section-submit" />

        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <span>You can submit the review feedback without uploading a file.</span>
          </Grid>
        </Grid>

        <Box marginBottom={5} className="document-approval-upload-section-submit" />
      </DialogContent>
      <DialogActions>
        <Grid style={{ margin: 'auto' }}>
          <Box pb={1}>
            <Button
              variant="contained"
              size="medium"
              onClick={debounce((): void => {
                setDialogOpen(false);
                handleDocumentApproval(false);
              }, DEBOUNCE_TIMEOUT)}
              style={{ fontSize: '0.85rem', backgroundColor: '#ddd', width: 135 }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={debounce(() => handleCloseConfirmDialog(true), DEBOUNCE_TIMEOUT)}
              style={{ width: 135 }}
              disabled={submitDissabled}
            >
              Submit
            </Button>{' '}
          </Box>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentApprovalUploadSection;

import { ProposalBond } from '../../../../template/Bond/components/ProposalBond/ProposalBond';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { tryUpdateProcedure } from 'utils/apollo';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useUI } from 'contexts/UiContext';
import paths from 'constants/paths';

import { validateBond } from '../../../../template/Bond/components/ProposalBond/validator';
import { FormValidationReport } from 'components/FormValidationReport/FormValidationReport';
import { useFormValidationReportContext } from 'components/FormValidationReport/FormValidationReportContext';
import { useCurrencies } from 'hooks/currencyHook';
import { BondType } from 'graphql/proposals/types/graphql-types';
import {
  BOND_CREATE_MUTATION,
  BOND_DELETE_MUTATION,
  BOND_LOCK_MUTATION,
  BOND_UNLOCK_MUTATION,
  BOND_UPDATE_MUTATION,
  GET_BOND,
} from 'graphql/proposals/bonds';
import { omit } from 'lodash';
import { bond, bond_bond } from 'graphql/proposals/types/bond';
import { IBondData } from 'template/Bond/components/ProposalBond/interface';
import { useComponentContext } from 'template/Proposal/ProposalContext';

interface IUrlParams {
  bondId: string;
  id: string;
}
export interface IProps {
  routes: Array<any>;
}

const getNewBond: (proposalId: string, partyId: string) => IBondData = (proposalId, partyId) => ({
  // required to pass validation
  proposalId,
  partyId,
  selectedProposal: { key: proposalId, name: '', ddLabel: '' },
  selectedParty: { key: partyId, name: '', ddLabel: '' },

  type: BondType.BID_BOND,
  tenderNumber: '',
  tenderName: '',
  contractNumber: '',
  contractName: '',
  description: '',
  beneficiaryNameAndAddress: '',
  beneficiaryPhoneNumber: '',
  beneficiaryFaxNumber: '',
  beneficiaryEmail: '',
  amount: 0,
  currency: '',
  issuingBankRequirements: '',
  wordingOrFormatRequirements: '',
  deliveryInstructions: '',
  isLocked: false,
});

export const ProposalBidPerformanceBonds = () => {
  const { bondId, id: proposalId } = useParams<IUrlParams>();
  const { proposal } = useComponentContext();

  const { addSnackbar } = useUI();
  const [createMutation] = useMutation(BOND_CREATE_MUTATION);
  const [updateMutation] = useMutation(BOND_UPDATE_MUTATION);
  const [deleteMutation] = useMutation(BOND_DELETE_MUTATION);
  const [bondLockMutation] = useMutation(BOND_LOCK_MUTATION);
  const [bondUnLockMutation] = useMutation(BOND_UNLOCK_MUTATION);

  const { resetChanged } = useFormChangedDialogContext();

  const history = useHistory();
  const { openValidationResult } = useFormValidationReportContext();
  const { currencyPairs } = useCurrencies();

  const [loadedBond, setLoadedBond] = useState<bond_bond>();
  const [bond, setBond] = useState<IBondData>();
  const [loadFilesOnly, setLoadFilesOnly] = useState(false);

  const [showValidator, setShowValidator] = useState(false);

  const defaultCurrency = useMemo(() => {
    let foundUSD = currencyPairs.find((pair) => pair.id === 'USD' || pair.id === '$');
    if (foundUSD) {
      return foundUSD;
    }
  }, [currencyPairs]);

  const setBondProxy = useCallback((cbOrData: ((bond?: IBondData) => IBondData) | IBondData) => {
    if (cbOrData instanceof Function) {
      setBond((bond) => {
        const newData = cbOrData(bond);
        return { ...newData, ...validateBond(newData) };
      });
    } else {
      setBond({ ...cbOrData, ...validateBond(cbOrData) });
    }
  }, []);

  const [getBond, { called, data, loading, error, refetch }] = useLazyQuery<bond>(GET_BOND, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const loadFilesOnlyRef = useRef(false);
  useEffect(() => {
    loadFilesOnlyRef.current = loadFilesOnly;
  }, [loadFilesOnly]);

  useEffect(() => {
    if (data && !loading && !error) {
      const foundBond = data.bond;
      if (foundBond) {
        if (!loadFilesOnlyRef.current) {
          const bondData = {
            ...foundBond,
            proposalId: foundBond.proposal?.id,
            selectedProposal: foundBond.proposal
              ? {
                  key: foundBond.proposal.id,
                  name: foundBond.proposal.name,
                  ddLabel: foundBond.proposal.name,
                }
              : undefined,
            partyId: foundBond.party?.id,
            selectedParty: foundBond.party
              ? {
                  key: foundBond.party.id,
                  name: foundBond.party.name,
                  ddLabel:
                    (foundBond.party.projectSetupClientCode ||
                      (foundBond.party.partyType?.name === 'CLIENT' ? 'Client' : 'Non-Client')) +
                    ' - ' +
                    foundBond.party.name,
                }
              : undefined,
          };
          setBond({ ...bondData, ...validateBond(bondData) });
          setLoadedBond(foundBond);
        } else {
          setBond((bond) => {
            const b = {
              ...bond,
              files: foundBond?.files,
            };
            return { ...b, ...validateBond(b) };
          });
          setLoadFilesOnly(false);
        }
      }
    }
  }, [data, loading, error, bondId]);

  const loadBond = useCallback(async () => {
    const variables = {
      id: bondId,
    };

    if (called) {
      await refetch!(variables);
    } else {
      await getBond({ variables });
    }
  }, [refetch, called, getBond, bondId]);

  const refetchFilesList = useCallback(async () => {
    setLoadFilesOnly(true);
    loadBond();
  }, [setLoadFilesOnly, loadBond]);

  useEffect(() => {
    if (!bondId || bondId === 'new') {
      if (proposal.id && proposal.selectedClient) {
        setBond(getNewBond(proposal.id, proposal.selectedClient.key));
      }
    } else {
      loadBond();
    }
  }, [bondId, loadBond, proposal]);

  useEffect(() => {
    if (defaultCurrency) {
      setBond((bond) => {
        if (bond && !bond.currency) {
          return { ...bond, currency: defaultCurrency.id };
        }
        return bond;
      });
    }
  }, [defaultCurrency]);

  const isNewItem = useMemo(() => !bond?.id, [bond?.id]);

  const onLock = useCallback(async () => {
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        bondLockMutation({
          variables: {
            bondId,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (!isError && result?.bondLock?.isLocked) {
      addSnackbar!({
        text: 'Bond is Locked',
        severity: 'success',
      });
      resetChanged && resetChanged();
      loadBond();
    } else {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Error...Unable to lock',
          severity: 'error',
        });
      }
    }
  }, [bondId, bondLockMutation, addSnackbar, loadBond, resetChanged]);

  const onUnLock = useCallback(async () => {
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        bondUnLockMutation({
          variables: {
            bondId,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });
    if (!isError && result?.bondUnlock?.isLocked === false) {
      addSnackbar!({
        text: 'Bond is Unlocked',
        severity: 'success',
      });
      resetChanged && resetChanged();
      loadBond();
    } else {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Error...Unable to unlock',
          severity: 'error',
        });
      }
    }
  }, [bondId, addSnackbar, loadBond, resetChanged, bondUnLockMutation]);

  const onValidateAndSubmit = useCallback(async () => {
    if (!bond) {
      return;
    }

    const { isValid } = bond;
    if (!isValid) {
      setShowValidator(true);
      openValidationResult && openValidationResult();
      return;
    }

    const {
      amount,
      beneficiaryEmail,
      beneficiaryFaxNumber,
      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      contractName,
      contractNumber,
      currency,
      deliveryInstructions,
      description,
      issuingBankRequirements,
      recipientReceiveDeadline,
      tenderName,
      tenderNumber,
      type,
      validityThroughDate,
      wordingOrFormatRequirements,
      stage,
    } = bond;

    const prepareData = {
      type,
      stage,
      description,

      tenderName,
      tenderNumber,
      contractName: type === BondType.BID_BOND ? '' : contractName,
      contractNumber: type === BondType.BID_BOND ? '' : contractNumber,

      amount,
      currency,

      beneficiaryNameAndAddress,
      beneficiaryPhoneNumber,
      beneficiaryFaxNumber,
      beneficiaryEmail,

      recipientReceiveDeadline,
      validityThroughDate,

      deliveryInstructions,
      issuingBankRequirements,
      wordingOrFormatRequirements,
    };

    if (isNewItem) {
      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createMutation({
            variables: {
              proposalId,
              ...prepareData,
            },
          }),
        parseResult: (data: any) => {
          return data;
        },
      });

      return { result, isError, errors, bondId: result?.bondCreate?.id ?? '' };
    } else {
      const requiredFields: any[] = [];
      const omitKeys: string[] = [];
      const loadedKeys = Object.keys(prepareData);
      // const compareFunctionsKeys = Object.keys(compareFunctions);
      if (loadedBond) {
        Object.keys(prepareData).forEach((key) => {
          if (requiredFields.includes(key)) {
            return;
          }

          if (
            Array.isArray(prepareData[key as keyof typeof prepareData]) &&
            !prepareData[key as keyof typeof prepareData].length
          ) {
            omitKeys.push(key);
            return;
          }

          if (
            loadedKeys.includes(key) &&
            prepareData[key as keyof typeof prepareData] ===
              loadedBond[key as keyof typeof loadedBond]
          ) {
            omitKeys.push(key);
            return;
          }

          if (typeof loadedBond[key as keyof typeof loadedBond] == 'boolean') {
            if (
              !loadedBond[key as keyof typeof loadedBond] ===
              !prepareData[key as keyof typeof prepareData]
            ) {
              omitKeys.push(key);
              return;
            }
          }
        });
      }

      const saveData = omitKeys.length ? omit(prepareData, omitKeys) : prepareData;

      if (!Object.keys(saveData).length) {
        return { isError: false, bondId: bond.id! };
      }

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          updateMutation({
            variables: {
              bondId,
              ...saveData,
            },
          }),
        parseResult: (data: any) => {
          return data;
        },
      });

      return { result, isError, errors, bondId };
    }
  }, [
    proposalId,
    bond,
    loadedBond,
    isNewItem,
    openValidationResult,
    createMutation,
    updateMutation,
    bondId,
  ]);

  const showResult = useCallback(
    ({ result, isError, errors }: { result: any; isError: boolean; errors: any }) => {
      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });

        resetChanged && resetChanged();
        if (!bond?.id) {
          const id = result?.bondCreate?.id;
          history.push(
            paths.client.PROPOSAL_BID_PERFORMANCE_BOND_VIEW.replace(':id', proposalId).replace(
              ':bondId',
              id
            )
          );
        } else {
          loadBond();
        }
      }
    },
    [addSnackbar, history, bond, loadBond, resetChanged, proposalId]
  );

  const onDeleteProcess = useCallback(async () => {
    if (!bond) {
      return;
    }
    const { result, isError, errors } = await tryUpdateProcedure({
      mutation: () =>
        deleteMutation({
          variables: {
            bondId: bond.id,
          },
        }),
      parseResult: (data: any) => {
        return data;
      },
    });

    if (isError || !result) {
      addSnackbar!({
        text: 'Error...' + errors?.join(' '),
        severity: 'error',
      });
    } else {
      addSnackbar!({
        text: 'Success',
        severity: 'success',
      });

      resetChanged && resetChanged();

      history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', proposalId));
    }
    return !isError;
  }, [proposalId, bond, deleteMutation, addSnackbar, history, resetChanged]);

  if (!bond) {
    return <></>;
  }
  return (
    <div>
      <div>
        <FormValidationReport
          errors={{
            ...bond.errors,
          }}
        ></FormValidationReport>
        <ProposalBond
          bond={bond}
          proposalId={proposalId}
          setBond={setBondProxy}
          showValidator={showValidator}
          onValidateAndSubmit={onValidateAndSubmit}
          showResult={showResult}
          onDeleteProcess={onDeleteProcess}
          refetchFilesList={refetchFilesList}
          onLockProcess={onLock}
          onUnLockProcess={onUnLock}
        ></ProposalBond>
      </div>
    </div>
  );
};

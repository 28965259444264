import React from 'react';
import { ProposalFileManagement as ProposalFileManagementTemplate } from 'template/Opportunity/components/ProposalFileManagement/ProposalFileManagement';
import { ProposalFrame } from 'template/Opportunity/components/ProposalFrame/ProposalFrame';

export const ProposalFileManagement = () => {
  return (
    <ProposalFrame>
      <ProposalFileManagementTemplate></ProposalFileManagementTemplate>
    </ProposalFrame>
  );
};

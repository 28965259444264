import { FormValidationReportProvider } from 'components/FormValidationReport/FormValidationReportContext';
import { NewBondProvider } from 'template/NewBond/NewBondContext';
import { NewBondTemplate } from 'template/NewBond/NewBondTemplate';

export const NewBond = () => {
  return (
    <FormValidationReportProvider>
      <NewBondProvider>
        <NewBondTemplate />
      </NewBondProvider>
    </FormValidationReportProvider>
  );
};

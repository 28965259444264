import { useLazyQuery } from '@apollo/client';
import { PROPOSAL_MONETARY_BREAKDOWN_AGGREGATES } from 'graphql/proposals/aggregates';
import { monetaryBreakdownAggregates } from 'graphql/proposals/types/monetaryBreakdownAggregates';
import { useCallback, useEffect, useRef } from 'react';

export interface AggregatesByDivisionAndSuccessChance {
  year: number;
}

export const useAggregatesByDivisionAndSuccessChance = ({
  year,
}: AggregatesByDivisionAndSuccessChance) => {
  const [aggregatesByDivisionAndSuccessChance, { called, data, loading, error, refetch }] =
    useLazyQuery<monetaryBreakdownAggregates>(PROPOSAL_MONETARY_BREAKDOWN_AGGREGATES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadAggregatesByDivisionAndSuccessChance = useCallback(
    (year: number) => {
      const variables = {
        filter: {
          yearFrom: year,
          yearTo: year,
          // entityType: 'PROPOSAL',
          stages: ['DRAFT', 'PENDING'],
        },
        group: {
          successChance: true,
          owningDivision: true,
        },
        sort: [
          {
            column: 'OWNING_DIVISION_NAME',
            order: 'ASC',
          },
          {
            column: 'SUCCESS_CHANCE',
            order: 'ASC',
          },
        ],
      };

      if (called) {
        refetch!(variables);
      } else {
        aggregatesByDivisionAndSuccessChance({ variables });
      }
    },
    [aggregatesByDivisionAndSuccessChance, called, refetch]
  );

  const loadAggregatesByDivisionAndSuccessChanceRef = useRef(
    loadAggregatesByDivisionAndSuccessChance
  );

  useEffect(() => {
    loadAggregatesByDivisionAndSuccessChanceRef.current &&
      loadAggregatesByDivisionAndSuccessChanceRef.current(year);
  }, [year, loadAggregatesByDivisionAndSuccessChanceRef]);

  return {
    loadAggregatesByDivisionAndSuccessChance,
    data,
    loading,
    error,
  };
};

import { gql } from '@apollo/client';

export const GET_GEO_LOCATION = gql`
  query resolveGeoAddress(
    $countryName: String
    $addressZip: String
    $addressState: String
    $addressCity: String
    $streetAddress2: String
    $streetAddress: String
  ) {
    resolveGeoAddress(
      countryName: $countryName
      addressZip: $addressZip
      addressState: $addressState
      addressCity: $addressCity
      streetAddress2: $streetAddress2
      streetAddress: $streetAddress
    ) {
      geoX
      geoY
      geoData
    }
  }
`;

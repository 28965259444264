import s from './style.module.scss';
import { InsertDriveFile } from '@mui/icons-material';

export const FileDotIcon = ({
  fontSize,
  style,
}: {
  fontSize?: 'small' | 'large' | 'inherit' | 'medium';
  style?: any;
}) => {
  return (
    <div className={s.dot} style={style}>
      <div>
        <InsertDriveFile fontSize={fontSize ?? 'large'} className={s.fileIcon}></InsertDriveFile>
      </div>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useCallback } from 'react';

export const useUpload = ({ handleDocumentApproval, handleFiles }: any): any => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [files, setFiles] = useState<Record<string, any>[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const removeFile = useCallback(() => {
    setFiles([]);
  }, []);

  const handleOpenConfirmDialog = (): void => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = (confirm: boolean): void => {
    handleDocumentApproval(confirm);
    setOpenConfirmDialog(false);
  };

  return {
    isUploading,
    percentage,
    files,
    onDrop,
    removeFile,
    openConfirmDialog,
    setOpenConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
  };
};

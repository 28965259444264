import { useLazyQuery } from '@apollo/client';
import { GET_PROPOSAL_TIMESTAMP } from 'graphql/proposals/proposals';
import { proposalTimestamp } from 'graphql/proposals/types/proposalTimestamp';
import { useCallback, useEffect, useRef, useState } from 'react';

import { TIMESTAMP_CHECK_PERIOD } from 'constants/config';

export interface IProposalTimestampHook {
  proposalId: string;
}
export const useProposalTimestampHook = ({ proposalId }: IProposalTimestampHook) => {
  const [timestamp, setTimestamp] = useState<number | undefined>();
  const [lastCheck, setLastCheck] = useState<number | undefined>();

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [loadProposalTimestamp, { data, loading, refetch, called, error }] =
    useLazyQuery<proposalTimestamp>(GET_PROPOSAL_TIMESTAMP, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    });

  const loadTimestamp = useCallback(() => {
    const variables = {
      id: proposalId,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadProposalTimestamp({ variables });
    }
  }, [proposalId, called, loadProposalTimestamp, refetch]);

  useEffect(() => {
    if (data && !loading && !error) {
      const newTimestamp = new Date(data.proposal_proposal.updatedAt).getTime();
      if (timestamp !== newTimestamp) {
        setTimestamp(newTimestamp);
      }
    }
    if ((data || error) && !loading) {
      setLastCheck(Date.now());
    }
  }, [data, loading, error, timestamp]);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  }, []);

  const startTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setTimeout(() => {
      loadTimestamp();
    }, TIMESTAMP_CHECK_PERIOD);

    return () => {
      stopTimer();
    };
  }, [loadTimestamp, stopTimer]);

  useEffect(() => {
    startTimer();
  }, [lastCheck, startTimer]);

  useEffect(() => {
    if (proposalId) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [proposalId, startTimer, stopTimer]);

  return {
    startTimer,
    stopTimer,
    timestamp,
  };
};

import React, { FC } from 'react';
import { IStockExchangeData } from './interface';
import { SelectExchangeTile } from './components/SelectExchangeTile/SelectExchangeTile';
import s from './style.module.scss';

export interface IExchangesTilesProps {
  exchanges: IStockExchangeData[];
  onDelete: (index: number) => void;
  onUpdate: (index: number, value: Partial<IStockExchangeData>) => void;
  showValidator?: boolean;
}

export const ExchangesTiles: FC<IExchangesTilesProps> = ({
  exchanges,
  onUpdate,
  onDelete,
  showValidator,
}) => {
  let index = -1;

  const onDeleteIndex = (index: number) => () => onDelete(index);
  const onUpdateindex = (index: number) => (value: Partial<IStockExchangeData>) =>
    onUpdate(index, value);

  const error = showValidator && !exchanges.length;

  return (
    <div
      style={
        error
          ? {
              border: '1px solid red',
              minHeight: '50px',
              borderRadius: '5px',
              width: '95%',
              margin: '12px auto',
            }
          : undefined
      }
    >
      {exchanges.map((exchange) => {
        index++;
        return (
          <div key={index} className={s.dataBlock}>
            {exchange.stockMarket?.id ? (
              <SelectExchangeTile
                exchange={exchange}
                onRemoveExchange={onDeleteIndex(index)}
                onSelectChange={onUpdateindex(index)}
                error={!!showValidator}
              ></SelectExchangeTile>
            ) : (
              <SelectExchangeTile
                exchange={exchange}
                onRemoveExchange={onDeleteIndex(index)}
                onSelectChange={onUpdateindex(index)}
                error={!!showValidator}
              ></SelectExchangeTile>
            )}
          </div>
        );
      })}
    </div>
  );
};

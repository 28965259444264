import { ProposalStage } from 'graphql/proposals/types/graphql-types';

export const getWorkflowGeneralItem = (itemId: ProposalStage) => {
  const found = WorkflowStates.find((item) => item.id === itemId);
  if (!found) return undefined;
  return { id: itemId, name: found.name };
};

export const WorkflowStates: { id: ProposalStage; name: string; group: number }[] = [
  { id: ProposalStage.DRAFT, name: 'Draft', group: 1 },
  { id: ProposalStage.PENDING, name: 'Pending Decision', group: 2 },
  { id: ProposalStage.WON, name: 'Won', group: 2 },
  { id: ProposalStage.LOST, name: 'Loss', group: 2 },
  { id: ProposalStage.CANCELED, name: 'Canceled', group: 2 },
  { id: ProposalStage.POSTPONED, name: 'Postponed', group: 2 },
];

export const NewProposalWorkflowStates: { id: ProposalStage; name: string; group: number }[] = [
  { id: ProposalStage.DRAFT, name: 'Draft', group: 1 },
  { id: ProposalStage.PENDING, name: 'Pending Decision', group: 2 },
  { id: ProposalStage.WON, name: 'Won', group: 2 },
  { id: ProposalStage.LOST, name: 'Loss', group: 2 },
  { id: ProposalStage.POSTPONED, name: 'Postponed', group: 2 },
];

export interface WorkflowTransition {
  toState: ProposalStage;
  askForReason?: boolean;
  askForLoss?: boolean;
}

export const proposalWorkflow: Array<{ state: ProposalStage; to: WorkflowTransition[] }> = [
  {
    state: ProposalStage.DRAFT,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.PENDING,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.WON,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.LOST,
    to: [
      { toState: ProposalStage.PENDING, askForLoss: true },
      { toState: ProposalStage.WON, askForLoss: true },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.CANCELED,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
  {
    state: ProposalStage.POSTPONED,
    to: [
      { toState: ProposalStage.PENDING },
      { toState: ProposalStage.WON },
      { toState: ProposalStage.LOST, askForLoss: true },
      { toState: ProposalStage.CANCELED, askForReason: true },
      { toState: ProposalStage.POSTPONED, askForReason: true },
    ],
  },
];

import { IFieldsDescription } from 'template/Proposal/components/ProposalForm/interface';

export const customFields: Array<Array<IFieldsDescription>> = [
  [
    {
      id: 'name',
      title: 'Client Name',
    },
    {
      id: 'alias',
      title: 'Alias',
    },
    {
      id: 'type',
      title: 'Client / Non-Client',
    },
    {
      id: 'streetAddress',
      title: 'Address',
    },
    {
      id: 'streetAddress2',
      title: 'Address (cont.)',
    },
    {
      id: 'addressCity',
      title: 'City',
    },
    {
      id: 'addressState',
      title: 'State',
    },
    {
      id: 'addressZip',
      title: 'Zip code',
    },
    {
      id: 'notes',
      title: 'Notes',
    },
    {
      id: 'twitterUsername',
      title: 'Twitter Username',
    },
    {
      id: 'linkedInVanityName',
      title: 'LinkedIn Page',
    },
    {
      id: 'websiteUrl',
      title: 'WebSite URL',
    },
    {
      id: 'isActive',
      title: 'Active',
      field: { type: 'boolean' },
    },
    {
      id: 'selectedRelationshipParty',
      title: 'Associated Clients',
    },
    {
      id: 'addressCountryId',
      title: 'Country',
      field: { type: 'countryId' },
    },

    {
      id: 'selectedDesignation',
      title: 'Client Type',
    },
    {
      id: 'selectedFocalPointUser',
      title: 'D&M Client Focal Point',
    },
    {
      id: 'selectedDivisionOwnership',
      title: 'D&M Client Division Ownership',
    },
    {
      id: 'stockExchanges',
      title: 'Stock Exchanges',
    },
    {
      id: 'associatedClients',
      title: 'Associated Clients',
    },
  ],
];

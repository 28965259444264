import { ChartType } from 'components/Chart/Chart';

export interface IChartOptions {
  stacked?: boolean;
}
export const getChartOptions = ({ stacked }: IChartOptions) => ({
  responsive: true,
  animation: false,
  scales: {
    y: {
      beginAtZero: true,
      stacked: stacked,
    },
  },
  plugins: {
    title: false,
    subtitle: undefined,
    legend: true,
    datalabels: false,
  },
});

export interface IChartTypeOptions {
  type: ChartType;
  title: string;
}
export const chartTypeOptions: IChartTypeOptions[] = [
  { type: 'bar', title: 'Bar Chart' },
  // { type: 'bubble', title: 'Bubble Chart' },
  { type: 'scatter', title: 'Scatter Plot' },
  { type: 'line', title: 'Line Chart' },
  // { type: 'pie', title: 'Pie Chart' },
  // { type: 'doughnut', title: 'Doughnut Chart' },
  { type: 'polarArea', title: 'Area Chart' },
  { type: 'radar', title: 'Radar Chart' },
];

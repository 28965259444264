import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { Asterisks } from 'components/Asterisks/Asterisks';

export interface IFieldRow {
  title: string;
  tooltipTitle?: string;
  isRequired?: boolean;
  children?: any;
  md1?: number;
  md2?: number;
  lg1?: number;
  lg2?: number;
  columns?: number;
  titleOnly?: boolean;
  disabled?: boolean;
  locked?: boolean;
}

export const FieldRow: FC<IFieldRow> = ({
  title,
  tooltipTitle,
  isRequired,
  children,
  md1,
  md2,
  lg1,
  lg2,
  columns = 12,
  titleOnly,
  disabled,
  locked,
}) => {
  return (
    <>
      <Grid
        item
        xs={columns}
        md={md1 || 4}
        lg={lg1 || 5}
        sx={{ opacity: disabled ? '0.4' : locked ? '0.4' : undefined }}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Typography variant="body2" className="label-title-nocase">
            {title}
            {isRequired ? (
              <Asterisks
                count={1}
                tooltipTitle={tooltipTitle ?? `${title} is required`}
              ></Asterisks>
            ) : undefined}
            {!!title && !titleOnly ? ':' : undefined}
          </Typography>
          {disabled ? (
            <div style={{ padding: '0 2px' }}>
              <Typography variant="body2" className="label-title-desc">
                (disabled)
              </Typography>
            </div>
          ) : locked ? (
            <div style={{ padding: '0 2px' }}>
              <Typography variant="body2" className="label-title-desc">
                (locked)
              </Typography>
            </div>
          ) : undefined}
        </div>
      </Grid>
      {!titleOnly ? (
        <Grid
          item
          xs={columns}
          md={md2 || 8}
          lg={lg2 || 7}
          sx={{ opacity: disabled ? '0.4' : undefined }}
        >
          {children}
        </Grid>
      ) : undefined}
    </>
  );
};

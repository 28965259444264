import { ProposalReviewStatus } from 'graphql/proposals/types/graphql-types';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { changeProposalReviewStatusName } from 'template/Proposal/components/ProposalForm/constants/proposalReviewStatusOptions';
import { enumToArray } from 'utils/formats';

export const useProposalReviewStatus = () => {
  const proposalReviewStatusPairs = useMemo(() => {
    const arr = enumToArray(ProposalReviewStatus);
    const pairs = arr
      .map((item) => ({
        id: item,
        name: changeProposalReviewStatusName(startCase(toLower(item))),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return pairs;
  }, []);

  return { proposalReviewStatusPairs };
};

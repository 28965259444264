import { DataSource, IChartingData } from 'template/Charting/context/DataSourceConfig';

export const joinCsvTable = (data: IChartingData[], columns: DataSource[]) => {
  if (!data.length) {
    return [];
  }

  const pickColumns = columns.map((column) => column.fieldKey);

  const result: IChartingData = {
    csvData: data[0].csvData.map((row) => ({ ...row })),
    headings: data.reduce((acc, curr) => {
      Object.assign(acc, curr.headings);
      return acc;
    }, {}),
    sheetName: data.map(({ sheetName }) => sheetName).join(' // '),
  };

  if (data.length > 1) {
    const usedColumns = Object.keys(data[0].headings);
    // Expand with other tables
    for (let i = 1; i < data.length; i++) {
      const addColumns = Object.keys(data[i].headings).filter((key) => !usedColumns.includes(key));
      if (!pickColumns.some((col) => addColumns.includes(col))) {
        continue;
      }

      // create hash by id
      const hash: { [id: string]: { used: boolean; rows: any[] } } = {};
      result.csvData.forEach((row) => {
        if (hash[row.id]) {
          hash[row.id].rows.push(row);
        } else {
          hash[row.id] = { rows: [row], used: false };
        }
      });

      const newTable: any = [];

      // expand existing or add columns
      data[i].csvData.forEach((row) => {
        if (hash[row.id]) {
          hash[row.id].rows.forEach((baseRow) => newTable.push({ ...row, ...baseRow }));
          hash[row.id].used = true;
        } else {
          newTable.push({ ...row });
        }
      });

      // keep not used from previous step
      Object.keys(hash).forEach((id) => {
        if (!hash[id].used) {
          newTable.push(...hash[id].rows);
        }
      });

      result.csvData = newTable;
    }
  }
  return [result];
};

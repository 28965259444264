import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';

export const filtersExtensionTableHead: IHeadCell[] = [
  {
    id: 'newParty',
    numeric: false,
    disablePadding: true,
    label: 'Clients',
    filter: 'dropdown',
  },
  {
    id: 'partyCreatedAtPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Client Created',
    filter: 'date-range',
  },
  {
    id: 'createdAtPeriod',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Created',
    filter: 'date-range',
  },
];

import { ProposalStage } from 'graphql/proposals/types/graphql-types';

export interface IProposalProratedMonetaryBreakdown {
  id?: number | string;
  year: number;
  value: number;
  updatedAt: Date;
  isProrated?: boolean;
}

export interface IProrateMonetaryBreakdownProps {
  expectedAwardDate?: Date;
  stage: string;
  stageDate?: Date;
  monetaryBreakdowns: IProposalProratedMonetaryBreakdown[];
  bidProposedValue?: number | null | string;
  proposalCreatedAt: Date | string;
}

export interface IProrateMonetaryBreakdownCalculateProps {
  expectedAwardDate?: Date;
  stage: string;
  stageDate?: Date;
  monetaryBreakdowns: IProposalProratedMonetaryBreakdown[];
  bidProposedValue?: number | null;
  proposalCreatedAt: Date;
}

export const prorateMonetaryBreakdown = ({
  expectedAwardDate,
  stage,
  stageDate,
  monetaryBreakdowns,
  bidProposedValue,
  proposalCreatedAt,
}: IProrateMonetaryBreakdownProps) => {
  return prorateMonetaryBreakdownCalculate({
    expectedAwardDate: fixDate(expectedAwardDate),
    stage,
    stageDate: fixDate(stageDate),
    monetaryBreakdowns: monetaryBreakdowns.map((mb) => {
      const { year, value, updatedAt } = mb;
      return {
        ...mb,
        year: fixInt(year),
        value: fixFloat(value)!,
        updatedAt: fixDate(updatedAt)!,
      };
    }),
    bidProposedValue: fixFloat(bidProposedValue),
    proposalCreatedAt: fixDate(proposalCreatedAt)!,
  });
};

const prorateMonetaryBreakdownCalculate = ({
  expectedAwardDate,
  stage,
  stageDate,
  monetaryBreakdowns: inputMonetaryBreakdowns,
  bidProposedValue,
  proposalCreatedAt,
}: IProrateMonetaryBreakdownCalculateProps) => {
  let monetaryBreakdowns: IProposalProratedMonetaryBreakdown[] = inputMonetaryBreakdowns;

  const finalStageDate = [ProposalStage.WON, ProposalStage.LOST, ProposalStage.CANCELED].includes(
    stage as ProposalStage
  )
    ? stageDate
    : undefined;

  const proratingDate: Date = getLatestDate([expectedAwardDate, finalStageDate || new Date()])!;

  const proratingDateY = proratingDate.getUTCFullYear();

  if (!monetaryBreakdowns?.length) {
    if (bidProposedValue == null) {
      return [];
    }

    monetaryBreakdowns = [
      {
        updatedAt: proposalCreatedAt,
        year: proposalCreatedAt.getUTCFullYear(),
        value: bidProposedValue,
      },
    ];
  }

  const proratingDateSOY = new Date(proratingDate.getUTCFullYear(), 0);
  const updateDate = getLatestDate(monetaryBreakdowns.map((mb) => mb.updatedAt));

  const enterDate: Date = getLatestDate([updateDate, proratingDateSOY, expectedAwardDate])!;

  // prepare object, key by year
  const activeMonetaryBreakdownsByYear: {
    [year: number]: IProposalProratedMonetaryBreakdown;
  } = monetaryBreakdowns
    .filter((bd) => bd.year >= proratingDateY)
    .reduce(
      (acc, bd) => ({
        ...acc,
        [bd.year]: {
          year: bd.year,
          value: typeof bd.value === 'number' ? bd.value : parseFloat(bd.value),
          updatedAt: new Date(bd.updatedAt),
        },
      }),
      {} as {
        [year: number]: IProposalProratedMonetaryBreakdown;
      }
    );

  // Accumulate previous years
  const accPreviousYears = monetaryBreakdowns
    .filter((bd) => bd.year < proratingDateY)
    .reduce((acc, bd) => acc + (typeof bd.value === 'number' ? bd.value : parseFloat(bd.value)), 0);

  if (accPreviousYears) {
    activeMonetaryBreakdownsByYear[proratingDateY] = activeMonetaryBreakdownsByYear[proratingDateY]
      ? ({
          ...activeMonetaryBreakdownsByYear[proratingDateY],
          value: activeMonetaryBreakdownsByYear[proratingDateY].value + accPreviousYears,
          isProrated: true,
        } as IProposalProratedMonetaryBreakdown)
      : ({
          year: proratingDateY,
          value: accPreviousYears,
          isProrated: true,
          updatedAt: new Date(),
        } as IProposalProratedMonetaryBreakdown);
  }

  // Prorating active only if we have current yaear data and data is not updated after prorating date
  if (activeMonetaryBreakdownsByYear.hasOwnProperty(proratingDateY) && enterDate < proratingDate) {
    let divider;
    let passedMonths;

    if (!accPreviousYears && enterDate.getUTCFullYear() === proratingDateY) {
      const updatedAtMonth = enterDate.getMonth();
      passedMonths = proratingDate!.getMonth() - updatedAtMonth;
      divider = 12 - updatedAtMonth;
    } else {
      passedMonths = proratingDate!.getMonth();
      divider = 12;
    }

    // Prorating active only if the data is older then one month
    if (passedMonths !== 0) {
      // transfer to the next year
      const passValue = parseFloat(
        ((activeMonetaryBreakdownsByYear[proratingDateY].value * passedMonths) / divider).toFixed(2)
      );

      // Keep in the current year
      const keepValue = activeMonetaryBreakdownsByYear[proratingDateY].value - passValue;

      activeMonetaryBreakdownsByYear[proratingDateY] = {
        ...activeMonetaryBreakdownsByYear[proratingDateY],
        value: keepValue,
        isProrated: true,
      };

      if (activeMonetaryBreakdownsByYear.hasOwnProperty(proratingDateY + 1)) {
        activeMonetaryBreakdownsByYear[proratingDateY + 1] = {
          ...activeMonetaryBreakdownsByYear[proratingDateY + 1],
          value: passValue + activeMonetaryBreakdownsByYear[proratingDateY + 1].value,
          isProrated: true,
        };
      } else {
        activeMonetaryBreakdownsByYear[proratingDateY + 1] = {
          year: proratingDateY + 1,
          value: passValue,
          updatedAt: new Date(),
          isProrated: true,
        };
      }
    }
  }

  const proratedBreakdown: any[] = [];
  for (const key of Object.keys(activeMonetaryBreakdownsByYear)
    .map((key) => parseInt(key))
    .sort()) {
    proratedBreakdown.push(activeMonetaryBreakdownsByYear[key]);
  }

  return proratedBreakdown;
};

const getLatestDate = (dates: (Date | undefined | null)[] | undefined) => {
  if (!dates?.length) {
    return undefined;
  }
  return dates.reduce((acc, date) => {
    if (!acc) return date;
    return date && date > acc ? date : acc;
  }, undefined as Date | undefined);
};

const fixDate = (date?: string | Date | null) =>
  typeof date === 'string' ? (date !== 'N/A' ? new Date(date) : undefined) : date ?? undefined;

const fixInt = (value: string | number) => (typeof value === 'string' ? parseInt(value) : value);

const fixFloat = (value?: string | number | null) =>
  typeof value === 'string'
    ? value !== 'N/A'
      ? parseFloat(value)
      : undefined
    : value ?? undefined;

import { IChartHeadCell } from 'components/ui/Table/components/TableHead/TableHead';

import { proposalTypeOptions } from 'template/Proposal/components/ProposalForm/constants/proposalTypeOptions';
import { capitalizeAllWordsFirstLetterUS, capitalizeFirstLetter } from 'utils/formats';
import { ProposalFileGroup } from 'graphql/proposals/types/graphql-types';
import { ChartingFieldType } from 'template/Charting/Components/SelectDataSource/constants';

export const proposalsExportToChartHead: IChartHeadCell[] = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'id',
  },

  // Stage
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Stage',
    fieldType: ChartingFieldType.category,
  },
  // Owners
  {
    id: 'owners',
    numeric: false,
    disablePadding: false,
    label: 'Owners',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      return data
        ?.map((owner: any) => owner.user.name)
        .sort((a: string, b: string) => a.localeCompare(b))
        .join(',');
    },
  },
  // Created Date
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    fieldType: ChartingFieldType.date,

    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  // Last Modified Date
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified Date',
    fieldType: ChartingFieldType.date,

    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'activity',
    numeric: false,
    disablePadding: false,
    label: 'Activity',
    fieldType: ChartingFieldType.category,
    group: 1,

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : ''),
  },

  // *********************
  // BASIC DETAILS
  // *********************

  // Business Proposal ID
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Business Proposal ID',
    fieldType: ChartingFieldType.numeric,
  },

  // Type
  {
    id: 'proposalType',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Type',
    fieldType: ChartingFieldType.category,
    group: 2,

    transformFunction: (proposalType) => {
      const found = proposalTypeOptions.find((item) => item.id === proposalType);
      return proposalType ? (found ? found.name : proposalType) : '';
    },
  },

  // // Proposal Name
  // {
  //   id: 'name',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Proposal Name',
  // },

  // Scope of Work (description)
  // {
  //   id: 'scopeOfWorkDescription',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Scope of Work (description)',
  //   transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '',
  //   excelRowStyle: {
  //     alignment: {
  //       wrapText: true,
  //     },
  //   },
  // },

  // Client Name
  {
    id: 'party',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    fieldType: ChartingFieldType.category,
    group: 1,

    transformFunction: (data) =>
      data
        ? (data.projectSetupClientCode ? data.projectSetupClientCode + ' - ' : '') + data.name
        : '',
  },

  // New Client
  {
    id: 'newParty',
    numeric: false,
    disablePadding: false,
    label: 'New Client',
    fieldType: ChartingFieldType.category,

    transformFunction: (_, row) => {
      if (!row?.party) {
        return '';
      } else {
        return row?.party?.projectSetupClientCode
          ? 'No'
          : 'Yes - Created on ' +
              new Date(row?.party?.createdAt).toLocaleDateString('en-EN', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              });
      }
    },
  },

  // Contact on Client side
  // {
  //   id: 'partyContacts',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Contact on Client side',

  //   transformFunction: (data) =>
  //     data?.length
  //       ? data
  //           .map((contact: any) => {
  //             const parts: Array<any> = [];

  //             if (contact.firstName || contact.lastName) {
  //               parts.push({
  //                 name: 'Name',
  //                 value:
  //                   (contact.firstName ? contact.firstName + ' ' : '') + (contact.lastName ?? ''),
  //               });
  //             }
  //             if (contact.email) {
  //               parts.push({ name: 'Email', value: contact.email });
  //             }
  //             if (contact.jobTitle) {
  //               parts.push({ name: 'Job Title', value: contact.jobTitle });
  //             }
  //             if (contact.phone) {
  //               parts.push({ name: 'Phone', value: contact.phone });
  //             }

  //             return parts
  //               .map((part) => part.name + ': ' + part.value)
  //               .join(String.fromCharCode(10));
  //           })
  //           .filter((contact: any) => !!contact)
  //
  //       : '',
  // },

  // D&M Client Division Ownership
  {
    id: 'referencePartyDivisionOwnership',
    numeric: false,
    disablePadding: false,
    label: 'D&M Client Division Ownership',
    fieldType: ChartingFieldType.category,

    transformFunction: (_, row) =>
      row?.party?.divisionOwnership?.name ? row.party.divisionOwnership.name : '',
  },

  // D&M Client Focal Point
  // {
  //   id: 'referencePartyFocalPointUser',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'D&M Client Focal Point',

  //   transformFunction: (data) => {
  //     let content: any[] = [];
  //     if (data?.user?.name) {
  //       content.push({ name: 'Phone', value: data?.user?.name });
  //     }
  //     if (data?.user?.email) {
  //       content.push({ name: 'Email', value: data?.user?.email });
  //     }
  //     return content.length
  //       ? content.map((part) => part.name + ': ' + part.value).join(String.fromCharCode(10))
  //       : '';
  //   },
  // },

  // Business Decisions to Consider
  // {
  //   id: 'businessDecisions',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Business Decisions to Consider',
  //   transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '',

  // },

  // Chance of Success
  {
    id: 'successChance',
    numeric: false,
    disablePadding: false,
    label: 'Chances of Success',
    fieldType: ChartingFieldType.category,
    group: 1,

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : ''),
  },

  // Assigned Division
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Assigned Division',
    transformFunction: (data) => data?.division?.name || '',
    fieldType: ChartingFieldType.category,
    group: 1,
  },

  // D&M Divisions Participating
  {
    id: 'participatingDivisions',
    numeric: false,
    disablePadding: false,
    label: 'D&M Divisions Participating',
    fieldType: ChartingFieldType.categoryArray,
    transformFunction: (data) =>
      data?.length
        ? data
            .map((item: any) => (item.division?.name || '') as string)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : '',
  },

  // Proposal Received Date
  {
    id: 'receiveDate',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Received Date',
    fieldType: ChartingFieldType.date,

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
  },

  // Proposal Due Date
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Due Date',
    fieldType: ChartingFieldType.date,

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
  },

  // Submission Method
  {
    id: 'submissionMethods',
    numeric: false,
    disablePadding: false,
    label: 'Submission Method',
    fieldType: ChartingFieldType.categoryArray,
    group: 2,

    transformFunction: (data) =>
      data?.length
        ? data
            .map((item: any) => (item.name || '') as string)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : '',
  },

  // D&M Proposal Lead
  // {
  //   id: 'leadUser',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Proposal Lead',
  //   transformFunction: transformProposalLeadText,
  // },

  // D&M Individuals Involved in Proposal
  // {
  //   id: 'individualsInvolved',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'D&M Individuals Involved in Proposal',

  //   excelRowStyle: {
  //     alignment: {
  //       wrapText: true,
  //     },
  //   },

  //   transformFunction: (data) =>
  //     data?.length
  //       ? data
  //           .map((item: any) => {
  //             const parts: Array<any> = [];

  //             if (item.user?.name) {
  //               parts.push({
  //                 name: 'Name',
  //                 value: item.user?.name,
  //               });
  //             }
  //             if (item.user?.email) {
  //               parts.push({
  //                 name: 'Email',
  //                 value: item.user?.email,
  //               });
  //             }

  //             return parts
  //               .map((part) => part.name + ': ' + part.value)
  //               .join(String.fromCharCode(10));
  //           })
  //           .filter((item: any) => !!item)
  //
  //       : '',
  // },

  // Expected Award Date
  {
    id: 'expectedAwardDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Award Date',
    fieldType: ChartingFieldType.date,
    group: 1,

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
  },

  // Expected Project Start Date
  {
    id: 'expectedProjectStartDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Project Start Date',
    fieldType: ChartingFieldType.date,

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
  },

  // Expected Project End Date
  {
    id: 'expectedProjectEndDate',
    numeric: false,
    disablePadding: false,
    label: 'Expected Project End Date',
    fieldType: ChartingFieldType.date,

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
  },

  // *********************
  // ADDITIONAL DETAILS
  // *********************

  // Project Countries
  {
    id: 'countries',
    numeric: false,
    disablePadding: false,
    label: 'Project Countries',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name + '(' + item.code + ')')
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
  },

  // Project Fields
  {
    id: 'fields',
    numeric: false,
    disablePadding: false,
    label: 'Project Fields',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
  },

  // Billing Origin
  {
    id: 'billingOrigins',
    numeric: false,
    disablePadding: false,
    label: 'Billing Origin',
    fieldType: ChartingFieldType.categoryArray,
    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
  },

  // Billing Basis
  {
    id: 'billingBases',
    numeric: false,
    disablePadding: false,
    label: 'Billing Basis',
    fieldType: ChartingFieldType.categoryArray,
    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
  },

  // Potential Revenue by Area
  {
    id: 'revenueAreas',
    numeric: false,
    disablePadding: false,
    label: 'Potential Revenue by Area',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
    group: 2,
  },

  // Number of Mature Fields
  {
    id: 'numberOfMatureFields',
    numeric: false,
    disablePadding: false,
    label: 'Number of Mature Fields',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data) => (data ? data : ''),
  },

  // Number of Green Fields
  {
    id: 'numberOfGreenFields',
    numeric: false,
    disablePadding: false,
    label: 'Number of Green Fields',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data) => (data ? data : ''),
  },

  // Work Type
  {
    id: 'workTypes',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },

    group: 2,
  },

  // Work Type Other Detail
  {
    id: 'workTypeDetails',
    numeric: false,
    disablePadding: false,
    label: 'Work Type Other Detail',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '',
  },

  // Evaluation Type
  {
    id: 'evaluationType',
    numeric: false,
    disablePadding: false,
    label: 'Evaluation Type',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => (data?.name ? data.name : ''),
  },

  // Budget Basis Type
  {
    id: 'budgetBasis',
    numeric: false,
    disablePadding: false,
    label: 'Budget Basis',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => (data?.name ? data.name : ''),
  },

  // Guideline Code
  {
    id: 'guidelineCode',
    numeric: false,
    disablePadding: false,
    label: 'Guideline',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => (data?.name ? data.name : ''),
  },

  // Proposal Fees (net to D&M)
  {
    id: 'feesValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Fees (net to D&M)',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data: any) => (data ? data : ''),
  },

  // Best Technical Estimate
  {
    id: 'bestTechnicalEstimateValue',
    numeric: true,
    disablePadding: false,
    label: 'Best Technical Estimate',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data: any) => (data ? data : ''),
  },

  // Proposal Expenses (net to D&M)
  {
    id: 'expensesValue',
    numeric: true,
    disablePadding: false,
    label: 'Proposal Expenses (net to D&M)',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data: any) => (data ? data : ''),
  },

  // Bid Price Proposed
  {
    id: 'bidProposedValue',
    numeric: true,
    disablePadding: false,
    label: 'Bid Price Proposed',
    fieldType: ChartingFieldType.numeric,
    group: 1,

    transformFunction: (data: any) => (data ? data : ''),
  },

  // Bid Price Final
  {
    id: 'bidFinalValue',
    numeric: true,
    disablePadding: false,
    label: 'Bid Price Final',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data: any) => (data ? data : ''),
  },

  // Average Hourly Rate Quoted
  {
    id: 'averageHourlyRate',
    numeric: true,
    disablePadding: false,
    label: 'Average Hourly Rate Quoted',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (data: any) => (data ? data : ''),
  },

  // Total Hours Estimated
  {
    id: 'estimatedTotalHours',
    numeric: false,
    disablePadding: false,
    label: 'Total Hours Estimated',
    fieldType: ChartingFieldType.numeric,
  },

  // Deliverables
  {
    id: 'deliverables',
    numeric: false,
    disablePadding: false,
    label: 'Deliverables',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
  },

  // Types of Reports
  {
    id: 'proposalReportTypes',
    numeric: false,
    disablePadding: false,
    label: 'Types of Reports',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) => {
      const dataNames: string[] = data
        ? data
            .map((item: any) => item.name)
            .sort((a: string, b: string) => a.localeCompare(b))
            .join(',')
        : undefined;
      return dataNames || '';
    },
  },

  // Figures Required
  {
    id: 'figuresRequired',
    numeric: false,
    disablePadding: false,
    label: 'Figures Required',
    fieldType: ChartingFieldType.category,

    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },

  // Potential Team Members
  {
    id: 'potentialTeamMembers',
    numeric: false,
    disablePadding: false,
    label: 'Potential Team Members',
    fieldType: ChartingFieldType.categoryArray,

    transformFunction: (data) =>
      data?.length
        ? data
            .map((teamMember: any) => {
              if (!teamMember.user) {
                return undefined;
              }

              const { user } = teamMember;
              const parts: Array<any> = [];

              if (user.name) {
                parts.push({
                  name: 'Name',
                  value: user.name,
                });
              }
              if (user.email) {
                parts.push({ name: 'Email', value: user.email });
              }

              return parts.map((part) => part.name + ': ' + part.value).join(',');
            })
            .filter((contact: any) => !!contact) || ''
        : '',
  },

  // Notes
  // {
  //   id: 'notes',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Notes',
  //   transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || '',

  // },

  // Travel Required
  {
    id: 'travelRequired',
    numeric: false,
    disablePadding: false,
    label: 'Travel Required',
    fieldType: ChartingFieldType.category,

    transformFunction: (data) => (data ? 'Yes' : 'No'),
  },

  // Price Type
  {
    id: 'priceType',
    numeric: false,
    disablePadding: false,
    label: 'Price Type',
    fieldType: ChartingFieldType.category,

    transformFunction: (data) => (data ? capitalizeAllWordsFirstLetterUS(data.toLowerCase()) : ''),
  },

  // Reviewers...
  // {
  //   id: 'pendingReviewers',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Reviewing',

  //   transformFunction: transformCurrentlyReviewingText,

  // },

  // ...& Review State
  {
    id: 'reviewStatus',
    numeric: false,
    disablePadding: false,
    label: 'Review State',
    fieldType: ChartingFieldType.category,

    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : ''),
  },

  // Competitor Details
  // {
  //   id: 'competitors',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Competitor Details',

  //   transformFunction: (data) =>
  //     data?.length
  //       ? data
  //           .map((competitor: any) => {
  //             const parts: Array<any> = [];

  //             if (competitor?.competitor?.name) {
  //               parts.push({
  //                 name: 'Name',
  //                 value: competitor.competitor.name,
  //               });
  //             }
  //             if (competitor.bidValue) {
  //               parts.push({
  //                 name: 'Bid Value',
  //                 value: formatCurrencyUSD(parseFloat(competitor.bidValue)),
  //               });
  //             }
  //             if (!!competitor) {
  //               parts.push({ name: 'Winning Bid', value: competitor.hasWonBid ? 'Yes' : 'No' });
  //             }

  //             return parts
  //               .map((part) => part.name + ': ' + part.value)
  //               .join(String.fromCharCode(10));
  //           })
  //           .filter((contact: any) => !!contact)

  //       : '',
  // },

  // *********************************
  // Proposal Documents & Reviewers
  // *********************************

  {
    id: 'documents.clientDocumentsCount',
    numeric: true,
    disablePadding: false,
    label: 'Number of Client Documents',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (_, row) => {
      return (
        row?.documents?.filter(
          (document: any) => document.proposalFileGroup === ProposalFileGroup.CLIENT_DOCUMENT
        ).length || 0
      );
    },
  },

  // Number of D&M Submitted Documents
  {
    id: 'documents.D&MSubmittedDocuments',
    numeric: true,
    disablePadding: false,
    label: 'Number of D&M Submitted Documents',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (_, row) => {
      return (
        row?.documents?.filter(
          (document: any) =>
            document.proposalFileGroup === ProposalFileGroup.DEMAC_SUBMITTED_DOCUMENT
        ).length || 0
      );
    },
  },

  // Number of D&M Supporting Documents
  {
    id: 'documents.D&MSupportingDocuments',
    numeric: true,
    disablePadding: false,
    label: 'Number of D&M Supporting Documents',
    fieldType: ChartingFieldType.numeric,

    transformFunction: (_, row) => {
      return (
        row?.documents?.filter(
          (document: any) =>
            document.proposalFileGroup === ProposalFileGroup.DEMAC_SUPPORTING_DOCUMENT
        ).length || 0
      );
    },
  },

  // Documents Approved
  // {
  //   id: 'documents.documentsApproved',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Documents Approved',

  //   transformFunction: (_, row) => {
  //     const approvedOnceOrMore: { [id: string]: boolean } = {};
  //     const notApprovedOnceOrMore: { [id: string]: boolean } = {};

  //     row?.reviewers.map((reviewer: any) =>
  //       reviewer.reviewerAssignedDocuments.map((doc: any) => {
  //         if (doc.reviewStatus === 'REVIEW_APPROVED') {
  //           approvedOnceOrMore[doc.proposalDocumentId] = true;
  //         } else {
  //           notApprovedOnceOrMore[doc.proposalDocumentId] = true;
  //         }
  //         return undefined;
  //       })
  //     );

  //     const approvedDocuments = row?.documents?.filter(
  //       (document: any) => approvedOnceOrMore[document.id] && !notApprovedOnceOrMore[document.id]
  //     );

  //     return (
  //       approvedDocuments
  //         ?.map((document: any) => {
  //           const proposalFileGroup = capitalizeAllWordsFirstLetterUS(
  //             document.proposalFileGroup.toLowerCase().replaceAll('_', ' ')
  //           ).replaceAll('Demac', 'D&M');

  //           const fileName =
  //             document.originalFilename ??
  //             document.versions?.slice(-1)[0].file?.originalFilename ??
  //             '<unknown>';

  //           // const version = document.versions?.slice(-1)[0].version ?? 0;

  //           return (
  //             fileName +
  //             // + ' V1.' + version
  //             ' (' +
  //             proposalFileGroup +
  //             ')'
  //           );
  //         })
  //         .join(String.fromCharCode(10)) || ''
  //     );
  //   },
  // },
];

import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { filtersExtensionTableHead } from 'constants/filtersExtensionTable';
import { proposalsTableHead } from 'constants/proposalsTable';

import { GET_PROPOSALS, GET_PROPOSAL_COUNT } from 'graphql/proposals/proposals';
import { ProposalEntityType, ProposalFilter } from 'graphql/proposals/types/graphql-types';
import { proposalCount } from 'graphql/proposals/types/proposalCount';
import { proposals } from 'graphql/proposals/types/proposals';
import { pick } from 'lodash';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { withApolloErrorHandler } from 'utils/apolloErrorHandler';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPageLoadParams {
  order?: SortOrder;
  orderBy?: string | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
}

export interface IProposalsTableHookProps {
  initFilter?: Partial<ProposalFilter>;
  overridePageLoadParams?: any;
  tableStorageKey: string;
  initRowsPerPage?: number;
}

export interface IProposalsTableHookValue {
  totalItems: number;
  proposals: any;
  loadPage: (
    order: SortOrder,
    orderBy: string | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  clearAllFilters: () => void;
  filterApplied: any;
  pageLoadParams: any;
}

const defaultFilterValues = {
  newParty: { id: 'All', name: 'All' },
  bidProposedValue: { id: 'All', name: 'All' },
  entityType: ProposalEntityType.PROPOSAL,
};

const useProposalsTableHookLocal = ({
  initFilter,
  overridePageLoadParams,
  tableStorageKey,
  initRowsPerPage,
}: IProposalsTableHookProps): IProposalsTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey,
  });

  const [totalItems, setTotalItems] = useState(0);
  const [proposals, setProposals] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: initRowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: initFilter,
    ...getItem(),
  });

  const [loadProposals, { called, data, loading, error, refetch }] = useLazyQuery<proposals>(
    GET_PROPOSALS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    loadProposalsCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCounter,
      refetch: refetchCount,
    },
  ] = useLazyQuery<proposalCount>(GET_PROPOSAL_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      setPageLoadParams((initial) => ({
        ...initial,
        ...overridePageLoadParams,
        filter: { ...initial.filter, ...overridePageLoadParams.filter },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    const { rowsPerPage, page, orderBy, order, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter: { ...filter },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadProposals({ variables });
    }
  }, [loadProposals, refetch, called, pageLoadParams]);

  useEffect(() => {
    if (!data && !loading && error) {
      setProposals([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const { filter } = pageLoadParams;

    const variables = {
      filter: { ...filter },
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadProposalsCount({ variables });
    }
  }, [loadProposalsCount, refetchCount, calledCount, pageLoadParams]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(dataCount.proposal_proposalCount);
    }
  }, [dataCount, loadingCount]);

  useEffect(() => {
    if (!loading && data) {
      setProposals(() => [...data.proposal_proposals]);
    }
  }, [data, loading]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (order: SortOrder, orderBy: string | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        order,
        orderBy,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const convertSelectedToIds = useCallback((item: { id: string } | undefined) => {
    return item && item.id !== 'All' ? [item.id] : undefined;
  }, []);

  const clearAllFilters = () => {
    onFilterChange(defaultFilterValues);
  };

  const onFilterChange = useCallback(
    (filterValues: any) => {
      const sada = new Date();

      sada.setFullYear(sada.getFullYear() - 1);

      if (pageLoadParams) {
        const bidProposedValueSelected = convertSelectedToIds(filterValues.bidProposedValue);
        const bidPair = bidProposedValueSelected
          ? bidProposedValueSelected[0].split('-')
          : [null, null];
        const newFilter: any = {
          nameContains: filterValues.name || undefined,
          stages: filterValues.stage || undefined,
          clientNameContains: filterValues.party || undefined,
          owningDivisionIds: filterValues.owningDivision || undefined,
          proposalTypes: filterValues.proposalType || undefined,
          reviewStatuses: filterValues.reviewStatus || undefined,
          proposalUserLeadNameContains: filterValues.leadUser || undefined,
          successChances: filterValues.successChance || undefined,
          dueDate: filterValues.dueDate ? new Date(filterValues.dueDate).toISOString() : undefined,
          workTypeIds: filterValues.workTypes || undefined,
          bidProposedValueLow: bidPair[0] ? parseFloat(bidPair[0]) : undefined,
          bidProposedValueHigh:
            bidPair[1] && bidPair[1] !== '0' ? parseFloat(bidPair[1]) : undefined,
          entityType: filterValues.entityType || undefined,
          pendingReviewersNameContains: filterValues.pendingReviewers || undefined,
          createdAt: filterValues.createdAt
            ? new Date(filterValues.createdAt).toISOString()
            : undefined,
          updatedAt: filterValues.updatedAt
            ? new Date(filterValues.updatedAt).toISOString()
            : undefined,
          activities: filterValues.activity || undefined,
          partyCreatedAtFrom: undefined,
          partyCreatedAtTo: undefined,
          createdAtFrom: undefined,
          createdAtTo: undefined,
        };

        if (filterValues?.newParty?.id !== 'All') {
          const yearFromNow = new Date();
          yearFromNow.setFullYear(yearFromNow.getFullYear() - 1);
          yearFromNow.setHours(0, 0, 0, 0);

          const currentYear = new Date().getFullYear();

          if (filterValues?.newParty?.id === 'YTD') {
            newFilter.partyCreatedAtFrom = yearFromNow;
          }

          if (filterValues?.newParty?.id === 'Year') {
            newFilter.partyCreatedAtFrom = new Date(currentYear, 0, 1);
          }

          if (filterValues?.newParty?.id === 'Custom') {
            if (filterValues.partyCreatedAtPeriod?.from) {
              newFilter.partyCreatedAtFrom = new Date(filterValues.partyCreatedAtPeriod.from);
            } else {
              newFilter.partyCreatedAtFrom = new Date(0);
            }

            if (filterValues.partyCreatedAtPeriod?.to) {
              const partyCreatedAtPeriodTo = new Date(filterValues.partyCreatedAtPeriod.to);
              partyCreatedAtPeriodTo.setDate(partyCreatedAtPeriodTo.getDate() + 1);
              newFilter.partyCreatedAtTo = partyCreatedAtPeriodTo;
            }
          }
        }

        if (filterValues.createdAtPeriod?.from) {
          newFilter.createdAtFrom = new Date(filterValues.createdAtPeriod.from);
        }

        if (filterValues.createdAtPeriod?.to) {
          const createdAtPeriodTo = new Date(filterValues.createdAtPeriod.to);
          createdAtPeriodTo.setDate(createdAtPeriodTo.getDate() + 1);
          newFilter.createdAtTo = createdAtPeriodTo;
        }

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, convertSelectedToIds]
  );

  const filterApplied = useMemo(() => {
    const { filterValues } = pageLoadParams;

    const pickFiltersExtensionTableHead =
      filterValues?.newParty.id !== 'Custom'
        ? filtersExtensionTableHead.filter((item) => item.id !== 'partyCreatedAtPeriod')
        : filtersExtensionTableHead;

    const columnsWithFilter = [...proposalsTableHead, ...pickFiltersExtensionTableHead]
      .filter((item) => !!item.filter)
      .map((item) => item.id);

    return (
      JSON.stringify(pick(filterValues, columnsWithFilter)) !==
      JSON.stringify(pick(defaultFilterValues, columnsWithFilter))
    );
  }, [pageLoadParams]);

  return {
    totalItems,
    proposals,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: error || errorCounter,
    clearAllFilters,
  };
};

export const useProposalsTableHook = withApolloErrorHandler(useProposalsTableHookLocal);

import React, { createContext, FC, useContext, useMemo } from 'react';

import { useAggregatesByDivisionAndStage } from './hooks/aggregatesByDivisionAndStageHook';
import { useAggregatesTotal } from './hooks/aggregatesTotalHook';
import { useAggregatesByStage } from './hooks/aggregatesByStageHook';
import { proposalAggregates } from 'graphql/proposals/types/proposalAggregates';
import { useAggregatesByDivision } from './hooks/aggregatesByDivisionHook';

export interface IProposalAggregatesContextState {
  aggregatesByDivisionAndStage?: proposalAggregates;
  aggregatesByStage?: proposalAggregates;
  aggregatesByDivision?: proposalAggregates;
  aggregatesTotal?: proposalAggregates;

  year?: number;
}

export interface IProposalAggregatesContextActions {}

const initialState: IProposalAggregatesContextState = {};

const ProposalAggregatesContext = createContext<
  IProposalAggregatesContextState & Partial<IProposalAggregatesContextActions>
>(initialState);

interface IProposalAggregatesProviderProps {
  children: any;
  year: number;
}

export const ProposalAggregatesProvider: FC<IProposalAggregatesProviderProps> = ({
  children,
  year,
}) => {
  const { data: aggregatesByDivisionAndStage } = useAggregatesByDivisionAndStage({ year });
  const { data: aggregatesByStage } = useAggregatesByStage({ year });
  const { data: aggregatesByDivision } = useAggregatesByDivision({ year });
  const { data: aggregatesTotal } = useAggregatesTotal({ year });

  const values = useMemo(
    () => ({
      aggregatesByDivisionAndStage,
      aggregatesByStage,
      aggregatesByDivision,
      aggregatesTotal,
      year,
    }),
    [aggregatesByDivisionAndStage, aggregatesByStage, aggregatesByDivision, aggregatesTotal, year]
  );
  return (
    <ProposalAggregatesContext.Provider value={values}>
      {children}
    </ProposalAggregatesContext.Provider>
  );
};

export const useProposalAggregatesContext = () => useContext(ProposalAggregatesContext);

import EasyAutoComplete from 'components/EasyAutoComplete';
import { useSelectClient } from 'hooks/selectClientHook';
import { FC, useEffect, useState } from 'react';

export interface IPartyListItem {
  key: string;
  name: string;
  ddLabel: string;
}

export interface ISelectPartyProps {
  onSelectChange: (selected?: IPartyListItem) => void;
  selected?: IPartyListItem;
  disabled: boolean;
  error?: boolean;
}

export const SelectParty: FC<ISelectPartyProps> = ({
  selected,
  onSelectChange,
  disabled,
  error,
}) => {
  const clientProps = useSelectClient({
    error: false,
  });

  const [selectedItem, setSelectedItem] = useState<IPartyListItem>();

  useEffect(() => {
    setSelectedItem(selected);
  }, [selected]);

  return (
    <EasyAutoComplete
      selected={selectedItem}
      key={`associatedClients`}
      label=""
      placeholder="Select Client"
      textFieldStyle="outlined"
      getOptionSelected={(option: any, value: any) => {
        return option.key === value.key;
      }}
      {...clientProps}
      selectedChanged={(value: any): void => {
        if (value) {
          onSelectChange(value);
          setSelectedItem(value);
        } else {
          onSelectChange(undefined);
          setSelectedItem(undefined);
        }
      }}
      disableClearable={false}
      disabled={disabled}
      error={error}
    />
  );
};

import React, { FC } from 'react';
import { Provider as ProposalProvider } from 'template/Opportunity/ProposalContext';

import { NewProposal as NewProposalComponent } from './components/NewProposal/NewProposal';

export interface IProps {
  routes: Array<any>;
}

export const NewProposalPage: FC<IProps> = ({ routes }) => {
  return (
    <ProposalProvider activeTab="new">
      <NewProposalComponent></NewProposalComponent>
    </ProposalProvider>
  );
};

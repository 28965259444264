import { IGanttChartTask } from 'components/GanttChart/interface';
import { IProposalEvent } from 'graphql/proposals/types/IProposalEvent';
import { startCase, toLower } from 'lodash';
import { useMemo } from 'react';
import { IEvent } from 'template/Proposal/ProposalContext';
import { changeReportStatusName } from './utils';
import { ProposalEventType } from 'constants/enums';
import { useParseEvents } from './parseEvents';

interface IGantTask extends IGanttChartTask {
  ev: IEvent;
}

export const useGanttEvents = (events: IProposalEvent[]) => {
  const { parsedEvents } = useParseEvents({ events });

  const tasks = useMemo(() => {
    const groupedEvents = {
      metaDataEvents: [] as IGantTask[],
      otherEvents: [] as IGantTask[],
    };

    for (const ev of parsedEvents) {
      const ganttEvent: IGantTask = {
        id: ev.id,
        name: changeReportStatusName(startCase(toLower(ev.ev.eventType))),
        start: ev.ev.createdAt,
        end: ev.ev.createdAt,
        progress: 0,
        dependencies: '',
        ev,
      };

      // change color
      switch (ev.ev.eventType as ProposalEventType) {
        case ProposalEventType.PROPOSAL_CREATED:
        case ProposalEventType.PROPOSAL_UPDATED:
        case ProposalEventType.PROPOSAL_MONETARY_BREAKDOWN_CHANGED:
        case ProposalEventType.PROPOSAL_BOND_CHANGED:
        case ProposalEventType.PROPOSAL_REVIEWERS_UPDATED:
          ganttEvent.custom_class = 'gantt_blue';
          break;
      }

      // Group proposal updates
      if (
        [
          ProposalEventType.PROPOSAL_CREATED,
          ProposalEventType.PROPOSAL_MONETARY_BREAKDOWN_CHANGED,
          ProposalEventType.PROPOSAL_BOND_CHANGED,
          ProposalEventType.PROPOSAL_UPDATED,
          ProposalEventType.PROPOSAL_REVIEWERS_UPDATED,
        ].includes(ev.ev.eventType as ProposalEventType)
      ) {
        if (
          ev.ev.eventType === ProposalEventType.PROPOSAL_UPDATED &&
          ev.payload &&
          !!ev.payload.reviewStatus
        ) {
          ganttEvent.name = 'Proposal review status changed to ' + ev.payload.reviewStatus;
        } else {
          if (groupedEvents.metaDataEvents.length) {
            const prev = groupedEvents.metaDataEvents[groupedEvents.metaDataEvents.length - 1];
            Object.assign(prev, { end: ganttEvent.start });
            ganttEvent.dependencies = [ganttEvent.dependencies, prev.id].join(',');
          }
          groupedEvents.metaDataEvents.push(ganttEvent);
          continue;
        }
      }

      if (ev.ev.eventType === ProposalEventType.PROPOSAL_FILE_CREATE) {
        ganttEvent.name =
          'Uploaded ' +
          startCase(toLower(ev.payload.proposalFileGroup)) +
          ' - ' +
          startCase(toLower(ev.payload.proposalFileType)) +
          ' file: ' +
          ev.payload.uploadedFile.fileFilename;

        const parent = groupedEvents.otherEvents
          .slice()
          .reverse()
          .find(
            (event) =>
              event.ev.ev.eventType === ProposalEventType.PROPOSAL_FILE_CREATE &&
              event.ev.payload.uploadedFile.fileFilename === ev.payload.uploadedFile.fileFilename &&
              event.ev.payload.proposalFileGroup === ev.payload.proposalFileGroup &&
              event.ev.payload.proposalFileType === ev.payload.proposalFileType
          );
        if (parent) {
          ganttEvent.dependencies = [ganttEvent.dependencies, parent.id].join(',');
        } else {
          ganttEvent.dependencies = [
            ganttEvent.dependencies,
            groupedEvents.metaDataEvents[0].id,
          ].join(',');
        }
      }

      if (ev.ev.eventType === ProposalEventType.PROPOSAL_DOCUMENT_SOFT_DELETE) {
        const parent = groupedEvents.otherEvents
          .slice()
          .reverse()
          .find(
            (event) =>
              event.ev.ev.eventType === ProposalEventType.PROPOSAL_FILE_CREATE &&
              event.ev.payload.uploadedFile.blobName === ev.payload.proposalDocument.blobName
          );
        ganttEvent.custom_class = 'gantt_red';
        if (parent) {
          ganttEvent.dependencies = [ganttEvent.dependencies, parent.id].join(',');
        }
      }

      if (ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_REQUESTED) {
        ganttEvent.name = `${(ev.ev as any).subject.user.name}`;
        ganttEvent.custom_class = 'gantt_orange';

        if (ev.payload?.proposalDocuments) {
          for (const doc of ev.payload.proposalDocuments) {
            const parent = groupedEvents.otherEvents.find(
              (event) =>
                event.ev.ev.eventType === ProposalEventType.PROPOSAL_FILE_CREATE &&
                event.ev.payload.uploadedFile.blobName === doc.blobName
            );

            if (parent) {
              ganttEvent.dependencies = [ganttEvent.dependencies, parent.id].join(',');
            }
          }
        }
      }

      if (
        ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_APPROVED ||
        ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_DISAPPROVED
      ) {
        if (ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_APPROVED) {
          ganttEvent.name = `${(ev.ev as any).author.user.name} approved`;
          ganttEvent.custom_class = 'gantt_green';
        } else {
          ganttEvent.name = `${(ev.ev as any).author.user.name} disapproved`;
          ganttEvent.custom_class = 'gantt_red';
        }

        if (ev?.payload?.proposalDocuments) {
          for (const doc of ev.payload.proposalDocuments) {
            const parent = groupedEvents.otherEvents
              .slice()
              .reverse()
              .find(
                (event) =>
                  event.ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_REQUESTED &&
                  event.ev.payload.proposalDocuments.some(
                    (pd: any) => pd.blobName === doc.blobName
                  ) &&
                  event.ev.ev.subject.user.name === ev.ev.author.user.name
              );

            if (parent) {
              ganttEvent.dependencies = [ganttEvent.dependencies, parent.id].join(',');
            }
          }
        }
      }

      if (ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_RECALLED) {
        ganttEvent.custom_class = 'gantt_yellow';

        if (ev?.payload?.proposalDocuments) {
          for (const doc of ev.payload.proposalDocuments) {
            const parent = groupedEvents.otherEvents
              .slice()
              .reverse()
              .find(
                (event) =>
                  event.ev.ev.eventType === ProposalEventType.PROPOSAL_REVIEW_REQUESTED &&
                  event.ev.payload.proposalDocuments.some(
                    (pd: any) => pd.blobName === doc.blobName
                  ) &&
                  event.ev.ev.subject.user.name === ev.ev.subject.user.name
              );

            if (parent) {
              ganttEvent.dependencies = [ganttEvent.dependencies, parent.id].join(',');
            }
          }
        }
      }

      groupedEvents.otherEvents.push(ganttEvent);
    }

    return [...groupedEvents.metaDataEvents, ...groupedEvents.otherEvents];
  }, [parsedEvents]);

  return {
    tasks,
  };
};

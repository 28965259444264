import { useLazyQuery } from '@apollo/client';
import { proposalFiles } from 'graphql/proposals/types/proposalFiles';
import { GET_PROPOSAL_FILES } from 'graphql/proposals/fileManager';
import { useCallback, useEffect } from 'react';

export interface IUseProposalFilesProps {
  id: string;
  updatedAt: any | undefined;
}

export const useProposalFiles = ({ id, updatedAt }: IUseProposalFilesProps) => {
  const [loadProposalFiles, { data, loading, refetch, called, error }] =
    useLazyQuery<proposalFiles>(GET_PROPOSAL_FILES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(() => {
    const variables = {
      id,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadProposalFiles({ variables });
    }
  }, [loadProposalFiles, refetch, called, id]);

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      loadPage();
    }
  }, [id, loadPage, updatedAt]);

  return {
    data,
    loading,
    error,
  };
};

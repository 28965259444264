import { gql } from '@apollo/client';
import { IProposalFile } from './fileManagerFragments';

export const IProposalDocumentVersion = gql`
  fragment IProposalDocumentVersion on ProposalDocumentVersion {
    version
    createdAt
    file {
      ...IProposalFile
    }
  }
  ${IProposalFile}
`;

export const IProposalDocument = gql`
  fragment IProposalDocument on ProposalDocument {
    id
    proposalFileType
    proposalFileGroup
    originalFilename
    versions {
      ...IProposalDocumentVersion
    }
  }
  ${IProposalDocumentVersion}
`;

import { FormControl, Grid, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ProposalFileGroup } from 'graphql/proposals/types/graphql-types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IProposalData } from 'template/Proposal/ProposalContext';
import { proposalDocuments_proposal_proposal_documents } from 'graphql/proposals/types/proposalDocuments';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& select': {
      minHeight: 28,
    },
    minWidth: 320,
  },
}));

export interface ISelectSingleFileFromDocGroups {
  proposal: IProposalData;
  setReviewFiles: (fileIds: Array<string>) => void;
  clientProposalDocuments: proposalDocuments_proposal_proposal_documents[];
}

export const SelectSingleFileFromDocGroups: FC<ISelectSingleFileFromDocGroups> = ({
  proposal,
  setReviewFiles,
  clientProposalDocuments,
}) => {
  const classes = useStyles();
  const [selectedDocument, setSelectedDocument] = useState<string>();

  useEffect(() => {
    const reviewFiles: string[] = [];
    if (selectedDocument) {
      reviewFiles.push(selectedDocument);
    }

    setReviewFiles(reviewFiles);
  }, [setReviewFiles, selectedDocument]);

  const getDocumentsList = useCallback(
    (proposalFileGroup: ProposalFileGroup) => {
      const documents = clientProposalDocuments
        ?.filter((proposalDocument) => proposalDocument.proposalFileGroup === proposalFileGroup)
        .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt));

      return documents?.map((proposalDocument) => {
        const lastVersion = proposalDocument.versions
          .slice()
          .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))[0];

        const { originalFilename } = lastVersion.file!;
        return { key: proposalDocument.id, name: originalFilename, version: lastVersion.version };
      });
    },
    [clientProposalDocuments]
  );

  const clientDocuments = useMemo(() => {
    return getDocumentsList(ProposalFileGroup.CLIENT_DOCUMENT);
  }, [getDocumentsList]);

  const submittedDocuments = useMemo(() => {
    return getDocumentsList(ProposalFileGroup.DEMAC_SUBMITTED_DOCUMENT);
  }, [getDocumentsList]);

  const supportingDocuments = useMemo(() => {
    return getDocumentsList(ProposalFileGroup.DEMAC_SUPPORTING_DOCUMENT);
  }, [getDocumentsList]);

  const selectForm = useMemo(() => {
    return (
      <Select
        native
        defaultValue=""
        id="grouped-files"
        onChange={(ev, child) => {
          setSelectedDocument(ev.target.value as string);
        }}
      >
        <option aria-label="None" value="" />
        <optgroup label="CLIENT DOCUMENTS">
          {clientDocuments.map((doc) => (
            <option key={doc.key} value={doc.key}>
              {doc.name}, v1.{doc.version}
            </option>
          ))}
        </optgroup>
        <optgroup label="D&M SUBMITTED DOCUMENTS">
          {submittedDocuments.map((doc) => (
            <option key={doc.key} value={doc.key}>
              {doc.name}, v1.{doc.version}
            </option>
          ))}
        </optgroup>
        <optgroup label="D&M SUPPORTING DOCUMENTS">
          {supportingDocuments.map((doc) => (
            <option key={doc.key} value={doc.key}>
              {doc.name}, v1.{doc.version}
            </option>
          ))}
        </optgroup>
      </Select>
    );
  }, [clientDocuments, submittedDocuments, supportingDocuments]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2" className="label-title">
          Select a Document to Review:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          {selectForm}
        </FormControl>
      </Grid>
    </Grid>
  );
};

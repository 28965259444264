import { FormValidationReportProvider } from 'components/FormValidationReport/FormValidationReportContext';
import { FC } from 'react';
import { ProposalBidPerformanceBonds } from './ProposalBidPerformanceBonds/ProposalBidPerformanceBonds';

export const ProposalBidPerformanceBondsView: FC = () => {
  return (
    <FormValidationReportProvider>
      <ProposalBidPerformanceBonds />
    </FormValidationReportProvider>
  );
};

import { useLazyQuery } from '@apollo/client';
import { proposalDocuments } from 'graphql/proposals/types/proposalDocuments';
import { GET_PROPOSAL_DOCUMENTS } from 'graphql/proposals/fileManager';
import { useCallback, useEffect } from 'react';

export interface IUseProposalDocumentsProps {
  id: string;
  updatedAt: any | undefined;
}

export const useProposalDocuments = ({ id, updatedAt }: IUseProposalDocumentsProps) => {
  const [loadProposalDocuments, { data, loading, refetch, called, error }] =
    useLazyQuery<proposalDocuments>(GET_PROPOSAL_DOCUMENTS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadPage = useCallback(() => {
    const variables = {
      id,
    };

    if (called) {
      refetch!(variables);
    } else {
      loadProposalDocuments({ variables });
    }
  }, [loadProposalDocuments, refetch, called, id]);

  useEffect(() => {
    if (id && parseInt(id) > 0) {
      loadPage();
    }
  }, [id, loadPage, updatedAt]);

  return {
    data,
    loading,
    error,
  };
};

import { Tab, Tabs } from '@mui/material';
import React, { useMemo } from 'react';
import { FC } from 'react';
import s from './style.module.scss';
import cn from 'classnames';
import paths from 'constants/paths';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useHistory } from 'react-router-dom';
import { useComponentContext } from 'template/Proposal/ProposalContext';

export interface IProposalMainTabsProps {
  currentView: string;
  changeView: (view: string) => void;
}

export const ProposalMainTabs: FC<IProposalMainTabsProps> = ({ currentView, changeView }) => {
  const { confirmRequest } = useFormChangedDialogContext();
  const history = useHistory();

  const {
    proposal: { id: proposalId },
  } = useComponentContext();

  const visibleTabs = useMemo(
    () => [
      {
        label: 'Proposal Details',
        value: ['details'],
      },
      {
        label: 'File Management',
        value: ['file-management'],
      },
      {
        label: 'Bid & Performance Bonds',
        value: ['bid-performance-bonds'],
        onClick: () => {
          confirmRequest!((confirmed) => {
            confirmed &&
              history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BONDS.replace(':id', proposalId));
          });
        },
      },
      {
        label: 'Comments/Event Log',
        value: ['events-log'],
      },
    ],
    [history, proposalId, confirmRequest]
  );

  return (
    <Tabs
      value={currentView}
      onChange={(e, val) => {
        confirmRequest!((confirmed) => {
          confirmed && changeView!(val);
        });
      }}
      variant="fullWidth"
      className={s.folderTypeTabs}
      TabIndicatorProps={{ style: { background: '#006AD4' } }}
    >
      {visibleTabs.map((tab) => {
        return (
          <Tab
            label={tab.label}
            value={tab.value[0]}
            className={cn(s.allTabs, tab.value.includes(currentView) ? s.activeTab : s.inactiveTab)}
            key={tab.value[0]}
            onClick={tab.onClick}
          />
        );
      })}
    </Tabs>
  );
};

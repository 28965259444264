import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { formatProposalFileManagementTime } from 'utils/time';
import './index.scss';
import s from './style.module.scss';

import { debounce } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AlertDialog, ConfirmationDialog } from 'components';
import { useMutation } from '@apollo/client';
import { FileDotIcon } from 'template/Proposal/components/FilesList.tsx/components/FileDotIcon/FileDotIcon';
import { proposalFiles_proposal_proposal_files } from 'graphql/proposals/types/proposalFiles';
import { IBondFile } from 'graphql/proposals/types/IBondFile';
import { BOND_FILE_DELETE } from 'graphql/proposals/bonds';

export interface IProposalFile extends proposalFiles_proposal_proposal_files {}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface IBondFileInfo {
  bondFile: IBondFile;
  refetchFiles: (() => Promise<any>) | undefined;
  disabled?: boolean;
}

export const BondFileInfo: FC<IBondFileInfo> = ({ bondFile, refetchFiles, disabled }) => {
  const [deleteBondFileConfirmationOpen, setDeleteBondFileConfirmationOpen] = useState(false);

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);

  const { originalFilename, blobName, createdAt, downloadUrl, event } = bondFile;

  const [deleteBondFile] = useMutation(BOND_FILE_DELETE);

  const deleteBondFileHandler = useCallback(() => {
    setDeleteBondFileConfirmationOpen(true);
  }, []);

  const deleteBondFileConfirmationCloseHandler = useCallback(
    (ok: boolean): void => {
      setDeleteBondFileConfirmationOpen(false);

      if (ok) {
        deleteBondFile({
          variables: {
            bondFileId: bondFile.id,
          },
        })
          .then(() => {
            refetchFiles && refetchFiles().then(() => {});
          })
          .catch((e) => {
            setMutationErrorMessage(e?.message);
            setMutationErrorOpen(true);
          });
      }
    },
    [deleteBondFile, refetchFiles, bondFile]
  );

  const classes = useStyles();
  return (
    <div className={s.FileInfoComponent}>
      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />
      <ConfirmationDialog
        title="Delete Proposal Bond File"
        message={`Are you sure you want to delete proposal bond file ${originalFilename}?`}
        open={deleteBondFileConfirmationOpen}
        onClose={deleteBondFileConfirmationCloseHandler}
      />
      <div style={{ display: 'flex' }}>
        <div style={{ width: '32px' }}>
          <FileDotIcon fontSize="small" style={{ width: '32px', height: '32px' }}></FileDotIcon>
        </div>
        <div className={s.fileInfo}>
          {downloadUrl && downloadUrl !== '' ? (
            <div
              className={'file-archive-item'}
              style={{
                overflow: 'hidden',
              }}
            >
              <Tooltip
                title={`Proposal Bond File to Download: ${blobName}`}
                placement="bottom-start"
                arrow
              >
                <a href={downloadUrl} className={s.bondFileLink} target="_blank" rel="noreferrer">
                  {originalFilename}
                </a>
              </Tooltip>
            </div>
          ) : (
            blobName
          )}
          <div>
            {event?.author?.name ? <>{event?.author?.name}</> : 'Undefined Uploader'},{' '}
            {formatProposalFileManagementTime(createdAt)}
          </div>
        </div>
        {!disabled ? (
          <div>
            <IconButton
              edge="end"
              onClick={debounce(() => {
                deleteBondFileHandler();
              }, DEBOUNCE_TIMEOUT)}
              style={{ padding: '12px' }}
              className={classes.hoverWarning}
            >
              <Tooltip title={`Bond Filename: ${blobName}`} placement="bottom-start" arrow>
                <DeleteIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

// import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { FC } from 'react';
// import s from './style.module.scss';

export interface IProposalPrintingMeta {
  onReady: () => void;
}
export const ProposalPrintingMeta: FC<IProposalPrintingMeta> = ({ onReady }) => {
  useEffect(() => {
    if (true) {
      onReady();
    }
  }, [onReady]);

  return (
    <div>
      <div>{window.location.href}</div>
      <Box mt={2} />
    </div>
  );
};

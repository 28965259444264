import { useCallback, useRef } from 'react';

export const useDebounce = () => {
  const timers = useRef<{ [functionName: string]: ReturnType<typeof setTimeout> }>({});
  const maxDelayTimers = useRef<{ [functionName: string]: ReturnType<typeof setTimeout> }>({});

  const debounceByName = useCallback(
    (name: string, func: any, delay: number, maxDelay?: number) => {
      timers.current[name] && clearInterval(timers.current[name]);

      timers.current[name] = setTimeout(() => {
        maxDelayTimers.current[name] && clearInterval(maxDelayTimers.current[name]);
        func && func();
      }, delay);

      if (maxDelay && !maxDelayTimers.current[name]) {
        maxDelayTimers.current[name] = setTimeout(() => {
          timers.current[name] && clearInterval(timers.current[name]);
          func && func();
        }, maxDelay);
      }
    },
    []
  );

  return {
    debounceByName,
  };
};

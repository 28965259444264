/* eslint-disable camelcase */
import React, { FC, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Snackbar,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  ListAltRounded as ListAltRoundedIcon,
  Home as HomeIcon,
  Camera,
  LocalOffer,
  Business,
} from '@mui/icons-material';

import { ExitToApp as ExitToAppIcon } from '@mui/icons-material';

import { grey } from '@mui/material/colors';

import { Logo } from '../../images';

import './index.scss';
import { UserInitials } from '..';

import cn from 'classnames';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import paths from 'constants/paths';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { useMsalAccount } from 'hooks/msalAccount';
import { getMsal1Account, msalLogout } from 'authorization/auth-utils-msal2';
import { isValidYearSearch } from 'utils/year';

const Header: FC<any> = () => {
  const { msalAccount: currentAccount } = useMsalAccount();
  const { checkAndProceed } = useFormChangedDialogContext();
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [autoLogoffMessageShown, setAutoLogoffMessageShown] = useState(false);

  const [loggedUserAccount, setLoggedUserAccount] = useState(currentAccount);
  const [userEmail, setUserEmail] = useState('');

  const history = useHistory();
  const { pathname } = useLocation();

  const firstTab = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  const lastUrlPart = useMemo(() => {
    return pathname.split('/').slice(-1)[0];
  }, [pathname]);

  const [reportsAnchorEl, setReportsAnchorEl] = React.useState<null | HTMLElement>(null);
  const openReportsMenu = Boolean(reportsAnchorEl);

  const tabLegalFoldersActive = useMemo(
    () =>
      [
        'project-folders',
        'project-folder',
        'project-folder-document',
        'new-project-folder',
      ].includes(firstTab),
    [firstTab]
  );

  const tabReportsActive = useMemo(() => ['reports'].includes(firstTab), [firstTab]);

  const gotoHome = (): void => {
    closeUserMenu();
    history.push('/');
  };

  const gotoProposals = (): void => {
    closeUserMenu();
    history.push(paths.client.PROPOSALS);
  };

  const gotoOpportunuities = (): void => {
    closeUserMenu();
    history.push(paths.client.OPPORTUNITIES);
  };

  const gotoClientManagement = (): void => {
    closeUserMenu();
    history.push(paths.client.CLIENT_MANAGEMENT);
  };

  const gotoBonds = (): void => {
    closeUserMenu();
    history.push(paths.client.BONDS);
  };

  const gotoYearSummaryReport = useCallback((): void => {
    closeUserMenu();
    setReportsAnchorEl(null);

    const year = isValidYearSearch(lastUrlPart) ? parseInt(lastUrlPart) : new Date().getFullYear();

    history.push(paths.client.REPORT_YEAR_SUMMARY.replace(':year', year.toString()));
  }, [history, lastUrlPart]);

  const gotoYearSummaryBySuccessChanceReport = useCallback((): void => {
    closeUserMenu();
    setReportsAnchorEl(null);

    const year = isValidYearSearch(lastUrlPart) ? parseInt(lastUrlPart) : new Date().getFullYear();

    history.push(
      paths.client.REPORT_YEAR_BY_SUCCESS_CHANCE_SUMMARY.replace(':year', year.toString())
    );
  }, [history, lastUrlPart]);

  const openUserMenu = (): void => {
    setUserDrawerOpen(true);
  };

  const closeUserMenu = (): void => {
    setUserDrawerOpen(false);
  };

  const logout = useCallback((): void => {
    msalLogout();
  }, []);

  const gotoReports = (event: Record<string, any>): void => {
    setReportsAnchorEl(event.currentTarget);
  };

  const handleCloseReportsMenu = () => {
    setReportsAnchorEl(null);
  };

  let autoLogoutTimeoutId = useRef(-1);
  let autoLogoutMessageTimeoutId = useRef(-1);

  const displayAutoLogoutWarning = useCallback(
    (location: any) => {
      clearTimeout(autoLogoutMessageTimeoutId.current);
      clearTimeout(autoLogoutTimeoutId.current);
      setAutoLogoffMessageShown(false);

      if (location && location.pathname && location.pathname.toLowerCase() === '/login') {
        setAutoLogoffMessageShown(false);
      } else {
        autoLogoutMessageTimeoutId.current = window.setTimeout(() => {
          setAutoLogoffMessageShown(true);

          autoLogoutTimeoutId.current = window.setTimeout(() => {
            logout();
          }, 1000 * 60 * 5); // logout 5 minutes after message is shown
        }, 1000 * 60 * 235); // show message after 235 minutes (4 hours - 5 minutes) of inactivity
      }
    },
    [logout]
  );

  const getLoggedInUserEmail = (): any => {
    return (
      <Typography
        variant="subtitle2"
        color="secondary"
        style={{ display: 'inline-block', padding: '1rem', color: '#006AD4BB' }}
      >
        {userEmail}
      </Typography>
    );
  };

  useEffect((): void => {
    // #Zoran, uncomment if auto renew token is wanted
    // Token expiry is set to 4 hours, so it is not needed
    // setInterval(() => renewToken(), 1000 * 120); // try to auto renew token every 2 minutes

    displayAutoLogoutWarning(history.location);

    // todo: check dependency and run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): void => {
    history.listen((location) => {
      displayAutoLogoutWarning(location);
    });
  }, [history, displayAutoLogoutWarning]);

  useEffect((): void => {
    const accountEmail: string =
      (loggedUserAccount?.idToken?.email as string | undefined) ||
      loggedUserAccount?.idToken?.preferred_username ||
      '';
    setUserEmail(accountEmail);
  }, [loggedUserAccount]);

  useEffect((): void => {
    setLoggedUserAccount(getMsal1Account());
  }, [currentAccount]);

  const userLoggedIn = (): any => {
    return getMsal1Account();
  };

  const getUserInfoButton = (): JSX.Element => <UserInitials {...getMsal1Account()} />;

  const tabProposalsActive = useMemo(
    () => ['proposals', 'proposal', 'new-proposal', ''].includes(firstTab),
    [firstTab]
  );

  const tabOpportunitiesActive = useMemo(
    () => ['opportunities', 'opportunity', 'new-opportunity'].includes(firstTab),
    [firstTab]
  );

  const tabClientManagementActive = useMemo(
    () => ['client-management', 'new-client', 'client'].includes(firstTab),
    [firstTab]
  );

  const tabBondsActive = useMemo(
    () => ['bonds', 'bond', 'new-bond'].includes(firstTab),
    [firstTab]
  );

  return (
    <AppBar position="sticky" elevation={3} style={{ backgroundColor: grey[50] }}>
      <div style={{ paddingLeft: '24px' }}>
        <Drawer anchor="right" open={userDrawerOpen} onClose={closeUserMenu}>
          <List style={{ width: 250 }}>
            <ListItem
              button
              key="home"
              onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </List>

          <Divider />
          <List>
            <ListItem
              button
              key="Proposals"
              onClick={debounce(checkAndProceed!(gotoProposals), DEBOUNCE_TIMEOUT)}
            >
              <ListItemIcon>
                <div style={{ paddingLeft: '2px' }}>
                  <LocalOffer />
                </div>
              </ListItemIcon>
              <ListItemText primary="Proposals" />
            </ListItem>
          </List>
          <List>
            <ListItem
              button
              key="Opportunities"
              onClick={debounce(checkAndProceed!(gotoOpportunuities), DEBOUNCE_TIMEOUT)}
            >
              <ListItemIcon>
                <div style={{ paddingLeft: '2px' }}>
                  <Camera />
                </div>
              </ListItemIcon>
              <ListItemText primary="Opportunities" />
            </ListItem>
          </List>
          <List>
            <ListItem
              button
              key="ClientManagement"
              onClick={debounce(checkAndProceed!(gotoClientManagement), DEBOUNCE_TIMEOUT)}
            >
              <ListItemIcon>
                <div style={{ paddingLeft: '2px' }}>
                  <Business />
                </div>
              </ListItemIcon>
              <ListItemText primary="Client Management" />
            </ListItem>
          </List>
          <List>
            <ListItem
              button
              key="Bonds"
              onClick={debounce(checkAndProceed!(gotoBonds), DEBOUNCE_TIMEOUT)}
            >
              <ListItemIcon>
                <div style={{ paddingLeft: '2px' }}>
                  <Business />
                </div>
              </ListItemIcon>
              <ListItemText primary="Bid & Performance Bonds" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key="logout"
              onClick={debounce(checkAndProceed!(logout), DEBOUNCE_TIMEOUT)}
            >
              <ListItemIcon>
                <div style={{ paddingLeft: '2px' }}>
                  <ExitToAppIcon />
                </div>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>

        <Grid
          container
          padding={0}
          spacing={3}
          alignContent="center"
          alignItems="center"
          width={'100%'}
        >
          <Grid xs={7}>
            <div className="header-line">
              <div className={cn({ 'header-separator': userLoggedIn() })}>
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  noWrap
                  style={
                    !userLoggedIn() || tabLegalFoldersActive
                      ? { color: grey[900] }
                      : { color: '#006ad4' }
                  }
                >
                  <div className="header-section-inline">
                    <div
                      onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <span id="header-logo">
                        <Logo />
                      </span>
                    </div>
                    {!userLoggedIn() ? (
                      <div
                        className="header-section-inline"
                        onClick={debounce(checkAndProceed!(gotoHome), DEBOUNCE_TIMEOUT)}
                        style={{ cursor: 'pointer' }}
                      >
                        <ListAltRoundedIcon fontSize="small" /> &nbsp;{' '}
                        <span>
                          Proposals & Opportunities {!userLoggedIn() ? 'Dashboard' : undefined}
                        </span>
                      </div>
                    ) : undefined}
                  </div>
                </Typography>
              </div>
              {userLoggedIn() && (
                <div className="header-section header-separator">
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    noWrap
                    style={
                      tabProposalsActive
                        ? { color: '#000000', fontWeight: 'bold' }
                        : { color: '#444643CC' }
                    }
                  >
                    <div
                      className="header-section-inline"
                      onClick={debounce(checkAndProceed!(gotoProposals), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Box component="div" display={{ xs: 'none', sm: 'inline' }}>
                        &nbsp;<span className="header-action">Proposals</span>
                      </Box>
                    </div>
                  </Typography>
                </div>
              )}
              {userLoggedIn() && (
                <div className="header-section header-separator">
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    noWrap
                    style={
                      tabOpportunitiesActive
                        ? { color: '#000000', fontWeight: 'bold' }
                        : { color: '#444643CC' }
                    }
                  >
                    <div
                      className="header-section-inline"
                      onClick={debounce(checkAndProceed!(gotoOpportunuities), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Box component="div" display={{ xs: 'none', sm: 'inline' }}>
                        &nbsp;<span className="header-action">Opportunities</span>
                      </Box>
                    </div>
                  </Typography>
                </div>
              )}
              {userLoggedIn() && (
                <div className="header-section header-separator">
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    noWrap
                    style={
                      tabBondsActive
                        ? { color: '#000000', fontWeight: 'bold' }
                        : { color: '#444643CC' }
                    }
                  >
                    <div
                      className="header-section-inline"
                      onClick={debounce(checkAndProceed!(gotoBonds), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Box component="div" display={{ xs: 'none', sm: 'inline' }}>
                        &nbsp;<span className="header-action">Bid & Performance Bonds</span>
                      </Box>
                    </div>
                  </Typography>
                </div>
              )}
              {userLoggedIn() && (
                <div className="header-section header-separator">
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    noWrap
                    style={
                      tabClientManagementActive
                        ? { color: '#000000', fontWeight: 'bold' }
                        : { color: '#444643CC' }
                    }
                  >
                    <div
                      className="header-section-inline"
                      onClick={debounce(checkAndProceed!(gotoClientManagement), DEBOUNCE_TIMEOUT)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Box component="div" display={{ xs: 'none', sm: 'inline' }}>
                        &nbsp;<span className="header-action">Client Management</span>
                      </Box>
                    </div>
                  </Typography>
                </div>
              )}
              {userLoggedIn() && (
                <div className="header-section header-separator">
                  <Menu
                    id="basic-menu"
                    anchorEl={reportsAnchorEl}
                    // getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={openReportsMenu}
                    onClose={handleCloseReportsMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      style: {
                        padding: 0,
                      },
                    }}
                    PaperProps={{
                      style: {
                        transform: 'translateY(10%)',
                      },
                    }}
                  >
                    <MenuItem
                      selected={false}
                      onClick={debounce(checkAndProceed!(gotoYearSummaryReport), DEBOUNCE_TIMEOUT)}
                    >
                      Proposals Submitted by Year
                    </MenuItem>
                    <MenuItem
                      selected={false}
                      onClick={debounce(
                        checkAndProceed!(gotoYearSummaryBySuccessChanceReport),
                        DEBOUNCE_TIMEOUT
                      )}
                    >
                      Proposals Forecast by Year
                    </MenuItem>
                  </Menu>
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    noWrap
                    style={
                      tabReportsActive
                        ? { color: '#000000', fontWeight: 'bold' }
                        : { color: '#444643CC' }
                    }
                  >
                    <div
                      className="header-section-inline"
                      onClick={gotoReports}
                      style={{ cursor: 'pointer' }}
                    >
                      <Box component="div" display={{ xs: 'inline' }}>
                        &nbsp;<span className="header-action">Reports</span>
                      </Box>
                    </div>
                  </Typography>
                </div>
              )}
            </div>
          </Grid>

          {userLoggedIn() && (
            <Grid xs={5}>
              <div
                style={{
                  textAlign: 'right',
                  cursor: 'pointer',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
                onClick={debounce(openUserMenu, DEBOUNCE_TIMEOUT)}
              >
                {/* <Box component="div" display={{ xs: 'none', md: 'inline' }}>
                  {getLoggedInUserEmail()}
                </Box> */}
                <Box component="div" display="inline">
                  {getLoggedInUserEmail()}
                </Box>
                <Box component="div" display="inline">
                  {getUserInfoButton()}
                </Box>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="You will be logged out in 5 minutes. Refresh page to cancel."
        open={autoLogoffMessageShown}
      />
    </AppBar>
  );
};

export default Header;

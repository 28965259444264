import { IconButton, Tooltip } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
export interface IOpenDocumentProps {
  url: string;
  tooltip: any;
}

export const OpenDocument: FC<IOpenDocumentProps> = ({ url, tooltip }) => {
  const history = useHistory();
  const { confirmRequest } = useFormChangedDialogContext();

  const onClick = useCallback(() => {
    confirmRequest!((confirmed) => {
      confirmed && history.push(url);
    });
  }, [history, url, confirmRequest]);

  return (
    <div onClick={debounce(onClick, DEBOUNCE_TIMEOUT)} style={{ cursor: 'pointer' }}>
      <Tooltip title={tooltip || ''} disableInteractive={!tooltip}>
        <IconButton>
          <LinkIcon style={{ color: '#006ad4' }}></LinkIcon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const getCurrencies = () => ({
  XPF: {
    name: 'CFP franc',
    symbol: '₣',
  },
  ISK: {
    name: 'Icelandic króna',
    symbol: 'kr',
  },
  EUR: {
    name: 'Euro',
    symbol: '€',
  },
  XOF: {
    name: 'West African CFA franc',
    symbol: 'Fr',
  },
  KMF: {
    name: 'Comorian franc',
    symbol: 'Fr',
  },
  AUD: {
    name: 'Australian dollar',
    symbol: '$',
  },
  CAD: {
    name: 'Canadian dollar',
    symbol: '$',
  },
  BYN: {
    name: 'Belarusian ruble',
    symbol: 'Br',
  },
  GYD: {
    name: 'Guyanese dollar',
    symbol: '$',
  },
  GMD: {
    name: 'dalasi',
    symbol: 'D',
  },
  TND: {
    name: 'Tunisian dinar',
    symbol: 'د.ت',
  },
  XAF: {
    name: 'Central African CFA franc',
    symbol: 'Fr',
  },
  RWF: {
    name: 'Rwandan franc',
    symbol: 'Fr',
  },
  KHR: {
    name: 'Cambodian riel',
    symbol: '៛',
  },
  USD: {
    name: 'United States dollar',
    symbol: '$',
  },
  KRW: {
    name: 'South Korean won',
    symbol: '₩',
  },
  MUR: {
    name: 'Mauritian rupee',
    symbol: '₨',
  },
  MVR: {
    name: 'Maldivian rufiyaa',
    symbol: '.ރ',
  },
  VUV: {
    name: 'Vanuatu vatu',
    symbol: 'Vt',
  },
  MWK: {
    name: 'Malawian kwacha',
    symbol: 'MK',
  },
  EGP: {
    name: 'Egyptian pound',
    symbol: 'E£',
  },
  GEL: {
    name: 'lari',
    symbol: '₾',
  },
  NZD: {
    name: 'New Zealand dollar',
    symbol: '$',
  },
  CVE: {
    name: 'Cape Verdean escudo',
    symbol: 'Esc',
  },
  UYU: {
    name: 'Uruguayan peso',
    symbol: '$',
  },
  LAK: {
    name: 'Lao kip',
    symbol: '₭',
  },
  DKK: {
    name: 'Danish krone',
    symbol: 'kr',
  },
  FOK: {
    name: 'Faroese króna',
    symbol: 'kr',
  },
  MKD: {
    name: 'denar',
    symbol: 'den',
  },
  CLP: {
    name: 'Chilean peso',
    symbol: '$',
  },
  MOP: {
    name: 'Macanese pataca',
    symbol: 'P',
  },
  JOD: {
    name: 'Jordanian dinar',
    symbol: 'JD',
  },
  JMD: {
    name: 'Jamaican dollar',
    symbol: '$',
  },
  BBD: {
    name: 'Barbadian dollar',
    symbol: '$',
  },
  DZD: {
    name: 'Algerian dinar',
    symbol: 'د.ج',
  },
  MAD: {
    name: 'Moroccan dirham',
    symbol: 'د.م.',
  },
  MRU: {
    name: 'Mauritanian ouguiya',
    symbol: 'UM',
  },
  QAR: {
    name: 'Qatari riyal',
    symbol: 'ر.ق',
  },
  GTQ: {
    name: 'Guatemalan quetzal',
    symbol: 'Q',
  },
  XCD: {
    name: 'Eastern Caribbean dollar',
    symbol: '$',
  },
  PGK: {
    name: 'Papua New Guinean kina',
    symbol: 'K',
  },
  BRL: {
    name: 'Brazilian real',
    symbol: 'R$',
  },
  MDL: {
    name: 'Moldovan leu',
    symbol: 'L',
  },
  KGS: {
    name: 'Kyrgyzstani som',
    symbol: 'с',
  },
  ANG: {
    name: 'Netherlands Antillean guilder',
    symbol: 'ƒ',
  },
  VND: {
    name: 'Vietnamese đồng',
    symbol: '₫',
  },
  AOA: {
    name: 'Angolan kwanza',
    symbol: 'Kz',
  },
  MYR: {
    name: 'Malaysian ringgit',
    symbol: 'RM',
  },
  CHF: {
    name: 'Swiss franc',
    symbol: 'Fr',
  },
  THB: {
    name: 'Thai baht',
    symbol: '฿',
  },
  DOP: {
    name: 'Dominican peso',
    symbol: '$',
  },
  UZS: {
    name: 'Uzbekistani soʻm',
    symbol: "so'm",
  },
  GNF: {
    name: 'Guinean franc',
    symbol: 'Fr',
  },
  BTN: {
    name: 'Bhutanese ngultrum',
    symbol: 'Nu.',
  },
  INR: {
    name: 'Indian rupee',
    symbol: '₹',
  },
  KYD: {
    name: 'Cayman Islands dollar',
    symbol: '$',
  },
  NOK: {
    name: 'krone',
    symbol: 'kr',
  },
  ILS: {
    name: 'Israeli new shekel',
    symbol: '₪',
  },
  BND: {
    name: 'Brunei dollar',
    symbol: '$',
  },
  SGD: {
    name: 'Singapore dollar',
    symbol: '$',
  },
  IQD: {
    name: 'Iraqi dinar',
    symbol: 'ع.د',
  },
  JPY: {
    name: 'Japanese yen',
    symbol: '¥',
  },
  LSL: {
    name: 'Lesotho loti',
    symbol: 'L',
  },
  ZAR: {
    name: 'South African rand',
    symbol: 'R',
  },
  TVD: {
    name: 'Tuvaluan dollar',
    symbol: '$',
  },
  SSP: {
    name: 'South Sudanese pound',
    symbol: '£',
  },
  GBP: {
    name: 'British pound',
    symbol: '£',
  },
  SHP: {
    name: 'Saint Helena pound',
    symbol: '£',
  },
  AFN: {
    name: 'Afghan afghani',
    symbol: '؋',
  },
  SBD: {
    name: 'Solomon Islands dollar',
    symbol: '$',
  },
  CNY: {
    name: 'Chinese yuan',
    symbol: '¥',
  },
  ERN: {
    name: 'Eritrean nakfa',
    symbol: 'Nfk',
  },
  RUB: {
    name: 'Russian ruble',
    symbol: '₽',
  },
  AMD: {
    name: 'Armenian dram',
    symbol: '֏',
  },
  SRD: {
    name: 'Surinamese dollar',
    symbol: '$',
  },
  BSD: {
    name: 'Bahamian dollar',
    symbol: '$',
  },
  BZD: {
    name: 'Belize dollar',
    symbol: '$',
  },
  JEP: {
    name: 'Jersey pound',
    symbol: '£',
  },
  SEK: {
    name: 'Swedish krona',
    symbol: 'kr',
  },
  BWP: {
    name: 'Botswana pula',
    symbol: 'P',
  },
  IMP: {
    name: 'Manx pound',
    symbol: '£',
  },
  AED: {
    name: 'United Arab Emirates dirham',
    symbol: 'د.إ',
  },
  IRR: {
    name: 'Iranian rial',
    symbol: '﷼',
  },
  STN: {
    name: 'São Tomé and Príncipe dobra',
    symbol: 'Db',
  },
  BDT: {
    name: 'Bangladeshi taka',
    symbol: '৳',
  },
  RON: {
    name: 'Romanian leu',
    symbol: 'lei',
  },
  SDG: {
    name: 'Sudanese pound',
  },
  BAM: {
    name: 'Bosnia and Herzegovina convertible mark',
  },
  SCR: {
    name: 'Seychellois rupee',
    symbol: '₨',
  },
  LKR: {
    name: 'Sri Lankan rupee',
    symbol: 'Rs  රු',
  },
  MXN: {
    name: 'Mexican peso',
    symbol: '$',
  },
  YER: {
    name: 'Yemeni rial',
    symbol: '﷼',
  },
  PYG: {
    name: 'Paraguayan guaraní',
    symbol: '₲',
  },
  VES: {
    name: 'Venezuelan bolívar soberano',
    symbol: 'Bs.S.',
  },
  UAH: {
    name: 'Ukrainian hryvnia',
    symbol: '₴',
  },
  PKR: {
    name: 'Pakistani rupee',
    symbol: '₨',
  },
  KPW: {
    name: 'North Korean won',
    symbol: '₩',
  },
  ZWL: {
    name: 'Zimbabwean dollar',
    symbol: '$',
  },
  TZS: {
    name: 'Tanzanian shilling',
    symbol: 'Sh',
  },
  CKD: {
    name: 'Cook Islands dollar',
    symbol: '$',
  },
  PLN: {
    name: 'Polish złoty',
    symbol: 'zł',
  },
  WST: {
    name: 'Samoan tālā',
    symbol: 'T',
  },
  CZK: {
    name: 'Czech koruna',
    symbol: 'Kč',
  },
  IDR: {
    name: 'Indonesian rupiah',
    symbol: 'Rp',
  },
  BOB: {
    name: 'Bolivian boliviano',
    symbol: 'Bs.',
  },
  COP: {
    name: 'Colombian peso',
    symbol: '$',
  },
  HNL: {
    name: 'Honduran lempira',
    symbol: 'L',
  },
  LYD: {
    name: 'Libyan dinar',
    symbol: 'ل.د',
  },
  MNT: {
    name: 'Mongolian tögrög',
    symbol: '₮',
  },
  CDF: {
    name: 'Congolese franc',
    symbol: 'FC',
  },
  FKP: {
    name: 'Falkland Islands pound',
    symbol: '£',
  },
  SYP: {
    name: 'Syrian pound',
    symbol: '£',
  },
  ZMW: {
    name: 'Zambian kwacha',
    symbol: 'ZK',
  },
  MMK: {
    name: 'Burmese kyat',
    symbol: 'Ks',
  },
  CRC: {
    name: 'Costa Rican colón',
    symbol: '₡',
  },
  SOS: {
    name: 'Somali shilling',
    symbol: 'Sh',
  },
  HUF: {
    name: 'Hungarian forint',
    symbol: 'Ft',
  },
  TRY: {
    name: 'Turkish lira',
    symbol: '₺',
  },
  OMR: {
    name: 'Omani rial',
    symbol: 'ر.ع.',
  },
  NPR: {
    name: 'Nepalese rupee',
    symbol: '₨',
  },
  MGA: {
    name: 'Malagasy ariary',
    symbol: 'Ar',
  },
  KZT: {
    name: 'Kazakhstani tenge',
    symbol: '₸',
  },
  DJF: {
    name: 'Djiboutian franc',
    symbol: 'Fr',
  },
  GIP: {
    name: 'Gibraltar pound',
    symbol: '£',
  },
  GGP: {
    name: 'Guernsey pound',
    symbol: '£',
  },
  NAD: {
    name: 'Namibian dollar',
    symbol: '$',
  },
  ALL: {
    name: 'Albanian lek',
    symbol: 'L',
  },
  HTG: {
    name: 'Haitian gourde',
    symbol: 'G',
  },
  TOP: {
    name: 'Tongan paʻanga',
    symbol: 'T$',
  },
  HKD: {
    name: 'Hong Kong dollar',
    symbol: '$',
  },
  SAR: {
    name: 'Saudi riyal',
    symbol: 'ر.س',
  },
  CUC: {
    name: 'Cuban convertible peso',
    symbol: '$',
  },
  CUP: {
    name: 'Cuban peso',
    symbol: '$',
  },
  PAB: {
    name: 'Panamanian balboa',
    symbol: 'B/.',
  },
  LRD: {
    name: 'Liberian dollar',
    symbol: '$',
  },
  AZN: {
    name: 'Azerbaijani manat',
    symbol: '₼',
  },
  KID: {
    name: 'Kiribati dollar',
    symbol: '$',
  },
  ETB: {
    name: 'Ethiopian birr',
    symbol: 'Br',
  },
  TTD: {
    name: 'Trinidad and Tobago dollar',
    symbol: '$',
  },
  SLL: {
    name: 'Sierra Leonean leone',
    symbol: 'Le',
  },
  MZN: {
    name: 'Mozambican metical',
    symbol: 'MT',
  },
  FJD: {
    name: 'Fijian dollar',
    symbol: '$',
  },
  TMT: {
    name: 'Turkmenistan manat',
    symbol: 'm',
  },
  TJS: {
    name: 'Tajikistani somoni',
    symbol: 'ЅМ',
  },
  LBP: {
    name: 'Lebanese pound',
    symbol: 'ل.ل',
  },
  NGN: {
    name: 'Nigerian naira',
    symbol: '₦',
  },
  ARS: {
    name: 'Argentine peso',
    symbol: '$',
  },
  BMD: {
    name: 'Bermudian dollar',
    symbol: '$',
  },
  NIO: {
    name: 'Nicaraguan córdoba',
    symbol: 'C$',
  },
  SZL: {
    name: 'Swazi lilangeni',
    symbol: 'L',
  },
  BHD: {
    name: 'Bahraini dinar',
    symbol: '.د.ب',
  },
  KES: {
    name: 'Kenyan shilling',
    symbol: 'Sh',
  },
  RSD: {
    name: 'Serbian dinar',
    symbol: 'дин.',
  },
  PHP: {
    name: 'Philippine peso',
    symbol: '₱',
  },
  KWD: {
    name: 'Kuwaiti dinar',
    symbol: 'د.ك',
  },
  TWD: {
    name: 'New Taiwan dollar',
    symbol: '$',
  },
  BIF: {
    name: 'Burundian franc',
    symbol: 'Fr',
  },
  BGN: {
    name: 'Bulgarian lev',
    symbol: 'лв',
  },
  PEN: {
    name: 'Peruvian sol',
    symbol: 'S/ ',
  },
  AWG: {
    name: 'Aruban florin',
    symbol: 'ƒ',
  },
  GHS: {
    name: 'Ghanaian cedi',
    symbol: '₵',
  },
  UGX: {
    name: 'Ugandan shilling',
    symbol: 'Sh',
  },
});

import { BondStage } from 'graphql/proposals/types/graphql-types';

export const bondWorkflowStates: { id: BondStage; name: string }[] = [
  {
    id: BondStage.GUARANTEE_REQUEST_REQUESTED,
    name: 'Garantee Request from project / proposal team - Review Requested',
  },
  {
    id: BondStage.GUARANTEE_REQUEST_FEEDBACK_PROVIDED,
    name: 'Garantee Request from project / proposal team -Request Missing Info',
  },
  {
    id: BondStage.GUARANTEE_REQUEST_REVIEWED,
    name: 'Garantee Request from project / proposal team - Reviewed',
  },

  { id: BondStage.BANK_APPLICATION_FILLED_OUT, name: 'Bank application filled out' },
  { id: BondStage.BANK_APPLICATION_SUBMITTED, name: 'Bank application submitted' },
  { id: BondStage.BANK_APPLICATION_REVIEWED, name: 'Bank application reviewed' },

  {
    id: BondStage.BANK_GUARANTEE_ISSUED_DIRECT_HARD_COPY,
    name: 'Bank garantee issued, Direct - hard copy to D&M or beneficiary',
  },
  {
    id: BondStage.BANK_GUARANTEE_ISSUED_SWIFT_TO_FOREIGN_BANK,
    name: 'Bank garantee issued, SWIFT - to Foreign Bank',
  },
  {
    id: BondStage.BANK_GUARANTEE_ISSUED_SWIFT_TO_US_OFFICE,
    name: 'Bank garantee issued, SWIFT - to US office of Foreign Bank',
  },

  {
    id: BondStage.THIRD_PARTY_BANK_FEEDBACK_PROVIDED,
    name: '3rd Party Bank reviews request, 3rd Party Bank feedback/requests provided additional info',
  },
  {
    id: BondStage.THIRD_PARTY_BANK_FEEDBACK_ADDRESSED,
    name: '3rd Party Bank reviews request, DM Bank and D&M work with 3rd Party Bank to resolve',
  },

  {
    id: BondStage.THIRD_PARTY_BANK_GUARANTEE_DELIVERED,
    name: '3rd Party Bank guarantee delivered, Hard copy to beneficiary or DM local rep',
  },
  {
    id: BondStage.THIRD_PARTY_BANK_GUARANTEE_ISSUED,
    name: '3rd Party Bank guarantee issued, Hard copy to beneficiary or DM local rep',
  },
];

import React, { FC, useState } from 'react';
// import { useMutation } from '@apollo/client';
import { TextareaAutosize, Button, Grid } from '@mui/material';

import { EventCommentSubmitProps } from './interfaces';

import { UserInitials, AlertDialog } from 'components';

// import { id } from 'utils/id';

import './index.scss';
// import { DOCUMENT_COMMENT_CREATE } from 'graphql/proposals/documents';

import { useMutation } from '@apollo/client';
import { id } from 'utils/id';
import { PROPOSAL_COMMENT_CREATE } from 'graphql/proposals/proposals';
import { useMsalAccount } from 'hooks/msalAccount';

export const EventCommentSubmit: FC<EventCommentSubmitProps> = ({
  proposalId,
  refetchDocument,
}: EventCommentSubmitProps) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const [commentError, setCommentError] = useState('');
  const [commentErrorOpen, setAlertOpen] = useState(false);

  const [setDocumentComment] = useMutation(PROPOSAL_COMMENT_CREATE);

  const handleSubmit = (event: any): void => {
    event.preventDefault();

    setDocumentComment({
      variables: { comment: value, proposalId },
    })
      .then(() => {
        refetchDocument({ variables: { id: id() } });
      })
      .catch((e) => {
        setCommentError(e?.message);
        setAlertOpen(true);
      });

    setValue('');
    setIsFocused(false);
  };

  const handleCommentSubmit = (event: any): void => {
    setValue(event.target.value);
  };

  const { msalAccount: currentAccount } = useMsalAccount();

  const getUserInfoButton = (): JSX.Element => <UserInitials {...currentAccount} />;

  return (
    <Grid container>
      <AlertDialog
        title="Error"
        message={commentError}
        open={commentErrorOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <Grid item xs={2} lg={1}>
        {getUserInfoButton()}
      </Grid>
      <Grid item xs={10} lg={11}>
        <form onSubmit={handleSubmit}>
          <TextareaAutosize
            // className="event-logs-comment-input"
            className="MuiInputBase-input"
            color="grey"
            style={{
              width: '85%',
              maxWidth: '85%',
              minWidth: '85%',
              minHeight: '1rem',
              padding: 10,
              borderRadius: 4,
              border: '1px #ccc solid',
              overflow: 'auto',
            }}
            aria-label="minimum height"
            minRows={1}
            placeholder="Write a requirement/comment..."
            onChange={handleCommentSubmit}
            onFocus={(): void => setIsFocused(true)}
            onBlur={(): void => {
              if (!value) {
                setIsFocused(false);
              }
            }}
            value={value}
          />
          {isFocused && (
            <div style={{ marginTop: '1rem' }}>
              <Button type="submit" variant="contained" color="primary" disabled={!value}>
                Submit
              </Button>
            </div>
          )}
        </form>
      </Grid>
    </Grid>
  );
};

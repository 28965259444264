import { useLazyQuery } from '@apollo/client';
import { PROPOSAL_AGGREGATES } from 'graphql/proposals/aggregates';
import { proposalAggregates } from 'graphql/proposals/types/proposalAggregates';
import { useCallback, useEffect, useRef } from 'react';

export interface AggregatesByDivisionAndStage {
  year: number;
}

export const useAggregatesByDivisionAndStage = ({ year }: AggregatesByDivisionAndStage) => {
  const [aggregatesByDivisionAndStage, { called, data, loading, error, refetch }] =
    useLazyQuery<proposalAggregates>(PROPOSAL_AGGREGATES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const loadAggregatesByDivisionAndStage = useCallback(
    (year: number) => {
      const variables = {
        filter: {
          createdAtFrom: new Date(year, 0, 1),
          createdAtTo: new Date(year, 11, 31, 23, 59, 59, 999),
          entityType: 'PROPOSAL',
        },
        group: {
          stage: true,
          owningDivision: true,
        },
        sort: [
          {
            column: 'OWNING_DIVISION_NAME',
            order: 'ASC',
          },
          {
            column: 'STAGE',
            order: 'ASC',
          },
        ],
      };

      if (called) {
        refetch!(variables);
      } else {
        aggregatesByDivisionAndStage({ variables });
      }
    },
    [aggregatesByDivisionAndStage, called, refetch]
  );

  const loadAggregatesByDivisionAndStageRef = useRef(loadAggregatesByDivisionAndStage);

  useEffect(() => {
    loadAggregatesByDivisionAndStageRef.current &&
      loadAggregatesByDivisionAndStageRef.current(year);
  }, [year, loadAggregatesByDivisionAndStageRef]);

  return {
    loadAggregatesByDivisionAndStage,
    data,
    loading,
    error,
  };
};

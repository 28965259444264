import { useAutoCompleteDataSource } from './autoCompleteDataSource';
import { GET_USERS, GET_USERS_COUNT } from 'graphql/proposals/users';
import { usersList } from 'graphql/proposals/types/usersList';
import { usersCount } from 'graphql/proposals/types/usersCount';

const getCount = (dataUsersCount: any) => dataUsersCount.userCount;
const getItems = (dataUsers: any) => dataUsers.users;
const getItemId = (item: any) => item.id;

export interface IUsersAutocompleteDataProps {
  search: string;
}

export interface IUser {
  id: string;
  name: string;
  email?: string | null;
}

export const useUsersAutocompleteDataHook = ({ search }: IUsersAutocompleteDataProps) => {
  const resp = useAutoCompleteDataSource<usersList, usersCount, IUser>({
    GET_QUERY: GET_USERS,
    GET_COUNT_QUERY: GET_USERS_COUNT,
    getCount,
    getItems,
    getItemId,
    search,
  });

  return {
    ...resp,
  };
};

import { Box, Container, Grid, Paper } from '@mui/material';
import { FC, useCallback } from 'react';

import './index.scss';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { useBondContext } from './BondContext';

import { ProposalBond } from 'template/Bond/components/ProposalBond/ProposalBond';
import { useUI } from 'contexts/UiContext';

import { FormValidationReport } from 'components/FormValidationReport/FormValidationReport';

export const BondTemplate: FC = () => {
  const { addSnackbar } = useUI();
  const { resetChanged } = useFormChangedDialogContext();

  const {
    bond,
    setBond,
    showValidator,
    validateAndSubmit,
    lock,
    unLock,
    deleteProcess,
    refetchFilesList,
  } = useBondContext();

  const showResult = useCallback(
    ({ result, isError, errors }: { result: any; isError: boolean; errors: any }) => {
      resetChanged && resetChanged();

      if (isError) {
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        window.scrollTo({ top: 0 });
      }
    },
    [addSnackbar, resetChanged]
  );

  return (
    <>
      <Paper elevation={3} style={{ padding: '1rem', borderTopLeftRadius: '0px' }} id="main-paper">
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box m={5} />
              {bond ? (
                <>
                  <FormValidationReport
                    errors={{
                      ...bond.errors,
                    }}
                  ></FormValidationReport>
                  <ProposalBond
                    bond={bond}
                    setBond={setBond}
                    showValidator={showValidator}
                    onValidateAndSubmit={validateAndSubmit}
                    showResult={showResult}
                    onDeleteProcess={deleteProcess}
                    refetchFilesList={refetchFilesList}
                    onLockProcess={lock}
                    onUnLockProcess={unLock}
                  ></ProposalBond>
                </>
              ) : undefined}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

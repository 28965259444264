import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';
import s from './style.module.scss';
import { Grid } from '@mui/material';
import { currencyFormatUS } from 'utils/currencyFormat';

export const AmountByDivision = () => {
  const { aggregatesByDivision } = useProposalAggregatesContext();

  return (
    <Grid container spacing={0}>
      <Grid item className={s.head} xs={6}>
        Division
      </Grid>
      <Grid item className={s.resultHead} xs={6}>
        Proposal Amount
      </Grid>
      {aggregatesByDivision?.proposals_monetaryBreakdownAggregates
        .filter((x) => x.valueSum)
        .map((row) => {
          return (
            <>
              <Grid item className={s.keyColumn} xs={6}>
                {row.owningDivision?.name || '--'}
              </Grid>
              <Grid item className={s.resultColumn} xs={6}>
                $ {currencyFormatUS(row.valueSum || 0)}
              </Grid>
            </>
          );
        })}
    </Grid>
  );
};

import React, { FC, useMemo } from 'react';
import {
  IProposalData,
  useComponentContext as useOpportunityContext,
} from 'template/Opportunity/ProposalContext';
import { Provider as ProposalProvider } from 'template/Proposal/ProposalContext';

import { NewProposal as NewProposalComponent } from 'pages/ProposalNew/components/NewProposal/NewProposal';
import { omit } from 'lodash';

export interface IProps {
  routes: Array<any>;
}

export const NewProposalPage: FC<IProps> = ({ routes }) => {
  const { proposal: opportunity } = useOpportunityContext();

  const preloadProposal: Partial<IProposalData> = useMemo(
    () => ({ ...omit(opportunity, ['id', 'entityType']), referenceOpportunityId: opportunity.id }),
    [opportunity]
  );

  return (
    <ProposalProvider preloadProposal={preloadProposal} activeTab="new">
      <NewProposalComponent></NewProposalComponent>
    </ProposalProvider>
  );
};

import { Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { Bonds } from 'template/Proposal/components/ProposalBonds/ProposalBonds';
import s from './style.module.scss';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { useHistory } from 'react-router-dom';
import paths from 'constants/paths';
import { useComponentContext } from 'template/Proposal/ProposalContext';
import { BondsTableContextProvider } from 'template/Bonds/BondsContext';

export interface IProposalBidPerformanceBondsListProps {}

export const ProposalBidPerformanceBondsList: FC<IProposalBidPerformanceBondsListProps> = () => {
  const history = useHistory();

  const {
    proposal: { id: proposalId },
  } = useComponentContext();

  const overridePageLoadParams = useMemo(() => {
    return {
      filterValues: {
        proposalIds: [proposalId],
      },
    };
  }, [proposalId]);

  const gotoNewBidAndPerformanceBond = useCallback(() => {
    history.push(paths.client.PROPOSAL_BID_PERFORMANCE_BOND_NEW.replace(':id', proposalId));
  }, [history, proposalId]);

  if (!proposalId) {
    return <></>;
  }

  return (
    <Grid container padding={0} spacing={2} className={s.dataBlockContainer}>
      <Grid xs={4} style={{ margin: '24px 0px 32px 0' }}>
        <ConfirmationButton
          action={gotoNewBidAndPerformanceBond}
          text="Add Bond"
        ></ConfirmationButton>
      </Grid>
      <Grid xs={12} className={s.dataBlock}>
        <Typography variant="body2" className="label-title-nocase">
          Bid & Performance Bonds:
        </Typography>
      </Grid>
      <Grid xs={12}>
        <BondsTableContextProvider
          overridePageLoadParams={overridePageLoadParams}
          initPageLoadParams={undefined}
          tableStorageKey={'ProposalBondsTable'}
        >
          <Bonds proposalId={proposalId} />
        </BondsTableContextProvider>
      </Grid>
    </Grid>
  );
};

import { FC, useState, useRef } from 'react';
import { ChartConfigProvider } from './context/ChartConfig';
import { ChartingPage, IChartingPageProps } from './Components/ChartingPage/ChartingPage';
import { ChartingTabs, ChartingTabsCode } from './Components/ChartingTabs/ChartingTabs';
import { DataSourcePage } from './Components/DataSourcePage/DataSourcePage';
import { Config, DataSourceConfigProvider, IChartingData } from './context/DataSourceConfig';
import s from './style.module.scss';

export interface IChartingProps extends IChartingPageProps {
  data: IChartingData[];
  config: Config;
}

export const Charting: FC<IChartingProps> = ({ data, config, ...props }) => {
  const [view, setView] = useState<ChartingTabsCode>('ds');

  const pageDivRef = useRef<any>();

  return (
    <div ref={pageDivRef} className={s.box}>
      <div style={{ paddingBottom: '10px' }}>
        <ChartingTabs onChange={(view) => setView(view)} />
      </div>
      <div>
        <DataSourceConfigProvider data={data} config={config}>
          <ChartConfigProvider>
            {view === 'chart' ? (
              <ChartingPage pageDivRef={pageDivRef} {...props}></ChartingPage>
            ) : undefined}
          </ChartConfigProvider>
          {view === 'ds' ? <DataSourcePage {...props}></DataSourcePage> : undefined}
        </DataSourceConfigProvider>
      </div>
    </div>
  );
};

import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { bond_bond_files } from 'graphql/proposals/types/bond';
import { BondFileType, BondType } from 'graphql/proposals/types/graphql-types';
import { bondWorkflowStates } from 'template/Proposal/components/ProposalForm/constants/bondWorkflow';
import { formatProposalFileManagementTime } from 'utils/time';

export const allBondsTableExportToExcelHead: IHeadCell[] = [
  {
    id: 'proposalName',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Name',
    transformFunction: (data, row: any | undefined) => row?.proposal?.name || 'N/A',
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',

    transformFunction: (data, row: any | undefined) => row?.party?.name || 'N/A',
  },
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Division',

    transformFunction: (data, row: any | undefined) => row?.owningDivision?.name || 'N/A',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Bond Type',

    transformFunction: (data) => (data === BondType.BID_BOND ? 'Bid Bond' : 'Performance Bond'),
  },
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Stage',
    transformFunction: (data) => {
      if (!data) {
        return 'N/A';
      }
      const stageText = bondWorkflowStates.find((state) => state.id === data)?.name;
      return stageText ?? data;
    },
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
  },
  {
    id: 'tenderName',
    numeric: false,
    disablePadding: false,
    label: 'Tender Name',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'tenderNumber',
    numeric: false,
    disablePadding: false,
    label: 'Tender Number',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'contractName',
    numeric: false,
    disablePadding: false,
    label: 'Contract Name',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'contractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Contract Number',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',

    transformFunction: (data: any) => (data ? data : 'N/A'),
    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',

    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'beneficiaryNameAndAddress',
    numeric: false,
    disablePadding: false,
    label: 'Beneficiary Name and Address',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
  },
  {
    id: 'beneficiaryPhoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Beneficiary Phone Number',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'beneficiaryFaxNumber',
    numeric: false,
    disablePadding: false,
    label: 'Beneficiary Fax Number',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'beneficiaryEmail',
    numeric: false,
    disablePadding: false,
    label: 'Beneficiary Email',
    transformFunction: (data) => data || 'N/A',
  },
  {
    id: 'recipientReceiveDeadline',
    numeric: false,
    disablePadding: false,
    label: 'Deadline for Recipient Receipt',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
  {
    id: 'validityThroughDate',
    numeric: false,
    disablePadding: false,
    label: 'Validity Through Date',

    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },

  {
    id: 'deliveryInstructions',
    numeric: false,
    disablePadding: false,
    label: 'Delivery Instructions',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
  },
  {
    id: 'issuingBankRequirements',
    numeric: false,
    disablePadding: false,
    label: 'Issuing Bank Requirements',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
  },
  {
    id: 'wordingOrFormatRequirements',
    numeric: false,
    disablePadding: false,
    label: 'Wording or Format Requirements',
    transformFunction: (data) => data?.split(/\n/).join(String.fromCharCode(10)) || 'N/A',
  },
  {
    id: 'wordingOrFormatRequirementFiles',
    numeric: false,
    disablePadding: false,
    label: 'Wording or Format Requirements Files',
    transformFunction: (data, row: any | undefined) => {
      const files: bond_bond_files[] | undefined = row.files?.filter(
        (file: bond_bond_files) => file.bondFileType === BondFileType.WORDING_OR_FORMAT_REQUIREMENT
      );
      if (!files?.length) {
        return 'N/A';
      }
      return files
        .map((file) => {
          return `${file.originalFilename} - ${
            file.event?.author?.name ?? 'Undefined Uploader'
          }, ${formatProposalFileManagementTime(file.createdAt)}`;
        })
        .join(String.fromCharCode(10));
    },
  },
  {
    id: 'bondFiles',
    numeric: false,
    disablePadding: false,
    label: 'Bond Files',
    transformFunction: (data, row: any | undefined) => {
      const files: bond_bond_files[] | undefined = row.files?.filter(
        (file: bond_bond_files) => file.bondFileType !== BondFileType.WORDING_OR_FORMAT_REQUIREMENT
      );
      if (!files?.length) {
        return 'N/A';
      }
      return files
        .map((file) => {
          return `${file.originalFilename} - ${
            file.event?.author?.name ?? 'Undefined Uploader'
          }, ${formatProposalFileManagementTime(file.createdAt)}`;
        })
        .join(String.fromCharCode(10));
    },
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created',

    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Changed',

    transformFunction: (date) =>
      new Date(date).toLocaleDateString('en-EN', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
  },
];

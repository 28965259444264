import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMutation, ApolloError } from '@apollo/client';
import { partyUpdateVariables } from 'graphql/proposals/types/partyUpdate';

import {
  PARTY_CREATE_MUTATION,
  PARTY_SOFT_DELETE,
  PARTY_UPDATE_MUTATION,
} from 'graphql/proposals/parties';
import { PartyTypeEnum } from 'graphql/proposals/types/graphql-types';

import { omit, pick } from 'lodash';

import { validators } from 'constants/validators';
import validate from 'validate.js';
import { useHistory } from 'react-router-dom';
import { party_party, party_party_events } from 'graphql/proposals/types/party';
import { useUI } from 'contexts/UiContext';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

import { paths } from '../../constants';

import { IUsePartyContactsValue, usePartyContacts } from 'hooks/partyContactsHook';
import { partyCreateVariables } from 'graphql/proposals/types/partyCreate';

import { tryUpdateProcedure } from 'utils/apollo';
import {
  IStockExchangeData,
  IUsePartyExchangesValue,
  usePartyExchanges,
} from './hooks/partyExchangesHook';
import { IPartyEvent } from 'graphql/proposals/types/IPartyEvent';
import { customFields } from './components/EventsLog/components/EventCommentLogs/hook/customFields';
import { grades, IPartiesFuzzySearchResult, usePartiesFuzzySearch } from 'hooks/partiesFuzzySearch';
import { IPartyGeo } from 'graphql/proposals/types/IPartyGeo';

export enum clientTypeDbMap {
  'CLIENT' = 'CLIENT',
  'NON-CLIENT' = 'NONCLIENT',
}

export interface IGeneralListItem {
  id: string;
  name: string;
}

interface IPartyData extends partyUpdateVariables {
  selectedClientType: { key: string; name: string };
  selectedClientDesignation?: { id: string; name: string };
  selectedDivisionOwnership?: { id: string; name: string };
  selectedFocalPointUser?: IUser;
  selectedCountry?: { id: string; name: string };
  selectedRelatedParties: any[];
  isApplicationControlled: boolean;
  projectSetupClientCode?: string | null;
  parentCompanyName?: string | null;
  isValid: boolean;
  showValidator: boolean;
  errors?: any;
  createEvent?: party_party_events;
  stockExchanges: IStockExchangeData[];
  events: IPartyEvent[] | null;
  geos: IPartyGeo[] | null;
  updatedAt?: Date;
}

const aliases: any = {
  name: 'Client Name',
  clientType: 'Client Type',
};

const partyValidatorOptions = {
  prettify: function prettify(string: string) {
    return aliases[string] || validate.prettify(string);
  },
};

const partyValidators = {
  name: validators.simpleText,
  designationId: validators.simpleText,
  type: validators.simpleText,
};

const newParty: IPartyData = {
  id: '',
  name: '',
  designationId: '',
  type: PartyTypeEnum.CLIENT,
  isActive: true,
  selectedClientType: { key: PartyTypeEnum.CLIENT, name: 'Client' },
  isValid: true,
  showValidator: false,
  isApplicationControlled: true,
  stockExchanges: [],
  selectedRelatedParties: [],
  events: [],
  geos: [],
};

interface IUser {
  id: string;
  name: string;
  email: string | null;
}

export interface IEvent {
  [id: string]: any;
}

export const TabToPageMap = {
  details: paths.client.CLIENT_DETAILS,
  'events-log': paths.client.CLIENT_EVENTS,
  new: paths.client.NEW_CLIENT,
};
export interface IContextState {
  partyId?: string;
  activeTab?: ActiveTab;
  party: IPartyData;
  originalParty: IPartyData;
  partyContacts?: IUsePartyContactsValue;
  partyExchanges?: IUsePartyExchangesValue;
  validationSummary: Array<string>;
  ts: number;
  fuzzySearchResult: IPartiesFuzzySearchResult;
}

export interface IContextActions {
  changeActiveTab: any;
  onChangeState: (cb: (old: IPartyData) => IPartyData) => void;

  onSubmitValidate: () => boolean;
  onSubmitProcess: () => void;
  onSubmitPopupProcess: () => Promise<boolean>;
  onDeleteProcess: () => void;

  refetchParty: any;
}

const initialState: IContextState = {
  party: newParty,
  originalParty: { ...newParty },
  validationSummary: [],
  ts: Date.now(),
  fuzzySearchResult: { cache: {}, grade: grades.IGNORE, search: '' },
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

export type ActiveTab = 'details' | 'events-log' | 'new';
interface IProviderProps {
  partyId?: string;
  loadedParty?: party_party | null;
  refetch?: any;
  activeTab: ActiveTab;
  loading?: boolean;
  children: any;
}

export const Provider: FC<IProviderProps> = ({
  partyId,
  loadedParty,
  refetch,
  children,
  activeTab,
  loading,
}) => {
  const { formChanged, resetChanged } = useFormChangedDialogContext();
  const history = useHistory();
  const { addSnackbar } = useUI();

  const [createPartyMutation] = useMutation(PARTY_CREATE_MUTATION);
  const [updatePartyMutation] = useMutation(PARTY_UPDATE_MUTATION);
  const [deletePartyMutation] = useMutation(PARTY_SOFT_DELETE);

  const [ts, setTs] = useState<number>(Date.now());

  const [party, setParty] = useState<IPartyData>(newParty);
  const [originalParty, setOriginalParty] = useState<IPartyData>(newParty);

  const {
    id,
    name,
    type,
    designationId,
    isValid,
    selectedDivisionOwnership,
    selectedFocalPointUser,
    selectedCountry,
  } = party;

  const fuzzySearchResult = usePartiesFuzzySearch({
    searchString: name || '',
    excludeIds: id ? [id] : [],
    countryId: selectedCountry?.id,
  });

  const partyContacts = usePartyContacts({
    loadPartyId: id,
    search: undefined,
    isActive: undefined,
  });

  const partyExchanges = usePartyExchanges({ initExchanges: party.stockExchanges });
  const { submit: submitPartyExchanges } = partyExchanges;

  const { submit: contactsSubmit, reloadContacts } = partyContacts;

  const compareFunctions = useMemo(
    () => ({
      designationId: (id: string) => loadedParty?.partyDesignation?.id === id,
      type: (id: string) => loadedParty?.partyType?.name === id,
      addressCountryId: (id: string) => loadedParty?.country?.id === id,
      divisionOwnershipId: (id: string) => loadedParty?.divisionOwnership?.id === id,
      focalPointUserId: (id: string) => loadedParty?.focalPointUser?.id === id,
    }),
    [loadedParty]
  );

  const validationSummary = useMemo(() => {
    const summary = [];
    const { errors, designationId } = party;
    let unrecognizedFields = 0;
    let recognizedFields = 0;

    if (!designationId) {
      summary.push('Client Type is required');
    }

    if (errors && Object.keys(errors).length > 0) {
      Object.keys(errors)
        .filter((key) => !['designationId'].includes(key))
        .forEach((errorKey) => {
          let found = false;
          [customFields].every((section) => {
            section.every((fieldGroup) => {
              fieldGroup.every((field) => {
                if (field.id === errorKey) {
                  // summary.push(`${field.title} (${errors[errorKey]})`);
                  // summary.push(field.title);
                  summary.push(errors[errorKey].join('. '));
                  found = true;
                }
                return !found;
              });
              return !found;
            });
            return !found;
          });
          if (!found) {
            unrecognizedFields++;
          } else {
            recognizedFields++;
          }
        });
    }

    if (unrecognizedFields === 1) {
      if (recognizedFields === 0) {
        summary.push('Please check an error found in a Client Details field');
      } else {
        summary.push('Please also check an error found in an other Client Details field');
      }
    }

    if (unrecognizedFields > 1) {
      if (recognizedFields === 0) {
        summary.push('Please check errors found in Client Details fields');
      } else {
        summary.push('Please also check errors found in other Client Details fields');
      }
    }

    return summary;
  }, [party]);

  const validateForm = useCallback((newState: any) => {
    const errors = validate(newState, partyValidators, partyValidatorOptions);
    return { ...newState, errors, isValid: errors ? false : true };
  }, []);

  const changeActiveTab = useCallback(
    (newTab: ActiveTab) => {
      if (partyId) {
        const newPath = TabToPageMap[newTab].replace(':id', partyId);
        history.push(newPath);
      } else {
        const newPath = TabToPageMap[newTab];
        history.push(newPath);
      }
    },
    [history, partyId]
  );

  useEffect(() => {
    if (!loading && partyId && !loadedParty) {
      addSnackbar!({
        text: 'Client does not exist!',
        severity: 'error',
      });
      addSnackbar!({
        text: 'Redirected to Clients list ...',
        severity: 'warning',
      });
      history.replace(paths.client.CLIENT_MANAGEMENT);
    }
  }, [loading, partyId, loadedParty, addSnackbar, history]);

  useEffect(() => {
    if (loadedParty) {
      const createEvent = loadedParty.events?.find((ev) => ev.eventType === 'PARTY_CREATED');
      const { divisionOwnership, focalPointUser, stockExchanges, assignedPartyRelationships } =
        loadedParty;

      const readyParty = {
        ...newParty,
        ...pick(loadedParty, [
          'id',
          'name',
          'alias',
          'streetAddress',
          'streetAddress2',
          'addressCity',
          'addressState',
          'addressZip',
          'isActive',
          'isApplicationControlled',
          'projectSetupClientCode',
          'parentCompanyName',
          'stockExchangeMarket',
          'notes',
          'twitterUsername',
          'linkedInVanityName',
          'websiteUrl',
          'events',
          'geos',
          'updatedAt',
        ]),
        designationId: loadedParty.partyDesignation?.id || '',
        addressCountryId: loadedParty.country?.id,
        type: PartyTypeEnum[
          clientTypeDbMap[loadedParty.partyType?.name! as keyof typeof clientTypeDbMap]
        ],

        selectedClientType: {
          key: loadedParty.partyType?.name!,
          name: loadedParty.partyType?.name === 'CLIENT' ? 'Client' : 'Non-Client',
        },
        selectedClientDesignation: loadedParty.partyDesignation
          ? { id: loadedParty.partyDesignation.id, name: loadedParty.partyDesignation.name }
          : undefined,
        selectedCountry: loadedParty.country
          ? {
              id: loadedParty.country.id,
              name: `${loadedParty.country.name} (${loadedParty.country.code})`,
            }
          : undefined,

        isValid: true,
        showValidator: false,
        createEvent,
        selectedDivisionOwnership: divisionOwnership
          ? pick(divisionOwnership, ['id', 'name'])
          : undefined,
        selectedFocalPointUser: focalPointUser
          ? pick(focalPointUser, ['id', 'name', 'email'])
          : undefined,
        stockExchanges: stockExchanges
          ? stockExchanges.map((ex) => ({
              ...omit(ex, ['__typename', 'stockMarket']),
              changed: false,
              stockMarket: ex.stockMarket
                ? {
                    ...ex.stockMarket,
                    ddLabel: `${ex.stockMarket.name}${
                      ex.stockMarket.acronym ? ' (' + ex.stockMarket.acronym + ')' : ''
                    }`,
                  }
                : undefined,
            }))
          : [],
        selectedRelatedParties: assignedPartyRelationships
          ? assignedPartyRelationships
              .filter((r) => r.otherParty?.id)
              .map((r) => ({
                key: r.otherParty?.id,
                name: r.otherParty?.name,
                clientCode: r.otherParty?.projectSetupClientCode,
                ddLabel:
                  (r.otherParty?.projectSetupClientCode || 'Non-Client') +
                  ' - ' +
                  r.otherParty?.name,
              }))
              .sort((a, b) => a.ddLabel.localeCompare(b.ddLabel))
          : [],
      };
      setParty(readyParty);
      setOriginalParty({ ...readyParty });
    }
  }, [loadedParty]);

  const onSubmitValidate = useCallback(() => {
    if (!isValid || !name || !designationId || !type) {
      setParty(validateForm({ ...party, showValidator: true }));
      return false;
    }
    return true;
  }, [designationId, isValid, name, party, type, validateForm]);

  const onSubmitProcess = useCallback(async () => {
    const id = parseInt(party.id);
    const isNewItem = !party.id || id <= 0;
    let newPartyId = undefined;

    let partyCreated = false;
    let partyUpdated = false;
    let contactsUpdated = false;
    let exchangesUpdated = false;
    let errorFound = false;

    if (!isNewItem) {
      const { selectedRelatedParties } = party;

      const oldRelatedPartiesIds: string[] =
        (loadedParty?.assignedPartyRelationships
          ?.filter((r) => r.otherParty?.id)
          .map((r) => r.otherParty?.id) as string[]) || [];

      const currentRelatedPartiesIds: string[] = selectedRelatedParties
        .filter((p) => !!p.key)
        .map((p) => p.key);

      const partyRelationshipPartyIdsToAdd = currentRelatedPartiesIds.filter(
        (id) => !oldRelatedPartiesIds?.includes(id)
      );
      const partyRelationshipPartyIdsToRemove = oldRelatedPartiesIds.filter(
        (id) => !currentRelatedPartiesIds?.includes(id)
      );

      const prepareData: partyUpdateVariables = {
        ...pick(party, [
          'id',
          'name',
          'alias',
          'type',
          'designationId',
          'addressCity',
          'addressState',
          'addressZip',
          'addressCountryId',
          'isActive',
          'streetAddress',
          'streetAddress2',
          'notes',
          'twitterUsername',
          'linkedInVanityName',
          'websiteUrl',
        ]),
        divisionOwnershipId: selectedDivisionOwnership ? selectedDivisionOwnership.id : undefined,
        focalPointUserId: selectedFocalPointUser ? selectedFocalPointUser.id : undefined,

        partyRelationshipPartyIdsToAdd: partyRelationshipPartyIdsToAdd.length
          ? partyRelationshipPartyIdsToAdd
          : undefined,
        partyRelationshipPartyIdsToRemove: partyRelationshipPartyIdsToRemove.length
          ? partyRelationshipPartyIdsToRemove
          : undefined,
      };

      const requiredFields = ['id'];
      const omitKeys: string[] = [];
      const loadedKeys = Object.keys(prepareData);
      const compareFunctionsKeys = Object.keys(compareFunctions);
      if (loadedParty) {
        Object.keys(prepareData).forEach((key) => {
          if (requiredFields.includes(key)) {
            return;
          }

          if (
            Array.isArray(prepareData[key as keyof typeof prepareData]) &&
            !(prepareData[key as keyof typeof prepareData] as any[]).length!
          ) {
            omitKeys.push(key);
            return;
          }

          if (
            loadedKeys.includes(key) &&
            prepareData[key as keyof typeof prepareData] ===
              loadedParty[key as keyof typeof loadedParty]
          ) {
            omitKeys.push(key);
            return;
          }

          if (typeof loadedParty[key as keyof typeof loadedParty] == 'boolean') {
            if (
              !loadedParty[key as keyof typeof loadedParty] ===
              !prepareData[key as keyof typeof prepareData]
            ) {
              omitKeys.push(key);
              return;
            }
          }

          if (
            compareFunctionsKeys.includes(key) &&
            prepareData[key as keyof typeof prepareData] &&
            compareFunctions[key as keyof typeof compareFunctions](
              prepareData[key as keyof typeof prepareData] as string
            )
          ) {
            omitKeys.push(key);
          }
        });
      }

      const saveData = omitKeys.length ? omit(prepareData, omitKeys) : prepareData;

      if (Object.keys(saveData).length > 1) {
        const { result, isError, errors } = await tryUpdateProcedure({
          mutation: () =>
            updatePartyMutation({
              variables: {
                ...saveData,
              },
            }),
          parseResult: (data: any) => {
            return data?.partyUpdate?.id;
          },
        });

        if (isError || !result) {
          errorFound = true;
          addSnackbar!({
            text: 'Error...' + errors?.join(' '),
            severity: 'error',
          });
        } else {
          partyUpdated = true;
        }
      }
    } else {
      const { selectedRelatedParties } = party;
      const currentRelatedPartiesIds: string[] = selectedRelatedParties
        .filter((p) => !!p.key)
        .map((p) => p.key);

      const variables: partyCreateVariables = {
        ...pick(party, [
          // 'id',
          // 'name',
          // 'type',
          // 'designationId',
          'alias',
          'addressCity',
          'addressState',
          'addressZip',
          'addressCountryId',
          'isActive',
          'streetAddress',
          'streetAddress2',
          'stockExchangeMarket',
          'notes',
          'twitterUsername',
          'linkedInVanityName',
          'websiteUrl',
        ]),
        name: party.name!,
        type: party.type as PartyTypeEnum,
        designationId: party.designationId!,

        divisionOwnershipId: selectedDivisionOwnership ? selectedDivisionOwnership.id : undefined,
        focalPointUserId: selectedFocalPointUser ? selectedFocalPointUser.id : undefined,

        partyRelationshipPartyIdsToAdd: currentRelatedPartiesIds.length
          ? currentRelatedPartiesIds
          : undefined,
      };

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createPartyMutation({
            variables,
          }),
        parseResult: (data: any) => {
          return data?.partyCreate?.id;
        },
      });

      if (isError || !result) {
        errorFound = true;
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        partyCreated = true;
        newPartyId = result;
      }
    }

    if (!errorFound) {
      const { foundError, allErrors } = await contactsSubmit(isNewItem ? newPartyId : party.id);
      if (foundError) {
        errorFound = true;
        console.log('Update Contacts Error', allErrors);
        addSnackbar!({
          text: 'Error...' + allErrors?.join(' '),
          severity: 'error',
        });
      } else {
        contactsUpdated = true;
      }
    }

    if (!errorFound) {
      const result = await submitPartyExchanges(isNewItem ? newPartyId : party.id);
      if (result?.isError) {
        errorFound = true;
        console.log('Exchange Error', result.errors);
        addSnackbar!({
          text: 'Error...' + result.errors?.join(' '),
          severity: 'error',
        });
      } else {
        exchangesUpdated = true;
      }
    }

    if (!errorFound && (partyUpdated || partyCreated || contactsUpdated || exchangesUpdated)) {
      resetChanged && resetChanged();
    }

    if (errorFound) {
      refetch && refetch();
      reloadContacts && reloadContacts();
      setTs(() => Date.now());
    } else {
      if (!partyCreated) {
        // history.push(paths.client.CLIENT_MANAGEMENT);
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });

        refetch && refetch();
        reloadContacts && reloadContacts();
        setTs(() => Date.now());
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        history.push(paths.client.CLIENT_DETAILS.replace(':id', newPartyId));
      }
    }
  }, [
    addSnackbar,
    contactsSubmit,
    createPartyMutation,
    history,
    party,
    refetch,
    resetChanged,
    selectedDivisionOwnership,
    selectedFocalPointUser,
    submitPartyExchanges,
    updatePartyMutation,
    compareFunctions,
    loadedParty,
    reloadContacts,
  ]);

  const onSubmitPopupProcess = useCallback(async () => {
    const id = parseInt(party.id);
    const isNewItem = !party.id || id <= 0;
    let newPartyId = undefined;

    let partyCreated = false;
    let partyUpdated = false;
    let contactsUpdated = false;
    let exchangesUpdated = false;
    let errorFound = false;

    if (!isNewItem) {
      const { selectedRelatedParties } = party;

      const oldRelatedPartiesIds: string[] =
        (loadedParty?.assignedPartyRelationships
          ?.filter((r) => r.otherParty?.id)
          .map((r) => r.otherParty?.id) as string[]) || [];
      const currentRelatedPartiesIds: string[] = selectedRelatedParties
        .filter((p) => !!p.key)
        .map((p) => p.key);

      const partyRelationshipPartyIdsToAdd = currentRelatedPartiesIds.filter(
        (id) => !oldRelatedPartiesIds?.includes(id)
      );
      const partyRelationshipPartyIdsToRemove = oldRelatedPartiesIds.filter(
        (id) => !currentRelatedPartiesIds?.includes(id)
      );

      const variables: partyUpdateVariables = {
        ...pick(party, [
          'id',
          'name',
          'alias',
          'type',
          'designationId',
          'addressCity',
          'addressState',
          'addressZip',
          'addressCountryId',
          'isActive',
          'streetAddress',
          'streetAddress2',
          'notes',
          'twitterUsername',
          'linkedInVanityName',
          'websiteUrl',
        ]),
        divisionOwnershipId: selectedDivisionOwnership ? selectedDivisionOwnership.id : undefined,
        focalPointUserId: selectedFocalPointUser ? selectedFocalPointUser.id : undefined,

        partyRelationshipPartyIdsToAdd: partyRelationshipPartyIdsToAdd.length
          ? partyRelationshipPartyIdsToAdd
          : undefined,
        partyRelationshipPartyIdsToRemove: partyRelationshipPartyIdsToRemove.length
          ? partyRelationshipPartyIdsToRemove
          : undefined,
      };

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          updatePartyMutation({
            variables,
          }),
        parseResult: (data: any) => {
          return data?.partyUpdate?.id;
        },
      });

      if (isError || !result) {
        errorFound = true;
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        partyUpdated = true;
      }
    } else {
      const { selectedRelatedParties } = party;
      const variables: partyCreateVariables = {
        ...pick(party, [
          // 'id',
          // 'name',
          // 'type',
          // 'designationId',
          'alias',
          'addressCity',
          'addressState',
          'addressZip',
          'addressCountryId',
          'isActive',
          'streetAddress',
          'streetAddress2',
          'stockExchangeMarket',
          'notes',
          'associatedClients',
          'twitterUsername',
          'linkedInVanityName',
          'websiteUrl',
        ]),
        name: party.name!,
        type: party.type as PartyTypeEnum,
        designationId: party.designationId!,

        divisionOwnershipId: selectedDivisionOwnership ? selectedDivisionOwnership.id : undefined,
        focalPointUserId: selectedFocalPointUser ? selectedFocalPointUser.id : undefined,

        partyRelationshipPartyIdsToAdd: selectedRelatedParties.length
          ? selectedRelatedParties.map((party) => party.key)
          : undefined,
      };

      const { result, isError, errors } = await tryUpdateProcedure({
        mutation: () =>
          createPartyMutation({
            variables,
          }),
        parseResult: (data: any) => {
          return data?.partyCreate?.id;
        },
      });

      if (isError || !result) {
        errorFound = true;
        addSnackbar!({
          text: 'Error...' + errors?.join(' '),
          severity: 'error',
        });
      } else {
        addSnackbar!({
          text: 'Success',
          severity: 'success',
        });
        partyCreated = true;
        newPartyId = result;
      }
    }

    if (!errorFound) {
      const { foundError, allErrors } = await contactsSubmit(isNewItem ? newPartyId : party.id);
      if (foundError) {
        errorFound = true;
        console.log('Update Contacts Error', allErrors);
        addSnackbar!({
          text: 'Error...' + allErrors?.join(' '),
          severity: 'error',
        });
      } else {
        contactsUpdated = true;
      }
    }

    if (!errorFound) {
      const result = await submitPartyExchanges(isNewItem ? newPartyId : party.id);
      if (result?.isError) {
        errorFound = true;
        console.log('Exchange Error', result.errors);
        addSnackbar!({
          text: 'Error...' + result.errors?.join(' '),
          severity: 'error',
        });
      } else {
        exchangesUpdated = true;
      }
    }

    if (!errorFound && (partyUpdated || partyCreated || contactsUpdated || exchangesUpdated)) {
      resetChanged && resetChanged();
    }

    if (errorFound) {
      refetch && refetch();
      return false;
    } else {
      return true;
    }
  }, [
    addSnackbar,
    contactsSubmit,
    createPartyMutation,
    party,
    refetch,
    resetChanged,
    selectedDivisionOwnership,
    selectedFocalPointUser,
    submitPartyExchanges,
    updatePartyMutation,
    loadedParty?.assignedPartyRelationships,
  ]);

  const onDeleteProcess = useCallback(async () => {
    const variables = pick(party, ['id']);
    try {
      const { data } = await deletePartyMutation({
        variables,
      });
      if (data.partySoftDelete) {
        resetChanged && resetChanged();
        addSnackbar!({ text: 'Client is deleted', severity: 'success' });
        history.push(paths.client.CLIENT_MANAGEMENT);
      } else {
        addSnackbar!({ text: 'Unable to process request, please try again', severity: 'error' });
      }
    } catch (error) {
      apolloErrorHandler(addSnackbar!)(error as ApolloError);
    }
  }, [addSnackbar, deletePartyMutation, history, party, resetChanged]);

  const onChangeState = useCallback(
    (cb: (oldState: IPartyData) => IPartyData) => {
      formChanged && formChanged();
      setParty((oldState) => {
        const newState = cb(oldState);
        return validateForm(newState);
      });
    },
    [setParty, formChanged, validateForm]
  );

  return (
    <ComponentContext.Provider
      value={{
        partyId,
        party,
        originalParty,
        onChangeState,
        onSubmitValidate,
        onSubmitProcess,
        onSubmitPopupProcess,
        onDeleteProcess,
        partyContacts,
        partyExchanges,
        activeTab,
        changeActiveTab,
        refetchParty: refetch,
        validationSummary,
        ts,
        fuzzySearchResult,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);

import { IGeneralListItem } from '../interface';

export const chancesOfSuccessOptions: IGeneralListItem[] = [
  { id: 'LOW', name: 'Low' },
  { id: 'MEDIUM', name: 'Medium' },
  { id: 'HIGH', name: 'High' },
];

export const priceTypeOptions: IGeneralListItem[] = [
  { id: 'HOURLY_BASED', name: 'Hourly Based' },
  { id: 'VALUE_BASED', name: 'Value Based' },
];

export const submissionMethodOptions: IGeneralListItem[] = [
  { id: 'email', name: 'Email' },
  { id: 'document', name: 'Phisycal Document' },
];

export const reportTypeOptions: IGeneralListItem[] = [
  { id: 'Executive', name: 'Executive' },
  { id: 'Technical', name: 'Technical' },
  { id: 'Executive;Technical', name: 'Executive & Technical' },
];

export const yesNoOptions: IGeneralListItem[] = [
  { id: '1', name: 'Yes' },
  { id: '0', name: 'No' },
];

export const trueFalseAsYesNoOptions: IGeneralListItem[] = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

import React from 'react';
import { ProposalEvents as ProposalEventsTemplate } from 'template/Opportunity/components/ProposalEvents/ProposalEvents';
import { ProposalFrame } from 'template/Opportunity/components/ProposalFrame/ProposalFrame';

export const ProposalEvents = () => {
  return (
    <ProposalFrame>
      <ProposalEventsTemplate></ProposalEventsTemplate>
    </ProposalFrame>
  );
};

import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { PartyMainTabs } from '../PartyMainTabs/PartyMainTabs';
import { useComponentContext } from 'template/Party/PartyContext';

export interface IPartyFrameProps {
  id: string;
  children: any;
}

export const PartyFrame: FC<IPartyFrameProps> = ({ children, id }) => {
  const { activeTab, changeActiveTab } = useComponentContext();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container
      maxWidth="lg"
      style={{ ...(!matches ? { padding: '0px' } : undefined), marginTop: '65px' }}
    >
      <Box m={4} />
      <div style={{ width: 'fit-content' }}>
        {activeTab ? (
          <PartyMainTabs
            changeView={(newTab) => {
              changeActiveTab(newTab);
            }}
            currentView={activeTab}
            id={id}
          ></PartyMainTabs>
        ) : undefined}
      </div>
      <Paper elevation={3} style={{ padding: '2rem', borderTopLeftRadius: '0px' }} id="main-paper">
        {children}
      </Paper>
    </Container>
  );
};

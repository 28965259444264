import {
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';

import { Delete } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { ICompetitor } from 'template/Opportunity/interfaces';
import { CurrencyInputField } from '../CurrencyInputField/CurrencyInputField';
import { TextInputField } from 'components/TextInputField/TextInputField';
import s from './style.module.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export interface ICompetitorDetailsProps {
  competitors: Array<ICompetitor>;
  onDelete: (index: number) => () => void;
  setValue: (index: number) => (value: Partial<ICompetitor>) => void;
}

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export const CompetitorDetails: FC<ICompetitorDetailsProps> = ({
  competitors,
  onDelete,
  setValue,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const onNameChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(index)({ competitorName: e.target.value });
    },
    [setValue]
  );

  const onBidValueChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(index)({ bidValue: parseInt(e.target.value) });
    },
    [setValue]
  );

  const onHasWonBidChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(index)({ hasWonBid: e.target.checked });
    },
    [setValue]
  );

  let index = -1;
  return (
    <div style={{ maxWidth: '800px' }}>
      <Grid container padding={0} spacing={1} className={s.dataBlockContainer}>
        {competitors
          .filter((proposalCompetitor) => !proposalCompetitor.deleted)
          .map((proposalCompetitor: ICompetitor) => {
            index++;
            const { bidValue, hasWonBid, competitorName } = proposalCompetitor;

            return (
              <Grid xs={12} key={index} className={s.dataBlock}>
                <div className={s.box}>
                  <Grid container padding={0} spacing={matches ? 6 : 2}>
                    <Grid xs={12} md={5} className={s.item}>
                      <TextInputField
                        value={competitorName || ''}
                        onChange={onNameChange(index)}
                      ></TextInputField>
                    </Grid>
                    <Grid xs={12} md={3} className={s.item}>
                      <CurrencyInputField
                        decimalPlaces={0}
                        value={bidValue}
                        onChange={onBidValueChange(index)}
                      ></CurrencyInputField>
                    </Grid>
                    <Grid xs={8} md={3} className={s.item}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={!!hasWonBid}
                            onChange={onHasWonBidChange(index)}
                          ></Switch>
                        }
                        label="Winning Bid"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid xs={4} md={1} className={s.item}>
                      <Tooltip title="Delete competitor details">
                        <IconButton
                          size="small"
                          onClick={debounce(onDelete(index), DEBOUNCE_TIMEOUT)}
                          className={classes.hoverWarning}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

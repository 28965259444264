import { FC, useMemo, useState } from 'react';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';
import { SelectDataSource } from '../../../SelectDataSource/SelectDataSource';

import { DataSource } from 'template/Charting/context/DataSourceConfig';
import {
  aggregateFunctionHash,
  conversionFunctionHash,
  sortFunctionHash,
} from 'template/Charting/Components/SelectDataSource/constants';

export interface IDataSourceItemProps {
  column: DataSource;
  setColumn: (cb: (record: DataSource) => DataSource) => void;
  onDelete: () => void;
}

export const DataSourceItem: FC<IDataSourceItemProps> = ({ column, setColumn, onDelete }) => {
  const desc = useMemo(() => {
    const { sortFunctionId, conversionFunctionId, aggregateFunctionId } = column;
    const codes = [
      conversionFunctionId ? conversionFunctionHash[conversionFunctionId].short : undefined,
      aggregateFunctionId ? aggregateFunctionHash[aggregateFunctionId].short : undefined,
      sortFunctionId ? sortFunctionHash[sortFunctionId].short : undefined,
    ].filter((code) => code);
    return codes.filter((code) => !!code).join(', ');
  }, [column]);

  const [expanded, setExpanded] = useState<boolean>(column.sheetIndex < 0 || !column.fieldKey);

  return (
    <Accordion defaultExpanded={expanded} onChange={() => setExpanded((exp) => !exp)}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto',
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          pointerEvents: 'none',
          fontSize: '0.9rem',
          margin: '0px !important',
          minHeight: '0px',
        }}
      >
        <DragIndicatorIcon fontSize="small" className="stakeholder-drag-icon" />
        {column.sheetIndex >= 0 && column.fieldKey ? (
          <>
            {column.fieldName}{' '}
            {desc ? (
              <>
                <br />({desc})
              </>
            ) : (
              ''
            )}
          </>
        ) : undefined}
      </AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <SelectDataSource
            setState={setColumn}
            state={column}
            dictionary={{
              notSelectedName: 'Select Data Source',
              notSelectedFieldName: 'Select Data Field',
              title: 'Data Field Configuration',
            }}
            onDelete={onDelete}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {} from 'graphql/proposals/types/proposalDocuments';

import { formatProposalFileManagementTime } from 'utils/time';
import './index.scss';
import s from './style.module.scss';
import { capitalizeAllWordsFirstLetterUS } from 'utils/formats';

import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AlertDialog, ConfirmationDialog, UserInitials } from 'components';
import { Skeleton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import { PROPOSAL_DOCUMENT_SOFT_DELETE } from 'graphql/proposals/fileManager';
import { id } from 'utils/id';
import { IProposalDocument } from 'graphql/proposals/types/IProposalDocument';
import { FileDotIcon } from 'template/Proposal/components/FilesList.tsx/components/FileDotIcon/FileDotIcon';
import { IProposalDocumentUser } from 'graphql/proposals/types/IProposalDocumentUser';

const useStyles = makeStyles((theme) => ({
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface IProposalDocumentInfo {
  proposalDocument: IProposalDocument;
  approvedDateTs: number | undefined;
  proposalId: string;
  refetchProposal: (props: any) => Promise<any>;
  reviewers?: IProposalDocumentUser[];
  disabled?: boolean;
}

export const ProposalDocumentInfo: FC<IProposalDocumentInfo> = ({
  proposalDocument,
  approvedDateTs,
  proposalId,
  refetchProposal,
  reviewers,
  disabled,
}) => {
  const [deleteProposalDocumentConfirmationOpen, setDeleteProposalDocumentConfirmationOpen] =
    useState(false);
  const [deleteProposalDocumentConfirmationComment, setDeleteProposalDocumentConfirmationComment] =
    useState('');

  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  const [mutationErrorOpen, setMutationErrorOpen] = useState(false);

  const [openReviewersWarning, setReviewersWarning] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const { versions } = proposalDocument;

  const lastVersion = versions
    .slice()
    .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))[0];

  const { version: minorVersion } = lastVersion;
  const { originalFilename, downloadUrl, blobName, createdAt, event, proposalFileType } =
    lastVersion.file!;

  const [deleteProposalDocument, { loading: deletingProposalDocument }] = useMutation(
    PROPOSAL_DOCUMENT_SOFT_DELETE
  );

  const disableDelete = useMemo(() => {
    const inReviewBy =
      reviewers?.filter((reviewer) => reviewer?.reviewStatus === 'REVIEW_REQUESTED') || [];
    return inReviewBy.length > 0;
  }, [reviewers]);

  const deleteProposalDocumentHandler = useCallback(() => {
    if (disableDelete) {
      setReviewersWarning(true);
      return;
    }

    setDeleteProposalDocumentConfirmationOpen(true);
  }, [disableDelete]);

  const deleteProposalDocumentConfirmationCloseHandler = useCallback(
    (ok: boolean): void => {
      setDeleteProposalDocumentConfirmationOpen(false);

      if (ok) {
        setIsRefetching(true);

        deleteProposalDocument({
          variables: {
            proposalId: proposalId,
            proposalDocumentId: proposalDocument.id,
            comment: deleteProposalDocumentConfirmationComment,
          },
        })
          .then(() => {
            refetchProposal({ variables: { id: id() } }).then(() => {
              setIsRefetching(false);
            });
          })
          .catch((e) => {
            setMutationErrorMessage(e?.message);
            setMutationErrorOpen(true);
          });
      }
      setDeleteProposalDocumentConfirmationComment('');
    },
    [
      deleteProposalDocument,
      refetchProposal,
      proposalDocument.id,
      proposalId,
      deleteProposalDocumentConfirmationComment,
    ]
  );

  const somethingIsLoading = (): boolean => {
    return deletingProposalDocument || isRefetching;
  };

  const reviewersInfo = useMemo(() => {
    const approvedBy =
      reviewers?.filter((reviewer) => reviewer?.reviewStatus === 'REVIEW_APPROVED') || [];
    const declinedBy =
      reviewers?.filter((reviewer) => reviewer?.reviewStatus === 'REVIEW_DISAPPROVED') || [];
    const inReviewBy =
      reviewers?.filter((reviewer) => reviewer?.reviewStatus === 'REVIEW_REQUESTED') || [];

    const reviewersInitials = [...approvedBy, ...inReviewBy, ...declinedBy].map((reviewer) => {
      return (
        <Tooltip
          key={reviewer?.user?.email || ''}
          title={`${reviewer?.user?.name} (${reviewer?.user?.email})`}
        >
          <div style={{ display: 'inline-block', transform: 'scale(0.7)' }}>
            <UserInitials
              {...reviewer?.user}
              divStyle={
                reviewer.reviewStatus === 'REVIEW_REQUESTED'
                  ? { backgroundColor: 'yellow', color: 'black' }
                  : reviewer.reviewStatus === 'REVIEW_DISAPPROVED'
                  ? { backgroundColor: 'red' }
                  : { backgroundColor: 'green' }
              }
            />
          </div>
        </Tooltip>
      );
    });
    return reviewersInitials;
  }, [reviewers]);

  const hasPendingReviewersMessage = useMemo(() => {
    return (
      <>
        The Document has pending reviewers. <br />
        Please, recall review requests. <br />
        If required, enable coresponding reviewers first. <br />
      </>
    );
  }, []);

  const classes = useStyles();
  const comment = JSON.parse(event?.payload || '{}').comment;
  return (
    <div className={s.FileInfoComponent}>
      <AlertDialog
        title="Error"
        message={mutationErrorMessage}
        open={mutationErrorOpen}
        onClose={() => {
          setMutationErrorOpen(false);
        }}
      />
      <AlertDialog
        title="Reviewers List - Action is Required"
        message={hasPendingReviewersMessage}
        open={openReviewersWarning}
        onClose={() => {
          setReviewersWarning(false);
        }}
      />
      <ConfirmationDialog
        title="Delete proposalDocument"
        message={`Are you sure you want to delete proposalDocument ${originalFilename}?`}
        comment={deleteProposalDocumentConfirmationComment}
        setComment={setDeleteProposalDocumentConfirmationComment}
        open={deleteProposalDocumentConfirmationOpen}
        onClose={deleteProposalDocumentConfirmationCloseHandler}
      />
      <div style={{ display: 'flex' }}>
        <div style={{ width: '46px' }}>
          <FileDotIcon></FileDotIcon>
        </div>
        <div className={s.fileInfo}>
          {downloadUrl && downloadUrl !== '' ? (
            <div
              className={'file-archive-item'}
              style={{
                overflow: 'hidden',
              }}
            >
              <Tooltip
                title={`Proposal Document File to Download: ${blobName}`}
                placement="bottom-start"
                arrow
              >
                <a href={downloadUrl} className={s.documentLink} target="_blank" rel="noreferrer">
                  {originalFilename}
                </a>
              </Tooltip>
            </div>
          ) : (
            blobName
          )}
          <div style={{ wordBreak: 'break-word' }}>
            {capitalizeAllWordsFirstLetterUS(
              proposalFileType?.toLowerCase().replaceAll('_', ' ') || '<unknown>'
            )}
            , v1.{minorVersion}
          </div>
          <div>
            {event?.author?.user?.name ? <>{event?.author?.user?.name}</> : 'Undefined Uploader'},{' '}
            {formatProposalFileManagementTime(createdAt)}
          </div>
          {reviewersInfo}
          <br />
        </div>
        <div>
          {!disabled ? (
            <IconButton
              edge="end"
              onClick={debounce(() => {
                deleteProposalDocumentHandler();
              }, DEBOUNCE_TIMEOUT)}
              style={{ padding: '12px' }}
              className={classes.hoverWarning}
            >
              <Tooltip title={`ProposalDocumentname: ${blobName}`} placement="bottom-start" arrow>
                <DeleteIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          ) : undefined}
        </div>
      </div>
      {comment ? <div>{JSON.parse(event?.payload || '{}').comment}</div> : undefined}
      <div style={{ display: 'flex' }}>
        <div style={{ width: '46px' }}></div>
        <div className={s.fileInfo}>
          <Box mt={2} />
          <Divider />
        </div>
      </div>
      {somethingIsLoading() && <Skeleton variant="rectangular" animation="wave" height={200} />}
    </div>
  );
};

import { gql } from '@apollo/client';
import { IDivisionName, IPartyRef } from './partiesFragments';

export const IProposalAggregate = gql`
  fragment IProposalAggregate on ProposalAggregate {
    count
    bidProposedValueSum
    stage
    partyId
    owningDivisionId
    party {
      ...IPartyRef
    }
    owningDivision {
      ...IDivisionName
    }
    successChance
  }
  ${IPartyRef}
  ${IDivisionName}
`;

import { FC, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_PARTY } from 'graphql/proposals/parties';
import { party, party_party } from 'graphql/proposals/types/party';
import { DialogForm } from 'components/DialogForm/DialogForm';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Provider as PartyProvider } from 'template/Party/PartyContext';
import { PartyContent } from './components/PartyContent/PartyContent';

export interface IEditClientDialog {
  id: string;
  onClose: () => void;
}

export const EditClientDialog: FC<IEditClientDialog> = ({ id, onClose }) => {
  const [loadedParty, setLoadedParty] = useState<party_party | null>();

  const [loadParty, { data, loading, called, refetch, error }] = useLazyQuery<party>(GET_PARTY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const load = useCallback(
    (id: string) => {
      const variables = {
        id,
      };

      if (called) {
        refetch!(variables);
      } else {
        loadParty({ variables });
      }
    },
    [loadParty, refetch, called]
  );

  useEffect(() => {
    if (data && !loading && !error) {
      setLoadedParty(data.party);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (id) {
      load(id);
    }
  }, [id, load]);

  return (
    <DialogForm
      dialogTitle={
        'CLIENT DETAILS (ID:' +
        (loadedParty?.projectSetupClientCode || loadedParty?.id || '...') +
        ')'
      }
      onClose={onClose}
      open={true}
      maxWidth="md"
    >
      <LoadingOverlay spinner active={loading} text="Loading your content...">
        <PartyProvider activeTab="details" loadedParty={data?.party} refetch={refetch}>
          <PartyContent onClose={onClose}></PartyContent>
        </PartyProvider>
      </LoadingOverlay>
    </DialogForm>
  );
};

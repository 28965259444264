import { FC, useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps, Checkbox } from '@mui/material';
import s from './style.module.scss';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    '&[data-shrink="false"]': {
      top: '-10px',
    },
  },
}));

export interface ISelectSeriesArrState {
  selectedKeys: string[];
}

export interface ISelectSeriesArrProps extends SelectProps {
  dictionary: {
    title: string;
    notSelectedName: string; // Sheet Name
  };
  options: { key: string; name: string }[];

  state?: ISelectSeriesArrState;
  setState: (cb: (record?: ISelectSeriesArrState) => ISelectSeriesArrState) => void;
  props?: { [id: string]: any };
}

export const SelectSeriesArr: FC<ISelectSeriesArrProps> = ({
  dictionary,
  options,
  state,
  setState,
  ...props
}) => {
  const extendedOptions = useMemo(() => {
    return options?.length ? [...options] : [];
  }, [options]);

  const classes = useStyles();

  return (
    <>
      <div className={s.field}>
        <FormControl fullWidth>
          <InputLabel id="definition-label" className={classes.inputLabel}>
            {dictionary.title}
          </InputLabel>
          <Select
            labelId="definition-label"
            id="definition"
            value={state?.selectedKeys?.length ? state?.selectedKeys : []}
            label={`${dictionary.title} Data Source`}
            onChange={(ev) => {
              setState((state) => ({
                ...state,
                selectedKeys: ev.target.value as string[],
              }));
            }}
            multiple
            renderValue={() =>
              state?.selectedKeys?.length && state?.selectedKeys?.length > 1
                ? 'Multiple'
                : !state?.selectedKeys?.length
                ? 'None'
                : extendedOptions.find((item) => item.key === state?.selectedKeys[0])?.name
            }
            {...props}
          >
            {extendedOptions.map((item) => (
              <MenuItem value={item.key}>
                <Checkbox
                  checked={
                    !!(state?.selectedKeys?.length && state?.selectedKeys.includes(item.key))
                  }
                />
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

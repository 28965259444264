import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent, SelectInputProps } from '@mui/material/Select/SelectInput';
import { reduce } from 'lodash';
import { useDebounce } from 'hooks/debounceHook';
import { DEBOUNCE_TIMEOUT } from 'constants/config';

export interface IHierarchyItem {
  [id: string]: any;
  id: string;
  level: number;
  name: string;
}

export interface IHierarchyMultipleSelectProps extends Partial<SelectInputProps> {
  items?: IHierarchyItem[];
  selected?: string[];
  selectedChanged?: (value: string[] | string) => void;
  selectMode?: 'leafOnly' | 'any';
}

export const HierarchyMultipleSelect: FC<IHierarchyMultipleSelectProps> = ({
  items,
  selected,
  selectedChanged,
  selectMode = 'any',
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { debounceByName } = useDebounce();

  const [localSelected, setLocalSelected] = useState<string[] | undefined>();

  useEffect(() => {
    setLocalSelected((old) => {
      if (JSON.stringify(old) !== JSON.stringify(selected)) {
        return selected ? [...selected] : undefined;
      }
      return old;
    });
  }, [selected]);

  const getUpdateSelectedFn = useCallback(
    (newValue: string[]) => () => {
      selectedChanged && selectedChanged(newValue);
    },
    [selectedChanged]
  );

  const changeSelected = useCallback(
    (newValue: string[]) => {
      setLocalSelected(newValue);
      debounceByName('getUpdateSelectedFn', getUpdateSelectedFn(newValue), 2 * DEBOUNCE_TIMEOUT);
    },
    [getUpdateSelectedFn, debounceByName]
  );

  const selectedIds = useMemo(() => {
    return localSelected && Array.isArray(localSelected) ? localSelected : [];
  }, [localSelected]);

  const changeSubtree = useCallback(
    (
      selectedItemsIds: string[],
      items: IHierarchyItem[],
      nodeId: string,
      operation: 'add' | 'remove'
    ) => {
      const indexOfChangedItem = items.findIndex((item) => item.id === nodeId);
      const changeItemsIds: string[] = [];
      let selectedIds;

      const changedItemLevel = items[indexOfChangedItem].level;
      for (
        let i = indexOfChangedItem + 1;
        i < items.length && items[i].level > changedItemLevel;
        i++
      ) {
        changeItemsIds.push(items[i].id);
      }
      if (operation === 'add') {
        selectedIds = [...selectedItemsIds, ...changeItemsIds];
      } else {
        selectedIds = selectedItemsIds.filter((itemId) => !changeItemsIds.includes(itemId));
      }
      return selectedIds;
    },
    []
  );

  const changeInternalNodes = useCallback((selectedItemsIds: string[], items: IHierarchyItem[]) => {
    const hash: { [id: string]: boolean } = {};
    const selected: { [id: string]: boolean } = reduce(
      selectedItemsIds,
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );

    for (let i = items.length - 1; i >= 0; i--) {
      const { id, level } = items[i];
      if (Object.hasOwn(hash, level + 1)) {
        selected[id] = hash[level + 1];
        delete hash[level + 1];
      }

      if (Object.hasOwn(hash, level)) {
        hash[level] = hash[level] && !!selected[id];
      } else {
        hash[level] = !!selected[id];
      }
    }
    return Object.keys(selected).filter((key) => selected[key]);
  }, []);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      if (!items) {
        return;
      }

      let newList: string[] | undefined = [...(event.target.value as string[])];

      if (selectMode === 'leafOnly') {
        let changedId: string;
        let operation: 'add' | 'remove';
        if (newList.length > selectedIds.length) {
          changedId = newList.find((item) => !selectedIds.includes(item))!;
          operation = 'add';
        } else {
          changedId = selectedIds.find((item) => !newList?.includes(item))!;
          operation = 'remove';
        }

        newList = changeSubtree(newList, items, changedId, operation);
        newList = changeInternalNodes(newList, items);
      }
      if (newList.length === 0) {
        newList = undefined;
      }

      changeSelected(newList as string[]);
    },
    [changeInternalNodes, changeSubtree, items, changeSelected, selectedIds, selectMode]
  );

  return items?.length ? (
    <FormControl variant="outlined" fullWidth style={{ backgroundColor: 'white' }}>
      <Select
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        {...props}
        multiple
        displayEmpty
        value={selectedIds}
        onChange={handleChange}
        renderValue={(selectedIds) =>
          selectedIds.length > 1
            ? 'Multiple'
            : !selectedIds?.length
            ? 'All'
            : items.find((item) => item.id === selectedIds[0])?.name
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {items?.map((item) => {
          const { name, id, level } = item;
          return (
            <MenuItem
              key={id}
              value={id}
              style={{
                paddingLeft: `${level * 15}px`,
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              <ListItemIcon>
                <Checkbox checked={selectedIds.includes(id)} style={{ padding: '6px' }} />
              </ListItemIcon>
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : (
    <></>
  );
};

import { FormControl, InputLabel, MenuItem, Select, IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import s from './style.module.scss';
import { makeStyles } from '@mui/styles';
import {
  AggregateFunctionId,
  ConversionFunctionId,
  SortFunctionId,
  aggregateFunction,
  conversionFunction,
  optionsByType,
  sortFunction,
} from './constants';

import { Delete as DeleteIcon } from '@mui/icons-material';

import { DataSource } from 'template/Charting/context/DataSourceConfig';
import { debounce } from 'lodash';
import { DEBOUNCE_TIMEOUT } from 'constants/config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  menu: {
    maxHeight: 600,
  },
  hoverWarning: {
    '&:hover': {
      color: 'red',
    },
  },
}));

export interface ISelectDataSourceProps {
  dictionary: {
    title: string;
    notSelectedName: string; // Sheet Name
    notSelectedFieldName: string;
  };
  state: DataSource;
  setState: (cb: (record: DataSource) => DataSource) => void;
  onDelete: () => void;
}

export const SelectDataSource: FC<ISelectDataSourceProps> = ({
  dictionary,
  state,
  setState,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <>
      {optionsByType[state.fieldType].conversionFunctionIds.length > 1 ? (
        <div className={s.field}>
          <FormControl fullWidth>
            <InputLabel id="math-label">Conversion Function</InputLabel>
            <Select
              disabled={!state.fieldKey}
              labelId="math-label"
              id="field-math"
              value={state.conversionFunctionId}
              label={`Conversion Function`}
              onChange={(ev) => {
                setState((state) => ({
                  ...state,
                  conversionFunctionId: ev.target.value as ConversionFunctionId,
                }));
              }}
              MenuProps={{
                className: classes.menu,
              }}
            >
              {conversionFunction
                .filter((f) => optionsByType[state.fieldType].conversionFunctionIds.includes(f.id))
                .map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      ) : undefined}

      {optionsByType[state.fieldType].aggregateFunctionIds.length > 1 ? (
        <div className={s.field}>
          <FormControl fullWidth>
            <InputLabel id="math-label">Aggregate Function</InputLabel>
            <Select
              disabled={!state.fieldKey}
              labelId="math-label"
              id="field-math"
              value={state.aggregateFunctionId}
              label={`Aggregate Function`}
              onChange={(ev) => {
                setState((state) => ({
                  ...state,
                  aggregateFunctionId: ev.target.value as AggregateFunctionId,
                }));
              }}
              MenuProps={{
                className: classes.menu,
              }}
            >
              {aggregateFunction
                .filter((f) => optionsByType[state.fieldType].aggregateFunctionIds.includes(f.id))
                .map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      ) : undefined}

      {optionsByType[state.fieldType].sortFunctionIds.length > 1 ? (
        <div className={s.field}>
          <FormControl fullWidth>
            <InputLabel id="math-label">Ordering</InputLabel>
            <Select
              labelId="math-label"
              id="field-math"
              value={state.sortFunctionId}
              label={`Ordering`}
              onChange={(ev) => {
                setState((state) => ({
                  ...state,
                  sortFunctionId: ev.target.value as SortFunctionId,
                }));
              }}
              MenuProps={{
                className: classes.menu,
              }}
            >
              {sortFunction
                .filter((f) => optionsByType[state.fieldType].sortFunctionIds.includes(f.id))
                .map((item: any) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      ) : undefined}
      <div className={s.buttons}>
        <IconButton
          edge="end"
          onClick={debounce(() => {
            onDelete();
          }, DEBOUNCE_TIMEOUT)}
          style={{ padding: '12px 0 0 0', margin: '0px' }}
          className={classes.hoverWarning}
        >
          <Tooltip title={`Delete column definition`} placement="bottom-start" arrow>
            <DeleteIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </div>
    </>
  );
};

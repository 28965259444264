import React from 'react';
import { ProposalDetails as ProposalDetailsTemplate } from 'template/Opportunity/components/ProposalDetails/ProposalDetails';
import { ProposalFrame } from 'template/Opportunity/components/ProposalFrame/ProposalFrame';

export const ProposalDetails = () => {
  return (
    <ProposalFrame>
      <ProposalDetailsTemplate></ProposalDetailsTemplate>
    </ProposalFrame>
  );
};

import { useMemo } from 'react';

interface IGeneralListItem {
  id: string;
  name: string;
}

export interface IUseGenericArrayToDropdownProps {
  selectedItem?: IGeneralListItem | IGeneralListItem[];
  options: IGeneralListItem[];
  sort?: (a: any, b: any) => number;
}

export const useGenericArrayToDropdown = ({
  selectedItem,
  options,
  sort,
}: IUseGenericArrayToDropdownProps) => {
  const items = useMemo(() => {
    if (Array.isArray(selectedItem)) {
      const missingOptions: any = [];
      let expanded = false;
      selectedItem.forEach((selected) => {
        if (!options.find((option) => selected.id === option.id)) {
          missingOptions.push(selected);
        }
        expanded = true;
      });
      const result = expanded ? [...missingOptions, ...options] : options;
      return sort ? [...result].sort(sort) : result;
    }
    if (!selectedItem || options.find((item) => item.id === selectedItem.id)) {
      return sort ? [...options].sort(sort) : options;
    }
    return sort ? [selectedItem, ...options].sort(sort) : [selectedItem, ...options];
  }, [options, selectedItem, sort]);

  return {
    items,
    selectedItem,
  };
};

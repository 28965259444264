import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_PARTY_CONTACTS,
  PARTY_CONTACT_CREATE_MUTATION,
  PARTY_CONTACT_SOFT_DELETE_MUTATION,
  PARTY_CONTACT_UPDATE_MUTATION,
} from 'graphql/proposals/partyContact';
import { IPartyContact } from 'graphql/proposals/types/IPartyContact';
import { partyContacts } from 'graphql/proposals/types/partyContacts';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { tryUpdateProcedure } from 'utils/apollo';

const rowsPerPage = 100;

export interface IUsePartyContactsProps {
  loadPartyId?: string;
  search?: string;
  isActive?: boolean;
}

export interface IUsePartyContactsValue {
  contacts: IContact[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  deleteContact: (index: number) => void;
  updateContact: (index: number, value: any) => void;
  addContact: (contact?: any | undefined) => void;
  submit: (partyId: string) => any;
  reloadContacts: () => void;
}

export interface IContact extends Omit<IPartyContact, '__typename'> {
  changed: boolean;
  deleted: boolean;
  isActive: boolean;
}

const getNewContact = (): IContact => ({
  id: '',
  partyId: '',
  contactType: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  phone: '',
  linkedInUrl: '',
  notes: '',
  changed: false,
  deleted: false,
  isActive: true,
});
export const usePartyContacts = ({
  loadPartyId,
  search,
  isActive,
}: IUsePartyContactsProps): IUsePartyContactsValue => {
  const [contacts, setContacts] = useState<IContact[]>();

  const [createPartyContactMutation] = useMutation(PARTY_CONTACT_CREATE_MUTATION);
  const [updatePartyContactMutation] = useMutation(PARTY_CONTACT_UPDATE_MUTATION);
  const [softDeletePartyContactMutation] = useMutation(PARTY_CONTACT_SOFT_DELETE_MUTATION);

  const filter = useMemo(() => {
    return {
      partyIds: [loadPartyId],
      nameContains: search && search !== '' ? search : undefined,
      isActive: isActive ? true : isActive === false ? false : undefined,
    };
  }, [isActive, loadPartyId, search]);

  const [getPartyContacts, { data, loading, refetch, called, error }] = useLazyQuery<partyContacts>(
    GET_PARTY_CONTACTS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const loadPage = useCallback(
    (page: number) => {
      if (loadPartyId && loadPartyId !== '') {
        const variables = {
          take: rowsPerPage,
          skip: page * rowsPerPage,
          filter: filter,
        };

        if (called) {
          refetch!(variables);
        } else {
          getPartyContacts({ variables });
        }
      }
    },
    [getPartyContacts, refetch, called, filter, loadPartyId]
  );

  useEffect(() => {
    if (data && !loading) {
      setContacts((old) => {
        const arr = old || [];
        return [
          ...arr,
          ...data.partyContacts.map((contact) => ({
            ...contact,
            deleted: false,
            changed: false,
            isActive: true,
          })),
        ];
      });
    }
  }, [data, loading]);

  const reloadContacts = useCallback(() => {
    setContacts(() => []);
    loadPage(0);
  }, [loadPage]);

  useEffect(() => {
    reloadContacts();
  }, [reloadContacts]);

  const deleteContact = useCallback((index: number) => {
    setContacts((old) => {
      if (!old?.length) {
        return [];
      }
      if (old.length < index) {
        return old;
      }
      old[index].deleted = true;
      return [...old];
    });
  }, []);

  const updateContact = useCallback((index: number, value: any) => {
    setContacts((old) => {
      if (!old?.length) {
        return [];
      }
      if (old.length < index) {
        return old;
      }
      old[index] = { ...old[index], ...value, changed: true };
      return [...old];
    });
  }, []);

  const addContact = useCallback((contact: any) => {
    setContacts((old) => {
      if (!old?.length) {
        return [contact || getNewContact()];
      }
      return [...old, contact || getNewContact()];
    });
  }, []);

  const submit = useCallback(
    async (partyId: string) => {
      let foundError = false;
      let allErrors: string[] | undefined = undefined;
      let lastResult = undefined;

      if (!partyId || partyId === '') {
        return { foundError: true, allErrors: ['Invalid action'] };
      }
      if (loadPartyId && loadPartyId !== partyId) {
        return { foundError: true, allErrors: ['Invalid action'] };
      }
      if (loadPartyId) {
        const toDelete = contacts?.filter(
          (contact) => contact.deleted && contact.id && contact.id !== ''
        );
        if (toDelete?.length) {
          for (let i = 0; i < toDelete.length && !foundError; i++) {
            const { result, isError, errors } = await tryUpdateProcedure({
              mutation: () =>
                softDeletePartyContactMutation({
                  variables: {
                    id: toDelete[i].id,
                    partyId,
                  },
                }),
              parseResult: (data: any) => {
                return data;
              },
            });
            foundError = isError;
            allErrors = errors;
            lastResult = result;
          }
        }

        const toUpdate = contacts?.filter(
          (contact) => contact.changed && !contact.deleted && contact.id && contact.id !== ''
        );
        if (toUpdate?.length) {
          for (let i = 0; i < toUpdate.length && !foundError; i++) {
            const contact = toUpdate[i];
            const updates = pick(contact, [
              'id',
              'firstName',
              'lastName',
              'jobTitle',
              'email',
              'phone',
              'linkedInUrl',
              'notes',
              'isActive',
            ]);
            const { result, isError, errors } = await tryUpdateProcedure({
              mutation: () =>
                updatePartyContactMutation({
                  variables: {
                    ...updates,
                    partyId,
                    contactType: 'REGULAR',
                  },
                }),
              parseResult: (data: any) => {
                return data;
              },
            });
            foundError = isError;
            allErrors = errors;
            lastResult = result;
          }
        }
      }
      const toAdd = contacts?.filter(
        (contact) => contact.changed && !contact.deleted && contact.id === ''
      );
      if (toAdd?.length) {
        for (let i = 0; i < toAdd.length && !foundError; i++) {
          const contact = toAdd[i];
          const updates = pick(contact, [
            'firstName',
            'lastName',
            'jobTitle',
            'email',
            'phone',
            'linkedInUrl',
            'notes',
            'isActive',
          ]);
          const { result, isError, errors } = await tryUpdateProcedure({
            mutation: () =>
              createPartyContactMutation({
                variables: {
                  ...updates,
                  partyId,
                  contactType: 'REGULAR',
                },
              }),
            parseResult: (data: any) => {
              return data;
            },
          });
          foundError = isError;
          allErrors = errors;
          lastResult = result;
        }
      }
      return { foundError, allErrors, lastResult };
    },
    [
      contacts,
      createPartyContactMutation,
      softDeletePartyContactMutation,
      updatePartyContactMutation,
      loadPartyId,
    ]
  );

  return {
    contacts,
    loading,
    error,
    deleteContact,
    updateContact,
    addContact,
    submit,
    reloadContacts,
  };
};

import * as Azure from '@azure/storage-blob';
import { ApolloClient } from '@apollo/client';

import { GET_BOND_SAS_URL } from 'graphql/proposals/sas';

export const azureStorageBondUpload = async (
  apolloClient: ApolloClient<any>,
  bondId: number,
  filename: string,
  file: File
): Promise<any> => {
  let sasUrl = '';
  let errorMessage = '';
  let hasErrors = false;

  await apolloClient
    .query({
      query: GET_BOND_SAS_URL,
      variables: {
        filename,
        bondId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    })
    .then((res) => {
      if (res.errors) {
        errorMessage =
          res.errors.length > 0 ? res.errors[0].message : 'Error while trying to fetch SAS Url';
        hasErrors = true;
      } else {
        errorMessage = '';
        hasErrors = false;
        sasUrl = res.data.bond_generateUploadBlobSASUrl.url;
      }
    })
    .catch();

  if (hasErrors) {
    return {
      url: sasUrl,
      errorMessage,
      hasErrors,
    };
  }

  const client = new Azure.BlockBlobClient(sasUrl);

  await client.upload(file, file.size);

  // alternative way, without SAS Url
  // const blobServiceClient = new Azure.BlobServiceClient(`https://${account}.blob.core.windows.net${sas}`);
  // const containerClient = blobServiceClient.getContainerClient(containerName);
  // const blobClient = containerClient.getBlockBlobClient(blobName);
  // await blobClient.upload(file, file.size);

  return {
    url: sasUrl,
    errorMessage,
    hasErrors,
    containerName: client.containerName,
    blobName: client.name,
    fileFilename: file.name,
    fileMimeType: file.type,
  };
};

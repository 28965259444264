import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { paths } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { useComponentContext } from 'template/Proposal/ProposalContext';

import { getWorkflowGeneralItem } from '../ProposalForm/constants/workflow';
import { AlertDialog, ConfirmationDialog } from 'components';

import { ProposalStage } from 'graphql/proposals/types/graphql-types';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { EditClientDialog } from '../ProposalForm/components/EditClientDialog/EditClientDialog';
import { ProposalForm } from '../ProposalForm/ProposalForm';
import { SaveAlt } from '@mui/icons-material';
import { Print } from 'template/Print/Print';
import { ProposalPrintingMeta } from '../ProposalPrintingMeta/ProposalPrintingMeta';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const ProposalFormFrame = () => {
  const history = useHistory();

  const { checkAndProceed, resetChanged } = useFormChangedDialogContext();

  const formRef = useRef<HTMLFormElement>(null);

  const [submited, setSubmited] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [draftAlertOpen, setDraftAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [processDraftSave, setProcessDraftSave] = useState(false);

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayCancelWarning, showCancelWarning] = useState(false);
  const [displayCopyWarning, showCopyWarning] = useState(false);

  const [printing, setPrinting] = useState(false);

  const [editClient, setEditClient] = useState<string>();

  const {
    proposal,
    proposalId,
    onChangeState,
    onSubmit,
    onCopy,
    proposalStage,
    validationSummary,

    onSubmitDraftValidateTest,
    onSubmitValidateTest,
    onDeleteProcess,
    onCancelProcess,
  } = useComponentContext();
  const { selectedStage, showValidator } = proposal;

  useEffect(() => {
    if (draftAlertOpen && processDraftSave && onSubmit) {
      setProcessDraftSave(false);
      setDraftAlertOpen(false);
      onSubmit();
    }
  }, [draftAlertOpen, onSubmit, processDraftSave]);

  const processValidationSummaryError = useCallback((err: any) => {
    if (!err) {
      return undefined;
    }

    if (typeof err === 'object' && !Array.isArray(err)) {
      return (
        <li>
          {Array.isArray(err.messages) ? err.messages.join('. ') : err.messages}
          {err.subErrors?.length ? (
            <ul>{err.subErrors.map((subError: any) => processValidationSummaryError(subError))}</ul>
          ) : undefined}
        </li>
      );
    } else {
      return <li>{Array.isArray(err) ? err.join('. ') : err}</li>;
    }
  }, []);

  useEffect(() => {
    if (submited && showValidator && !!validationSummary?.length) {
      setAlertMessage(
        <div>
          Please make sure following fields are filled in correctly:
          <ul>
            {validationSummary.map((err: any) => {
              return processValidationSummaryError(err);
            })}
          </ul>
        </div>
      );
      setAlertOpen(() => true);
    }
  }, [showValidator, validationSummary, submited, processValidationSummaryError]);

  const isNewItem = useMemo(() => {
    if (!proposalId) {
      return true;
    }
    const id = parseInt(proposalId);
    return id <= 0;
  }, [proposalId]);

  const onValidateAndSubmit = useCallback(() => {
    if (formRef.current!.reportValidity()) {
      const id = parseInt(proposal.id);
      const isNew = !proposal.id || id <= 0;
      if (!isNew || !onSubmitDraftValidateTest!(isNewItem) || onSubmitValidateTest!(isNewItem)) {
        onSubmit!();
        setSubmited(true);
      } else {
        setDraftAlertOpen(true);
      }
    }
  }, [onSubmit, onSubmitDraftValidateTest, onSubmitValidateTest, isNewItem, proposal.id]);

  const handleCopyDocument = useCallback(
    (confirm: boolean) => {
      showCopyWarning(() => false);
      if (confirm) {
        onCopy && onCopy();
        history.push(paths.client.NEW_PROPOSAL);
      }
    },
    [history, onCopy]
  );

  const handleDeleteDocument = useCallback(
    async (confirm: boolean) => {
      showDeleteWarning(() => false);
      if (confirm) {
        if (await onDeleteProcess!()) {
          resetChanged && resetChanged();
          history.push(paths.client.PROPOSALS);
        }
      }
    },
    [onDeleteProcess, history, resetChanged]
  );

  const handleCancelDocument = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onCancelProcess!();
      }
      showCancelWarning(() => false);
    },
    [onCancelProcess]
  );

  return (
    <Container>
      {editClient ? (
        <EditClientDialog
          id={editClient}
          onClose={() => {
            setEditClient(undefined);
            onChangeState &&
              onChangeState!((old) => {
                return { ...old, selectedClient: { ...old.selectedClient! } };
              });
          }}
        ></EditClientDialog>
      ) : undefined}
      <AlertDialog
        title="Form validation failed"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setSubmited(false);
          setAlertOpen(() => false);
        }}
      />
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Proposal deletion"
        message={'Selected Proposal will be deleted! The action is irreversible!'}
        onClose={handleDeleteDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={displayCopyWarning}
        title="Please Confirm Proposal Data Copy"
        message={
          'Current Proposal will be used to fill in a New Proposal Form. Please review the New Proposal and submit the form.'
        }
        onClose={handleCopyDocument}
        confirmButtonProps={{ style: { background: 'blue' } }}
      />

      <ConfirmationDialog
        open={displayCancelWarning}
        title="Please confirm Proposal cancelation"
        message={'Selected Proposal will be canceled! The action is irreversible!'}
        onClose={handleCancelDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <ConfirmationDialog
        title="Proposal not fully configured!"
        message={
          'You have not completed the proposals configuration. This proposal will be saved as a draft and you can complete its configuration later. Status will be set to “Draft”.'
        }
        notes={
          <div>
            <p>
              To keep {selectedStage?.name ? '"' + selectedStage.name + '"' : 'selected'} proposal
              status, please make sure that following fields are filled in correctly:
            </p>
            <ul>
              {validationSummary.map((err: any) => {
                return processValidationSummaryError(err);
              })}
            </ul>
          </div>
        }
        open={draftAlertOpen}
        onClose={async (confirm: boolean) => {
          if (confirm) {
            onChangeState!((old) => {
              const newStatus = getWorkflowGeneralItem(ProposalStage.DRAFT);
              return { ...old, selectedStage: newStatus };
            });
            setProcessDraftSave(true);
          } else {
            onChangeState!((old) => {
              return { ...old, showValidator: true };
            });
            setDraftAlertOpen(() => false);
          }
          setSubmited(false);
        }}
      />
      <form ref={formRef}>
        <ProposalForm></ProposalForm>
      </form>
      <Print
        printing={printing}
        setPrinting={setPrinting}
        PrintingMeta={ProposalPrintingMeta}
        PrintingData={ProposalForm}
      ></Print>

      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <Grid item xs={12} md={4} lg={3}>
              <Typography variant="body2" className="label-section">
                Cover Sheet:
              </Typography>
            </Grid>
            <Grid item xs={11} md={4} lg={6}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '1em',
                  cursor: 'pointer',
                }}
                onClick={debounce(() => {
                  setPrinting(true);
                }, DEBOUNCE_TIMEOUT)}
              >
                <SaveAlt></SaveAlt>
                <Button variant="outlined" color="primary">
                  Download
                </Button>
              </div>
            </Grid>
            <Grid item xs={1} md={4} lg={3}></Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box m={5} />
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container justifyContent="center" spacing={3}>
            {!isNewItem ? (
              <Grid item xs={12} sm={2} order={{ xs: 5, sm: 1 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={debounce(() => {
                    showDeleteWarning(true);
                  }, DEBOUNCE_TIMEOUT)}
                  style={
                    proposal.stageAsString === 'CANCELED'
                      ? {
                          background: 'red',
                        }
                      : undefined
                  }
                  disabled={proposal.stageAsString !== 'CANCELED'}
                >
                  Delete
                </Button>
              </Grid>
            ) : undefined}
            {!isNewItem ? (
              <Grid item xs={12} sm={2} order={{ xs: 4, sm: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={debounce(
                    checkAndProceed!(() => {
                      showCopyWarning(true);
                    }),
                    DEBOUNCE_TIMEOUT
                  )}
                >
                  Copy
                </Button>
              </Grid>
            ) : undefined}

            <Grid item style={{ width: '50px' }} xs={12} sm={1} order={{ xs: 3, sm: 3 }}></Grid>

            <Grid item xs={12} sm={3} order={{ xs: 2, sm: 4 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(
                  checkAndProceed!(() => {
                    history.push(paths.client.PROPOSALS);
                  }),
                  DEBOUNCE_TIMEOUT
                )}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item xs={12} sm={3} order={{ xs: 1, sm: 5 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={debounce(onValidateAndSubmit, DEBOUNCE_TIMEOUT)}
                disabled={showValidator && !!validationSummary.length && proposalStage !== 'DRAFT'}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Container>
  );
};

import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { validateBond } from './validator';
import { BondType } from 'graphql/proposals/types/graphql-types';
import { IBondData } from 'template/Bond/components/ProposalBond/interface';

export interface INewBondContextState {
  changed: boolean;
  confirmCallback?: (confirmed: boolean) => void;
  bond?: IBondData;
}

export interface INewBondContextActions {
  formChanged: () => void;
  resetChanged: () => void;
  confirmRequest: (cb: (confirmed: boolean) => void) => void;
  leaveAllow: () => void;
  leaveDecline: () => void;
  checkAndProceed: (cb: any) => () => void;

  onSubmitValidate: () => boolean;
  onSubmitProcess: () => void;
  onDeleteProcess: () => void;

  setBond: any;
}

const initialState: INewBondContextState = {
  changed: false,
  bond: undefined,
};

const NewBondContext = createContext<INewBondContextState & Partial<INewBondContextActions>>(
  initialState
);

interface INewBondProviderProps {
  children: any;
}

const getNewBond: () => IBondData = () => ({
  proposalId: '',
  type: BondType.BID_BOND,
  tenderNumber: '',
  tenderName: '',
  contractNumber: '',
  contractName: '',
  description: '',
  beneficiaryNameAndAddress: '',
  beneficiaryPhoneNumber: '',
  beneficiaryFaxNumber: '',
  beneficiaryEmail: '',
  amount: 0,
  currency: '',
  issuingBankRequirements: '',
  wordingOrFormatRequirements: '',
  deliveryInstructions: '',
  isLocked: false,
});

export const NewBondProvider: FC<INewBondProviderProps> = ({ children }) => {
  const [bond, setBond] = useState<IBondData>();

  const [changed, setChanged] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState<(confirmed: boolean) => void>();

  const formChanged = useCallback(() => {
    setChanged(true);
  }, []);

  const resetChanged = useCallback(() => {
    setChanged(false);
  }, []);

  const confirmRequest = useCallback(
    (cb: (confirmed: boolean) => void) => {
      if (changed) {
        setConfirmCallback(() => cb);
      } else {
        cb(true);
      }
    },
    [changed]
  );

  useEffect(() => {
    setBond(getNewBond());
  }, []);

  const leaveAllow = () => {
    setChanged(false);
    setConfirmCallback(() => {
      return undefined;
    });
    confirmCallback && confirmCallback(true);
  };

  const leaveDecline = () => {
    setConfirmCallback(() => {
      return undefined;
    });
    confirmCallback && confirmCallback(false);
  };

  const checkAndProceed = (callback: () => void) => () => {
    confirmRequest!((confirmed) => {
      confirmed && callback();
    });
  };

  const onSubmitValidate = useCallback(() => {
    return false;
  }, []);
  const onSubmitProcess = useCallback(() => {}, []);

  const setBondProxy = useCallback((cbOrData: ((bond?: IBondData) => IBondData) | IBondData) => {
    if (cbOrData instanceof Function) {
      setBond((bond) => {
        const newData = cbOrData(bond);
        return { ...newData, ...validateBond(newData) };
      });
    } else {
      setBond({ ...cbOrData, ...validateBond(cbOrData) });
    }
  }, []);

  return (
    <NewBondContext.Provider
      value={{
        formChanged,
        resetChanged,
        confirmRequest,
        leaveDecline,
        leaveAllow,
        changed,
        confirmCallback,
        checkAndProceed,
        bond,
        setBond: setBondProxy,
        onSubmitProcess,
        onSubmitValidate,
      }}
    >
      {children}
    </NewBondContext.Provider>
  );
};

export const useNewBondContext = () => useContext(NewBondContext);

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { YearSummaryBySuccessChance } from 'template/Reports/YearSummaryBySuccessChance/YearSummaryBySuccessChance';
import { isValidChancesYearSearch } from 'utils/year';

interface IYearSummaryBySuccessChancePageParams {
  year: string;
}

export const YearSummaryBySuccessChancePage: FC = () => {
  const { year } = useParams<IYearSummaryBySuccessChancePageParams>();

  const yearChecked = isValidChancesYearSearch(year) ? parseInt(year) : new Date().getFullYear();

  return <YearSummaryBySuccessChance year={yearChecked} />;
};

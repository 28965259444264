import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { BondSortableColumn, BondType } from 'graphql/proposals/types/graphql-types';
import { TransformCurrencyUS } from 'utils/TableTransformFunctions';

export const allBondsTableHead: IHeadCell[] = [
  {
    id: 'proposalName',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Name',
    inputLabel: 'Input proposal name',
    sortable: true,
    sortBy: BondSortableColumn.PROPOSAL_NAME,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    transformFunction: (data, row: any | undefined) => row?.proposal?.name || '--',
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    inputLabel: 'Input client name',
    sortable: true,
    sortBy: BondSortableColumn.PARTY_NAME,
    filter: 'text-dropdown',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
    },
    transformFunction: (data, row: any | undefined) =>
      row?.party
        ? (row?.party.projectSetupClientCode ? row?.party.projectSetupClientCode + ' - ' : '') +
          row?.party.name
        : '--',
  },
  {
    id: 'owningDivision',
    numeric: false,
    disablePadding: false,
    label: 'Division',
    sortable: true,
    sortBy: BondSortableColumn.OWNING_DIVISION_NAME,
    filter: 'hierarchy-multiple',
    padding: 'half',
    dataCell: {
      shrink: 'content',
      padding: 'half',
      wrap: 'box',
    },
    transformFunction: (data, row: any | undefined) => row?.owningDivision?.name || '--',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Bond Type',
    sortable: true,
    sortBy: BondSortableColumn.TYPE,
    filter: 'dropdown',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },

    transformFunction: (data) => (data === BondType.BID_BOND ? 'Bid Bond' : 'Performance Bond'),
  },
  {
    id: 'tenderNumber',
    numeric: false,
    disablePadding: false,
    label: 'Tender Number',
    inputLabel: 'Input tender number...',
    sortable: true,
    sortBy: BondSortableColumn.TENDER_NUMBER,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data || '--',
  },
  {
    id: 'tenderName',
    numeric: false,
    disablePadding: false,
    label: 'Tender Name',
    inputLabel: 'Input tender name...',
    sortable: true,
    sortBy: BondSortableColumn.TENDER_NAME,
    filter: 'text',
    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data || '--',
  },
  {
    id: 'contractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Contract Number',
    inputLabel: 'Input contract number...',
    sortable: true,
    sortBy: BondSortableColumn.CONTRACT_NUMBER,
    filter: 'text',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data || '--',
  },
  {
    id: 'contractName',
    numeric: false,
    disablePadding: false,
    label: 'Contract Name',
    inputLabel: 'Input contract name...',
    sortable: true,
    sortBy: BondSortableColumn.CONTRACT_NAME,
    filter: 'text',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data || '--',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    sortable: true,
    sortBy: BondSortableColumn.AMOUNT,
    filter: 'dropdown',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },

    transformFunction: (data: any) => (data ? data : '-- '),
    transformDataCell: TransformCurrencyUS,
    excelRowStyle: { numFmt: '$ #,###.00', alignment: { horizontal: 'right' } },
  },
  {
    id: 'currency',
    numeric: false,
    disablePadding: false,
    label: 'Currency',
    sortable: true,
    sortBy: BondSortableColumn.CURRENCY,
    filter: 'hierarchy-multiple',

    dataCell: {
      connected: 'right',
      shrink: 'content',
    },
    transformFunction: (data) => data || '--',
  },
];

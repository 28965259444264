import { gql } from '@apollo/client';
import { IUserData } from './fragments';

// TODO: use config to preset application
// import { APPLICATION } from 'constants/config';

export const GET_DIVISIONS = gql`
  query divisionsList(
    $filter: DivisionFilter = { application: REPORTS }
    $sort: [DivisionSort!] = [{ column: NAME, order: ASC }]
  ) {
    divisions(filter: $filter, sort: $sort) {
      id
      name
    }
  }
`;

export const GET_PARTIES_DIVISIONS = gql`
  query partiesDivisionsList(
    $filter: DivisionFilter = { application: REPORTS }
    $sort: [DivisionSort!] = [{ column: NAME, order: ASC }]
  ) {
    divisions(filter: $filter, sort: $sort) {
      id
      name
    }
  }
`;

// hack - using REPORTS app managers
export const GET_DIVISION_MANAGERS = gql`
  query divisionManagers($filter: DivisionFilter = { application: REPORTS }) {
    divisions(filter: $filter) {
      manager {
        ...IUserData
      }
    }
  }
  ${IUserData}
`;

import { FC, useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import s from './style.module.scss';

export interface ISelectSeriesState {
  selectedKey: string;
}

export interface ISelectSeriesProps extends SelectProps {
  dictionary: {
    title: string;
    notSelectedName: string; // Sheet Name
  };
  options: { key: string; name: string }[];

  state?: ISelectSeriesState;
  setState: (cb: (record?: ISelectSeriesState) => ISelectSeriesState) => void;
  props?: { [id: string]: any };
}

export const SelectSeries: FC<ISelectSeriesProps> = ({
  dictionary,
  options,
  state,
  setState,
  ...props
}) => {
  const extendedOptions = useMemo(() => {
    return options?.length
      ? [{ key: '--none--', name: dictionary.notSelectedName }, ...options]
      : [{ key: '--none--', name: dictionary.notSelectedName }];
  }, [dictionary, options]);

  return (
    <>
      <div className={s.field}>
        <FormControl fullWidth>
          <InputLabel id="definition-label">{dictionary.title}</InputLabel>
          <Select
            labelId="definition-label"
            id="definition"
            value={state?.selectedKey || '--none--'}
            label={`${dictionary.title} Data Source`}
            onChange={(ev) => {
              setState((state) => ({
                ...state,
                selectedKey: ev.target.value === '--none--' ? '' : (ev.target.value as string),
              }));
            }}
            {...props}
          >
            {extendedOptions.map((item) => (
              <MenuItem value={item.key}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

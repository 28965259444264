import { useProposalAggregatesContext } from '../../Context/ProposalAggregatesContext';
import s from './style.module.scss';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { currencyFormatUS } from 'utils/currencyFormat';
import { changeProposalStageName } from 'utils/formats';

export const AmountByDivisionAndStage = () => {
  const {
    aggregatesByDivisionAndStage,
    // aggregatesTotal
  } = useProposalAggregatesContext();

  const rows = useMemo(() => {
    const list = aggregatesByDivisionAndStage?.proposal_proposalAggregates.filter(
      (x) => x.bidProposedValueSum
    );

    return list?.map((row, index) => ({
      ...row,
      isLastDivisionItem:
        index < list.length - 1 && row.owningDivision?.id !== list[index + 1].owningDivision?.id,
    }));
  }, [aggregatesByDivisionAndStage]);

  return (
    <Grid container spacing={0}>
      <Grid item className={s.head} xs={2}>
        Division
      </Grid>
      <Grid item className={s.head} xs={4}>
        Proposal Stage
      </Grid>
      <Grid item className={s.resultHead} xs={6}>
        Proposal Amount
      </Grid>
      {rows?.map((row) => {
        return (
          <>
            <Grid
              item
              className={s.keyColumn}
              xs={2}
              style={row.isLastDivisionItem ? { borderBottom: '1px solid gray' } : undefined}
            >
              {row.owningDivision?.name || '--'}
            </Grid>
            <Grid item className={s.keyColumn} xs={4}>
              {changeProposalStageName(row.stage || '--')}
            </Grid>
            <Grid item className={s.resultColumn} xs={6}>
              $ {currencyFormatUS(row.bidProposedValueSum || 0)}
            </Grid>
          </>
        );
      })}
      {/* <Grid item xs={3}></Grid>
      <Grid item xs={3}>
        Total
      </Grid>
      <Grid item xs={3}>
        {aggregatesTotal?.proposal_proposalAggregates[0].bidProposedValueSum}
      </Grid> */}
    </Grid>
  );
};

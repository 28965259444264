import { useQuery } from '@apollo/client';
import { GET_CURRENCIES } from 'graphql/proposals/proposals';
import { currencies } from 'graphql/proposals/types/currencies';
import { useCallback, useMemo } from 'react';

export const useCurrencies = () => {
  const { data, loading } = useQuery<currencies>(GET_CURRENCIES);

  const getCurrenciesPairs = useCallback(() => {
    return (
      data?.currencies.map(({ abbreviation, description }) => {
        return {
          id: abbreviation,
          name: description,
          ddLabel: `${description} (${abbreviation})`,
        };
      }) || []
    );
  }, [data]);

  const currencyPairs = useMemo(() => {
    return getCurrenciesPairs();
  }, [getCurrenciesPairs]);

  return { data, currencyPairs, loading };
};

import { FC } from 'react';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

export interface IArrowIconProps {
  down: boolean;
}

export const ArrowIcon: FC<IArrowIconProps> = ({ down }): JSX.Element => {
  if (down) {
    return (
      <ArrowDropDownIcon fontSize="medium" style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
    );
  }
  return (
    <ArrowDropUpIcon fontSize="medium" style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
  );
};

import { gql } from '@apollo/client';
import { IProposalAggregate } from './aggregatesFragments';
import { IProposalMonetaryBreakdownAggregate } from './proposalsFragments';

export const PROPOSAL_AGGREGATES = gql`
  query proposalAggregates(
    $sort: [ProposalAggregateSort!]
    $filter: ProposalFilter
    $group: ProposalAggregateGroup
    $take: Int
    $skip: Int = 0
  ) {
    proposal_proposalAggregates(
      sort: $sort
      filter: $filter
      group: $group
      take: $take
      skip: $skip
    ) {
      ...IProposalAggregate
    }
  }
  ${IProposalAggregate}
`;

export const PROPOSAL_MONETARY_BREAKDOWN_AGGREGATES = gql`
  query monetaryBreakdownAggregates(
    $filter: ProposalMonetaryBreakdownAggregateFilter
    $group: ProposalMonetaryBreakdownAggregateGroup
    $sort: [ProposalMonetaryBreakdownAggregateSort!]
    $skip: Int = 0
    $take: Int = 100
  ) {
    proposals_monetaryBreakdownAggregates(
      filter: $filter
      sort: $sort
      group: $group
      take: $take
      skip: $skip
    ) {
      ...IProposalMonetaryBreakdownAggregate
    }
  }
  ${IProposalMonetaryBreakdownAggregate}
`;

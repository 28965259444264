import React, { useState } from 'react';
import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Print } from 'template/Print/Print';
import { Provider as ProposalsListViewProvider } from '../ProposalsListView/ProposalsListViewContext';
import { ProposalsListView } from '../ProposalsListView/ProposalsListView';
import { ProposalsTablePrintingMeta } from '../ProposalsListView/ProposalsListViewPrintingMeta';

export const ProposalsList = () => {
  const [printing, setPrinting] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth="xl"
      style={
        matches
          ? { paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px', minWidth: '100%' }
          : undefined
      }
    >
      <Paper
        elevation={2}
        style={
          matches
            ? { padding: '4px 4px 16px 4px' }
            : { padding: '4px 4px 16px 4px', marginTop: '6px' }
        }
        id="main-paper"
      >
        <ProposalsListViewProvider>
          <ProposalsListView></ProposalsListView>

          <Print
            printing={printing}
            setPrinting={setPrinting}
            PrintingMeta={ProposalsTablePrintingMeta}
            PrintingData={ProposalsListView}
            landscape
            subtitle="Documents List"
          ></Print>

          <Box mt={4} />
        </ProposalsListViewProvider>
      </Paper>
    </Container>
  );
};

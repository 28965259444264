import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { capitalizeFirstLetter } from 'utils/formats';
import { ProposalSortableColumn } from 'graphql/proposals/types/graphql-types';
import { TransformProposalStage } from 'utils/TableTransformFunctions';
import { proposalTypeOptions } from 'template/Proposal/components/ProposalForm/constants/proposalTypeOptions';

export const relatedProposalsTableHead: IHeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Id',
  //   sortable: true,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'entityType',
    numeric: false,
    disablePadding: false,
    label: 'Entity Type',
    sortBy: ProposalSortableColumn.ENTITY_TYPE,
    sortable: true,
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : '--'),
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    sortable: true,
    sortBy: ProposalSortableColumn.NAME,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
  },
  // {
  //   id: 'divisionOwner',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Division Ownership',
  //   sortable: false,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'clientName',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Client Name',
  //   sortable: false,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'proposalType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: true,
    sortBy: ProposalSortableColumn.PROPOSAL_TYPE,
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (proposalType) => {
      const found = proposalTypeOptions.find((item) => item.id === proposalType);
      return proposalType ? (found ? found.name : proposalType) : '--';
    },
  },
  {
    id: 'successChance',
    numeric: false,
    disablePadding: false,
    label: 'Chance of Success',
    sortable: true,
    sortBy: ProposalSortableColumn.SUCCESS_CHANCE,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (data: string) => (data ? capitalizeFirstLetter(data.toLowerCase()) : '--'),
  },
  // {
  //   id: 'receiveDate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Proposal Received Date',
  //   sortable: false,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  //   transformFunction: (date) =>
  //     date
  //       ? new Date(date).toLocaleDateString('en-EN', {
  //           year: 'numeric',
  //           month: 'numeric',
  //           day: 'numeric',
  //         })
  //       : 'N/A',
  // },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    label: 'Proposal Due Date',
    sortable: true,
    sortBy: ProposalSortableColumn.DUE_DATE,
    filter: 'text',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformFunction: (date) =>
      date
        ? new Date(date).toLocaleDateString('en-EN', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : 'N/A',
  },
  // {
  //   id: 'workType',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Work Type',
  //   sortable: false,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  // },
  // {
  //   id: 'method',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Submission Method',
  //   sortable: false,
  //   filter: 'text',
  //   padding: 'half',
  //   dataCell: {
  //     padding: 'half',
  //     shrink: 'content',
  //   },
  // },
  {
    id: 'stage',
    numeric: false,
    disablePadding: false,
    label: 'Stage',
    sortable: true,
    sortBy: ProposalSortableColumn.STAGE,
    filter: 'dropdown',
    padding: 'half',
    dataCell: {
      padding: 'half',
      shrink: 'content',
    },
    transformDataCell: TransformProposalStage,
  },
];

export enum ProjectStatus {
  PENDING = 'PENDING',
}

export enum DocumentPages {
  DocumentInfo = 'document-info',
  FileManagement = 'file-management',
}

export enum ProjectUserRole {
  PROJECT_LEADER = 'PROJECT_LEADER',
  PROJECT_COORDINATOR = 'PROJECT_COORDINATOR',
  TECHNICAL_COORDINATOR = 'TECHNICAL_COORDINATOR',
}

export enum UserDiscipline {
  ANALYST = 'ANALYST',
  ECONOMIST = 'ECONOMIST',
  ENGINEER = 'ENGINEER',
  GEOLOGIST = 'GEOLOGIST',
  GEOPHYSICIST = 'GEOPHYSICIST',
  OTHER = 'OTHER',
  PETROPHYSICIST = 'PETROPHYSICIST',
}

export enum DocumentStatus {
  IDLE = 'IDLE',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORT_ARCHIVED = 'REPORT_ARCHIVED',
  REPORT_APPROVED = 'REPORT_APPROVED',
  REPORT_REVIEW_REQUESTED = 'REPORT_REVIEW_REQUESTED',
  REPORT_REVIEW_APPROVED = 'REPORT_REVIEW_APPROVED',
  REPORT_REVIEW_DISAPPROVED = 'REPORT_REVIEW_DISAPPROVED',
  REPORT_REVIEW_POSTPONED = 'REPORT_REVIEW_POSTPONED',
}

export enum DocumentType {
  PDF = 'PDF',
  DOCX = 'DOCX',
}

export enum DocumentUserStatus {
  IDLE = 'IDLE',
  REVIEW_REQUESTED = 'REVIEW_REQUESTED',
  REVIEW_POSTPONED = 'REVIEW_POSTPONED',
  REVIEW_DISAPPROVED = 'REVIEW_DISAPPROVED',
  REVIEW_APPROVED = 'REVIEW_APPROVED',
}

export enum DocumentOwnership {
  NONE = 'NONE',
  OWNER = 'OWNER',
  STAKEHOLDER = 'STAKEHOLDER',
}

export enum ProposalEventType {
  PROPOSAL_CREATED = 'PROPOSAL_CREATED',
  PROPOSAL_UPDATED = 'PROPOSAL_UPDATED',
  PROPOSAL_COMMENT = 'PROPOSAL_COMMENT',
  PROPOSAL_FILE_CREATE = 'PROPOSAL_FILE_CREATE',
  PROPOSAL_FILE_DELETE = 'PROPOSAL_FILE_DELETE',

  PROPOSAL_DOCUMENT_SOFT_DELETE = 'PROPOSAL_DOCUMENT_SOFT_DELETE',

  PROPOSAL_REVIEW_REQUESTED = 'PROPOSAL_REVIEW_REQUESTED',
  PROPOSAL_REVIEW_APPROVED = 'PROPOSAL_REVIEW_APPROVED',
  PROPOSAL_REVIEW_DISAPPROVED = 'PROPOSAL_REVIEW_DISAPPROVED',
  PROPOSAL_REVIEW_POSTPONED = 'PROPOSAL_REVIEW_POSTPONED',
  PROPOSAL_REVIEW_RECALLED = 'PROPOSAL_REVIEW_RECALLED',
  PROPOSAL_REVIEWERS_UPDATED = 'PROPOSAL_REVIEWERS_UPDATED',
  PROPOSAL_REVIEW_RESENT = 'PROPOSAL_REVIEW_RESENT',
  PROPOSAL_COMPETITORS_CHANGE = 'PROPOSAL_COMPETITORS_CHANGE',

  PROPOSAL_BOND_CHANGED = 'PROPOSAL_BOND_CHANGED',
  PROPOSAL_BOND_LOCKED = 'PROPOSAL_BOND_LOCKED',
  PROPOSAL_BOND_UNLOCKED = 'PROPOSAL_BOND_UNLOCKED',

  PROPOSAL_MONETARY_BREAKDOWN_CHANGED = 'PROPOSAL_MONETARY_BREAKDOWN_CHANGED',
}

export enum BondEventType {
  BOND_CREATED = 'BOND_CREATED',
  BOND_UPDATED = 'BOND_UPDATED',
  BOND_FILE_CREATED = 'BOND_FILE_CREATED',
  BOND_FILE_DELETED = 'BOND_FILE_DELETED',
}

export enum ProcessCallSource {
  INTERACTIVE = 'INTERACTIVE',
}

export enum PartyEventType {
  PARTY_CREATED = 'PARTY_CREATED',
  PARTY_UPDATED = 'PARTY_UPDATED',
  PARTY_COMMENT = 'PARTY_COMMENT',
  PARTY_COMMENTED = 'PARTY_COMMENTED',
  PARTY_COMMENT_REPLIED = 'PARTY_COMMENT_REPLIED',
  PARTY_CONTACT_DELETED = 'PARTY_CONTACT_DELETED',
  PARTY_CONTACT_UPDATED = 'PARTY_CONTACT_UPDATED',
  PARTY_CONTACT_CREATED = 'PARTY_CONTACT_CREATED',
  PARTY_STOCK_EXCHANGES_CHANGED = 'PARTY_STOCK_EXCHANGES_CHANGED',
}

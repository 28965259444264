import { ProposalStage } from 'graphql/proposals/types/graphql-types';
import { useMemo } from 'react';
import { changeProposalStageName, enumToArray } from 'utils/formats';

export const defaultSelectedProposalStages = enumToArray(ProposalStage).filter(
  (stage) => stage !== ProposalStage.CANCELED
);

export const useProposalStage = () => {
  const pairs = useMemo(() => {
    const arr = enumToArray(ProposalStage);
    const pairs = arr
      .map((item) => ({
        id: item,
        name: changeProposalStageName(item),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return pairs;
  }, []);

  return { pairs };
};

import { gql } from '@apollo/client';
import { IBond, IBondListItem } from './proposalsFragments';
import { IBondFile } from './fileManagerFragments';

export const GET_BONDS = gql`
  query bondsList($sort: [BondSort!] = [], $filter: BondFilter, $take: Int = 100, $skip: Int = 0) {
    bonds(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IBondListItem
    }
  }
  ${IBondListItem}
`;

export const GET_BONDS_EXPORT = gql`
  query bondsList($sort: [BondSort!] = [], $filter: BondFilter, $take: Int = 100, $skip: Int = 0) {
    bonds(sort: $sort, filter: $filter, take: $take, skip: $skip) {
      ...IBond
    }
  }
  ${IBond}
`;

export const GET_BOND = gql`
  query bond($id: ID!) {
    bond(id: $id) {
      ...IBond
    }
  }
  ${IBond}
`;

export const GET_BONDS_COUNT = gql`
  query bondsListCount($filter: BondFilter) {
    bondCount(filter: $filter)
  }
`;

export const BOND_CREATE_MUTATION = gql`
  mutation bondCreate(
    $partyId: ID
    $proposalId: ID
    $type: BondType!
    $tenderNumber: String!
    $tenderName: String!
    $contractNumber: String!
    $contractName: String!
    $description: String!
    $beneficiaryNameAndAddress: String!
    $beneficiaryPhoneNumber: String!
    $beneficiaryFaxNumber: String!
    $beneficiaryEmail: String!
    $recipientReceiveDeadline: DateTime
    $validityThroughDate: DateTime
    $amount: Float!
    $currency: String!
    $issuingBankRequirements: String!
    $wordingOrFormatRequirements: String!
    $deliveryInstructions: String!
    $stage: BondStage
  ) {
    bondCreate(
      partyId: $partyId
      proposalId: $proposalId
      type: $type
      tenderNumber: $tenderNumber
      tenderName: $tenderName
      contractNumber: $contractNumber
      contractName: $contractName
      description: $description
      beneficiaryNameAndAddress: $beneficiaryNameAndAddress
      beneficiaryPhoneNumber: $beneficiaryPhoneNumber
      beneficiaryFaxNumber: $beneficiaryFaxNumber
      beneficiaryEmail: $beneficiaryEmail
      recipientReceiveDeadline: $recipientReceiveDeadline
      validityThroughDate: $validityThroughDate
      amount: $amount
      currency: $currency
      issuingBankRequirements: $issuingBankRequirements
      wordingOrFormatRequirements: $wordingOrFormatRequirements
      deliveryInstructions: $deliveryInstructions
      stage: $stage
    ) {
      id
    }
  }
`;

export const BOND_UPDATE_MUTATION = gql`
  mutation bondUpdate(
    $bondId: ID!
    $partyId: ID
    $proposalId: ID
    $type: BondType
    $tenderNumber: String
    $tenderName: String
    $contractNumber: String
    $contractName: String
    $description: String
    $beneficiaryNameAndAddress: String
    $beneficiaryPhoneNumber: String
    $beneficiaryFaxNumber: String
    $beneficiaryEmail: String
    $recipientReceiveDeadline: DateTime
    $validityThroughDate: DateTime
    $amount: Float
    $currency: String
    $issuingBankRequirements: String
    $wordingOrFormatRequirements: String
    $deliveryInstructions: String
    $stage: BondStage
  ) {
    bondUpdate(
      bondId: $bondId
      partyId: $partyId
      proposalId: $proposalId
      type: $type
      tenderNumber: $tenderNumber
      tenderName: $tenderName
      contractNumber: $contractNumber
      contractName: $contractName
      description: $description
      beneficiaryNameAndAddress: $beneficiaryNameAndAddress
      beneficiaryPhoneNumber: $beneficiaryPhoneNumber
      beneficiaryFaxNumber: $beneficiaryFaxNumber
      beneficiaryEmail: $beneficiaryEmail
      recipientReceiveDeadline: $recipientReceiveDeadline
      validityThroughDate: $validityThroughDate
      amount: $amount
      currency: $currency
      issuingBankRequirements: $issuingBankRequirements
      wordingOrFormatRequirements: $wordingOrFormatRequirements
      deliveryInstructions: $deliveryInstructions
      stage: $stage
    ) {
      id
    }
  }
`;

export const BOND_DELETE_MUTATION = gql`
  mutation bondDelete($bondId: ID!) {
    bondDelete(bondId: $bondId)
  }
`;

export const BOND_LOCK_MUTATION = gql`
  mutation bondLock($bondId: ID!) {
    bondLock(bondId: $bondId) {
      id
      isLocked
    }
  }
`;

export const BOND_UNLOCK_MUTATION = gql`
  mutation bondUnlock($bondId: ID!) {
    bondUnlock(bondId: $bondId) {
      id
      isLocked
    }
  }
`;

export const BOND_FILE_CREATE = gql`
  mutation bondFileCreate(
    $bondId: ID!
    $bondFileType: BondFileType!
    $uploadedFile: UploadedFileInput!
  ) {
    bondFileCreate(bondId: $bondId, bondFileType: $bondFileType, uploadedFile: $uploadedFile) {
      ...IBondFile
    }
  }
  ${IBondFile}
`;

export const BOND_FILE_DELETE = gql`
  mutation bondFileDelete($bondFileId: ID!) {
    bondFileDelete(bondFileId: $bondFileId)
  }
`;

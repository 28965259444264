import { Box, Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { paths } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { useComponentContext } from 'template/Opportunity/ProposalContext';

import { getWorkflowGeneralItem } from '../ProposalForm/constants/workflow';
import { AlertDialog, ConfirmationDialog } from 'components';

import { ProposalStage } from 'graphql/proposals/types/graphql-types';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

import { EditClientDialog } from '../ProposalForm/components/EditClientDialog/EditClientDialog';
import { ProposalForm } from '../ProposalForm/ProposalForm';
import { SaveAlt } from '@mui/icons-material';
import { Print } from 'template/Print/Print';
import { ProposalPrintingMeta } from '../ProposalPrintingMeta/ProposalPrintingMeta';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export const ProposalFormFrame = () => {
  const history = useHistory();

  const { checkAndProceed, resetChanged } = useFormChangedDialogContext();

  const formRef = useRef<HTMLFormElement>(null);

  const [submited, setSubmited] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [draftAlertOpen, setDraftAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [processDraftSave, setProcessDraftSave] = useState(false);

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [displayCancelWarning, showCancelWarning] = useState(false);
  const [displayCopyWarning, showCopyWarning] = useState(false);
  const [displayNewProposalWarning, showNewProposalWarning] = useState(false);
  const [displayConvertToProposalWarning, showConvertToProposalWarning] = useState(false);

  const [printing, setPrinting] = useState(false);

  const [editClient, setEditClient] = useState<string>();

  const {
    proposal,
    proposalId,
    onChangeState,
    onSubmit,
    onCopy,
    proposalStage,
    validationSummary,

    onSubmitDraftValidateTest,
    onSubmitValidateTest,
    onDeleteProcess,
    onCancelProcess,
    promotedProposal,
  } = useComponentContext();
  const { showValidator } = proposal;

  useEffect(() => {
    if (draftAlertOpen && processDraftSave && onSubmit) {
      setProcessDraftSave(false);
      setDraftAlertOpen(false);
      onSubmit();
    }
  }, [draftAlertOpen, onSubmit, processDraftSave]);

  const processValidationSummaryError = useCallback((err: any) => {
    if (!err) {
      return undefined;
    }

    if (typeof err === 'object' && !Array.isArray(err)) {
      return (
        <li>
          {Array.isArray(err.messages) ? err.messages.join('. ') : err.messages}
          {err.subErrors?.length ? (
            <ul>{err.subErrors.map((subError: any) => processValidationSummaryError(subError))}</ul>
          ) : undefined}
        </li>
      );
    } else {
      return <li>{Array.isArray(err) ? err.join('. ') : err}</li>;
    }
  }, []);

  useEffect(() => {
    if (submited && showValidator && !!validationSummary?.length) {
      setAlertMessage(
        <div>
          Please make sure following fields are filled in correctly:
          <ul>
            {validationSummary.map((err: any) => {
              return processValidationSummaryError(err);
            })}
          </ul>
        </div>
      );
      setAlertOpen(() => true);
    }
  }, [showValidator, validationSummary, submited, processValidationSummaryError]);

  const isNewItem = useMemo(() => {
    if (!proposalId) {
      return true;
    }
    const id = parseInt(proposalId);
    return id <= 0;
  }, [proposalId]);

  const onValidateAndSubmit = useCallback(() => {
    if (formRef.current!.reportValidity()) {
      const id = parseInt(proposal.id);
      const isNew = !proposal.id || id <= 0;
      if (!isNew || !onSubmitDraftValidateTest!(isNewItem) || onSubmitValidateTest!(isNewItem)) {
        onSubmit!();
        setSubmited(true);
      } else {
        setDraftAlertOpen(true);
      }
    }
  }, [onSubmit, onSubmitDraftValidateTest, onSubmitValidateTest, isNewItem, proposal.id]);

  const handleCopyDocument = useCallback(
    (confirm: boolean) => {
      showCopyWarning(() => false);
      if (confirm) {
        onCopy && onCopy();
        history.push(paths.client.NEW_OPPORTUNITY);
      }
    },
    [history, onCopy]
  );

  const handleNewProposalDocument = useCallback(
    (confirm: boolean) => {
      showNewProposalWarning(() => false);
      if (confirm) {
        history.push(paths.client.OPPORTUNITY_NEW_PROPOSAL.replace(':id', proposal.id));
      }
    },
    [history, proposal.id]
  );

  const handleConvertToProposalDocument = useCallback(
    (confirm: boolean) => {
      showConvertToProposalWarning(() => false);
      if (confirm) {
        history.push(paths.client.OPPORTUNITY_CONVERT_TO_PROPOSAL.replace(':id', proposal.id));
      }
    },
    [history, proposal.id]
  );

  const handleDeleteDocument = useCallback(
    async (confirm: boolean) => {
      showDeleteWarning(() => false);
      if (confirm) {
        if (await onDeleteProcess!()) {
          resetChanged && resetChanged();
          history.push(paths.client.OPPORTUNITIES);
        }
      }
    },
    [onDeleteProcess, history, resetChanged]
  );

  const handleCancelDocument = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onCancelProcess!();
      }
      showCancelWarning(() => false);
    },
    [onCancelProcess]
  );

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      {editClient ? (
        <EditClientDialog
          id={editClient}
          onClose={() => {
            setEditClient(undefined);
            onChangeState &&
              onChangeState!((old) => {
                return { ...old, selectedClient: { ...old.selectedClient! } };
              });
          }}
        ></EditClientDialog>
      ) : undefined}
      <AlertDialog
        title="Form validation failed"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setSubmited(false);
          setAlertOpen(() => false);
        }}
      />
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Opportunity deletion"
        message={'Selected Opportunity will be deleted! The action is irreversible!'}
        onClose={handleDeleteDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <ConfirmationDialog
        open={displayCancelWarning}
        title="Please confirm Opportunity cancelation"
        message={'Selected Opportunity will be canceled! The action is irreversible!'}
        onClose={handleCancelDocument}
        confirmButtonProps={{ style: { background: 'red' } }}
      />

      <ConfirmationDialog
        open={displayCopyWarning}
        title="Please Confirm Opportunity Data Copy"
        message={
          'Current Opportunity will be used to fill in a New Opportunity Form. Please review the New Opportunity and submit the form.'
        }
        onClose={handleCopyDocument}
        confirmButtonProps={{ style: { background: 'blue' } }}
      />

      <ConfirmationDialog
        open={displayNewProposalWarning}
        title="Please Confirm"
        message={
          'Current Opportunity will be used to fill in a New Proposal Form. Please review the New Proposal and submit the form.'
        }
        onClose={handleNewProposalDocument}
        confirmButtonProps={{ style: { background: 'blue' } }}
      />

      <ConfirmationDialog
        open={displayConvertToProposalWarning}
        title="Please Confirm"
        message={
          'Current Opportunity will be used to fill the Convert to Proposal Form. Please review the form and submit it when ready to convert Opportunity to Proposal.'
        }
        onClose={handleConvertToProposalDocument}
        confirmButtonProps={{ style: { background: 'blue' } }}
      />

      <ConfirmationDialog
        title="Opportunity not fully configured!"
        message={
          'You have not completed the proposals configuration. This proposal will be saved as a draft and you can complete its configuration later. Status will be set to “Draft”.'
        }
        open={draftAlertOpen}
        onClose={async (confirm: boolean) => {
          if (confirm) {
            onChangeState!((old) => {
              const newStatus = getWorkflowGeneralItem(ProposalStage.DRAFT);
              return { ...old, selectedStage: newStatus };
            });
            setProcessDraftSave(true);
          } else {
            onChangeState!((old) => {
              return { ...old, showValidator: true };
            });
            setDraftAlertOpen(() => false);
          }
          setSubmited(false);
        }}
      />
      <form ref={formRef}>
        <ProposalForm></ProposalForm>
      </form>
      <Print
        printing={printing}
        setPrinting={setPrinting}
        PrintingMeta={ProposalPrintingMeta}
        PrintingData={ProposalForm}
      ></Print>

      <Box mt={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignContent="center" alignItems="center">
            <Grid item xs={12} md={4} lg={3}>
              <Typography variant="body2" className="label-section">
                Cover Sheet:
              </Typography>
            </Grid>
            <Grid item xs={11} md={4} lg={6}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '1em',
                  cursor: 'pointer',
                }}
                onClick={debounce(() => {
                  setPrinting(true);
                }, DEBOUNCE_TIMEOUT)}
              >
                <SaveAlt></SaveAlt>
                <Button variant="outlined" color="primary">
                  Download
                </Button>
              </div>
            </Grid>
            <Grid item xs={1} md={4} lg={3}></Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box m={5} />
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Grid container justifyContent="center" spacing={3}>
            {!isNewItem && !promotedProposal ? (
              <>
                {proposal.stageAsString === 'CANCELED' ? (
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    order={{ xs: 5, sm: 1 }}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={debounce(() => {
                        showDeleteWarning(true);
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ background: 'red' }}
                    >
                      Delete
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    order={{ xs: 6, md: 1 }}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Button
                      fullWidth={!matchesSm}
                      variant="contained"
                      color="primary"
                      onClick={debounce(() => {
                        showCancelWarning(true);
                      }, DEBOUNCE_TIMEOUT)}
                      style={{ background: 'red' }}
                    >
                      Cancel Opportunity
                    </Button>
                  </Grid>
                )}
              </>
            ) : undefined}
            {!isNewItem ? (
              <Grid
                item
                xs={12}
                sm={2}
                order={{ xs: 4, sm: 2 }}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={debounce(
                    checkAndProceed!(() => {
                      showCopyWarning(true);
                    }),
                    DEBOUNCE_TIMEOUT
                  )}
                >
                  Copy
                </Button>
              </Grid>
            ) : undefined}

            <Grid item style={{ width: '50px' }} xs={12} sm={1} order={{ xs: 3, sm: 3 }}></Grid>

            <Grid
              item
              xs={12}
              sm={2}
              order={{ xs: 2, sm: 4 }}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={debounce(
                  checkAndProceed!(() => {
                    history.push(paths.client.OPPORTUNITIES);
                  }),
                  DEBOUNCE_TIMEOUT
                )}
              >
                Cancel
              </Button>
            </Grid>
            {!promotedProposal ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  order={{ xs: 1, sm: 5 }}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={debounce(onValidateAndSubmit, DEBOUNCE_TIMEOUT)}
                    disabled={
                      showValidator && !!validationSummary.length && proposalStage !== 'DRAFT'
                    }
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{
                    // display: 'flex',
                    display: 'none',
                    justifyContent: 'center',
                  }}
                  order={{ xs: 6, sm: 6 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={debounce(
                      checkAndProceed!(() => {
                        showConvertToProposalWarning(true);
                      }),
                      DEBOUNCE_TIMEOUT
                    )}
                  >
                    Convert to Proposal
                  </Button>
                </Grid>

                {!isNewItem ? (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    order={{ xs: 7, sm: 7 }}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Button
                      fullWidth={!matchesSm}
                      variant="contained"
                      color="primary"
                      onClick={debounce(
                        checkAndProceed!(() => {
                          showNewProposalWarning(true);
                        }),
                        DEBOUNCE_TIMEOUT
                      )}
                    >
                      Promote to Proposal
                    </Button>
                  </Grid>
                ) : undefined}
              </>
            ) : undefined}
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </Container>
  );
};

import { Close as CloseIcon } from '@mui/icons-material';
import {
  Theme,
  Dialog,
  IconButton,
  DialogTitle as MuiDialogTitle,
  Breakpoint,
  DialogTitleProps,
} from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { FC } from 'react';

export interface IDialogFormProps {
  open: boolean;
  onClose: () => void;
  dialogTitle: any;
  children: any;
  maxWidth?: Breakpoint;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface IDialogTitleProps extends DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle component={'h6'} className={classes?.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes?.closeButton}
          onClick={debounce(onClose, DEBOUNCE_TIMEOUT)}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogForm: FC<IDialogFormProps> = ({
  children,
  open,
  onClose,
  dialogTitle,
  maxWidth,
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={maxWidth || 'lg'}
      PaperProps={{
        style: {
          borderRadius: 20,
          margin: '14px',
          width: '100%',
          paddingBottom: '28px',
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {dialogTitle}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

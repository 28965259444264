import React, { FC, useCallback, useMemo } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { uniqueId, startCase, toLower } from 'lodash';

import { EventCommentLogProps } from './interfaces';

import { IEvent } from 'template/Proposal/ProposalContext';
import { ProcessCallSource, ProposalEventType } from 'constants/enums';
import { UserInitials } from 'components';
import { documentStatusColor } from 'utils/colors';
import { formatTime } from 'utils/time';
import s from './style.module.scss';
import { usePayloadConvert } from './hook/payloadConvertHook';
import LoadingOverlay from 'react-loading-overlay-ts';
import { capitalizeAllWordsFirstLetterUS } from 'utils/formats';
import paths from 'constants/paths';
import { currencyFormatUS } from 'utils/currencyFormat';

const changeReportStatusName = (status: string): string => {
  return status
    .replace(/Archived/gi, 'Retired')
    .replace(/Archive/gi, 'Retire')
    .replace(/Report/gi, 'Document')
    .replace(/Party/gi, 'Other Party');
};

const formatCategoryName = (name?: string) =>
  name ? capitalizeAllWordsFirstLetterUS(name.toLowerCase().replaceAll('_', ' ')) : undefined;

export const EventCommentLog: FC<EventCommentLogProps> = ({
  events = [],
  documentArchived,
}: EventCommentLogProps) => {
  const { payloadConvert, loading: convertDataLoading } = usePayloadConvert({ events });

  const commentByEvent = useCallback((val: IEvent): JSX.Element => {
    let text;
    if (val.payload) {
      try {
        text = JSON.parse(val.payload).comment;
      } catch {
        console.log('Payload not in JSON');
      }
    }
    return (
      <>
        {text ? (
          <div
            className={s.eventLogItem}
            style={{
              border: '1px solid #ccc',
              padding: '11px 10px',
              borderRadius: '3px',
              width: '85%',
            }}
          >
            {text}
          </div>
        ) : undefined}
      </>
    );
  }, []);

  const proposalDocumentsInfoByEvent = useCallback((val: IEvent): JSX.Element => {
    const payload = val.payload ? JSON.parse(val.payload) : {};
    const { proposalDocuments } = payload;
    if (!proposalDocuments?.length) {
      return <></>;
    }
    return (
      <div style={{ padding: '5px 0px' }}>
        {proposalDocuments.map((documentInfo: any) => {
          const {
            // id,
            proposalFileType,
            proposalFileGroup,
            version,
            originalFilename,
            // blobContainer,
            // blobName
          } = documentInfo;
          return (
            <div>
              Filename: {originalFilename}, v1.{version} ({formatCategoryName(proposalFileType)}{' '}
              from {formatCategoryName(proposalFileGroup)}s)
            </div>
          );
        })}
      </div>
    );
  }, []);

  const proposalDocumentInfoByEvent = useCallback((val: IEvent): JSX.Element => {
    const payload = val.payload ? JSON.parse(val.payload) : {};
    const { proposalDocument } = payload;
    if (!proposalDocument.id) {
      return <></>;
    }
    const {
      id,
      proposalFileType,
      proposalFileGroup,
      version,
      originalFilename,
      // blobContainer,
      // blobName
    } = proposalDocument;

    return (
      <div style={{ padding: '5px 0px' }}>
        <div>
          Document #{id}: {originalFilename}
          {version ? ', v1.' + version : undefined} ({formatCategoryName(proposalFileType)} from{' '}
          {formatCategoryName(proposalFileGroup)}s)
        </div>
      </div>
    );
  }, []);

  const eventType = useCallback(
    (val: IEvent): JSX.Element => (
      <span style={{ color: documentStatusColor(val) }}>
        {changeReportStatusName(startCase(toLower(val?.eventType)))}
      </span>
    ),
    []
  );

  // const content = (val: IEvent): JSX.Element => {
  //   return <div>{val.payload}</div>;
  // };

  const proposalCreated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const { referenceOpportunityId } = payload;

      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {commentByEvent(val)}
          {referenceOpportunityId && (
            <div>
              Opportunity Reference:{' '}
              <a href={paths.client.OPPORTUNITY_DETAILS.replace(':id', referenceOpportunityId)}>
                #{referenceOpportunityId}
              </a>
            </div>
          )}
        </div>
      );
    },
    [commentByEvent, eventType]
  );

  const proposalUpdated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvert(payload);

      return (
        <div>
          <div>Proposal Information Updated</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const proposalCompetitorsChange = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      const parsedPayload = payloadConvert(payload);
      return (
        <div>
          <div>Proposal Competitors Change</div>
          <div style={{ width: '85%', display: parsedPayload?.length ? 'block' : 'none' }}>
            {parsedPayload.map((item) => {
              return (
                <div key={item.id} style={{ wordBreak: 'keep-all' }}>
                  {item.title}: {item.value}
                </div>
              );
            })}
          </div>
          <div
            style={{
              width: '85%',
              display: parsedPayload?.length ? 'none' : 'block',
              opacity: '.6',
            }}
          >
            no recorded changes
          </div>
        </div>
      );
    },
    [payloadConvert]
  );

  const proposalBondChange = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};

      return (
        <div>
          <div>{eventType(val)}</div>
          <div style={{ width: '85%', display: 'block', paddingLeft: '10px' }}>
            {[
              ...(payload.bondsToAdd
                ? payload.bondsToAdd?.map((bond: any, index: number) => (
                    <div key={index} style={{ wordBreak: 'keep-all' }}>
                      <div style={{ color: 'blue' }}>Bond added:</div>
                      <div style={{ display: 'block', paddingLeft: '10px' }}>
                        {Object.keys(bond)
                          .filter((key) => !!bond[key])
                          .map((key: string, index: number) => {
                            return (
                              <div key={index}>{`${capitalizeAllWordsFirstLetterUS(
                                key.split(/(?<=[a-z])(?=[A-Z])/).join(' ')
                              )}: ${bond[key]}`}</div>
                            );
                          })}
                      </div>
                    </div>
                  ))
                : []),
            ]}
            {[
              ...(payload.bondsToModify
                ? payload.bondsToModify?.map((bond: any, index: number) => (
                    <div key={index} style={{ wordBreak: 'keep-all' }}>
                      <div style={{ color: 'blue' }}>Bond modified:</div>
                      <div style={{ display: 'block', paddingLeft: '10px' }}>
                        {Object.keys(bond)
                          .filter((key) => !!bond[key])
                          .map((key: string, index: number) => {
                            return (
                              <div key={index}>{`${capitalizeAllWordsFirstLetterUS(
                                key.split(/(?<=[a-z])(?=[A-Z])/).join(' ')
                              )}: ${bond[key]}`}</div>
                            );
                          })}
                      </div>
                    </div>
                  ))
                : []),
            ]}
            {[
              ...(payload.bondsToRemove
                ? payload.bondsToRemove?.map((bond: any, index: number) => (
                    <div key={index} style={{ wordBreak: 'keep-all' }}>
                      <div style={{ color: 'blue' }}>Bond removed:</div>
                      <div style={{ display: 'block', paddingLeft: '10px' }}>
                        {Object.keys(bond)
                          .filter((key) => !!bond[key])
                          .map((key: string, index: number) => {
                            return (
                              <div key={index}>{`${capitalizeAllWordsFirstLetterUS(
                                key.split(/(?<=[a-z])(?=[A-Z])/).join(' ')
                              )}: ${bond[key]}`}</div>
                            );
                          })}
                      </div>
                    </div>
                  ))
                : []),
            ]}
          </div>
        </div>
      );
    },
    [eventType]
  );

  const showKey = useCallback((val: any, name: string) => {
    return val ? (
      <>
        <br />
        {name}: {capitalizeAllWordsFirstLetterUS(val.toLowerCase().replaceAll('_', ' '))}
      </>
    ) : undefined;
  }, []);

  const proposalMonetaryBreakdownChange = useCallback((val: IEvent): JSX.Element => {
    const payload = val.payload ? JSON.parse(val.payload) : {};

    return (
      <div>
        <div>Proposal Monetary Breakdown Changed</div>
        <div style={{ width: '85%', display: 'block', paddingLeft: '10px' }}>
          {[
            ...(payload.monetaryBreakdownsToAdd
              ? payload.monetaryBreakdownsToAdd?.map(
                  ({ year, value }: { year: number; value: number }) => (
                    <div key={year} style={{ wordBreak: 'keep-all' }}>
                      {year}: $ {currencyFormatUS(value, 0)}
                    </div>
                  )
                )
              : []),
            ...(payload.monetaryBreakdownsToModify
              ? payload.monetaryBreakdownsToModify?.map(
                  ({ year, value }: { year: number; value: number }) => (
                    <div key={year} style={{ wordBreak: 'keep-all' }}>
                      {year}: $ {currencyFormatUS(value, 0)}
                    </div>
                  )
                )
              : []),
          ]}
          {/* {payload.monetaryBreakdownsToRemove ? (
              <div key={'removeIds'} style={{ wordBreak: 'keep-all' }}>
                {' '}
                Removed:{' '}
                {payload.monetaryBreakdownsToRemove
                  .map(
                    ({
                      monetaryTransactionBreakdownId,
                    }: {
                      monetaryTransactionBreakdownId: string;
                    }) => '#' + monetaryTransactionBreakdownId
                  )
                  .join(', ')}
              </div>
            ) : undefined} */}
          {payload.monetaryBreakdownsToRemove ? (
            <div key={'removeIds'} style={{ wordBreak: 'keep-all' }}>
              {payload.monetaryBreakdownsToRemove.length > 1
                ? payload.monetaryBreakdownsToRemove.length + ' records'
                : 'One record'}{' '}
              (
              {payload.monetaryBreakdownsToRemove
                .map(
                  ({
                    monetaryTransactionBreakdownId,
                  }: {
                    monetaryTransactionBreakdownId: string;
                  }) => '#' + monetaryTransactionBreakdownId
                )
                .join(', ')}
              ) removed
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }, []);

  const proposalBondLockChange = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};

      return (
        <>
          {eventType(val)}
          {showKey(payload.proposalBondId, 'Proposal Bond ID')}
        </>
      );
    },
    [eventType, showKey]
  );

  const proposalVersionCreated = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {showKey(payload.uploadedFile?.fileFilename, 'Filename')}
          {showKey(payload.proposalBondId, 'Proposal Bond ID')}
          {showKey(payload.fileType, 'File Type')}
          {showKey(payload.proposalFileType, 'File Type')}
          {showKey(payload.proposalFileGroup, 'File Group')}
          {/* {commentByEvent(val)} */}
          {commentByEvent(val)}
        </div>
      );
    },
    [eventType, commentByEvent, showKey]
  );

  const proposalVersionDeleted = useCallback(
    (val: IEvent): JSX.Element => {
      const payload = val.payload ? JSON.parse(val.payload) : {};
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {showKey(payload.proposalFile?.originalFilename, 'Filename')}
          {showKey(payload.proposalFile?.proposalFileType, 'File Type')}
          {showKey(payload.proposalFile?.proposalFileGroup, 'File Group')}
          {/* {commentByEvent(val)} */}
          {commentByEvent(val)}
        </div>
      );
    },
    [eventType, commentByEvent, showKey]
  );

  const proposalReviewRequested = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.subject?.user?.name || 'Unknown'}
        {proposalDocumentsInfoByEvent(val)}
      </div>
    ),
    [eventType, proposalDocumentsInfoByEvent]
  );

  const proposalReviewResent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div>
          {eventType(val)} for {val?.subject?.user?.name}
        </div>
      );
    },
    [eventType]
  );

  const proposalReviewRecalled = useCallback(
    (val: IEvent): JSX.Element => (
      <div>
        {eventType(val)} for {val?.author?.user?.name}
        {proposalDocumentsInfoByEvent(val)}
      </div>
    ),
    [eventType, proposalDocumentsInfoByEvent]
  );

  const proposalUserEvent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {proposalDocumentsInfoByEvent(val)}
          {commentByEvent(val)}
        </div>
      );
    },
    [eventType, commentByEvent, proposalDocumentsInfoByEvent]
  );

  const proposalDocumentSoftDeleteEvent = useCallback(
    (val: IEvent): JSX.Element => {
      return (
        <div className={s.eventLogItem}>
          {eventType(val)}
          {proposalDocumentInfoByEvent(val)}
          {commentByEvent(val)}
        </div>
      );
    },
    [eventType, commentByEvent, proposalDocumentInfoByEvent]
  );

  const content = useCallback(
    (val: IEvent): JSX.Element => {
      switch (val.eventType) {
        case ProposalEventType.PROPOSAL_CREATED:
          return proposalCreated(val);

        case ProposalEventType.PROPOSAL_UPDATED:
          return proposalUpdated(val);

        case ProposalEventType.PROPOSAL_FILE_DELETE:
          return proposalVersionDeleted(val);

        case ProposalEventType.PROPOSAL_FILE_CREATE:
          return proposalVersionCreated(val);

        case ProposalEventType.PROPOSAL_COMMENT:
          return commentByEvent(val);

        case ProposalEventType.PROPOSAL_REVIEW_REQUESTED:
          return proposalReviewRequested(val);

        case ProposalEventType.PROPOSAL_REVIEW_RESENT:
          return proposalReviewResent(val);

        case ProposalEventType.PROPOSAL_REVIEW_RECALLED:
          return proposalReviewRecalled(val);

        case ProposalEventType.PROPOSAL_REVIEW_APPROVED:
        case ProposalEventType.PROPOSAL_REVIEW_DISAPPROVED:
        case ProposalEventType.PROPOSAL_REVIEW_POSTPONED:
          return proposalUserEvent(val);

        case ProposalEventType.PROPOSAL_DOCUMENT_SOFT_DELETE:
          return proposalDocumentSoftDeleteEvent(val);

        case ProposalEventType.PROPOSAL_COMPETITORS_CHANGE:
          return proposalCompetitorsChange(val);

        case ProposalEventType.PROPOSAL_MONETARY_BREAKDOWN_CHANGED:
          return proposalMonetaryBreakdownChange(val);

        case ProposalEventType.PROPOSAL_BOND_CHANGED:
          return proposalBondChange(val);

        case ProposalEventType.PROPOSAL_BOND_LOCKED:
        case ProposalEventType.PROPOSAL_BOND_UNLOCKED:
          return proposalBondLockChange(val);

        default:
          return <div>{eventType(val)}</div>;
      }
    },
    [
      proposalCompetitorsChange,
      proposalCreated,
      proposalReviewRecalled,
      proposalReviewRequested,
      proposalReviewResent,
      proposalUpdated,
      proposalUserEvent,
      proposalVersionCreated,
      proposalVersionDeleted,
      proposalDocumentSoftDeleteEvent,
      proposalMonetaryBreakdownChange,
      eventType,
      commentByEvent,
      proposalBondChange,
      proposalBondLockChange,
    ]
  );

  const filterValues = useCallback((): IEvent[] => {
    const result = events;

    return result;
  }, [events]);

  const user = (data: any): string =>
    data?.author ? `${data?.author?.user?.name}` : 'Undefined Author';

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const eventsList = useMemo(() => {
    return filterValues()
      .slice()
      .sort((a: any, b: any) => +new Date(b?.createdAt) - +new Date(a?.createdAt))
      .map((data) => {
        let jsonPayload;
        if (data?.payload) {
          try {
            jsonPayload = JSON.parse(data?.payload);
          } catch {}
        }
        if (jsonPayload?.callerSource === ProcessCallSource.INTERACTIVE) {
          return (
            <Grid item xs={12} key={uniqueId()}>
              <Grid container spacing={0}>
                <Grid item xs={2} lg={1}>
                  <UserInitials {...data?.author?.user} />
                </Grid>
                <Grid item xs={10} lg={11} className="event-comment-log-header">
                  <span className="event-comment-log-header-title">
                    <strong>INTERACTIVE automatically generated &nbsp;-&nbsp;</strong>
                  </span>
                  <span className="event-comment-log-header-date">
                    {isSmall ? <br /> : undefined}
                    {formatTime(data.createdAt)}
                  </span>
                </Grid>
                <Grid item xs={2} lg={1} />
                <Grid item xs={10} lg={11}>
                  {content(data)}
                </Grid>
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} key={uniqueId()}>
              <Grid container spacing={0}>
                <Grid item xs={2} lg={1}>
                  <UserInitials {...data?.author?.user} />
                </Grid>
                <Grid item xs={10} lg={11} className="event-comment-log-header">
                  <span className="event-comment-log-header-title">
                    <strong>
                      {user(data)}
                      {/* &nbsp;-&nbsp;
                {getVersion(data)} */}
                    </strong>
                  </span>
                  <span className="event-comment-log-header-date">
                    {isSmall ? <br /> : undefined}
                    {formatTime(data.createdAt)}
                  </span>
                </Grid>
                <Grid item xs={2} lg={1} />
                <Grid item xs={10} lg={11}>
                  {content(data)}
                </Grid>
              </Grid>
            </Grid>
          );
        }
      });
  }, [content, filterValues, isSmall]);

  return (
    <LoadingOverlay
      spinner
      active={convertDataLoading && events.length}
      text="Loading your content..."
    >
      <Grid container spacing={3}>
        {eventsList}
      </Grid>
    </LoadingOverlay>
  );
};

import React, { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { IProposalsTableHookValue, useProposalsTableHook } from './proposalsTableHook';
import { defaultSelectedProposalStages, useProposalStage } from './proposalStageHook';
import paths from 'constants/paths';
import { useHistory } from 'react-router-dom';
import { useDivisions } from 'hooks/divisionsHook';
import { useProposalType } from 'hooks/proposalTypeHook';
import { useChancesOfSuccess } from 'hooks/chancesOfSuccessHook';
import { useWorkTypes } from 'hooks/workTypesHook';
import { amountRange } from 'constants/amountRange';
import { ProposalEntityType } from 'graphql/proposals/types/graphql-types';
import { useProposalReviewStatus } from 'hooks/proposalReviewStatusHook';
import { useActivity } from 'hooks/activityHook';

export interface IContextState extends IProposalsTableHookValue {
  filterOptions?: any;
  filterValues?: any;
}

export interface IContextActions {
  clearAllFilters: any;
  onProposalSelect: (id: string) => boolean;
}

const initialState: IContextState = {
  loading: false,
  proposals: [],
  filterApplied: {},
  loadPage: () => {},
  onFilterChange: () => true,
  clearAllFilters: () => {},
  pageLoadParams: { rowsPerPage: 0 },
  totalItems: 0,
  filterOptions: {},
  error: undefined,
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

const defaultFilterValues = {
  newParty: { id: 'All', name: 'All' },
  bidProposedValue: { id: 'All', name: 'All' },
  entityType: ProposalEntityType.PROPOSAL,
  stage: defaultSelectedProposalStages,
};

interface IProviderProps {
  children: any;
}

export const Provider: FC<IProviderProps> = ({ children }) => {
  const { pairs: proposalStagePairs } = useProposalStage();
  const history = useHistory();
  const { divisionPairs } = useDivisions();
  const { proposalTypePairs } = useProposalType();
  const { proposalReviewStatusPairs } = useProposalReviewStatus();
  const { items: successChancePairs } = useChancesOfSuccess({});
  const { items: activityPairs } = useActivity({});
  const { pairs: workTypePairs } = useWorkTypes();

  const {
    totalItems,
    proposals,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    error,
    clearAllFilters,
  } = useProposalsTableHook({ tableStorageKey: 'ProposalsTable' });

  const onProposalSelect = useCallback(
    (id: string) => {
      history.push(paths.client.PROPOSAL_DETAILS.replace(':id', id));
      return true;
    },
    [history]
  );

  const filterOptions = useMemo(() => {
    return {
      newParty: [
        { id: 'All', name: 'All' },
        { id: 'YTD', name: 'Added in last 12 months' },
        { id: 'Year', name: 'Added in the current year' },
        { id: 'Custom', name: '-- Custom Date Range --' },
      ],
      owningDivision: [...divisionPairs],
      stage: [...proposalStagePairs],
      proposalType: [...proposalTypePairs],
      reviewStatus: [...proposalReviewStatusPairs],
      successChance: [...successChancePairs],
      workTypes: [...workTypePairs],
      bidProposedValue: [{ id: 'All', name: 'All' }, ...amountRange],
      activity: [...activityPairs],
    };
  }, [
    proposalStagePairs,
    divisionPairs,
    proposalTypePairs,
    proposalReviewStatusPairs,
    successChancePairs,
    workTypePairs,
    activityPairs,
  ]);

  return (
    <ComponentContext.Provider
      value={{
        totalItems,
        proposals,
        loadPage,
        pageLoadParams,
        loading,
        onFilterChange,
        filterApplied,
        clearAllFilters,
        onProposalSelect,
        filterOptions,
        filterValues: pageLoadParams.filterValues || defaultFilterValues,
        error,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);

import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { useHistory } from 'react-router-dom';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';

import { paths } from '../../../../constants';

import { useComponentContext } from '../../PartyContext';
import { PartyForm } from '../PartyForm/PartyForm';
import { AlertDialog } from 'components';
import { SaveAlt } from '@mui/icons-material';
import { Print } from 'template/Print/Print';
import { PartyPrintingMeta } from '../PartyPrintingMeta/PartyPrintingMeta';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { grades } from 'hooks/partiesFuzzySearch';

export const PartyDetails = () => {
  const {
    party,
    onSubmitProcess,
    onSubmitValidate,
    onDeleteProcess,
    validationSummary,
    fuzzySearchResult,
  } = useComponentContext();

  const { checkAndProceed } = useFormChangedDialogContext();
  const history = useHistory();

  const [displayDeleteWarning, showDeleteWarning] = useState(false);
  const [partyDialogOpen, setPartyDialogOpen] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [requestPartyNameConfirm, setRequestPartyNameConfirm] = useState(false);

  const [printing, setPrinting] = useState(false);

  const { isApplicationControlled, showValidator } = party;
  const { grade } = fuzzySearchResult;

  const handleDeleteParty = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        onDeleteProcess!();
      }
      setPartyDialogOpen(() => false);
    },
    [onDeleteProcess]
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [party.id]);

  useEffect(() => {
    if (submited && showValidator && !!validationSummary?.length) {
      setAlertMessage(
        <div>
          Please make sure following fields are filled in correctly:
          <ul>
            {validationSummary.map((err) => (
              <li>{Array.isArray(err) ? err.join('. ') : err}</li>
            ))}
          </ul>
        </div>
      );
      setAlertOpen(() => true);
    }
  }, [showValidator, validationSummary, submited]);

  const handleCloseConfirmDialog = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        if (onSubmitValidate!()) {
          setPartyDialogOpen(true);
        } else {
          setSubmited(true);
        }
      }
      setRequestPartyNameConfirm(false);
    },
    [onSubmitValidate]
  );

  return (
    <Container maxWidth="lg" style={!matches ? { padding: '0px' } : undefined}>
      <AlertDialog
        title="Form validation failed"
        message={alertMessage}
        open={alertOpen}
        onClose={() => {
          setSubmited(false);
          setAlertOpen(() => false);
        }}
      />
      <ConfirmationDialog
        title="Client update"
        message={'Please confirm Client update'}
        open={partyDialogOpen}
        onClose={(confirm: boolean) => {
          if (confirm) {
            onSubmitProcess!();
            setTimeout(() => window.scrollTo({ top: 0 }), 0);
          }
          setPartyDialogOpen(() => false);
        }}
      />
      <ConfirmationDialog
        open={displayDeleteWarning}
        title="Please confirm Client deletion"
        message={'Selected Client will be deleted! The action is irreversible!'}
        onClose={handleDeleteParty}
        confirmButtonProps={{ style: { background: 'red' } }}
      />
      <ConfirmationDialog
        open={requestPartyNameConfirm}
        title={'Duplicate Warning'}
        message={'Are you sure? You will create a duplicate record'}
        onClose={handleCloseConfirmDialog}
        reverseColors
      />

      <Container maxWidth="md">
        <PartyForm></PartyForm>

        <Print
          printing={printing}
          setPrinting={setPrinting}
          PrintingMeta={PartyPrintingMeta}
          PrintingData={PartyForm}
        ></Print>
      </Container>

      <Container maxWidth="md">
        <Box mt={2} />
        <Grid container padding={0} spacing={2}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <Grid xs={12} md={4} lg={3}>
                <Typography variant="body2" className="label-title-nocase">
                  Cover Sheet:
                </Typography>
              </Grid>
              <Grid xs={11} md={4} lg={6}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1em',
                    cursor: 'pointer',
                  }}
                  onClick={debounce(() => {
                    setPrinting(true);
                  }, DEBOUNCE_TIMEOUT)}
                >
                  <SaveAlt></SaveAlt>
                  <Button variant="outlined" color="primary">
                    Download
                  </Button>
                </div>
              </Grid>
              <Grid xs={1} md={4} lg={3}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={5} />
        <Grid container justifyContent="center" spacing={3}>
          <Grid xs={12} sm={3} order={{ xs: 4, sm: 1 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={debounce(() => {
                showDeleteWarning(true);
              }, DEBOUNCE_TIMEOUT)}
              style={isApplicationControlled ? { background: 'red' } : undefined}
              disabled={!isApplicationControlled}
            >
              Delete
            </Button>
          </Grid>

          <Grid xs={12} sm={3} order={{ xs: 3, sm: 2 }}></Grid>

          <Grid xs={12} sm={3} order={{ xs: 2, sm: 3 }}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={debounce(
                checkAndProceed!(() => {
                  history.push(paths.client.CLIENT_MANAGEMENT);
                }),
                DEBOUNCE_TIMEOUT
              )}
            >
              Cancel
            </Button>
          </Grid>

          <Grid xs={12} sm={3} order={{ xs: 1, sm: 4 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={debounce(() => {
                if (grade === grades.EXACT_MATCH_FOUND) {
                  setRequestPartyNameConfirm(true);
                } else {
                  if (onSubmitValidate!()) {
                    setPartyDialogOpen(true);
                  } else {
                    setSubmited(true);
                  }
                }
              }, DEBOUNCE_TIMEOUT)}
              // disabled={!isApplicationControlled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import React, { FC, RefObject } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, ButtonProps as MuiButtonProps } from '@mui/material';

export interface IChartingPrint extends MuiButtonProps {
  componentRef: RefObject<HTMLElement>;
  title: string;
}

export const ChartingPrint: FC<IChartingPrint> = ({ componentRef, title, ...props }) => {
  // it will print the bar chart
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      size="medium"
      onClick={debounce((): void => {
        handlePrint();
      }, DEBOUNCE_TIMEOUT)}
      {...props}
    >
      {title}
    </Button>
  );
};
